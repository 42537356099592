import { Box } from '@mui/material';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Costs from './components/costs/Costs';
import Header from './components/header/Header';
import Incentives from './components/incentives/Incentives';
import Specs from './components/specs/Specs';
import Tabs from '../../components/tabs/Tabs';
import ChargingStationsMap from '../../components/maps/components/chargingStationsMap/ChargingStationsMap';
import DealersMap from '../../components/maps/components/dealersMap/DealersMap';
import TravelRadiusMap from '../../components/maps/components/TravelRadiusMap/TravelRadiusMap';
import TravelRouteMap from '../../components/maps/components/TravelRouteMap/TravelRouteMap';
import { useMemo, useState } from 'react';
import smoothScroll from '../../utils/smoothScroll';
import { useIntl } from 'react-intl';
import useElectricVehicles from '../../api/hooks/useElectricVehicles';
import Page from '../../components/page/Page';

export default function UsedVehicleDetails(): JSX.Element | null {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [activeMap, setActiveMap] = useState<number>();
  const match = useRouteMatch<{ id: string; year?: string }>();
  const { usedElectricVehicles, status: evStatus } = useElectricVehicles();

  // get all vehicles that match modelId, only difference is year
  const vehicles = useMemo(() => {
    return usedElectricVehicles
      ? usedElectricVehicles
          .filter((vehicle) => vehicle.modelId === match.params.id)
          .sort((ev1, ev2) => ev2.modelYear - ev1.modelYear)
      : [];
  }, [usedElectricVehicles, match.params.id]);

  const vehicle = useMemo(() => {
    return vehicles.find((v) => String(v.modelYear) === match.params.year) || vehicles[0] || null;
  }, [vehicles, match.params.year]);

  if (evStatus === 'error') {
    return <Box p="4rem">{formatMessage({ id: 'vehicleDetails.notFound' })}</Box>;
  } else if (evStatus === 'loading' || !vehicle) {
    return <Loading />;
  }

  const handleChangeVehicleYear = (year: number) => {
    const newVehicle = vehicles.find((v) => v.modelYear === year) || vehicles[0];
    history.replace(`/used-vehicles/${newVehicle.modelId}/${newVehicle.modelYear}`);
  };

  return (
    <Page>
      <Box width="100%">
        <Header
          vehicle={vehicle}
          vehicleYears={vehicles.map((vehicle) => vehicle.modelYear)}
          onChangeVehicleYear={handleChangeVehicleYear}
          onShowDealerMap={() => {
            setActiveMap(3);
            smoothScroll('tabbed-maps-container');
          }}
        />
        <Specs vehicle={vehicle} />
        <Costs vehicle={vehicle} />
        <Incentives vehicle={vehicle} />
        <Tabs
          ariaLabel="Map Features"
          id="tabbed-maps-container"
          activeTabIndex={activeMap}
          tabs={[
            {
              label: formatMessage({ id: 'vehicleDetails.maps.chargingStations' }),
              content: <ChargingStationsMap />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.rangeMap' }),
              content: <TravelRadiusMap showVehicleSelector={false} />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.routePlanner' }),
              content: <TravelRouteMap />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.dealerMap' }),
              content: <DealersMap oems={[vehicle.make]} />,
            },
          ]}
        />
      </Box>
    </Page>
  );
}
