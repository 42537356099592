import { Box, Typography } from '@mui/material';
import Link from '../../../../components/link/Link';
import Button from '../../../../components/button/Button';
import { useIntl } from 'react-intl';
import ProgramCard from './ProgramCard';

const Incentives = () => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Box textAlign="center">
        <Typography variant="h2" mb="0.5rem">
          {formatMessage({ id: 'evOwners.incentives.headline' })}
        </Typography>
        <Typography whiteSpace="pre-line" fontSize="1.25rem" fontWeight="300" mb="1rem">
          {formatMessage({ id: 'evOwners.incentives.details' })}
        </Typography>
      </Box>

      <Box>
        <Box
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
          gridTemplateRows={{ xs: 'repeat(2, 1fr)', md: '1fr' }}
          gap="16px"
          m="0 auto 30px auto"
          maxWidth="990px"
        >
          <ProgramCard
            heading={formatMessage({ id: 'evOwners.incentives.preOwnedEvs.heading' })}
            description={formatMessage({ id: 'evOwners.incentives.preOwnedEvs.description' })}
            title={formatMessage({ id: 'evOwners.incentives.preOwnedEvs.title' })}
            to="https://evrebates.pge.com/"
          />
          <ProgramCard
            heading={formatMessage({ id: 'evOwners.incentives.homeCharging.heading' })}
            description={formatMessage({ id: 'evOwners.incentives.homeCharging.description' })}
            title={formatMessage({ id: 'evOwners.incentives.homeCharging.title' })}
            to="https://www.pge.com/en/clean-energy/electric-vehicles/empower-ev-program.html"
          />
          <ProgramCard
            heading={formatMessage({ id: 'evOwners.incentives.residentialChargingRebate.heading' })}
            description={formatMessage({
              id: 'evOwners.incentives.residentialChargingRebate.description',
            })}
            title={formatMessage({ id: 'evOwners.incentives.residentialChargingRebate.title' })}
            to="https://www.pge.com/en/clean-energy/electric-vehicles/getting-started-with-electric-vehicles/residential-charging-solutions-rebate.html"
          />
          <ProgramCard
            heading={formatMessage({ id: 'evOwners.incentives.smallBusinesses.heading' })}
            description={formatMessage({ id: 'evOwners.incentives.smallBusinesses.description' })}
            title={formatMessage({ id: 'evOwners.incentives.smallBusinesses.title' })}
            to="https://www.pge.com/en/clean-energy/electric-vehicles/ev-charge-program/multifamily-housing-and-small-business-ev-charger-program.html"
          />
        </Box>
      </Box>

      <Box textAlign="center">
        <Link to="/incentives">
          <Button>{formatMessage({ id: 'evOwners.incentives.personalizeButton' })}</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Incentives;
