import { VehicleIncentive } from '../../../types/vehicleIncentive';

const prioritizeIncentives = (incentives: Array<VehicleIncentive>) => {
  return [...incentives].sort((a, b) => {
    if (a.grantor === 'PG&E' && b.grantor !== 'PG&E') {
      return -1;
    } else if (a.grantor !== 'PG&E' && b.grantor === 'PG&E') {
      return 1;
    } else {
      return 0;
    }
  });
};

export default prioritizeIncentives;
