import { ELECTRICITY_RATE_IN_DOLLARS_PER_KWH } from '../../../../data/assumptions/ASSUMPTIONS';
import type { ElectricVehicle } from '../../../../types';
import isPHEV from '../../isPHEV';

const ElectricityCost = {
  perHundredElectricMiles: function (vehicle: ElectricVehicle): number {
    const vehicleKwhPerMile = vehicle.electricEfficiency > 0 ? vehicle.electricEfficiency : 0;

    let cost = ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value * vehicleKwhPerMile;

    return isFinite(cost) ? cost : 0;
  },

  perMile: function (vehicle: ElectricVehicle, electricMilesPortionForPhev: number): number {
    const PHEVFuelSplitAdjustment =
      isPHEV(vehicle) && electricMilesPortionForPhev >= 0 ? electricMilesPortionForPhev / 100 : 1;

    const cost = (this.perHundredElectricMiles(vehicle) * PHEVFuelSplitAdjustment) / 100;

    return isFinite(cost) ? cost : 0;
  },
};

export default ElectricityCost;
