import type { ElectricVehicle } from '../../../../types';
import { useEffect, useState } from 'react';
import { sampleSize, groupBy } from 'lodash';
import { Box, Typography } from '@mui/material';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';
import useElectricVehicles from '../../../../api/hooks/useElectricVehicles';
import EvCard from '../../../homepage/components/electricVehicles/EvCard';
import Loading from '../../../../components/loading/Loading';
import CostsChartArea from '../../../homepage/components/electricVehicles/CostsChartArea';
import Card from '../../../../components/card/Card';
import { useIntl } from 'react-intl';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import useSubState from '../../../../api/hooks/useSubState';

const MAX_MSRP = 40000;

export default function ElectricVehicles(): JSX.Element {
  const { formatMessage } = useIntl();
  const { usedElectricVehicles, status } = useElectricVehicles();
  const [selectedEv, setSelectedEv] = useState(1);
  const [sampleEvs, setSampleEvs] = useState<Array<ElectricVehicle>>();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [filters, setFilters] = useSubState(userPrefs, setUserPrefs, 'vehicleFilters');

  const vehicles = usedElectricVehicles?.filter((ev) => ev.msrp <= MAX_MSRP);

  useEffect(() => {
    if (vehicles && !sampleEvs) {
      setSampleEvs(sampleSize(vehicles, 4));
    }
  }, [sampleEvs, vehicles]);

  if (status === 'loading') return <Loading />;

  if (!vehicles || vehicles.length <= 3) return <></>;

  const usedVehicleGroups = Object.values(groupBy(vehicles, (vehicle) => vehicle.modelId));

  const vehicleCount = usedVehicleGroups.length;

  const findEvsUrl = '/vehicles';
  const setIncomeQualifiedFilters = () => {
    setFilters({
      priceMax: MAX_MSRP,
      vehicleStatus: 'used',
      rangeMin: 0,
      seatsMin: 4,
      fuelType: ['BEV', 'PHEV'],
      type: 'Passenger',
    });
  };

  return (
    <Box>
      <Box mb="3rem" textAlign="center">
        <ButtonLink to={findEvsUrl} onClick={setIncomeQualifiedFilters}>
          {formatMessage({ id: 'incomeQualified.evCosts.findEvButton' })}
        </ButtonLink>
      </Box>
      <Box textAlign="center" mb="1.25rem">
        <Typography component="h2" fontSize="2rem">
          {formatMessage({ id: 'incomeQualified.evCosts.availableEvs' }, { vehicleCount })}
        </Typography>
        <Typography fontSize="1.25rem">
          {formatMessage({ id: 'incomeQualified.evCosts.compareCosts' })}
        </Typography>
      </Box>
      <Box
        display="grid"
        gap="16px"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
        gridTemplateRows={{ xs: 'repeat(2, 1fr)', sm: '1fr' }}
      >
        {sampleEvs?.map((ev, i) => (
          <EvCard
            key={ev.id}
            ev={ev}
            selected={selectedEv === i}
            onClick={() => setSelectedEv(i)}
            urlBuilder={(ev) => `/used-vehicles/${ev.modelId}/${ev.modelYear}`}
          />
        ))}
      </Box>
      {sampleEvs?.[selectedEv] && (
        <Card
          sx={{
            display: { xs: 'none', sm: 'block' },
            marginTop: '1.5rem',
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <Box
            className="arrow-up"
            sx={{
              left: selectedEv * 25 + 11 + '%',
              top: 'calc(-0.75rem - 1px)',
              position: 'absolute',
              display: 'block',
              width: '1.5rem',
              height: '1.5rem',
              backgroundColor: (theme) => theme.palette.white[100],
              borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
              borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
              transform: 'rotate(45deg)',
            }}
          />
          <CostsChartArea
            car={sampleEvs[selectedEv]}
            hasExtraPadding
            displayEvCostBreakdownOption
            forceUserPrefsPresets
          />
        </Card>
      )}
      <Box mt="2rem" textAlign="center">
        <ButtonLink to={findEvsUrl} onClick={setIncomeQualifiedFilters}>
          {formatMessage({ id: 'incomeQualified.evCosts.findEvButton' })}
        </ButtonLink>
      </Box>
    </Box>
  );
}
