import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isVehicleStatus } from '../../../types';
import useFilteredVehiclesForIncentives from '../../../api/hooks/useFilteredVehiclesForIncentives';
import Select from '../../../components/select/Select';
import { useUserPrefs } from '../../../context/UserPrefsProvider';
import { uniqBy, sortBy } from 'lodash';

export default function VehicleFilters() {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { vehicles, makes, filters, setFilters } = useFilteredVehiclesForIncentives();
  const currentVehicle = vehicles.find(
    (vehicle) => vehicle.handle === userPrefs.comparedVehicleId1,
  );

  useEffect(() => {
    if (currentVehicle && currentVehicle.make !== userPrefs.vehicleMakeFilter) {
      setUserPrefs({ vehicleMakeFilter: currentVehicle.make });
    }
    if (!currentVehicle && userPrefs.comparedVehicleId1) {
      setUserPrefs({ comparedVehicleId1: null });
    }
  }, [userPrefs.vehicleMakeFilter, userPrefs.comparedVehicleId1, currentVehicle, setUserPrefs]);

  // `vehicles` is limited to vehicles
  // from the current make, so we can
  // get all the models from that list
  const modelOptions = sortBy(
    uniqBy(
      vehicles.map((vehicle) => ({
        label: vehicle.displayName,
        value: vehicle.modelId,
      })),
      (option) => option.value,
    ),
    (option) => option.label,
  );

  // `vehicles` is limited to vehicles
  // from the current make, so we can
  // get all the years for the current
  // model from that list
  const yearOptions = sortBy(
    vehicles
      .filter((vehicle) => vehicle.modelId === currentVehicle?.modelId)
      .map((vehicle) => ({
        label: String(vehicle.modelYear),
        value: String(vehicle.modelYear),
      })),
    (option) => option.label,
  ).reverse();

  return (
    <Box display="flex" flexDirection="column" gap="1rem" width="100%">
      <Typography variant="h5">
        {formatMessage({ id: 'incentives.vehicleFilters.headline' })}
      </Typography>
      <Select
        value={filters.vehicleStatus}
        label={formatMessage({ id: 'incentives.vehicleFilters.status' })}
        onChange={(value) => isVehicleStatus(value) && setFilters({ vehicleStatus: value })}
        options={[
          { label: formatMessage({ id: 'vehicleCatalog.filterControls.new' }), value: 'new' },
          { label: formatMessage({ id: 'vehicleCatalog.filterControls.preOwned' }), value: 'used' },
          // 'Upcoming' was not included here because
          // there are few or no incentives for them
        ]}
      />
      <Select
        label={formatMessage({ id: 'incentives.vehicleFilters.make' })}
        value={userPrefs.vehicleMakeFilter || ''}
        options={makes.map((make) => ({ label: make, value: make }))}
        onChange={(value) => {
          setUserPrefs({ vehicleMakeFilter: value, comparedVehicleId1: null });
        }}
      />
      <Select
        label={formatMessage({ id: 'incentives.vehicleFilters.model' })}
        value={currentVehicle?.modelId || ''}
        options={modelOptions}
        onChange={(value) => {
          // The model and year fields are based on
          // the current value of `comparedVehicleId1`
          const vehicle = vehicles.find((vehicle) => vehicle.modelId === value);
          vehicle && setUserPrefs({ comparedVehicleId1: vehicle.handle });
        }}
      />
      {filters.vehicleStatus === 'used' && (
        <Select
          label={formatMessage({ id: 'incentives.vehicleFilters.year' })}
          value={String(currentVehicle?.modelYear || '')}
          options={yearOptions}
          onChange={(value) => {
            // The model and year fields are based on
            // the current value of `comparedVehicleId1`
            const vehicle = vehicles.find(
              (vehicle) =>
                vehicle.modelId === currentVehicle?.modelId && String(vehicle.modelYear) === value,
            );
            vehicle && setUserPrefs({ comparedVehicleId1: vehicle.handle });
          }}
        />
      )}
    </Box>
  );
}
