import eone from './e1.json';
import eelec from './eelec.json';
import ev2a from './ev2a.json';
import etouc from './etouc.json';
import etoud from './etoud.json';
import eone_territories from './e1_territories.json';
import eelec_territories from './eelec_territories.json';
import ev2a_territories from './ev2a_territories.json';
import etouc_territories from './etouc_territories.json';
import etoud_territories from './etoud_territories.json';
import { RateCalculatorInterface, RateKey } from '../../types';

const RATES: Record<RateKey, Partial<RateCalculatorInterface> | null> = {
  'E-1': eone as Partial<RateCalculatorInterface>,
  'E-ELEC': eelec as Partial<RateCalculatorInterface>,
  'EV2-A': ev2a as Partial<RateCalculatorInterface>,
  'E-TOU-B': null,
  'E-TOU-C': etouc as Partial<RateCalculatorInterface>,
  'E-TOU-D': etoud as Partial<RateCalculatorInterface>,
  'EV-B': null,
};

export default RATES;

export const TERRITORIES: Record<RateKey, any> = {
  'E-1': eone_territories,
  'EV2-A': ev2a_territories,
  'E-ELEC': eelec_territories,
  'E-TOU-B': null,
  'E-TOU-C': etouc_territories,
  'E-TOU-D': etoud_territories,
  'EV-B': null,
};
