import { Box, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import spinner from '../../assets/images/ellipsis-3s-90px/Ellipsis-3s-90px.svg';

type Props = {
  src: string;
  alt: string;
  height?: string | number;
  width?: string | number;
  sx?: SxProps<Theme>;
};

export default function Image({ src, alt, height, width, sx }: Props): JSX.Element {
  const [status, setStatus] = useState<'loading' | 'complete'>('loading');
  const [originalSrc, setOriginalSrc] = useState<string>();

  useEffect(() => {
    if (originalSrc !== src) {
      setOriginalSrc(src);
      setStatus('loading');
    }
  }, [originalSrc, src]);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      maxHeight="100%"
    >
      {src && status === 'loading' && (
        <Box
          component="img"
          src={spinner}
          alt={alt}
          sx={{
            position: 'absolute',
            width: '60px',
            maxWidth: '30%',
            ...sx,
          }}
        />
      )}
      <Box
        component="img"
        src={originalSrc}
        alt={alt}
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          ...sx,
          ...(status === 'loading' ? { opacity: 0.4 } : {}),
        }}
        height={height}
        width={width}
        onLoad={() => setStatus('complete')}
      />
    </Box>
  );
}
