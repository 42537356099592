import type { ReactNode } from 'react';
import { ButtonGroup as MuiButtonGroup, FormGroup, InputLabel, Typography } from '@mui/material';
import Button from '../button/Button';

type Option = {
  label: ReactNode;
  value: string;
};

type Props = {
  options: Array<Option>;
  value: string | undefined;
  label?: string;
  onClick: (value: string) => void;
};

export default function ButtonGroup({ options, label, value, onClick }: Props) {
  return (
    <FormGroup>
      {label && (
        <InputLabel sx={{ position: 'relative', transform: 'unset' }}>
          <Typography variant="label1">{label}</Typography>
        </InputLabel>
      )}
      <MuiButtonGroup disableElevation>
        {options.map((option) => {
          const active = option.value === value;
          return (
            <Button
              variant={active ? 'contained' : 'outlined'}
              onClick={() => onClick(option.value)}
            >
              {option.label}
            </Button>
          );
        })}
      </MuiButtonGroup>
    </FormGroup>
  );
}
