import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { useIntl } from 'react-intl';
import { InsuranceCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import {
  AVERAGE_INSURANCE_COST_ANNUALLY,
  AVERAGE_MSRP,
} from '../../../../data/assumptions/ASSUMPTIONS';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import {
  formatAsDollars,
  formatAsThousands,
  formatVehicleDisplayName,
} from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

export default function InsuranceTable({ vehicle1, vehicle2, vehicle1Tco, vehicle2Tco }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: { monthsOfOwnership },
  } = useUserPrefs();

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.description' })}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle1)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle2)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.source' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.msrp' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1.msrp)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2.msrp)}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.insurance.avgInsurancePerYear' })}
            </TableCell>
            <TableCell colSpan={2}>{AVERAGE_INSURANCE_COST_ANNUALLY.valueAsFormatted}</TableCell>
            <TableCell>
              <a href={AVERAGE_INSURANCE_COST_ANNUALLY.link}>
                {formatMessage({ id: 'compare.table.insurance.avgInsuranceCostText' })}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.insurance.insurancePerYear' })}
            </TableCell>
            <TableCell>{formatAsDollars(InsuranceCost.perYear(vehicle1))}</TableCell>
            <TableCell>{formatAsDollars(InsuranceCost.perYear(vehicle2))}</TableCell>
            <TableCell>
              {formatMessage(
                { id: 'compare.table.insurance.insurancePerYearExplainer' },
                {
                  avgMsrp: AVERAGE_MSRP.valueAsFormatted,
                },
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.insurance.yearsOfOwnership' })}
            </TableCell>
            <TableCell colSpan={2}>{formatAsThousands(monthsOfOwnership / 12)}</TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.userInput' })}</TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.insurance.totalInsuranceCost' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1Tco.insurance.total)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2Tco.insurance.total)}</TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.insurance.totalInsuranceCostExplainer' })}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
