import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import TextInput from '../textInput/TextInput';

type Props = {
  buttonText?: string;
  error?: string;
  isLoading?: boolean;
  onSubmitZipcode: (zipCode: string) => void;
  zipcode?: string;
  inline?: boolean;
};

export default function ZipcodeInputWithButton({
  buttonText,
  error,
  isLoading = false,
  onSubmitZipcode,
  zipcode,
  inline,
}: Props): JSX.Element {
  const {
    userPrefs: { zipcodeError },
  } = useUserPrefs();
  const { formatMessage } = useIntl();
  const [workingZipcode, setWorkingZipcode] = useState<string>(zipcode || '');

  // Sync the internal working zipcode to an external value,
  // most probably one from context
  useEffect(() => {
    if (zipcode) {
      setWorkingZipcode(zipcode);
    }
  }, [zipcode, setWorkingZipcode]);

  const isSubmittingAllowed = Boolean(
    workingZipcode && workingZipcode.length === 5 && /\D/.test(workingZipcode) && !isLoading,
  );

  return (
    <Box
      display="flex"
      flexDirection={inline ? { xs: 'column', sm: 'row' } : 'column'}
      alignItems={inline ? { xs: 'stretch', sm: 'flex-end' } : 'stretch'}
      gap="1rem"
    >
      <TextInput
        label={formatMessage({ id: 'common.zipInput.label' })}
        placeholder={formatMessage({ id: 'common.zipInput.placeholder' })}
        errorMessage={
          error ||
          (zipcodeError || workingZipcode.length > 5 || /\D/.test(workingZipcode)
            ? formatMessage({ id: 'common.zipInput.invalid' })
            : undefined)
        }
        startIcon={<Icon variant="pin" />}
        value={workingZipcode}
        onChange={(value) => setWorkingZipcode(value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && isSubmittingAllowed) {
            onSubmitZipcode(workingZipcode);
          }
        }}
      />
      <Button
        variant="contained"
        onClick={() => onSubmitZipcode(workingZipcode)}
        disabled={isSubmittingAllowed}
        sx={{ mt: '12px' }}
      >
        {buttonText || formatMessage({ id: 'common.zipInput.button' })}
      </Button>
    </Box>
  );
}
