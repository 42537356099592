import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatAsDollars } from '../../../../utils/formatters';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import { baseTooltipStyles } from '../../../../utils/chartStyles';

ChartJS.register(ChartDataLabels, CategoryScale, LinearScale, BarElement);

type Props = {
  carNames: string[];
  carCosts: VehicleTCO;
  equivalentGasVehicleCosts: VehicleTCO;
  displayEvCostBreakdownOption?: boolean;
  displayEvCostBreakdown?: boolean;
  displayFuelOnly?: boolean;
  onAnimationComplete?: () => void;
};

export default function Chart({
  carNames,
  carCosts,
  equivalentGasVehicleCosts,
  displayFuelOnly,
  displayEvCostBreakdownOption,
  displayEvCostBreakdown,
  onAnimationComplete,
}: Props): JSX.Element {
  let chartData;

  if (displayFuelOnly) {
    chartData = {
      labels: carNames,
      datasets: [
        {
          label: 'Electricity',
          backgroundColor: '#FBBB36',
          data: [carCosts.electricity.perMonth, equivalentGasVehicleCosts.electricity.perMonth],
        },
        {
          label: 'Gasoline',
          backgroundColor: '#333333',
          data: [carCosts.gasoline.perMonth, equivalentGasVehicleCosts.gasoline.perMonth],
        },
      ],
    };
  } else if (!displayEvCostBreakdown) {
    chartData = {
      labels: carNames,
      datasets: [
        {
          backgroundColor: ['#FBBB36', '#333333'],
          data: [
            carCosts.vehicle.total +
              carCosts.electricity.total +
              carCosts.gasoline.total +
              carCosts.maintenance.total +
              carCosts.insurance.total,
            equivalentGasVehicleCosts.vehicle.total +
              equivalentGasVehicleCosts.electricity.total +
              equivalentGasVehicleCosts.gasoline.total +
              equivalentGasVehicleCosts.maintenance.total +
              equivalentGasVehicleCosts.insurance.total,
          ],
        },
      ],
    };
  } else {
    chartData = {
      labels: carNames,
      datasets: [
        {
          label: 'Vehicle net Incentives',
          backgroundColor: '#749D25',
          data: [carCosts.vehicle.total, equivalentGasVehicleCosts.vehicle.total],
        },
        {
          label: 'Electricity',
          backgroundColor: '#FBBB36',
          data: [carCosts.electricity.total, equivalentGasVehicleCosts.electricity.total],
        },

        {
          label: 'Gasoline',
          backgroundColor: '#333333',
          data: [carCosts.gasoline.total, equivalentGasVehicleCosts.gasoline.total],
        },

        {
          label: 'Maintenance',
          backgroundColor: '#005179',
          data: [carCosts.maintenance.total, equivalentGasVehicleCosts.maintenance.total],
        },

        {
          label: 'Insurance',
          backgroundColor: '#0089C4',
          data: [carCosts.insurance.total, equivalentGasVehicleCosts.insurance.total],
        },
      ],
    };
  }

  // defaults.global.defaultFontSize = 14;

  return (
    <Bar
      aria-label="Chart comparing your EV selection to a comparable gas vehicle"
      data={chartData}
      height={212}
      options={{
        indexAxis: 'y',
        maintainAspectRatio: false,
        animation: {
          duration: displayEvCostBreakdownOption ? 0 : 1000,
          onComplete: onAnimationComplete,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        layout: {
          padding: {
            right: 75,
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let datasets = ctx.chart.data.datasets as any[];
              if (ctx.datasetIndex === datasets.length - 1) {
                let sum = 0;
                datasets.forEach((dataset) => {
                  sum += dataset.data[ctx.dataIndex];
                });
                return formatAsDollars(sum);
              } else {
                return '';
              }
            },
            align: 'end',
            anchor: 'end',
            color: '#333333',
            font: {
              weight: 700,
            },
          },
          legend: {
            display: !!displayEvCostBreakdown,
          },
          tooltip: {
            ...baseTooltipStyles,
            callbacks: {
              label: (item) =>
                displayEvCostBreakdown
                  ? `${item.dataset.label}: ${formatAsDollars(item.raw as number)}`
                  : formatAsDollars(item.raw as number),
            },
          },
        },
      }}
    />
  );
}
