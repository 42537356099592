import { useCallback, useEffect, useMemo } from 'react';
import { getValidZipcode, useUserPrefs } from '../../context/UserPrefsProvider';
import { useVehicleCache } from '../../context/VehicleCacheProvider';
import type { ApiElectricVehicle } from '../../types';
import { fetchRecords } from '../../utils/fetch';
import { transformElectricVehicle } from '../transformers/transformElectricVehicle';
import { useLanguage } from '../../utils/ReactIntlProvider';

const path = '/vehicles';
const defaultParams = {
  zipcode: '94108',
  taxFilingStatus: 'single',
  householdSize: '1',
  householdIncome: '75000',
  turnInClunker: 'true',
};

export default function useElectricVehicles() {
  const { zrLanguage } = useLanguage();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { electricVehicles, fetchVehicles, status, error } = useVehicleCache();

  const zipcode = getValidZipcode(userPrefs);

  const fetch = useCallback(
    async (params: Record<string, string>) => {
      const internalFetchVehicles = async () => {
        try {
          const data = await fetchElectricVehicles(params);
          return data.vehicles.map(transformElectricVehicle);
        } catch (e: any) {
          if (typeof e === 'object' && e.message.includes('postcode')) {
            setUserPrefs({ zipcodeError: true });
          }
          return [];
        }
      };

      fetchVehicles(internalFetchVehicles, 'electric', JSON.stringify(params));
    },
    [fetchVehicles, setUserPrefs],
  );

  useEffect(() => {
    fetch({
      include_used_vehicles: 'true',
      postcode: zipcode,
      tax_filing_type: userPrefs?.taxFilingStatus || defaultParams.taxFilingStatus,
      household_size: userPrefs?.householdSize.toString() || defaultParams.householdSize,
      turn_in_clunker: defaultParams.turnInClunker,
      household_income:
        userPrefs?.householdIncome === 0
          ? '1'
          : userPrefs?.householdIncome?.toString() || defaultParams.householdIncome,
      lang: zrLanguage,
    });
  }, [
    fetch,
    userPrefs?.householdIncome,
    userPrefs?.householdSize,
    userPrefs?.taxFilingStatus,
    zipcode,
    zrLanguage,
  ]);

  const usedElectricVehicles = useMemo(
    () => electricVehicles?.filter((vehicle) => vehicle.status === 'used'),
    [electricVehicles],
  );
  const newElectricVehicles = useMemo(
    () => electricVehicles?.filter((vehicle) => vehicle.status === 'new'),
    [electricVehicles],
  );
  const upcomingElectricVehicles = useMemo(
    () => electricVehicles?.filter((vehicle) => vehicle.status === 'upcoming'),
    [electricVehicles],
  );

  return {
    vehicles: newElectricVehicles,
    newElectricVehicles,
    usedElectricVehicles,
    upcomingElectricVehicles,
    allElectricVehicles: [
      ...(newElectricVehicles || []),
      ...(usedElectricVehicles || []),
      ...(upcomingElectricVehicles || []),
    ],
    error,
    status,
  };
}

const fetchElectricVehicles = (
  params?: Record<string, string>,
): Promise<{ vehicles: Array<ApiElectricVehicle> }> => {
  return fetchRecords(path, params);
};
