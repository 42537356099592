import {
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
} from '../../../data/assumptions/ASSUMPTIONS';
import type { ElectricVehicle, EquivalentFossilVehicle } from '../../../types';
import isBEV from '../isBEV';
import isGasVehicle from '../isGasVehicle';
import isPHEV from '../isPHEV';

function electricEmissionsInLbs(kwhPer100Mi: number, miles: number): number {
  return (ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.value * kwhPer100Mi * miles) / 1000 / 100;
}

function gasolineEmissionsInLbs(mpg: number, miles: number): number {
  return (GASOLINE_EMISSIONS_IN_LBS_PER_GAL.value * miles) / mpg;
}

function emissionsInLbs(
  vehicle: ElectricVehicle | EquivalentFossilVehicle,
  milesDrivenAnnually: number,
  electricMilesPortionForPhev: number,
): number {
  let emissions = 0;

  if (isGasVehicle(vehicle)) {
    emissions = gasolineEmissionsInLbs(vehicle.fossilFuelEfficiency, milesDrivenAnnually);
  } else {
    if (isBEV(vehicle)) {
      emissions = electricEmissionsInLbs(vehicle.electricEfficiency, milesDrivenAnnually);
    }

    if (isPHEV(vehicle) && vehicle.fossilFuelEfficiency) {
      emissions =
        electricEmissionsInLbs(
          vehicle.electricEfficiency,
          milesDrivenAnnually * (electricMilesPortionForPhev / 100),
        ) +
        gasolineEmissionsInLbs(
          vehicle.fossilFuelEfficiency,
          milesDrivenAnnually * (1 - electricMilesPortionForPhev / 100),
        );
    }
  }
  return emissions;
}

function reductionInLbs(
  vehicle: ElectricVehicle,
  milesDrivenAnnually: number,
  electricMilesPortionForPhev: number,
): number {
  return (
    emissionsInLbs(vehicle.equivalentGasVehicle, milesDrivenAnnually, electricMilesPortionForPhev) -
    emissionsInLbs(vehicle, milesDrivenAnnually, electricMilesPortionForPhev)
  );
}

function treesPlanted(
  vehicle: ElectricVehicle,
  milesDrivenAnnually: number,
  electricMilesPortionForPhev: number,
): number {
  return (
    reductionInLbs(vehicle, milesDrivenAnnually, electricMilesPortionForPhev) /
    EMISSIONS_PER_TREE_IN_LBS_OF_CO2.value
  );
}

function gallonsUsed(
  vehicle: EquivalentFossilVehicle | ElectricVehicle,
  milesDrivenAnnually: number,
  electricMilesPortionForPhev: number,
): number {
  if (isGasVehicle(vehicle)) {
    return milesDrivenAnnually / vehicle.fossilFuelEfficiency;
  }

  if (isPHEV(vehicle) && vehicle.fossilFuelEfficiency) {
    return (
      ((1 - electricMilesPortionForPhev / 100) * milesDrivenAnnually) / vehicle.fossilFuelEfficiency
    );
  }

  // All-electric --> no gasoline used
  return 0;
}

function gallonsSaved(
  vehicle: ElectricVehicle,
  milesDrivenAnnually: number,
  electricMilesPortionForPhev: number,
): number {
  return (
    gallonsUsed(vehicle.equivalentGasVehicle, milesDrivenAnnually, electricMilesPortionForPhev) -
    gallonsUsed(vehicle, milesDrivenAnnually, electricMilesPortionForPhev)
  );
}

const calcEmissions = {
  electricEmissionsInLbs,
  gasolineEmissionsInLbs,
  emissionsInLbs,
  reductionInLbs,
  treesPlanted,
  gallonsUsed,
  gallonsSaved,
};

export default calcEmissions;
