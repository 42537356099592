import type { ElectricVehicle } from '../../../types';
import useElectricVehicles from '../../../api/hooks/useElectricVehicles';
import { useSearch } from './useSearch';

const vehicleIndexFields: Array<keyof ElectricVehicle> = [
  'fuelType',
  'make',
  'model',
  'type',
  'trim',
  'subtype',
  'drivetrain',
  'modelYear',
];

const vehicleSynonymGroups = {
  car: ['vehicle', 'auto', 'automobile'],
};

export const useVehicleSearch = (searchPhrase: string) => {
  const { vehicles: vehiclesFromApi } = useElectricVehicles();
  const { results: vehicles } = useSearch<ElectricVehicle>(searchPhrase, vehiclesFromApi, {
    indexFields: vehicleIndexFields,
    synonymGroups: vehicleSynonymGroups,
  });

  return { vehicles };
};
