import { useEffect, useState } from 'react';
import type { ApiPspsEvent, PspsEvent } from '../../types';
import { transformPspsEvent } from '../transformers/transformPspsEvent';

const fetchPspsEvents = async (): Promise<{ event: ApiPspsEvent }> => {
  // Use these URLs to test the UI code
  const urlParams = new URLSearchParams(window.location.search);

  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const isForcingMockEvent = urlParams.get('with_psps_event') === 'true';

  const url =
    !isProduction && isForcingMockEvent
      ? 'https://psps-events-with-active-event.herokuapp.com/active'
      : 'https://psps.pge-stage.d.zappyride.com/active';

  const response = await window.fetch(url);
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Error fetching PSPS events');
  }
};

export default function usePspsEvents() {
  const [pspsEvents, setPspsEvents] = useState<Array<PspsEvent>>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetch = async () => {
      try {
        setStatus('loading');
        setPspsEvents(undefined);
        const data = await fetchPspsEvents();
        // json.event will be null if there is no active event. Filtering non-truthy
        // values means we return an empty array if there are no active events.
        setPspsEvents([data.event].filter((e) => e).map(transformPspsEvent));
        setStatus('idle');
      } catch (err) {
        setStatus('error');
        setError('Could not load PSPS Events');
      }
    };
    if (!pspsEvents && status === 'idle') {
      fetch();
    }
  }, [status, pspsEvents]);

  return {
    pspsEvents,
    status,
    error,
  };
}
