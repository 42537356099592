import { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../button/Button';
import FeedbackModal from './FeedbackModal';

export default function FeedbackButton(): JSX.Element {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button fullWidth onClick={() => setOpen(true)}>
        {formatMessage({ id: 'feedback.title' })}
      </Button>
      <FeedbackModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}
