import { useState } from 'react';
import ChargingStationCard from '../chargingStationCard/ChargingStationCard';
import { ChargingStation } from '../../../../types';
import { Box } from '@mui/material';
import Button from '../../../button/Button';
import { useIntl } from 'react-intl';

type Props = {
  chargingStations?: Array<ChargingStation>;
};

export default function ChargingStationsList({ chargingStations }: Props) {
  const { formatMessage } = useIntl();

  const [show, setShow] = useState(false);

  if (!chargingStations) return <></>;

  return (
    <Box>
      <Box textAlign="center" mb="1rem">
        <Button variant="outlined" onClick={() => setShow(!show)}>
          {formatMessage({
            id: show ? 'maps.stationsMap.hideButton' : 'maps.stationsMap.showButton',
          })}
        </Button>
      </Box>
      {show && (
        <Box
          flex="1 1"
          minWidth="300px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          gap="1rem 0.75rem"
        >
          {chargingStations.map((station, i) => {
            return (
              <Box
                key={station.id}
                sx={{
                  flex: '1 1',
                  minWidth: '250px',
                  maxWidth: '320px',
                }}
              >
                <ChargingStationCard chargingStation={station} />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
