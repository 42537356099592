import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import useIncentives from '../../../api/hooks/useIncentives';
import type { Incentive } from '../../../types';
import { useSearch } from './useSearch';

const incentiveIndexFields: Array<keyof Incentive> = [
  'applicantType',
  'grantor',
  'grantorType',
  'name',
  'description',
  'supportFor',
  'type',
  'typicalAmount',
];

const incentiveSynonymGroups = {
  car: ['vehicle', 'auto', 'automobile'],
};

export const useIncentiveSearch = (searchPhrase: string) => {
  const { incentives: incentivesFromApi } = useIncentives();
  const incentivesWithMadeUpIds = useMemo(() => {
    return (
      incentivesFromApi?.allEligible.map((incentive) => {
        return { id: uniqueId(), ...incentive };
      }) || []
    );
  }, [incentivesFromApi?.allEligible]);

  const { results: incentives } = useSearch<Incentive & { id: string }>(
    searchPhrase,
    incentivesWithMadeUpIds,
    {
      indexFields: incentiveIndexFields,
      synonymGroups: incentiveSynonymGroups,
    },
  );

  return { incentives };
};
