import { ReactNode } from 'react';
import GoogleMapReact from 'google-map-react';
import MapStyles from './MapStyles';
import { Box } from '@mui/material';
import { Props as googleMapProps } from 'google-map-react';

const DEFAULT_CENTER = {
  lat: 37.7916858,
  lng: -122.397855,
};
const INITIAL_ZOOM = 12;
const K_HOVER_DISTANCE = 30;
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

type Props = {
  center?: {
    lat: number;
    lng: number;
  };
  height?: string | number;
  width?: string | number;
  mapProps?: googleMapProps;
  children?: ReactNode;
  onMapClick?: () => void;
  onLoad?: (map: google.maps.Map) => void;
};

export default function BaseGoogleMap({
  center,
  height = 600,
  width = '100%',
  mapProps = {},
  children,
  onMapClick,
  onLoad,
}: Props): JSX.Element {
  return (
    // Important! Always set the container height explicitly
    <Box height={height} width={width}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY, libraries: 'places' }}
        defaultCenter={center || DEFAULT_CENTER}
        defaultZoom={INITIAL_ZOOM}
        hoverDistance={K_HOVER_DISTANCE}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => onLoad?.(map)}
        onClick={onMapClick}
        {...mapProps}
        options={{ styles: MapStyles, ...(mapProps.options || {}) }}
      >
        {children}
      </GoogleMapReact>
    </Box>
  );
}
