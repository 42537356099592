import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../../../icon/Icon';

type Props = {
  hasSuperChargerStations?: boolean;
};

const StationItem = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
}): JSX.Element => {
  return (
    <Box display="flex" gap="1rem">
      {icon}
      <Box flex="1 1">
        <Typography fontWeight="700">{title}</Typography>
        <Typography>{description}</Typography>
      </Box>
    </Box>
  );
};

export default function MapMarkerLegend({ hasSuperChargerStations }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <StationItem
        icon={<Icon variant="chargingPublic" sx={{ width: '24px' }} />}
        title={formatMessage({ id: 'maps.availabilityMap.availabilityStationTitle' })}
        description={formatMessage({ id: 'maps.availabilityMap.availabilityStationDescription' })}
      />
      <StationItem
        icon={<Icon variant="chargingPrivate" sx={{ width: '24px' }} />}
        title={formatMessage({ id: 'maps.availabilityMap.plugOnlyStationTitle' })}
        description={formatMessage({ id: 'maps.availabilityMap.plugOnlyStationDescription' })}
      />
    </Box>
  );
}
