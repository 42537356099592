import { useState } from 'react';

export default function useChartLegendTooltip(translationIds: string[]) {
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [tooltipTranslationId, setTooltipTranslationId] = useState(
    'compare.charts.tooltipVehicles',
  );
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onShowTooltip = (xPos: number, yPos: number, tooltipIndex: number) => {
    setTooltipPosition({ x: xPos || 0, y: yPos || 0 });
    setTooltipTranslationId(translationIds[tooltipIndex]);
    setIsTooltipVisible(true);
  };

  const onHideTooltip = () => {
    setIsTooltipVisible(false);
  };

  return {
    tooltipPosition,
    tooltipTranslationId,
    isTooltipVisible,
    onShowTooltip,
    onHideTooltip,
  };
}
