import { Box, Typography } from '@mui/material';
import isReactSnapping from '../../utils/predicates/isReactSnapping';

export default function RightChargingStation() {
  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Level 1 Charging Station
      </Typography>
      <p>
        Every new EV is sold with a Level 1 charging station. It can be plugged into a standard
        household 110-volt grounded wall outlet and usually requires no upgrade to your utility
        panel. A Level 1 charging station will deliver about 5 miles per hour of charge.
      </p>
      <p>
        This ready-to-go option might be right if you have a short commute, drive a plug-in hybrid
        such as the Kia Niro Plug-in Hybrid, are offered workplace charging, or if you're able to
        charge your vehicle for 8 or more hours each night.
      </p>

      <Typography variant="h5" mb={1}>
        Level 2 Charging Station
      </Typography>
      <p>
        Level 2 charging stations are four times faster than Level 1 and can provide about 25 miles
        per hour of charge. Level 2 stations require a professionally installed 240-volt outlet on a
        dedicated circuit. If you'd like one installed in your home, contact a licensed electrician
        to get an estimate and to determine if a permit is required.
      </p>
      <p>
        Level 2 might be the right choice if you drive a battery EV such as a Tesla Model 3, as
        these cars have larger batteries that require longer charging times. Drivers with longer
        commutes or who want a faster charge or a longer electric driving range should also consider
        choosing a Level 2 charging station.
      </p>
      <p>
        <a
          href="https://www.youtube.com/watch?v=dMrZLojvHC0"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check-out this video to learn more
        </a>
      </p>

      <Typography variant="h5" mb={1}>
        Charging on the go
      </Typography>
      <p>
        If your vehicle supports them, look for publicly available DC fast chargers when you're on
        the road. These high-power stations can charge a battery to 80 percent of capacity in 30
        minutes or less. Check with your manufacturer for more information on DC fast charging for
        your EV.
      </p>

      <Typography variant="h5" mb={1}>
        Finding the right EV charging station for you
      </Typography>
      {!isReactSnapping() && (
        <iframe
          src="https://players.brightcove.net/1688216792001/NyJ21yKYe_default/index.html?videoId=6222022309001"
          title="Public Charging"
          allowFullScreen
        />
      )}
      <p>
        <small>
          <a
            href="https://www.pge.com/en_US/residential/solar-and-vehicles/options/clean-vehicles/electric/charger-options/electric-vehicles-charging-pge.page"
            target="_blank"
            rel="noopener noreferrer"
          >
            See the PG&amp;E site for ADA-compliant resources for this video such as an audio
            descriptive version and a transcript.
          </a>
        </small>
      </p>
    </Box>
  );
}
