import { createContext, useCallback, useContext, useState } from 'react';
import { ElectricVehicle, GasVehicle } from '../types';

type VehicleTypeType = 'electric' | 'gas';

type VehicleCacheContextType = {
  electricVehicles: Array<ElectricVehicle> | undefined;
  gasVehicles: Array<GasVehicle> | undefined;
  status: 'idle' | 'loading' | 'error';
  error: string | undefined;
  fetchVehicles: (
    fetchFunction: () => Promise<any>,
    vehicleType: VehicleTypeType,
    cacheKey?: string,
  ) => void;
};

const VehicleCacheContext = createContext<VehicleCacheContextType>({
  electricVehicles: undefined,
  gasVehicles: undefined,
  status: 'loading',
  error: undefined,
  fetchVehicles: () => {},
});

type CacheKeysType = Record<VehicleTypeType, string | undefined>;

export default function VehicleCacheProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string>();
  const [gasVehicles, setGasVehicles] = useState<Array<GasVehicle>>();
  const [electricVehicles, setElectricVehicles] = useState<Array<ElectricVehicle>>();
  const [cacheKeys, setCacheKeys] = useState<CacheKeysType>({
    electric: undefined,
    gas: undefined,
  });

  const setVehicles = (
    vehicleType: VehicleTypeType,
    vehicles: Array<ElectricVehicle> | Array<GasVehicle> | undefined,
  ) => {
    if (vehicleType === 'electric') {
      setElectricVehicles(vehicles as Array<ElectricVehicle> | undefined);
    } else {
      setGasVehicles(vehicles as Array<GasVehicle> | undefined);
    }
  };

  const fetchVehicles = useCallback(
    async (
      fetchFunction: () => Promise<any>,
      vehicleType: VehicleTypeType,
      cacheKey: string = '',
    ) => {
      if (!error && cacheKeys[vehicleType] === cacheKey) {
        return;
      }

      setStatus('loading');

      try {
        setVehicles(vehicleType, await fetchFunction());
        setStatus('idle');
        setError(undefined);
        setCacheKeys((currentCacheKey) => ({
          ...currentCacheKey,
          [vehicleType]: cacheKey,
        }));
      } catch (err) {
        setVehicles(vehicleType, undefined);
        setStatus('error');
        setError('Could not load vehicles');
      }
    },
    [cacheKeys, error],
  );

  return (
    <VehicleCacheContext.Provider
      value={{
        status,
        error,
        gasVehicles,
        electricVehicles,
        fetchVehicles,
      }}
    >
      {children}
    </VehicleCacheContext.Provider>
  );
}

export function useVehicleCache() {
  return useContext(VehicleCacheContext);
}
