import { Box } from '@mui/material';

export default function Level2Purchase() {
  return (
    <Box>
      <p>
        On average, the cost of a Level 2 charging station ranges from $500 - $700. A charger may
        cost more or less depending on key features such as portability, amperage, and WiFi
        capability.
      </p>
      <iframe
        src="https://players.brightcove.net/1688216792001/NyJ21yKYe_default/index.html?videoId=6222024822001"
        title="Installing Home Charging"
        allowFullScreen
      />
      <p>
        <small>
          <a
            href="https://www.pge.com/en_US/residential/solar-and-vehicles/options/clean-vehicles/electric/charger-options/electric-vehicles-charging-pge.page"
            target="_blank"
            rel="noopener noreferrer"
          >
            See the PG&amp;E site for ADA-compliant resources for this video such as an audio
            descriptive version and a transcript.
          </a>
        </small>
      </p>
    </Box>
  );
}
