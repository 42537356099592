import { ApiChargingStation, ChargingStation } from '../../types';

export const transformChargingStation = (apiStation: ApiChargingStation): ChargingStation => {
  return {
    id: apiStation.id,
    lat: apiStation.latitude,
    lng: apiStation.longitude,
    evDcFastNum: apiStation.ev_dc_fast_num,
    accessCode: apiStation.access_code,
    stationName: apiStation.station_name,
    streetAddress: apiStation.street_address,
    city: apiStation.city,
    state: apiStation.state,
    zip: apiStation.zip,
    stationPhone: apiStation.station_phone,
    evNetwork: apiStation.ev_network,
    evNetworkWeb: apiStation.ev_network_web,
  };
};
