import type { Charger, Vehicle } from '../types';

export const formatAsThousands = (val: number) =>
  Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatAsDollars = (val: number) => `$${formatAsThousands(val)}`;

export const formatAsCents = (val: number) => `$${val.toFixed(2)}`;

export const formatHours = (hours24: number) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? 'pm' : 'am';
  return hours + amPm;
};

export const titleCase = (string: string) => {
  if (string === null || string === '') return '';
  const str = string.toString();

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatVehicleName = ({ make, model, type, subtype }: Vehicle) => {
  return make ? `${make} ${model}` : titleCase(`${type} (${subtype})`).replaceAll('_', ' ');
};

export const formatVehicleDisplayName = (vehicle: Vehicle) =>
  `${vehicle.displayName.startsWith(vehicle.make) ? '' : `${vehicle.make} `}${vehicle.displayName}`;

export const formatVehicleSetTitle = (vehicleCount: number, vehicle: Vehicle) =>
  `${vehicleCount}x ${formatVehicleName(vehicle)}`;

export const formatChargerName = ({ make, model, modelVariant }: Charger) =>
  `${make} ${model}${modelVariant ? ` (${modelVariant})` : ''}`;

export const formatGenericChargerName = (type: string, portKw: string | number) =>
  `${type} - ${portKw}kW`;

/**
 * Renders a time given in hours
 * as a string in the format of
 * 'X hr Y min'.
 * Returns blank for bad values.
 */
export const formatAsTime = (time: number): string => {
  const hh = Math.floor(time);
  const mm = Math.round((time - hh) * 12) * 5;
  const renderHours = hh === 0 || isNaN(hh) || hh === Infinity ? '' : hh + ' hr';
  const renderMinutes = mm === 0 || isNaN(mm) || mm === Infinity ? '' : ' ' + mm + ' min';
  return renderHours + renderMinutes;
};

export const formatAsPercent = (val: number) => {
  return val.toFixed(2) + '%';
};

export const formatAsPercentRounded = (val: number) => {
  return val.toFixed(0) + '%';
};

export const formatAsDollarsPerGal = (val: number) => {
  return '$' + val.toFixed(2).toString() + '/gal';
};

export const formatAsCentsDetailed = (val: number) => {
  return '$' + val.toFixed(4).toString();
};
