import { Box } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dealer, OemImage } from '../../../../types';
import Button from '../../../button/Button';
import DealerCard from '../dealerCard/DealerCard';

const findOemImage = (images: Array<OemImage>, dealer: Dealer) => {
  return images.find((image) => image.name.toUpperCase() === dealer.oem.toUpperCase());
};

type Props = {
  dealers: Array<Dealer>;
  oemImages: Array<OemImage>;
};

export default function DealersList({ dealers, oemImages }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const [show, setShow] = useState<boolean>(false);

  if (dealers.length === 0) return <></>;

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Box textAlign="center">
        <Button variant="outlined" onClick={() => setShow(!show)}>
          {formatMessage({
            id: show ? 'maps.dealerMap.hideButton' : 'maps.dealerMap.showButton',
          })}
        </Button>
      </Box>
      {show && (
        <Box display="flex" gap="1rem" flexWrap="wrap" justifyContent="center">
          {dealers.map((dealer, i) => {
            return (
              <Box key={dealer.handle} flex="1 1" sx={{ minWidth: '240px', maxWidth: '300px' }}>
                <DealerCard {...dealer} oemImage={findOemImage(oemImages, dealer)} />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
