import {
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
  MAINTENANCE_COST_SCALING_FACTOR,
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  AVERAGE_MSRP,
} from '../../../../data/assumptions/ASSUMPTIONS';
import type { Vehicle } from '../../../../types';
import isBEV from '../../isBEV';
import isPHEV from '../../isPHEV';

const MaintenanceCost = {
  maintenanceCostPerGasMile: function (vehicle: Vehicle): number {
    return (
      AVERAGE_MAINTENANCE_COST_PER_MILE.value *
      (1 + MAINTENANCE_COST_SCALING_FACTOR.value * (vehicle.msrp / AVERAGE_MSRP.value - 1))
    );
  },

  maintenanceCostPerElectricMile: function (vehicle: Vehicle): number {
    return (
      this.maintenanceCostPerGasMile(vehicle) *
      (1 - ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.value)
    );
  },

  perMile: function (vehicle: Vehicle, electricMilesPortionForPhev: number): number {
    let cost;
    if (isBEV(vehicle)) {
      cost = this.maintenanceCostPerElectricMile(vehicle);
    } else if (isPHEV(vehicle)) {
      cost =
        (electricMilesPortionForPhev / 100) * this.maintenanceCostPerElectricMile(vehicle) +
        (1 - electricMilesPortionForPhev / 100) * this.maintenanceCostPerGasMile(vehicle);
    } else {
      cost = this.maintenanceCostPerGasMile(vehicle); // ICE cost
    }

    return isFinite(cost) ? cost : 0;
  },
};

export default MaintenanceCost;
