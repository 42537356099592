import { useState } from 'react';
import Uuid from '../../utils/Uuid/Uuid';

const UUID_KEY = 'uuid';

const getUuid = () => {
  let uuid = localStorage.getItem(UUID_KEY);
  if (uuid) return uuid;

  uuid = Uuid();
  localStorage.setItem(UUID_KEY, uuid);
  return uuid;
};

export const useUuid = () => {
  const [uuid] = useState(getUuid());

  return { uuid };
};
