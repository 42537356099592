import { useState } from 'react';
import { ChargingStation } from '../../../types';

const getStation = (
  chargingStations: Array<ChargingStation>,
  id?: string,
): ChargingStation | null =>
  (id && (chargingStations || []).find((station) => station.id === id)) || null;

const useSelectedStation = (
  chargingStations: Array<ChargingStation>,
  initialStationId?: string,
) => {
  const [selectedStation, setSelectedStation] = useState<ChargingStation | null>(
    getStation(chargingStations, initialStationId),
  );
  const selectStation = (stationId: string) => {
    setSelectedStation(getStation(chargingStations, stationId));
  };

  const deselectStation = () => setSelectedStation(null);

  return {
    selectedStation,
    selectStation,
    deselectStation,
  };
};

export default useSelectedStation;
