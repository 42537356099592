import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { ChargingStation } from '../../../../types';
import Card from '../../../card/Card';

type Props = {
  chargingStation: ChargingStation;
};

export default function ChargingStationCard({
  chargingStation: {
    streetAddress,
    city,
    state,
    zip,
    stationName,
    accessCode,
    evNetwork,
    evNetworkWeb,
  },
}: Props) {
  const { formatMessage } = useIntl();

  const address = `${streetAddress}, ${city}, ${state} ${zip}`;
  return (
    <Card sx={{ height: '100%' }}>
      <Box>
        <b>{stationName}</b> {address}
        &nbsp;
        <span>{accessCode}</span>
      </Box>
      <Box>
        {evNetwork && evNetworkWeb && (
          <a href={evNetworkWeb} target="_blank" rel="noopener noreferrer">
            {evNetwork}
          </a>
        )}
        {(!evNetwork || !evNetworkWeb) && (
          <em>{formatMessage({ id: 'maps.chargingStationNetworkUnavailable' })}</em>
        )}
      </Box>
    </Card>
  );
}
