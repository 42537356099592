import { Alert, Box, Grid, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonLink from '../../components/buttonLink/ButtonLink';
import Card from '../../components/card/Card';
import Select from '../../components/select/Select';
import Slider from '../../components/slider/Slider';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { ElectricVehicle } from '../../types';
import { formatAsCents } from '../../utils/formatters';

type Props = {
  selectedVehicle: ElectricVehicle;
  vehicles: ElectricVehicle[];
  onVehicleChange: (vehicleId: string) => void;
};

const makeVehicleMakeOptions = (vehicles: ElectricVehicle[], currentVehicle: ElectricVehicle) => {
  const makes = uniq(vehicles.map((vehicle) => vehicle.make));
  return makes.sort().map((make) => ({
    label: make,
    value: make,
  }));
};

const makeVehicleOptions = (vehicles: ElectricVehicle[], currentVehicle: ElectricVehicle) => {
  return vehicles
    .filter((vehicle) => {
      return vehicle.make === currentVehicle.make;
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((vehicle) => ({
      label: vehicle.displayName,
      value: vehicle.handle,
    }));
};

export default function EstimatedChargingCost({
  selectedVehicle,
  vehicles,
  onVehicleChange,
}: Props) {
  const {
    userPrefs: { publicChargingCostInCentsPerKwh },
  } = useUserPrefs();
  const { formatMessage } = useIntl();

  const [centsPerKwh, setCentsPerKwh] = useState(publicChargingCostInCentsPerKwh);

  const handleVehicleMakeChange = (vehicleMake: string) => {
    const firstVehicleOfMake = vehicles.find(({ make }) => make === vehicleMake) || vehicles[0];
    onVehicleChange(firstVehicleOfMake.handle);
  };

  const chargeToFullCost = (centsPerKwh * selectedVehicle.batteryCapacity) / 100;

  return (
    <Card sx={{ mt: 3 }}>
      <Typography variant="h5" mb={2} fontWeight="bold">
        {formatMessage({ id: 'chargerDetails.estimatedChargingCost.headline' })}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Box display="flex" flexDirection="column">
            <Select
              label={formatMessage({ id: 'chargerDetails.estimatedChargingCost.makeLabel' })}
              value={selectedVehicle.make}
              options={makeVehicleMakeOptions(vehicles, selectedVehicle)}
              onChange={handleVehicleMakeChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box display="flex" flexDirection="column">
            <Select
              label={formatMessage({ id: 'chargerDetails.estimatedChargingCost.modelLabel' })}
              value={selectedVehicle.handle}
              options={makeVehicleOptions(vehicles, selectedVehicle)}
              onChange={onVehicleChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <ButtonLink
            variant="outlined"
            size="medium"
            sx={{ mt: '24px', minHeight: '40px', py: 0 }}
            to={`/vehicles/${selectedVehicle.handle}`}
          >
            {formatMessage({ id: 'chargerDetails.estimatedChargingCost.vehicleLink' })}
          </ButtonLink>
        </Grid>
        <Grid item xs={12} md={3}>
          <Slider
            value={centsPerKwh}
            label={formatMessage({ id: 'chargerDetails.estimatedChargingCost.costPerKwhLabel' })}
            min={0}
            max={100}
            step={1}
            onChange={setCentsPerKwh}
            valueFormatter={(value) => `$${(value / 100).toFixed(2)}`}
            valueVariant="small"
          />
        </Grid>
        <Grid item xs={12} md={3} display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'space-between' }}
            borderLeft={{ xs: 'none', md: '1px solid' }}
            borderColor={{ md: 'grey.200' }}
            pl={{ xs: 2, lg: 3 }}
            width="100%"
          >
            <Typography fontWeight="500" mr={2}>
              {formatMessage({ id: 'chargerDetails.estimatedChargingCost.result' })}
            </Typography>
            <Typography variant="h1" component="span">
              {formatAsCents(chargeToFullCost)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Alert severity="info">
          {formatMessage({ id: 'chargerDetails.estimatedChargingCost.disclaimer' })}
        </Alert>
      </Box>
    </Card>
  );
}
