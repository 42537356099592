import type { ApiVehicleIncentive, VehicleIncentive } from '../../types/vehicleIncentive';

export const transformVehicleIncentive = (
  apiVehicleIncentive: ApiVehicleIncentive,
): VehicleIncentive => {
  return {
    grantor: apiVehicleIncentive.grantor,
    grantorType: apiVehicleIncentive.grantor_type,
    applicantType: apiVehicleIncentive.applicant_type,
    name: apiVehicleIncentive.name,
    description: apiVehicleIncentive.description,
    type: apiVehicleIncentive.type,
    supportFor: apiVehicleIncentive.support_for,
    typicalAmount: apiVehicleIncentive.typical_amount,
    limitations: apiVehicleIncentive.limitations,
    detailsUrl: apiVehicleIncentive.details_url,
    incomeDependent: apiVehicleIncentive.income_dependent,
    applicableToNewVehicles: apiVehicleIncentive.applicable_to_new_vehicles,
    applicableToUsedVehicles: apiVehicleIncentive.applicable_to_used_vehicles,
    applicableToFcevs: apiVehicleIncentive.applicable_to_fcevs,
    available: apiVehicleIncentive.available,
    evaluation: {
      applicableToLease: apiVehicleIncentive.evaluation.applicable_to_lease,
      applicableToOwnership: apiVehicleIncentive.evaluation.applicable_to_ownership,
      capitalizedInLease: apiVehicleIncentive.evaluation.capitalized_in_lease,
      eligibility: apiVehicleIncentive.evaluation.eligibility || 'ineligible',
      amountInLease: apiVehicleIncentive.evaluation.amount_in_lease,
      amountInPurchase: apiVehicleIncentive.evaluation.amount_in_purchase,
      amountInLeasePrecision: apiVehicleIncentive.evaluation.amount_in_lease_precision,
      amountInPurchasePrecision: apiVehicleIncentive.evaluation.amount_in_purchase_precision,
      leaseCalculationRationale: apiVehicleIncentive.evaluation.lease_calculation_rationale,
      purchaseCalculationRationale: apiVehicleIncentive.evaluation.purchase_calculation_rationale,
    },
  };
};
