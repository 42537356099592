import { Box, Typography } from '@mui/material';
import type { ElectricVehicle } from '../../../../types';
import FuelTypeIcon from '../../../../components/fuelTypeIcon/FuelTypeIcon';
import Image from '../../../../components/image/Image';
import Card from '../../../../components/card/Card';
import { formatAsDollars, formatVehicleDisplayName } from '../../../../utils/formatters';
import Spec from '../../../../components/spec/Spec';
import { VehicleCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import { useIntl } from 'react-intl';

type Props = {
  ev: ElectricVehicle;
  onClick?: () => void;
};

export default function VehicleCard({ ev, onClick }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const imgSrc = ev.images?.find((image) => image.urlThumbnail)?.urlThumbnail;

  return (
    <Card
      onClick={onClick}
      sx={{
        p: 0,
        borderRadius: '4px',
        height: 'fit-content',
        boxShadow: 0,
        ':hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box>
        <Box p="15px 15px 10px 15px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3" component="h4">
              {ev.make}
            </Typography>
            <FuelTypeIcon fuelType={ev.fuelType} />
          </Box>
          <Typography variant="h6" component="h6" height="50px">
            {formatVehicleDisplayName(ev)}
          </Typography>
          {imgSrc && (
            <Image
              src={imgSrc}
              alt={'Go to ' + ev.make + ' ' + ev.model + ' ' + ev.type}
              sx={{ width: '100%' }}
            />
          )}
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.evRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {formatMessage(
                  { id: 'vehicleCatalog.vehicleCard.rangeMiles' },
                  { numMiles: Math.round(ev.electricRange) },
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.totalRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {formatMessage(
                  { id: 'vehicleCatalog.vehicleCard.rangeMiles' },
                  { numMiles: Math.round(ev.totalRange) },
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.msrp' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {formatAsDollars(ev.msrp)}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          p="1rem"
          sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}` }}
        >
          <Spec
            size="sm"
            label={formatMessage({ id: 'vehicleCatalog.vehicleCard.afterIncentives' })}
          >
            {formatAsDollars(VehicleCost.afterIncentives(ev))}
          </Spec>
        </Box>
      </Box>
    </Card>
  );
}
