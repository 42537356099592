import { Box, Grid, Typography } from '@mui/material';
import calcTotalCostOfOwnership from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import CompareCta from '../CompareCta';
import FastChargingChart from './FastChargingChart';
import FilterPanel from '../FilterPanel';
import Level2ChargingChart from './Level2ChargingChart';
import MonthlyFuelComparison from '../MonthlyFuelComparison';
import PaybackPeriodChart from './PaybackPeriodChart';
import PriceAssumptions from '../assumptions/PriceAssumptions';
import RangeAssumptions from '../assumptions/RangeAssumptions';
import RangeChart from './RangeChart';
import TcoChart from './TcoChart';
import isElectricVehicle from '../../../../calculations/vehicle/isElectricVehicle';
import FastChargingAssumptions from '../assumptions/FastChargingAssumptions';
import Level2ChargingAssumptions from '../assumptions/Level2ChargingAssumptions';
import CalcTables from '../calcTables/CalcTables';
import { formatVehicleDisplayName } from '../../../../utils/formatters';
import isPHEV from '../../../../calculations/vehicle/isPHEV';
import { useIntl } from 'react-intl';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2?: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
};

const isVehicleWithRangeData = (vehicle: any): vehicle is ElectricVehicle | GasVehicle => {
  return typeof vehicle === 'object' && vehicle.hasOwnProperty('totalRange');
};

export default function CompareCharts({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: {
      purchaseMethod,
      monthsOfOwnership,
      milesDrivenAnnually,
      interestRateAsBasisPoints,
      electricMilesPortionForPhev,
      gasolinePriceInCentsPerGal,
      includeResaleValue,
    },
  } = useUserPrefs();

  if (!vehicle2) return null;

  const vehicle1Tco = calcTotalCostOfOwnership(
    vehicle1,
    purchaseMethod,
    monthsOfOwnership,
    milesDrivenAnnually,
    interestRateAsBasisPoints,
    electricMilesPortionForPhev,
    gasolinePriceInCentsPerGal,
    includeResaleValue,
  );

  const vehicle2Tco = calcTotalCostOfOwnership(
    vehicle2,
    purchaseMethod,
    monthsOfOwnership,
    milesDrivenAnnually,
    interestRateAsBasisPoints,
    electricMilesPortionForPhev,
    gasolinePriceInCentsPerGal,
    includeResaleValue,
  );

  const showChargingGraphs = isElectricVehicle(vehicle1) && isElectricVehicle(vehicle2);
  const showDcfcGraph = showChargingGraphs && vehicle1.dcChargingPower && vehicle2.dcChargingPower;

  if (!vehicle2.displayName) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      <CompareCta vehicle1={vehicle1} vehicle2={vehicle2} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box mb={3}>
            <FilterPanel isComparingPhev={isPHEV(vehicle1) || isPHEV(vehicle2)} />
          </Box>
          <Box mb={3}>
            <PriceAssumptions />
          </Box>
          <Box mb={3}>
            <Typography>
              {formatMessage(
                { id: 'compare.assumptions.equivalentVehicleDisclaimer' },
                {
                  vehicle2: formatVehicleDisplayName(vehicle2),
                  vehicle1: formatVehicleDisplayName(vehicle1),
                },
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box mb={3}>
            <MonthlyFuelComparison
              vehicle1={vehicle1}
              vehicle2={vehicle2}
              vehicle1Tco={vehicle1Tco}
              vehicle2Tco={vehicle2Tco}
            />
          </Box>

          <Box mb={3}>
            <TcoChart
              vehicle1={vehicle1}
              vehicle2={vehicle2}
              vehicle1Tco={vehicle1Tco}
              vehicle2Tco={vehicle2Tco}
            />
          </Box>

          <Box mb={3}>
            <PaybackPeriodChart vehicle1={vehicle1} vehicle2={vehicle2} />
          </Box>
        </Grid>
      </Grid>

      {showChargingGraphs && (
        <>
          {showDcfcGraph && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box mb={3}>
                  <FastChargingAssumptions vehicle1={vehicle1} vehicle2={vehicle2} />
                </Box>
              </Grid>

              <Grid item xs={12} md={9}>
                <Box mb={3}>
                  <FastChargingChart vehicle1={vehicle1} vehicle2={vehicle2} />
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box mb={3}>
                <Level2ChargingAssumptions vehicle1={vehicle1} vehicle2={vehicle2} />
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box mb={3}>
                <Level2ChargingChart vehicle1={vehicle1} vehicle2={vehicle2} />
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {isVehicleWithRangeData(vehicle1) && isVehicleWithRangeData(vehicle2) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box mb={3}>
              <RangeAssumptions />
            </Box>
          </Grid>

          <Grid item xs={12} md={9}>
            <Box mb={3}>
              <RangeChart vehicle1={vehicle1} vehicle2={vehicle2} />
            </Box>
          </Grid>
        </Grid>
      )}

      <Box display={{ xs: 'none', md: 'block' }}>
        <CalcTables
          vehicle1={vehicle1}
          vehicle1Tco={vehicle1Tco}
          vehicle2={vehicle2}
          vehicle2Tco={vehicle2Tco}
        />
      </Box>
    </Box>
  );
}
