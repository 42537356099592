import useElectricVehicles from './useElectricVehicles';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import useSubState from './useSubState';
import filterVehicles from '../../utils/filterVehicles';

const useFilteredVehiclesForCatalog = () => {
  /**
   * First we need all the user's preferences
   * and all the vehicles you can muster.
   */
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [filters, setFilters] = useSubState(userPrefs, setUserPrefs, 'vehicleFilters');
  const [sort, setSort] = useSubState(filters, setFilters, 'sort');
  const { allElectricVehicles: vehicles, status } = useElectricVehicles();

  /**
   * Now filter the vehicles based on those
   * user preferences
   */
  const { visibleVehicles } = filterVehicles(vehicles || [], {
    type: filters.type,
    subtype: filters.subtype,
    rangeMin: filters.rangeMin,
    priceMax: filters.priceMax,
    seatsMin: filters.seatsMin,
    fuelType: filters.fuelType,
    vehicleStatus: filters.vehicleStatus,
    sort: filters.sort,
  });

  return {
    status,
    vehicles: visibleVehicles,
    sort,
    setSort,
    filters,
    setFilters,
    showCount: filters.showCount,
  };
};

export default useFilteredVehiclesForCatalog;
