import { USER_PREF_PRESETS } from '../../constants/userPrefs';
import { TERRITORY_BY_ZIPCODE } from '../../data/territory_by_zipcode';

const selectTerritory = (zipcode: string) => {
  // Resource: https://docs.google.com/spreadsheets/d/1VNlrLjNtcqFVSz1dmqMa4NGDv2dYMYoGd9j-J_ClU9c
  // Map: https://cutmybill.com/build/images/pages/electric-rate-plans/pgande_baseline-territories-6e4528cb98.jpg
  const territory = TERRITORY_BY_ZIPCODE[zipcode];

  if (territory !== undefined) return territory;

  const presetTerritory = TERRITORY_BY_ZIPCODE[USER_PREF_PRESETS.zipcode];

  if (presetTerritory !== undefined) return presetTerritory;

  console.log(
    'Neither the user input zipcode nor the preset zipcode returned a valid Baseline Territory',
  );
  return 'T';
};

export default selectTerritory;
