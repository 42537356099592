import RATES, { TERRITORIES } from '../../data/rates/rates';
import { RateKey, isRateKey } from '../../types';
import selectTerritory from './selectTerritory';
import { mergeWith, isArray } from 'lodash';

/**
 * Use this to get a rate object with territory modifications already merged in
 */
export default function selectRate(rateKey: RateKey, zipcode: string) {
  const rate = RATES[rateKey];

  if (!rate) {
    throw new Error(`No such rate: ${rateKey}`);
  }

  /**
   * If there is a territory associated with the user's preferences (zipcode in particular)
   * then we'll use it to find and merge in any elements that are specific to that territory.
   */
  const territory = selectTerritory(zipcode);

  if (territory && TERRITORIES[rateKey] && TERRITORIES[rateKey][territory]) {
    return mergeRate(rate, TERRITORIES[rateKey][territory]);
  } else {
    return rate;
  }
}

export function mergeRate<T>(rate: T, modifier: Partial<T>): T {
  return mergeWith({}, rate, modifier, (a, b) => {
    if (isArray(a)) {
      return a.concat(b ?? []);
    }
  });
}

export const RATE_KEYS = Object.keys(RATES).filter((key) => isRateKey(key) && Boolean(RATES[key]));
