import { Box, Container, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../../components/card/Card';
import { ElectricVehicle } from '../../../../types';
import CompareCharts from '../../../compare/components/charts/CompareCharts';

type Props = {
  vehicle: ElectricVehicle;
};

export default function Costs({ vehicle }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Box id="vehicle-details-compare-costs" bgcolor="grey.100" py={6}>
      <Container>
        <CompareCharts vehicle1={vehicle} vehicle2={vehicle.equivalentGasVehicle} />
        <Box display={{ xs: 'block', sm: 'none' }} mb={2}>
          <Card>
            <Typography fontWeight="bold">
              {formatMessage({ id: 'vehicleDetails.costsSmallScreenDisclaimer' })}
            </Typography>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}
