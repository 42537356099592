import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import getPaymentDetails from '../../../calculations/vehicle/getPaymentDetails';
import Link from '../../../components/link/Link';
import { useUserPrefs } from '../../../context/UserPrefsProvider';
import { ElectricVehicle, GasVehicle } from '../../../types';
import { formatAsDollars } from '../../../utils/formatters';

type Props = {
  vehicle?: ElectricVehicle | GasVehicle;
};

export default function PricePanel({ vehicle }: Props) {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const monthsOfOwnership = userPrefs.monthsOfOwnership;
  const interestRateAsBasisPoints = userPrefs.monthsOfOwnership;
  const milesDrivenAnnually = userPrefs.milesDrivenAnnually;

  const paymentDetails =
    vehicle &&
    getPaymentDetails(vehicle, monthsOfOwnership, interestRateAsBasisPoints, milesDrivenAnnually);

  const incentives = vehicle?.incentives || [];
  const incentivesQuantity = incentives.filter(
    (incentive) => incentive.evaluation.eligibility !== 'ineligible',
  ).length;

  const handleClickIncentives = () => {
    vehicle && setUserPrefs({ comparedVehicleId1: vehicle.handle });
  };

  return (
    <Box border="1px solid" borderColor="grey.200">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={2.5}
        borderBottom="1px solid"
        borderColor="grey.200"
      >
        <Typography fontWeight="bold" textTransform="uppercase" fontSize="0.75rem" color="grey.500">
          {formatMessage({ id: 'compare.pricePanel.afterIncentives' })}
        </Typography>
        <Typography fontSize="2.5rem" fontWeight="bold" color="grey.600">
          {paymentDetails ? formatAsDollars(paymentDetails.afterIncentives) : '--'}
        </Typography>
      </Box>
      <Box py={2} px={3}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="0.75rem"
              color="grey.500"
            >
              {formatMessage({ id: 'compare.pricePanel.msrp' })}
            </Typography>
            <Typography fontSize="1.75rem" fontWeight="bold" color="grey.600">
              {paymentDetails ? formatAsDollars(paymentDetails.msrp) : '--'}
            </Typography>
          </Box>
          <Box>
            <Typography
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="0.75rem"
              color="grey.500"
            >
              {formatMessage({ id: 'compare.pricePanel.estimatedIncentives' })}
            </Typography>
            <Typography fontSize="1.75rem" fontWeight="bold" color="grey.600">
              {paymentDetails ? formatAsDollars(paymentDetails.totalIncentivesForPurchase) : '--'}
            </Typography>
            <Box minHeight="24px">
              {incentivesQuantity > 0 && (
                <Link to="/incentives" onClick={handleClickIncentives}>
                  <small>
                    {formatMessage(
                      { id: 'compare.pricePanel.availableIncentives' },
                      { incentivesQuantity },
                    )}
                  </small>
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
