import { ElectricVehicle } from '../../types';
import isBEV from './isBEV';
import isPHEV from './isPHEV';

const calcVehicleElectricityUsage = (
  vehicle: ElectricVehicle,
  miles: number,
  electricMilesPortionForPhev: number,
) => {
  if (!isBEV(vehicle) && !isPHEV(vehicle)) return 0;

  const vehicleKwhPerMile =
    vehicle.electricEfficiency && vehicle.electricEfficiency > 0
      ? vehicle.electricEfficiency / 100
      : 0;

  return isPHEV(vehicle)
    ? vehicleKwhPerMile * miles * (electricMilesPortionForPhev / 100)
    : vehicleKwhPerMile * miles;
};

export default calcVehicleElectricityUsage;
