import { ReactChart } from 'chartjs-react';
import 'chartjs-plugin-datalabels';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { formatAsDollars, formatVehicleName } from '../../../../utils/formatters';
import Loading from '../../../../components/loading/Loading';
import genRateCompData, {
  RateCompData,
} from '../../../../calculations/genRateCompData/genRateCompData';
import { Box, Typography } from '@mui/material';
import useElectricVehicleDetails from '../../../../api/hooks/useElectricVehicleDetails';
import { toNumber } from 'lodash';
import Switch from '../../../../components/switch/Switch';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { useIntl, IntlFormatters } from 'react-intl';
import { ChartData } from 'chart.js';
import type { UserPrefs } from '../../../../types/userPrefs';

function generateChartData(
  graphData: RateCompData,
  userPrefs: UserPrefs,
  formatMessage: IntlFormatters['formatMessage'],
): ChartData {
  const labels = [graphData.lowestRate.label, graphData.currentRate.label];

  const output = {
    home: [graphData.currentRate.home, graphData.lowestRate.home],
    ev: [graphData.currentRate.ev, graphData.lowestRate.ev],
    gasoline: [graphData.currentRate.gasoline, graphData.lowestRate.gasoline],
  };

  // Set datasets in order: Home (if selected), Electricity for EV, then
  // Gasoline (if selected)
  let datasets = userPrefs.showHomeElectricityCost
    ? [
        {
          label: formatMessage({ id: 'rates.chart.homeElec' }),
          data: output.home,
          backgroundColor: '#3C87BF',
        },
      ]
    : [];

  datasets = [
    ...datasets,
    {
      label: formatMessage({
        id: userPrefs.hasSolarAtHome ? 'rates.chart.evPlusSolar' : 'rates.chart.ev',
      }),
      data: output.ev,
      backgroundColor: '#FBBB36',
    },
  ];

  if (userPrefs.showGasolineCost) {
    datasets = [
      ...datasets,
      {
        label: formatMessage({ id: 'rates.chart.gas' }),
        data: output.gasoline,
        backgroundColor: '#333333',
      },
    ];
  }

  return {
    labels: [
      [
        formatMessage({ id: 'rates.chart.currentRate' }, { rate: labels[1] }),
        `(${formatMessage({ id: 'rates.chart.withoutEv' })})`,
      ],
      [
        formatMessage({ id: 'rates.chart.lowestRate' }, { rate: labels[0] }),
        `(${formatMessage({ id: 'rates.chart.withEv' })})`,
      ],
    ],
    datasets: datasets,
  };
}

type CompareChartParams = {
  onShowWizard: () => void;
  historicalLoadProfile: Array<number> | null;
  usingHistorical: boolean;
};

export default function CompareChart({
  onShowWizard,
  historicalLoadProfile,
  usingHistorical,
}: CompareChartParams): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { vehicle, status } = useElectricVehicleDetails(userPrefs.comparedVehicleId1);

  if (!userPrefs.comparedVehicleId1)
    return <Box p="1.5rem">{formatMessage({ id: 'rates.chart.noVehicleMessage' })}</Box>;
  if (status === 'loading' || (usingHistorical && !historicalLoadProfile)) return <Loading />;
  if (!vehicle)
    return <Box p="1.5rem">{formatMessage({ id: 'rates.chart.noVehicleMessage' })}</Box>;

  const graphData = genRateCompData(userPrefs, vehicle, historicalLoadProfile || undefined);

  if (graphData == null) return <></>;

  const currentTotal =
    graphData.currentRate.home + graphData.currentRate.ev + graphData.currentRate.gasoline;

  const data = generateChartData(graphData, userPrefs, formatMessage);

  const totalBillSavings =
    graphData.currentRate.home +
    graphData.currentRate.ev +
    graphData.currentRate.gasoline -
    graphData.lowestRate.home -
    graphData.lowestRate.ev -
    graphData.lowestRate.gasoline;

  let chartTitleKey = 'rates.chart.title';
  if (userPrefs.hasSolarAtHome && usingHistorical) {
    chartTitleKey = 'rates.chart.titleWithBothSolarAndHistorical';
  } else if (userPrefs.hasSolarAtHome) {
    chartTitleKey = 'rates.chart.titleWithSolar';
  } else if (usingHistorical) {
    chartTitleKey = 'rates.chart.historicalTitle';
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#004C75',
          color: '#fff',
          fontSize: '1rem',
          fontWeight: 700,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          padding: '1rem',
        }}
      >
        {formatMessage({
          id: chartTitleKey,
        })}
      </Box>
      <Box
        id="rate-comparison"
        padding="1rem"
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: 0,
        }}
      >
        <Box>
          <Box>
            <Typography variant="h5">
              {formatMessage(
                {
                  id:
                    totalBillSavings < 0
                      ? 'rates.chart.headerExtraSpend'
                      : 'rates.chart.headerSavings',
                },
                {
                  amount: (
                    <span style={{ color: '#7D9C3B', fontWeight: '700' }}>
                      {formatAsDollars(Math.abs(totalBillSavings))}
                    </span>
                  ),
                  rate: graphData.lowestRate.label,
                  vehicle: formatVehicleName(vehicle),
                },
              )}
            </Typography>
          </Box>
        </Box>

        <Box>
          <ReactChart
            type="bar"
            data={data}
            width={100}
            height={400}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  top: 20,
                },
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 14,
                    },
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    callback: (value) => formatAsDollars(toNumber(value)),
                    font: {
                      size: 14,
                    },
                  },
                  title: {
                    display: true,
                    text: formatMessage({ id: 'rates.chart.costLabel' }),
                    font: {
                      size: 14,
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  reverse: true,
                  position: 'bottom',
                  onClick: function (e) {
                    e.native?.stopPropagation();
                  },
                  labels: {
                    font: {
                      size: 14,
                    },
                  },
                  display: true,
                  onHover: (evt, item, legend) => {
                    if (Array.isArray(legend.chart.data.datasets[0].backgroundColor)) {
                      legend.chart.data.datasets[0].backgroundColor?.forEach(
                        (color, index, colors) => {
                          colors[index] =
                            index === item.datasetIndex || color.length === 9
                              ? color
                              : color + '4D';
                        },
                      );
                      legend.chart.update();
                    }
                  },
                  onLeave: (evt, item, legend) => {
                    if (Array.isArray(legend.chart.data.datasets[0].backgroundColor)) {
                      legend.chart.data.datasets[0].backgroundColor.forEach(
                        (color, index, colors) => {
                          colors[index] = color.length === 9 ? color.slice(0, -2) : color;
                        },
                      );
                      legend.chart.update();
                    }
                  },
                },
                datalabels: {
                  formatter: (_value, ctx) => {
                    let datasets = ctx.chart.data.datasets;
                    if (ctx.datasetIndex === datasets.length - 1) {
                      let sum = 0;
                      datasets.forEach((dataset) => {
                        const value = dataset.data[ctx.dataIndex];
                        if (value && typeof value === 'number') {
                          sum += value;
                        }
                      });

                      if (userPrefs.showGasolineCost && userPrefs.showHomeElectricityCost) {
                        if (sum < currentTotal) {
                          return formatMessage(
                            { id: 'rates.allRates.savings' },
                            { dollars: formatAsDollars(currentTotal - sum) },
                          );
                        } else {
                          return '';
                        }
                      } else {
                        return '';
                      }
                    } else {
                      return '';
                    }
                  },
                  align: 'end' as const,
                  anchor: 'end',
                  color: '#7D9C3B',
                  font: {
                    weight: 700,
                    size: 14,
                  },
                },
                tooltip: {
                  ...baseTooltipStyles,
                  callbacks: {
                    title: (dataset) => {
                      return dataset[0].label.replace(',', ' ');
                    },
                    label: (value) =>
                      `${value.dataset.label}: ${formatAsDollars(value.raw as number)}`,
                  },
                },
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap="1rem"
          alignItems="center"
          mt="2rem"
        >
          <Box display="flex" alignSelf={{ xs: 'flex-start', md: 'stretch' }} alignItems="center">
            <Switch
              label={formatMessage({ id: 'rates.chart.gasolineToggle' })}
              checked={userPrefs.showGasolineCost}
              onChange={() => setUserPrefs({ showGasolineCost: !userPrefs.showGasolineCost })}
            />
            <Switch
              label={formatMessage({ id: 'rates.chart.homeToggle' })}
              checked={userPrefs.showHomeElectricityCost}
              onChange={() =>
                setUserPrefs({ showHomeElectricityCost: !userPrefs.showHomeElectricityCost })
              }
            />
          </Box>
          <Box flex="1 1" textAlign="right">
            <ButtonLink to="https://m.pge.com/#myaccount/oreenrollsteps/step1/" external>
              {formatMessage({ id: 'rates.chart.rateChangeButton' })}
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    </>
  );
}
