import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonMultiSelect from '../../../components/buttonMultiSelect/ButtonMultiSelect';
import Slider from '../../../components/slider/Slider';
import ZipcodeInput from '../../../components/zipcodeInput/ZipcodeInput';
import { useUserPrefs } from '../../../context/UserPrefsProvider';
import { formatAsDollars } from '../../../utils/formatters';

export default function EligibilityFilters() {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Typography variant="h5">
        {formatMessage({ id: 'incentives.eligibilityFilters.headline' })}
      </Typography>
      <ZipcodeInput
        zipcode={userPrefs.zipcode}
        onValidZipcode={(value) => setUserPrefs({ zipcode: value })}
      />
      {/* <Select
        label={formatMessage({id: 'incentives.eligibilityFilters.provider'})}
        value={}
        options={[]}
        onChange={() => { }}
      /> */}
      <Slider
        label={formatMessage({ id: 'incentives.eligibilityFilters.income' })}
        value={userPrefs.householdIncome}
        valueFormatter={formatAsDollars}
        max={300000}
        min={0}
        step={10000}
        onChange={(value) => {
          setUserPrefs({ householdIncome: value });
        }}
      />
      <Slider
        label={formatMessage({ id: 'incentives.eligibilityFilters.sizeOfHousehold' })}
        value={userPrefs.householdSize}
        max={8}
        min={1}
        step={1}
        onChange={(value) => {
          setUserPrefs({ householdSize: value });
        }}
      />
      <ButtonMultiSelect
        label={formatMessage({ id: 'incentives.eligibilityFilters.taxFilingStatus' })}
        selected={[userPrefs.taxFilingStatus]}
        options={[
          {
            label: formatMessage({ id: 'incentives.eligibilityFilters.taxStatuses.single' }),
            value: 'single',
          },
          {
            label: formatMessage({ id: 'incentives.eligibilityFilters.taxStatuses.joint' }),
            value: 'joint',
          },
          {
            label: formatMessage({
              id: 'incentives.eligibilityFilters.taxStatuses.headOfHousehold',
            }),
            value: 'head_of_household',
          },
        ]}
        onChange={(values) => {
          const newValue = values.find((v) => v !== userPrefs.taxFilingStatus);
          newValue && setUserPrefs({ taxFilingStatus: newValue });
        }}
      />
      {/* <Select
      label={formatMessage({id: 'incentives.eligibilityFilters.retiringVehicle'})}
      value={}
      /> */}
    </Box>
  );
}
