import { ReactNode, useState } from 'react';
import Chart from './Chart';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { USER_PREF_PRESETS } from '../../../../constants/userPrefs';
import { formatAsDollars, formatVehicleName } from '../../../../utils/formatters';
import type { ElectricVehicle, EquivalentFossilVehicle } from '../../../../types';
import calcTotalCostOfOwnership from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CollapseToggleButton from '../../../../components/collapseToggleButton/CollapseToggleButton';
import Switch from '../../../../components/switch/Switch';
import { useIntl } from 'react-intl';

const TotalCell = ({ children }: { children: ReactNode }) => (
  <TableCell
    sx={{
      fontWeight: '500',
      fontSize: '1rem',
      color: (theme) => theme.palette.grey[900],
    }}
  >
    {children}
  </TableCell>
);

type Props = {
  car: ElectricVehicle;
  chartLabels?: Array<string>;
  comparedCar?: EquivalentFossilVehicle | ElectricVehicle;
  displayFuelOnly?: boolean;
  displayEvCostBreakdown?: boolean;
  displayEvCostBreakdownOption?: boolean;
  forceUserPrefsPresets?: boolean;
  hasExtraPadding?: boolean;
};

export default function CostsChartArea({
  car,
  chartLabels,
  comparedCar,
  displayFuelOnly,
  displayEvCostBreakdown,
  displayEvCostBreakdownOption,
  forceUserPrefsPresets,
  hasExtraPadding,
}: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const { userPrefs } = useUserPrefs();
  const [showCostBreakdown, setShowCostBreakdown] = useState(displayEvCostBreakdown);

  const equivalentGasVehicle = comparedCar || car.equivalentGasVehicle || {};

  const prefs = forceUserPrefsPresets ? USER_PREF_PRESETS : userPrefs;
  const yearsOfOwnership = prefs.monthsOfOwnership / 12;

  const tableStyles = {
    '.MuiTableBody-root, .MuiTableFooter-root': {
      '.MuiTableCell-root:not(:first-child)': {
        textAlign: 'right',
      },
    },
  };

  const carCosts = calcTotalCostOfOwnership(
    car,
    prefs.purchaseMethod,
    prefs.monthsOfOwnership,
    prefs.milesDrivenAnnually,
    prefs.interestRateAsBasisPoints,
    prefs.electricMilesPortionForPhev,
    prefs.gasolinePriceInCentsPerGal,
    prefs.includeResaleValue,
  );
  const equivalentGasVehicleCosts = calcTotalCostOfOwnership(
    equivalentGasVehicle,
    prefs.purchaseMethod,
    prefs.monthsOfOwnership,
    prefs.milesDrivenAnnually,
    prefs.interestRateAsBasisPoints,
    prefs.electricMilesPortionForPhev,
    prefs.gasolinePriceInCentsPerGal,
    prefs.includeResaleValue,
  );

  const costDelta = displayFuelOnly
    ? equivalentGasVehicleCosts.electricity.perMonth +
      equivalentGasVehicleCosts.gasoline.perMonth -
      carCosts.electricity.perMonth -
      carCosts.gasoline.perMonth
    : equivalentGasVehicleCosts.summed.total - carCosts.summed.total;

  const carNames = chartLabels || [
    [formatMessage({ id: 'compare.charts.costChart.selectionLabel' }), formatVehicleName(car)].join(
      ': ',
    ),
    [
      formatMessage({ id: 'compare.charts.costChart.similarLabel' }),
      formatVehicleName(equivalentGasVehicle),
    ].join(': '),
  ];

  const totalRow = (
    <TableRow>
      {!displayFuelOnly ? (
        <>
          <TotalCell>{formatMessage({ id: 'homepage.evCosts.total' })}</TotalCell>
          <TotalCell>{formatAsDollars(carCosts.summed.total)}</TotalCell>
          <TotalCell>{formatAsDollars(equivalentGasVehicleCosts.summed.total)}</TotalCell>
        </>
      ) : (
        <>
          <TotalCell>{formatMessage({ id: 'homepage.evCosts.total' })}</TotalCell>
          <TotalCell>
            {formatAsDollars(carCosts.electricity.perMonth + carCosts.gasoline.perMonth)}
          </TotalCell>
          <TotalCell>
            {formatAsDollars(
              equivalentGasVehicleCosts.electricity.perMonth +
                equivalentGasVehicleCosts.gasoline.perMonth,
            )}
          </TotalCell>
        </>
      )}
    </TableRow>
  );

  return (
    <Box style={hasExtraPadding ? { padding: '2.5rem' } : { padding: '1rem' }}>
      <Typography fontSize="1rem" fontWeight={500} textAlign="center">
        {formatMessage(
          {
            id: costDelta < 0 ? 'compare.charts.expensive' : 'compare.charts.cheap',
          },
          {
            vehicle: formatVehicleName(car),
            costDelta: (
              <Box component="span" sx={{ color: (theme) => theme.palette.green[500] }}>
                {formatAsDollars(Math.abs(costDelta))}
              </Box>
            ),
            ownershipYears: yearsOfOwnership,
          },
        )}
      </Typography>

      {displayEvCostBreakdownOption && (
        <Box textAlign="center" marginTop="1rem" marginBottom="2rem">
          <Switch
            label={formatMessage({ id: 'compare.charts.costChart.toggleCostBreakdown' })}
            labelPlacement="end"
            checked={!!showCostBreakdown}
            onChange={() => setShowCostBreakdown(!showCostBreakdown)}
          />
        </Box>
      )}
      <Box>
        <Chart
          carNames={carNames}
          carCosts={carCosts}
          equivalentGasVehicleCosts={equivalentGasVehicleCosts}
          displayEvCostBreakdownOption={displayEvCostBreakdownOption}
          displayEvCostBreakdown={showCostBreakdown}
          displayFuelOnly={displayFuelOnly}
        />
      </Box>
      {hasExtraPadding && <br />}
      <CollapseToggleButton
        openText={formatMessage({ id: 'homepage.evCosts.showTableButton' })}
        closeText={formatMessage({ id: 'homepage.evCosts.hideTableButton' })}
        buttonPosition="center"
      >
        <TableContainer sx={tableStyles}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{formatVehicleName(car)}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {formatVehicleName(equivalentGasVehicle)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayFuelOnly ? (
                <>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.electricity' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.electricity.perMonth)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.electricity.perMonth)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.gasoline' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.gasoline.perMonth)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.gasoline.perMonth)}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.vehicle' })}</TableCell>
                    <TableCell>
                      {carCosts.vehicle.total <= 500
                        ? formatMessage({ id: 'homepage.evCosts.atOrNearZero' })
                        : formatAsDollars(carCosts.vehicle.total)}
                    </TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.vehicle.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.maintenance' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.maintenance.total)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.maintenance.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.insurance' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.insurance.total)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.insurance.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.electricity' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.electricity.total)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.electricity.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{formatMessage({ id: 'homepage.evCosts.gasoline' })}</TableCell>
                    <TableCell>{formatAsDollars(carCosts.gasoline.total)}</TableCell>
                    <TableCell>
                      {formatAsDollars(equivalentGasVehicleCosts.gasoline.total)}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
            <TableFooter>{totalRow}</TableFooter>
          </Table>
        </TableContainer>
      </CollapseToggleButton>
    </Box>
  );
}
