import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import usePspsEvents from '../../api/hooks/usePspsEvents';
import Card from '../../components/card/Card';
import ChargingStationAvailabilityMap from '../../components/maps/components/chargingStationAvailabilityMap/ChargingStationAvailabilityMap';
import ChargingStationsMap from '../../components/maps/components/chargingStationsMap/ChargingStationsMap';
import DealersMap from '../../components/maps/components/dealersMap/DealersMap';
import TravelRadiusMap from '../../components/maps/components/TravelRadiusMap/TravelRadiusMap';
import TravelRouteMap from '../../components/maps/components/TravelRouteMap/TravelRouteMap';
import Page from '../../components/page/Page';
import Select from '../../components/select/Select';
import PspsEventList from './components/pspsEventList/pspsEventList';
import PspsEventsBanner from './components/pspsEventsBanner/PspsEventsBanner';

export default function Map() {
  const { formatMessage } = useIntl();

  const match = useRouteMatch<{ basePath: string }>('/:basePath');
  const history = useHistory();
  const { pspsEvents, error: pspsError } = usePspsEvents();

  const mapTabs = [
    {
      label: formatMessage({ id: 'maps.stations' }),
      content: ChargingStationsMap,
      route: '/charging-stations',
    },
    {
      label: formatMessage({ id: 'maps.availability' }),
      content: ChargingStationAvailabilityMap,
      route: '/charging-station-availability',
    },
    {
      label: formatMessage({ id: 'maps.range' }),
      content: TravelRadiusMap,
      route: '/travel-range',
    },
    {
      label: formatMessage({ id: 'maps.route' }),
      content: TravelRouteMap,
      route: '/map-a-route',
    },
    {
      label: formatMessage({ id: 'maps.dealer' }),
      content: DealersMap,
      route: '/dealers',
    },
  ];

  const title =
    mapTabs.find((tab) => tab.route === '/' + match?.params.basePath)?.label ||
    formatMessage({ id: 'maps.header' });

  return (
    <Page>
      <PspsEventsBanner pspsEvents={pspsEvents} pspsError={pspsError} />
      <Box px={{ xs: 0, md: 4 }} py={{ xs: 2, md: 4 }} bgcolor="grey.100">
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          maxWidth="1140px"
          m="auto"
          px={{ xs: 2, md: 0 }}
        >
          <PspsEventList pspsEvents={pspsEvents} />
          <Typography variant="h1" component="h1">
            {title}
          </Typography>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Select
              ariaLabel="Map Features"
              fullWidth
              value={`/${match?.params.basePath}`}
              label={''}
              onChange={(newRoute: string) => {
                history.replace(newRoute);
              }}
              options={mapTabs.map((tab) => ({
                label: tab.label,
                value: tab.route,
              }))}
            />
          </Box>
          <Card>
            <TabContext value={`/${match?.params.basePath}`}>
              <Box
                display={{ xs: 'none', md: 'block' }}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <TabList
                  aria-label={'Tabbed content'}
                  onChange={(_event: React.SyntheticEvent, newRoute: string) => {
                    history.replace(newRoute);
                  }}
                >
                  {mapTabs.map((tab) => (
                    <Tab
                      key={tab.route}
                      label={tab.label}
                      value={tab.route}
                      sx={{ '&.Mui-selected': { color: 'orange.600' } }}
                    />
                  ))}
                </TabList>
              </Box>
              {mapTabs.map((tab) => (
                <TabPanel key={tab.route} value={tab.route} sx={{ p: 0 }}>
                  <tab.content pspsEvents={pspsEvents} />
                </TabPanel>
              ))}
            </TabContext>
          </Card>
        </Box>
      </Box>
    </Page>
  );
}
