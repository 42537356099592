import { Coords } from '../../types';

const toRadian = (angle: number) => (Math.PI / 180) * angle;
const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);

// From: https://stackoverflow.com/a/48805273
const haversineDistance = (latlngA: Coords, latlngB: Coords, isMiles?: boolean) => {
  const RADIUS_OF_EARTH_IN_KM = 6371;

  let lat1 = latlngA.lat;
  let lat2 = latlngB.lat;
  const lon1 = latlngA.lng;
  const lon2 = latlngB.lng;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

export default haversineDistance;
