import backgroundImg from './../../../../assets/images/existing-ev-hero.png';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';

export default function ExistingEvOwners() {
  const { formatMessage } = useIntl();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            py: '40px',
            px: { xs: 3, sm: 5 },
            minHeight: '202px',
          }}
        >
          <Typography
            fontSize="32px"
            sx={{
              color: 'white.100',
              textShadow: '2px 2px 3px hsl(0deg 0% 100% / 10%)',
            }}
            mb={3}
          >
            {formatMessage({ id: 'homepage.existingEvOwner.header' })}
          </Typography>
          <ButtonLink to="/ev-owners">
            {formatMessage({ id: 'homepage.existingEvOwner.button' })}
          </ButtonLink>
        </Box>
      </Box>
    </>
  );
}
