import { LoadProfile } from '@bellawatt/electric-rate-engine';
import type { UserPrefs } from '../../types/userPrefs';
import { YEAR, LOAD_PROFILE_HOURS } from '../../utils/assumptions';
import { SOLAR_GHI } from '../../data/solarGhi';

// Assumptions used in
// https://docs.google.com/spreadsheets/d/1RJtyf84pvfsJCVcscMf6O89wZYLr1pC_i_S_tKf7TTM/edit#gid=0
const AVERAGE_SOLAR_PANEL_DIMENSIONS = 1;

export default function genSolarLoadProfile(userPrefs: UserPrefs) {
  const { solarPanelCount, solarPanelPower } = userPrefs;
  // If we need an 8760, then the data is fine as is.
  // If we need an 8784, we need to duplicate the segment
  // that represents February 28 to represent February 29
  // too.
  const ghis =
    LOAD_PROFILE_HOURS === 8760
      ? SOLAR_GHI
      : [
          ...SOLAR_GHI.slice(0, 1416), // Jan 1 to Feb 28
          ...SOLAR_GHI.slice(1392), // Feb 28 (again) to Dec 31
        ];
  const loadProfile = ghis.map((ghi) => {
    return (
      (solarPanelCount *
        AVERAGE_SOLAR_PANEL_DIMENSIONS *
        (solarPanelPower / AVERAGE_SOLAR_PANEL_DIMENSIONS > ghi ? ghi : solarPanelPower)) /
      1000
    );
  });
  return new LoadProfile(loadProfile, { year: YEAR });
}
