import type { VehicleIncentive } from '../../../types/vehicleIncentive';

const capitalizedInPurchase = (incentives: VehicleIncentive[]): number => {
  return incentives.reduce((acc, incentive) => {
    let incentiveAmount =
      incentive &&
      incentive.evaluation &&
      incentive.evaluation.applicableToOwnership &&
      !isNaN(incentive.evaluation.amountInPurchase)
        ? incentive.evaluation.amountInPurchase
        : 0;

    return acc + incentiveAmount;
  }, 0);
};

export default capitalizedInPurchase;
