import type { ApiIncentive, Incentive } from '../../types/incentive';

export const transformApiIncentive = (apiIncentive: ApiIncentive): Incentive => {
  return {
    zrId: apiIncentive.id,
    grantor: apiIncentive.grantor,
    grantorType: apiIncentive.grantor_type,
    applicantType: apiIncentive.applicant_type,
    type: apiIncentive.type,
    name: apiIncentive.name,
    description: apiIncentive.description,
    typicalAmount: apiIncentive.typical_amount,
    limitations: apiIncentive.limitations,
    available: apiIncentive.available,
    detailsUrl: apiIncentive.details_url,
    supportFor: apiIncentive.support_for,
    applicableToNewVehicles: apiIncentive.applicable_to_new_vehicles,
    applicableToUsedVehicles: apiIncentive.applicable_to_used_vehicles,
    applicableToFcevs: apiIncentive.applicable_to_fcevs,
    incomeDependent: apiIncentive.income_dependent,
  };
};
