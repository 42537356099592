import { useEffect } from 'react';
import { useUserPrefs } from '../../context/UserPrefsProvider';

export default function useResetZipOnNav(currentUrl: string) {
  const { userPrefs, setUserPrefs } = useUserPrefs();

  useEffect(() => {
    if (currentUrl && userPrefs.zipcodeError) {
      setUserPrefs({ zipcode: userPrefs.zipcodeLastValid });
    }
    // ONLY run on location change to reset the zipcode to a valid value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return null;
}
