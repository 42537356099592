// https://lunrjs.com/docs/index.html
import { random } from 'lodash';
import lunr, { Builder, Token } from 'lunr';
import { useMemo } from 'react';

type SynonymGroups = Record<string, Array<string>>;

const addSynonyms = (synonymGroups: SynonymGroups) => (builder: Builder) => {
  const pipelineFunction = (token: Token) => {
    Object.entries(synonymGroups).forEach(([rootWord, synonyms]) => {
      if (synonyms.includes(token.toString())) {
        return token.update(() => rootWord);
      }
    });
    return token;
  };

  // Register the pipeline function so the index can be serialised
  lunr.Pipeline.registerFunction(pipelineFunction, `addSynonyms${random(1, 999999)}`);

  // Add the pipeline function to both the indexing pipeline and the
  // searching pipeline
  builder.pipeline.before(lunr.stemmer, pipelineFunction);
  builder.searchPipeline.before(lunr.stemmer, pipelineFunction);
};

const createIndex = <T extends object>(
  vehicles: Array<T> = [],
  indexedFieldList: Array<string>,
  synonymGroups?: SynonymGroups,
) => {
  // One note: lunr.js expects completely flat documents. This is discussed here:
  // https://github.com/olivernn/lunr.js/issues/100#issuecomment-412748327
  // To use nested fields, you need to manually map the field. The library has a helper options
  // to faciliate that.
  const index = lunr(function () {
    synonymGroups && this.use(addSynonyms(synonymGroups));
    indexedFieldList.forEach((field) => {
      this.field(field);
    });
    vehicles.forEach((v) => this.add(v));
  });

  return index;
};

type SearchOptions = {
  indexFields: Array<string>;
  synonymGroups?: SynonymGroups;
  editDistance?: number;
};

export const useSearch = <T extends { id: string }>(
  searchPhrase: string,
  collection: Array<T> = [],
  options: SearchOptions,
): {
  results: Array<T>;
} => {
  const index = useMemo(() => {
    // Lunr.js uses an immutable index for performance reasons. So rather than adding documents to the index,
    // we actually need to rebuild the whole thing in order to add new documents:
    // https://github.com/olivernn/lunr.js/issues/284
    return createIndex(collection, options.indexFields, options.synonymGroups);
  }, [collection, options.indexFields, options.synonymGroups]);

  if (!searchPhrase || !collection?.length) {
    return { results: [] };
  }

  // https://lunrjs.com/guides/searching.html#fuzzy-matches
  // Lunr.js allows fuzzy searching by appending a tilde and an integer to a search, like "search~1".
  // This signifies "edit distance", defined by wikipedia as
  // "measured by counting the minimum number of operations required to transform one string into the other".
  // For our purposes, "the number of allowed typos" is probably close enough.
  const searchResults = index.search(`${searchPhrase}~${options.editDistance || 1}`);

  // Search results do not include the actual records, just references to them.
  const resultIds = searchResults.map((result) => result.ref);

  const results = resultIds.reduce((acc: Array<T>, id: string) => {
    const vehicle = collection.find((v) => v.id === id);
    if (vehicle) {
      return [...acc, vehicle];
    }
    return acc;
  }, []);

  return {
    results,
  };
};
