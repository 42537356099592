import type { ElectricVehicle, UserPrefs } from '../../types';
import calcPublicChargingUsage from './calcPublicChargingUsage';

const calcPublicChargingCost = (userPrefs: UserPrefs, electricVehicle: ElectricVehicle): number => {
  const publicChargingUsage = calcPublicChargingUsage(userPrefs, electricVehicle);

  return (publicChargingUsage * userPrefs.publicChargingCostInCentsPerKwh) / 100;
};

export default calcPublicChargingCost;
