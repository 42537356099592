import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

export default function Section({
  id,
  sx,
  children,
}: {
  id?: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      id={id}
      sx={{
        padding: '60px 15px',
        mx: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
