import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default function LocalInstallation() {
  return (
    <Box>
      <p>
        Use the free online services listed below to find local EV charging station installers in
        your area. In addition to providing quotes from local installers, the sites also include
        customer reviews and helpful installation information. To obtain quotes, you may be asked to
        provide your installation timeline, property type (house or apartment), ownership rights, EV
        charger type and installation location, and confirm your dedicated voltage. PG&E is not
        responsible for the requirements of these online services, which are subject to the terms
        and conditions of the online service provider.
      </p>

      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col" style={{ width: '20%' }}>
              Online Services
            </TableCell>
            <TableCell component="th" scope="col">
              Requires creating an account; free to use
            </TableCell>
            <TableCell component="th" scope="col">
              Provides quotes based on details you provide
            </TableCell>
            <TableCell component="th" scope="col">
              Available in all PG&E service areas
            </TableCell>
            <TableCell component="th" scope="col">
              Offers extra guidance on costs and more
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <a
                href="https://match.angi.com/task.Electrical-Vehicle-Charging.61620.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Angie's List
              </a>
            </TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <a
                href="https://www.homeadvisor.com/task.Electrical-Vehicle-Charging.61620.html?7735=20767&502104=10001&502105=10001&step=location&sar=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                Home Advisor
              </a>
            </TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <a
                href="https://porch.com/near-me/ev-charging-station-installation?utm_source=PGE&utm_medium=referral&utm_campaign=PGE-partner-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Porch
              </a>
            </TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
            <TableCell>Yes</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
