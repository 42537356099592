import { useEffect, useState } from 'react';
import useMapBounds from '../../hooks/useMapBounds';
import useSelectedStation from '../../hooks/useSelectedStation';
import useChargingStations from '../../hooks/useChargingStations';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import useMappedZipcode from '../../hooks/useMappedZipcode';
import MapControlPanel from '../mapControlPanel/MapControlPanel';
import BaseGoogleMap from '../baseGoogleMap/BaseGoogleMap';
import ChargingStationsList from '../chargingStationsList/ChargingStationsList';
import useGeoJsonUrls from '../../hooks/useGeojsonUrls';
import MapLegend from '../mapLegend/MapLegend';
import PspsEventLegend from '../pspsEventLegend/PspsEventLegend';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import ZipcodeInputWithButton from '../../../zipcodeInputWithButton/ZipcodeInputWithButton';
import ChargingStationsMapMarker from '../chargingStationsMapMarker/ChargingStationsMapMarker';
import type { ChargingStation, PspsEvent } from '../../../../types';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

type Props = {
  chargingStationsFilterFn?: (station: ChargingStation) => boolean;
  isVisible?: boolean;
  pspsEvents?: Array<PspsEvent>;
};

export default function ChargingStationsMap({
  chargingStationsFilterFn,
  isVisible = true,
  pspsEvents = [],
}: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { bounds, registerMapBoundsListeners, filterWithinBounds, center } = useMapBounds();
  const { chargingStations, fetchChargingStations } = useChargingStations();
  const { selectedStation, selectStation, deselectStation } = useSelectedStation(chargingStations);

  const [hoveredStation, setHoveredStation] = useState<ChargingStation | null>(null);

  const {
    zipcode: geocodedZipcode,
    loading: isFetchingZipcode,
    error: zipcodeError,
    fetchZipcode,
    registerMappedZipcodeListeners,
  } = useMappedZipcode({
    zoom: 15,
  });

  const registerGeojsonUrlMap = useGeoJsonUrls(pspsEvents.flatMap((event) => event.fileUrls));

  useEffect(() => {
    if (geocodedZipcode && geocodedZipcode !== userPrefs.zipcode) {
      setUserPrefs({ zipcode: geocodedZipcode });
    }
  }, [geocodedZipcode, setUserPrefs, userPrefs.zipcode]);

  useDeepCompareEffect(() => {
    if (bounds && center) {
      fetchChargingStations(center, bounds);
    }
  }, [center, bounds]);

  const visibleChargingStations = filterWithinBounds(chargingStations);
  const filteredChargingStations = selectedStation
    ? [selectedStation]
    : chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  return (
    <Box>
      <ZipcodeInputWithButton
        buttonText={formatMessage({ id: 'maps.availabilityMap.searchChargingStations' })}
        error={zipcodeError}
        isLoading={isFetchingZipcode}
        onSubmitZipcode={fetchZipcode}
        zipcode={userPrefs.zipcode}
        inline
      />
      <Box
        mt="1.75rem"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap="1rem"
        mb="1.75rem"
      >
        <Box flex="1 1" minWidth="270px">
          <Box>
            <MapLegend>{pspsEvents.length > 0 && <PspsEventLegend />}</MapLegend>
            {isVisible && (
              <BaseGoogleMap
                onLoad={(map) => {
                  registerMapBoundsListeners(map);
                  registerMappedZipcodeListeners(map, userPrefs.zipcode);
                  registerGeojsonUrlMap(map);
                }}
                onMapClick={() => {
                  if (selectedStation) {
                    deselectStation();
                  }
                }}
                mapProps={{
                  options: {
                    minZoom: 12,
                  },
                }}
              >
                {filteredChargingStations.map((station) => (
                  <ChargingStationsMapMarker
                    lat={station.lat}
                    lng={station.lng}
                    key={station.id}
                    station={station}
                    selected={selectedStation?.id === station.id}
                    onClick={() => {
                      if (selectedStation) {
                        deselectStation();
                      } else {
                        selectStation(station.id);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredStation(station);
                    }}
                  />
                ))}
              </BaseGoogleMap>
            )}
            <p>{formatMessage({ id: 'maps.availabilityMap.disclaimer' })}</p>
          </Box>
        </Box>
        <Box
          width={{ xs: '100%', sm: '240px' }}
          display="flex"
          flexDirection="column"
          gap="1.75rem"
        >
          <MapControlPanel
            selectedStation={hoveredStation || selectedStation}
            chargingStations={filteredChargingStations}
          />
        </Box>
      </Box>
      <ChargingStationsList chargingStations={filteredChargingStations} />
    </Box>
  );
}
