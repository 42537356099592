import { Box, useMediaQuery, useTheme } from '@mui/material';
import LinkCard from './LinkCard';

type LinkCardType = {
  icon: JSX.Element;
  link: string;
  title: string;
};

type Props = {
  linkCards: LinkCardType[];
  mt: string;
};

export default function LinkCardSet({ linkCards, mt }: Props) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const rowSize = md ? 5 : sm ? 3 : 2;
  const cardGroups: LinkCardType[][] = [];
  linkCards.forEach((card, i) => {
    const rowIndex = Math.floor(i / rowSize);
    if (!cardGroups[rowIndex]) {
      cardGroups[rowIndex] = [];
    }
    cardGroups[rowIndex].push(card);
  });

  return (
    <Box mt={mt}>
      {cardGroups.map((cards, rowIndex) => (
        <Box
          display="flex"
          gap={{ xs: 1, sm: 3, md: 6 }}
          justifyContent="center"
          alignItems="stretch"
          mt="20px"
          key={rowIndex}
        >
          {cards.map((cardData) => (
            <LinkCard
              key={cardData.link}
              title={cardData.title}
              icon={cardData.icon}
              link={cardData.link}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
}
