import { Box, Typography } from '@mui/material';
import { formatAsDollars } from '../../../../utils/formatters';
import Link from '../../../../components/link/Link';
import { useIntl } from 'react-intl';
import genRateCompData from '../../../../calculations/genRateCompData/genRateCompData';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import useElectricVehicleDetails from '../../../../api/hooks/useElectricVehicleDetails';
import Loading from '../../../../components/loading/Loading';

const useTotalBillSavings = (): {
  loading?: boolean;
  noVehicle?: boolean;
  noGraphData?: boolean;
  totalBillSavings: number;
} => {
  const { userPrefs } = useUserPrefs();
  const { vehicle, status } = useElectricVehicleDetails(userPrefs.comparedVehicleId1);

  if (status === 'loading') {
    return {
      loading: true,
      totalBillSavings: 0,
    };
  }
  if (!userPrefs.comparedVehicleId1 || !vehicle) {
    return {
      noVehicle: true,
      totalBillSavings: 0,
    };
  }

  const graphData = genRateCompData(userPrefs, vehicle);

  if (graphData == null) {
    return {
      noGraphData: true,
      totalBillSavings: 0,
    };
  }

  const totalBillSavings =
    graphData.currentRate.home +
    graphData.currentRate.ev +
    graphData.currentRate.gasoline -
    graphData.lowestRate.home -
    graphData.lowestRate.ev -
    graphData.lowestRate.gasoline;

  return {
    totalBillSavings,
  };
};

export default function SolarGeneration() {
  const { formatMessage } = useIntl();

  const { totalBillSavings, noVehicle, loading, noGraphData } = useTotalBillSavings();

  if (loading) {
    return <Loading />;
  }
  if (noVehicle) {
    return <Box p="1.5rem">{formatMessage({ id: 'rates.chart.noVehicleMessage' })}</Box>;
  }
  if (noGraphData) {
    return <></>;
  }

  return (
    <Box>
      <Typography variant="h5" pb={2}>
        {formatMessage({ id: 'rates.solarCard.title' })}
      </Typography>
      <p>
        {formatMessage(
          { id: 'rates.solarCard.savings' },
          {
            amount: (
              <span style={{ color: '#7D9C3B', fontWeight: '700' }}>
                {formatAsDollars(Math.abs(totalBillSavings))}
              </span>
            ),
          },
        )}
      </p>
      <p>
        {formatMessage(
          { id: 'rates.solarCard.link' },
          {
            link: (
              <Link
                to="https://www.pge.com/en_US/residential/solar-and-vehicles/green-energy-incentives/getting-credit-for-surplus-energy/getting-credit-for-surplus-energy.page"
                external
              >
                {formatMessage({ id: 'rates.solarCard.linkText' })}
              </Link>
            ),
          },
        )}
      </p>
    </Box>
  );
}
