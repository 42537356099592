import { LoadProfile } from '@bellawatt/electric-rate-engine';

// If you wish to subtract a load profile
// from another one, you can invert it and
// then add it.
export default function invertLoadProfile(profile: LoadProfile) {
  return new LoadProfile(
    profile.loadValues().map((hour) => -hour),
    { year: profile.year },
  );
}
