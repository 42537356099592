import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../components/button/Button';
import Modal from '../../../../components/modal/Modal';
import AnnualMilesStep from './steps/AnnualMilesStep';
import ChargingTimeStep from './steps/ChargingTimeStep';
import ElectricRateStep from './steps/ElectricRateStep';
import MonthlyBillStep from './steps/MonthlyBillStep';
import SummaryStep from './steps/SummaryStep';
import { VehicleStep } from './steps/VehicleStep';

const STEPS = [
  <MonthlyBillStep />,
  <VehicleStep />,
  <AnnualMilesStep />,
  <ChargingTimeStep />,
  <ElectricRateStep />,
  <SummaryStep />,
];

type Props = {
  open?: boolean;
  onClose: () => void;
};

export default function Wizard({ open = false, onClose }: Props) {
  const { formatMessage } = useIntl();

  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (open) {
      setStepIndex(0);
    }
  }, [open]);

  const onPrevious = () => stepIndex > 0 && setStepIndex(stepIndex - 1);
  const onNext = () => {
    if (stepIndex === STEPS.length - 2) {
      // Final step displays results
    }
    stepIndex < STEPS.length - 1 && setStepIndex(stepIndex + 1);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        {formatMessage(
          { id: 'rates.wizard.stepProgress' },
          {
            currentStep: stepIndex + 1,
            totalSteps: STEPS.length,
          },
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="2rem"
        p={{ xs: 2, sm: 4 }}
        width="700px"
        maxWidth="100%"
        minHeight="360px"
        maxHeight="90vh"
      >
        {STEPS[stepIndex]}
      </Box>
      <Box display="flex" justifyContent="center" gap="1rem">
        {stepIndex > 0 && (
          <Button variant="outlined" onClick={onPrevious}>
            {formatMessage({ id: 'rates.wizard.previous' })}
          </Button>
        )}
        {stepIndex < STEPS.length - 1 && (
          <Button variant="contained" onClick={onNext}>
            {formatMessage({ id: 'rates.wizard.next' })}
          </Button>
        )}
        {stepIndex === STEPS.length - 1 && (
          <Button variant="contained" onClick={onClose}>
            {formatMessage({ id: 'rates.wizard.seeResults' })}
          </Button>
        )}
      </Box>
    </Modal>
  );
}
