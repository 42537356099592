import { Box, Typography } from '@mui/material';
import useIncentives from '../../../../api/hooks/useIncentives';
import Loading from '../../../../components/loading/Loading';
import IncentiveCatalog from '../../../homepage/components/incentives/IncentiveCatalog';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';
import { useIntl } from 'react-intl';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';

/**
 * Incentive 2: Clean Vehicle Rebate Project
 * Incentive 128: Vehicle Retirement Consumer Assistance Program
 * Incentive 378: Used Electric Vehicle Incentive
 * Incentive 172: Clean Vehicle Assistance Program Beneficial State Foundation
 */
const PREFERRED_INCENTIVE_IDS = [2, 128, 378, 172];

const Incentives = () => {
  const { formatMessage } = useIntl();
  const { incentives, status } = useIncentives();
  const { setUserPrefs } = useUserPrefs();

  const allIncentives = incentives?.allIncentives || [];
  const incentivesForUsedVehicles = PREFERRED_INCENTIVE_IDS.map(
    (id) => allIncentives.find((incentive) => incentive.zrId === id) || null,
  ).filter((incentive): incentive is Exclude<typeof incentive, null> => Boolean(incentive));
  return (
    <Box>
      <Box textAlign="center">
        <Typography variant="h2" mb="0.5rem">
          {formatMessage({ id: 'incomeQualified.incentives.headline' })}
        </Typography>
        <Typography whiteSpace="pre-line" fontSize="1.25rem" fontWeight="300" mb="1rem">
          {formatMessage({ id: 'incomeQualified.incentives.details' })}
        </Typography>
      </Box>
      {status === 'loading' && <Loading />}
      {status === 'idle' && incentivesForUsedVehicles && (
        <IncentiveCatalog incentives={incentivesForUsedVehicles} />
      )}
      <Box textAlign="center">
        <ButtonLink
          to="/incentives"
          onClick={() => {
            setUserPrefs({
              householdIncome: 50000,
              householdSize: 4,
              taxFilingStatus: 'head_of_household',
              zipcode: '95110',
            });
          }}
        >
          {formatMessage({ id: 'incomeQualified.incentives.personalizeButton' })}
        </ButtonLink>
      </Box>
    </Box>
  );
};

export default Incentives;
