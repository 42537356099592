import { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';

import BaseGoogleMap from '../baseGoogleMap/BaseGoogleMap';
import DealerCard from '../dealerCard/DealerCard';
import DealersList from '../dealersList/DealersList';

import useMapBounds from '../../hooks/useMapBounds';
import useDealers from '../../hooks/useDealers';
import useSelectedDealer from '../../hooks/useSelectedDealer';
import useMappedZipcode from '../../hooks/useMappedZipcode';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import useOemImages from '../../hooks/useOemImages';

import Select from '../../../select/Select';
import type { Dealer, PspsEvent } from '../../../../types';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import haversineDistance from '../../../../utils/Helpers/haversineDistance';
import useGeoJsonUrls from '../../hooks/useGeojsonUrls';
import ZipcodeInputWithButton from '../../../zipcodeInputWithButton/ZipcodeInputWithButton';
import GaTracker from '../../../../utils/GaTracker/GaTracker';
import MapLegend from '../mapLegend/MapLegend';
import PspsEventLegend from '../pspsEventLegend/PspsEventLegend';
import { Box } from '@mui/material';
import DealerMarker from '../dealerMarker/DealerMarker';

const SelectSpecificDealer = ({
  dealers,
  onChange,
  selectedDealerId = '',
}: {
  dealers: Array<Dealer>;
  onChange: (dealerId: string) => void;
  selectedDealerId?: string;
}) => {
  const { formatMessage } = useIntl();

  const sortedDealers = [...dealers].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Select
      value={selectedDealerId === '' ? 'empty' : selectedDealerId}
      onChange={(value: string) => {
        onChange(value === 'empty' ? '' : value);
      }}
      label={formatMessage({ id: 'maps.dealersMap.dealers' })}
      options={[
        {
          label: formatMessage({ id: 'maps.dealersMap.allDealers' }),
          value: 'empty',
        },
        ...sortedDealers.map((dealer) => ({
          value: dealer.handle,
          label: dealer.name,
        })),
      ]}
    />
  );
};

const SelectOem = ({
  dealers,
  onChange,
  selectedOem = '',
}: {
  dealers: Array<Dealer>;
  onChange: (value: string) => void;
  selectedOem?: string;
}) => {
  const { formatMessage } = useIntl();

  const oems = dealers.map((d) => d.oem);
  const uniqueOems = uniq(oems);
  const sortedOems = uniqueOems.sort((a, b) => a.localeCompare(b));

  if (sortedOems.length < 2) {
    return null;
  }

  return (
    <Select
      value={selectedOem === '' ? 'empty' : selectedOem}
      onChange={(value: string) => {
        onChange(value === 'empty' ? '' : value);
      }}
      label={formatMessage({ id: 'maps.dealersMap.brands' })}
      options={[
        {
          label: formatMessage({ id: 'maps.dealersMap.allBrands' }),
          value: 'empty',
        },
        ...sortedOems.map((oem) => ({
          label: oem,
          value: oem,
        })),
      ]}
    />
  );
};

type Props = {
  isVisible?: boolean;
  oems?: Array<string>;
  pspsEvents?: Array<PspsEvent>;
};

export default function DealersMap({
  isVisible = true,
  oems = [],
  pspsEvents = [],
}: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  const [hoveredDealer, setHoveredDealer] = useState<Dealer>();
  const [selectedOem, setSelectedOem] = useState<string>();

  const { bounds, center, filterWithinBounds, registerMapBoundsListeners } = useMapBounds();

  const { dealers, fetchDealers } = useDealers();

  const { selectedDealer, selectDealer, deselectDealers } = useSelectedDealer(dealers);
  const { images: oemImages } = useOemImages();

  const {
    zipcode: geocodedZipcode,
    loading: isFetchingZipcode,
    error: zipcodeError,
    fetchZipcode,
    registerMappedZipcodeListeners,
  } = useMappedZipcode({
    zoom: 10,
  });

  useEffect(() => {
    if (geocodedZipcode && geocodedZipcode !== userPrefs.zipcode) {
      setUserPrefs({ zipcode: geocodedZipcode });
    }
  }, [geocodedZipcode, setUserPrefs, userPrefs.zipcode]);

  useDeepCompareEffect(() => {
    if (!center || !bounds) return;
    const { lat, lng } = center;
    const coordinates = [
      { lat: bounds.top, lng: bounds.left },
      { lat: bounds.top, lng: bounds.right },
      { lat: bounds.bottom, lng: bounds.left },
      { lat: bounds.bottom, lng: bounds.right },
    ];

    const distancesInMeters = coordinates.map(
      (coordinate) => haversineDistance(center, coordinate, false) * 1000,
    );

    const maxDistanceInMeters = Math.max(...distancesInMeters);

    fetchDealers({
      lat,
      lng,
      radiusInMi: maxDistanceInMeters / 1609,
    });
  }, [center, bounds, haversineDistance]);

  const registerGeojsonUrlMap = useGeoJsonUrls(pspsEvents.flatMap((event) => event.fileUrls));

  const uppercaseOems = oems.map((oem) => oem.toUpperCase());
  const visibleDealers = filterWithinBounds(dealers).filter((dealer) => {
    return oems.length > 0 ? uppercaseOems.includes(dealer.oem.toUpperCase()) : true;
  });

  const filteredDealers = selectedDealer
    ? [selectedDealer]
    : visibleDealers.filter((dealer) => {
        return selectedOem ? dealer.oem === selectedOem : true;
      });

  const selectedOemImage = oemImages.find((image) => {
    if (!selectedDealer || !selectedDealer.oem) return null;
    return image.name.toUpperCase() === selectedDealer.oem.toUpperCase();
  });

  const currentDealer = hoveredDealer || selectedDealer;

  return (
    <Box display="flex" flexDirection="column" gap="1.75rem">
      <ZipcodeInputWithButton
        inline
        buttonText={formatMessage({ id: 'maps.dealersMap.searchQualifiedDealers' })}
        error={zipcodeError}
        isLoading={isFetchingZipcode}
        onSubmitZipcode={fetchZipcode}
        zipcode={userPrefs.zipcode}
      />
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="1rem">
        <Box flex="1 1" minWidth="270px">
          {isVisible && (
            <BaseGoogleMap
              onLoad={(map) => {
                registerMapBoundsListeners(map);
                registerMappedZipcodeListeners(map, userPrefs.zipcode);
                registerGeojsonUrlMap(map);
              }}
              onMapClick={() => {
                if (selectedDealer) {
                  deselectDealers();
                }
              }}
            >
              {filteredDealers.map((dealer) => {
                if (!dealer.lat || !dealer.lng) return null;
                return (
                  <DealerMarker
                    key={dealer.handle}
                    lat={dealer.lat}
                    lng={dealer.lng}
                    label={dealer.name}
                    isSelected={dealer.handle === (selectedDealer || {}).handle}
                    onClick={() => {
                      if (selectedDealer) {
                        deselectDealers();
                      } else {
                        selectDealer(dealer.handle);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredDealer(dealer);
                    }}
                    onMouseLeave={() => {
                      setHoveredDealer(undefined);
                    }}
                  />
                );
              })}
            </BaseGoogleMap>
          )}
          <MapLegend>{pspsEvents.length > 0 && <PspsEventLegend />}</MapLegend>
        </Box>
        <Box
          width={{ xs: '100%', sm: '240px' }}
          display="flex"
          flexDirection="column"
          gap="1.75rem"
        >
          <SelectOem
            dealers={visibleDealers}
            selectedOem={selectedOem || ''}
            onChange={(oem) => {
              deselectDealers();
              setSelectedOem(oem);
              GaTracker.trackEvent({
                category: 'Dealers',
                action: 'Filtered List to a Single OEM',
                label: oem,
              });
            }}
          />
          <SelectSpecificDealer
            dealers={
              selectedOem
                ? visibleDealers.filter((dealer) => dealer.oem === selectedOem)
                : visibleDealers
            }
            selectedDealerId={currentDealer?.handle || ''}
            onChange={(dealerId) => {
              selectDealer(dealerId);
              const dealer = dealers.find((d) => d.handle === dealerId);
              GaTracker.trackEvent({
                category: 'Dealers',
                action: 'Filtered List to a Single Dealer',
                label: dealer ? dealer.name : 'Unknown',
              });
            }}
          />
          {currentDealer && <DealerCard oemImage={selectedOemImage} {...currentDealer} />}
        </Box>
      </Box>
      <DealersList dealers={filteredDealers} oemImages={oemImages} />
    </Box>
  );
}
