import type { PurchaseMethod } from './common';
import type { RateKey } from './rate';
import type { ElectricVehicle } from './vehicle';

export type VehicleFilters = {
  type: string;
  subtype: Array<string>;
  rangeMin: number;
  priceMax: number | null;
  seatsMin: number;
  fuelType: Array<'BEV' | 'PHEV'>;
  vehicleStatus: 'new' | 'used' | 'upcoming';
  sort: {
    field: keyof ElectricVehicle;
    order: 'asc' | 'desc';
  };
  showCount: number;
};

export type UserPrefs = {
  canTurnInClunker: boolean;
  chargerInstallAllowed: boolean | null;
  chargerLevel: string;
  closedExperienceSurveyAt: number | null;
  comparedVehicleId1: string | null;
  comparedVehicleId2: string | null;
  currentElectricRate: RateKey;
  electricMilesPortionForPhev: number;
  equivalentMilesPerGallon: number;
  gasolinePriceInCentsPerGal: number;
  hasCareDiscount: boolean;
  hasOvernightCharger: boolean | null;
  hasRegularPowerOutlet: boolean | null;
  hasSolarAtHome: boolean;
  solarPanelPower: number;
  solarPanelCount: number;
  homeElectricBillMonthlyAvg: number;
  householdIncome: number;
  householdSize: number;
  includeResaleValue: boolean;
  interestRateAsBasisPoints: number;
  maxBudget: number;
  milesDrivenAnnually: number;
  milesDrivenDaily: number;
  minSeats: number;
  monthsOfOwnership: number;
  parkingLocation: string;
  publicChargingCostInCentsPerKwh: number;
  publicChargingPercentage: number;
  purchaseMethod: PurchaseMethod;
  showGasolineCost: boolean;
  showHomeElectricityCost: boolean;
  showRateDetails: boolean;
  showDetailsForRate: string;
  taxFilingStatus: string;
  vehicleChargingPattern: string;
  vehicleMakeFilter: string;
  vehicleSortDirection: string;
  vehicleSortType: string;
  zipcode: string;
  zipcodeError: boolean;
  zipcodeLastValid: string;
  zipcodeIsDefault: boolean;

  vehicleFuelTypeFilters: {
    bev: boolean;
    phev: boolean;
  };

  vehicleFormFactorFilters: {
    sedan: boolean;
    hatchback: boolean;
    coupe: boolean;
    crossover: boolean;
    minivan: boolean;
    suv: boolean;
    'station wagon': boolean;
    truck: boolean;
  };

  vehicleFilters: VehicleFilters;
};

export const isVehicleStatus = (
  vehicleStatus: string,
): vehicleStatus is VehicleFilters['vehicleStatus'] => {
  return ['new', 'used', 'upcoming'].includes(vehicleStatus);
};

export const isSortOrder = (order: string): order is VehicleFilters['sort']['order'] => {
  return ['asc', 'desc'].includes(order);
};

export const isSortField = (fieldName: string): fieldName is VehicleFilters['sort']['field'] => {
  return ['electricRange', 'make', 'msrp'].includes(fieldName);
};
