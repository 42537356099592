import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useElectricVehicles from '../../api/hooks/useElectricVehicles';
import Card from '../../components/card/Card';
import { LeftArrowIcon } from '../../components/icon/Vectors';
import Image from '../../components/image/Image';
import Link from '../../components/link/Link';
import Loading from '../../components/loading/Loading';
import TravelRouteMap from '../../components/maps/components/TravelRouteMap/TravelRouteMap';
import useChargerAvailability from '../../components/maps/hooks/useChargerAvailability';
import useChargerDetails from '../../components/maps/hooks/useChargerDetails';
import ConnectorAvailability from './ConnectorAvailability';
import EstimatedChargingCost from './EstimatedChargingCost';
import EvGoChargerImage from '../../assets/images/chargers/evgo-charger.png';
import EvConnectChargerImage from '../../assets/images/chargers/ev-connect-charger.png';
import BlinkChargerImage from '../../assets/images/chargers/blink-charger.png';
import TeslaChargerImage from '../../assets/images/chargers/tesla-charger.png';
import SemaConnectChargerImage from '../../assets/images/chargers/sema-connect-charger.png';
import ElectrifyAmericaChargerImage from '../../assets/images/chargers/electrify-america-charger.png';
import ChargepointChargerImage from '../../assets/images/chargers/chargepoint-charger.png';
import GenericChargerImage from '../../assets/images/chargers/generic-charger.png';
import { useIntl } from 'react-intl';
import ContactInfo from './components/ContactInfo';

const chargerImages = [
  {
    image: EvGoChargerImage,
    urlPattern: 'evgo',
  },
  {
    image: EvConnectChargerImage,
    urlPattern: 'evconnect',
  },
  {
    image: BlinkChargerImage,
    urlPattern: 'blinkcharging',
  },
  {
    image: TeslaChargerImage,
    urlPattern: 'tesla',
  },
  {
    image: SemaConnectChargerImage,
    urlPattern: 'semaconnect',
  },
  {
    image: ElectrifyAmericaChargerImage,
    urlPattern: 'electrifyamerica',
  },
  {
    image: ChargepointChargerImage,
    urlPattern: 'chargepoint',
  },
];

export default function ChargingStationDetails() {
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ id: string }>('/charging-station-availability/:id');
  const { vehicles, status } = useElectricVehicles();

  const [selectedVehicle, setSelectedVehicle] = useState(vehicles?.[0]);
  const { charger, loading } = useChargerDetails(match?.params.id || '');
  const { availability } = useChargerAvailability(
    charger?.dataSources?.chargingAvailability.id || '',
  );

  useEffect(() => {
    if (!selectedVehicle && vehicles) {
      setSelectedVehicle(vehicles[0]);
    }
  }, [status, vehicles, selectedVehicle]);

  const handleChangeSelectedVehicle = (vehicleId: string) => {
    setSelectedVehicle(
      (vehicles || []).find(({ handle }) => handle === vehicleId) || vehicles?.[0],
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (!charger) {
    return null;
  }

  const chargerImage = charger.poi.name.includes('ChargePoint')
    ? ChargepointChargerImage
    : chargerImages.find(({ urlPattern }) => charger.poi.url?.includes(urlPattern))?.image ||
      GenericChargerImage;

  return (
    <Box bgcolor="grey.100" py={4}>
      <Container>
        <Link to="/charging-station-availability">
          <Box display="flex" alignItems="center" mb={6}>
            <LeftArrowIcon />
            <Typography
              variant="h5"
              fontWeight="bold"
              color="black"
              ml={2}
              textTransform="uppercase"
            >
              {formatMessage({ id: 'chargerDetails.backToMap' })}
            </Typography>
          </Box>
        </Link>

        <Typography variant="h1" mb={3}>
          {charger.poi.name}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ConnectorAvailability charger={charger} connectors={availability?.connectors} />
              </Grid>
              <Grid item xs={12}>
                <ContactInfo charger={charger} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            {chargerImage && (
              <Image
                src={chargerImage}
                alt={charger.poi.name}
                height="auto"
                width="100%"
                sx={{ maxHeight: 360, objectFit: 'contain', mb: 3 }}
              />
            )}
          </Grid>
        </Grid>

        <Box mt={3}>
          {vehicles && status === 'idle' && selectedVehicle && (
            <EstimatedChargingCost
              selectedVehicle={selectedVehicle}
              vehicles={vehicles}
              onVehicleChange={handleChangeSelectedVehicle}
            />
          )}
        </Box>

        <Card sx={{ mt: 3 }} id="charger-details-route-map">
          <Typography variant="h5" mb={2} fontWeight="bold">
            {formatMessage({ id: 'chargerDetails.routeToChargerHeadline' })}
          </Typography>
          <TravelRouteMap defaultDestinationAddress={charger.address.freeformAddress} />
        </Card>
      </Container>
    </Box>
  );
}
