import { SvgIcon, useTheme } from '@mui/material';

interface CheckMarkIconProps {
  color?: string;
}
export default function CheckMarkIcon({ color, ...rest }: CheckMarkIconProps) {
  const {
    palette: {
      grey: { 500: gray500 },
    },
  } = useTheme();
  const iconColor = color || gray500;
  return (
    <SvgIcon
      height="48"
      width="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" transform="translate(2 2)">
        <circle cx="22" cy="22" r="22" stroke={iconColor} strokeWidth="3" />
        <path
          d="m32.6085075 14.6544183-2.8913325-2.5431393c-.0887172-.0785319-.2066352-.1184672-.3229929-.1102124-.1179181.0078086-.2280344.0620224-.306052.15104l-10.4869038 11.9462179-3.7287959-4.9397006c-.0711074-.0941491-.1769885-.1563945-.2940149-.1724578-.1168035-.017402-.236059.0142785-.3301259.0858942l-3.0712189 2.3220453c-.1963814.1485859-.2353902.4283557-.0869339.6249086l6.9043354 9.1462882c.0806925.1068658.2055206.1720116.3392651.1769198h.0162723c.1279488 0 .2501021-.0548831.3350299-.1517093l13.9638142-15.9065009c.1627225-.1849515.144667-.4671755-.0403462-.6295937z"
          fill={iconColor}
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
}
