import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { useIntl } from 'react-intl';
import isElectricVehicle from '../../../../calculations/vehicle/isElectricVehicle';
import Card from '../../../../components/card/Card';
import { ElectricVehicle, GasVehicle } from '../../../../types';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatVehicleDisplayName } from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle;
  vehicle2: ElectricVehicle | GasVehicle;
};

const getChartOptions = (isLargeScreen: boolean): ChartOptions => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  layout: {
    padding: {
      right: isLargeScreen ? 75 : 35,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      display: true,
      onClick: () => {},
      labels: {
        boxHeight: 12,
        boxWidth: 12,
      },
    },
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets as any[];
        if (ctx.datasetIndex === datasets.length - 1) {
          let sum = 0;
          datasets.forEach((dataset) => {
            sum += dataset.data[ctx.dataIndex];
          });
          return `${sum} miles`;
        } else {
          return '';
        }
      },
      align: 'end',
      anchor: 'end',
      color: '#333333',
      font: {
        weight: 700,
      },
    },
    tooltip: {
      ...baseTooltipStyles,
      callbacks: {
        label: (item) => `${item.dataset.label}: ${item.raw} miles`,
      },
    },
  },
});

export default function RangeChart({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const chartData = {
    labels: [formatVehicleDisplayName(vehicle1), formatVehicleDisplayName(vehicle2)],
    datasets: [
      {
        label: formatMessage({ id: 'compare.charts.electricity' }),
        backgroundColor: theme.palette.orange[400],
        data: [
          isElectricVehicle(vehicle1) ? vehicle1.electricRange : 0,
          isElectricVehicle(vehicle2) ? vehicle2.electricRange : 0,
        ],
        barPercentage: 0.6,
      },
      {
        label: formatMessage({ id: 'compare.charts.gasoline' }),
        backgroundColor: theme.palette.grey[800],
        data: [
          isElectricVehicle(vehicle1)
            ? vehicle1.totalRange - vehicle1.electricRange
            : vehicle1.totalRange,
          isElectricVehicle(vehicle2)
            ? vehicle2.totalRange - vehicle2.electricRange
            : vehicle2.totalRange,
        ],
        barPercentage: 0.6,
      },
    ],
  };

  const isSameRange = vehicle1.totalRange === vehicle2.totalRange;
  const isVehicle1RangeHigher = vehicle1.totalRange > vehicle2.totalRange;

  const title = isSameRange
    ? formatMessage(
        { id: 'compare.charts.sameRange' },
        {
          vehicle1: formatVehicleDisplayName(vehicle1),
          vehicle2: formatVehicleDisplayName(vehicle2),
        },
      )
    : formatMessage(
        { id: 'compare.charts.longerRange' },
        {
          vehicle: formatVehicleDisplayName(isVehicle1RangeHigher ? vehicle1 : vehicle2),
        },
      );

  return (
    <Card>
      <Box>
        <Typography textAlign="center" fontWeight="bold" mb={1}>
          {title}
        </Typography>
        <Box height={220}>
          <ReactChart
            height={220}
            type="bar"
            options={getChartOptions(isLargeScreen)}
            data={chartData}
          />
        </Box>
      </Box>
    </Card>
  );
}
