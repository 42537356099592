import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

export default function Header() {
  const { formatMessage } = useIntl();
  return (
    <Box maxWidth="1140px" mx="auto" textAlign="center">
      <Typography component="h1" fontSize="2rem" fontWeight="500" mb={3}>
        {formatMessage({ id: 'incomeQualified.header.title' })}
      </Typography>
      <Box>{formatMessage({ id: 'incomeQualified.header.subtitle' })}</Box>
    </Box>
  );
}
