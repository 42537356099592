import { useState, useEffect } from 'react';
import { ApiOemImage, OemImage } from '../../../types';
import { fetchRecords } from '../../../utils/fetch';

const path = '/assets/oems';

const fetchOemImages = (
  params: Record<string, string> = {},
): Promise<{ assets: Array<ApiOemImage> }> => {
  return fetchRecords(path, {
    ...params,
  });
};

const transformOemImage = (apiImage: ApiOemImage): OemImage => {
  return {
    name: apiImage.name,
    imageUrl: apiImage.image_url,
  };
};

const useOemImages = () => {
  const [images, setImages] = useState<Array<OemImage>>([]);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');

  useEffect(() => {
    const fetch = async () => {
      try {
        setStatus('loading');
        const data = await fetchOemImages();
        setImages(data.assets.map(transformOemImage));
        setStatus('idle');
      } catch (err) {
        setStatus('error');
        setError('Could not load incentives');
      }
    };
    if (!images && status === 'idle') {
      fetch();
    }
  }, [status, images]);

  return {
    status,
    error,
    images: images || [],
  };
};

export default useOemImages;
