import { Box } from '@mui/material';
import Section from '../../components/section/Section';
import ElectricVehicles from './components/electricVehicles/ElectricVehicles';
import Header from './components/header/Header';
import Incentives from './components/incentives/Incentives';
import MapLauncher from './components/mapLauncher/MapLauncher';
import WhyElectric from './components/whyElectric/WhyElectric';
import FinancialAssistancePrograms from './components/financialAssistancePrograms/FinancialAssistancePrograms';
import Page from '../../components/page/Page';

const IncomeQualified = () => {
  return (
    <Page>
      <Box bgcolor="grey.100">
        <Section>
          <Header />
        </Section>
        <Section>
          <WhyElectric />
        </Section>
        <Section sx={{ maxWidth: '990px' }}>
          <ElectricVehicles />
        </Section>
        <Section sx={{ backgroundColor: 'white.100' }}>
          <FinancialAssistancePrograms />
        </Section>
        <Section>
          <Incentives />
        </Section>
        <Section sx={{ backgroundColor: 'white.100' }}>
          <MapLauncher />
        </Section>
      </Box>
    </Page>
  );
};

export default IncomeQualified;
