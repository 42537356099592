import { ElectricVehicle, VehicleFilters } from '../types';
import uniq from 'lodash/uniq';
import { VehicleCost } from '../calculations/vehicle/CostOfOwnership/calcs';

type Filters = VehicleFilters & { make: string | null };

/**
 * Given some vehicles and some constraints,
 * return the vehicles that match, along
 * with some Option values the client code
 * could use in a Select.
 *
 * The `filters` *can* be the ones from
 * UserPrefs.vehicleFilters, but they could
 * also be a Partial of that, and they could
 * even include an extra `make` field.
 *
 * Any `filters` field not provided will not
 * remove any vehicles from the results.
 *
 * The `visibleVehicles` return value will
 * have all `filters` applied.
 *
 * The `types` return value will give all
 * `type` values from the given vehicles.
 *
 * The `subtypes` return value will have
 * only those `subtype` values available
 * after the vehicles have been filtered by
 * `type`.
 *
 * The `makes` return value will have only
 * those `make` values available after the
 * vehicles have been filtered by `type`,
 * `subtype`, and `vehicleStatus`.
 *
 * The `models` return value will have only
 * those values available after the vehicles
 * have been filtered by `type`, `subtype`,
 * `vehicleStatus`, and `make`.
 */
const filterVehicles = (allVehicles: ElectricVehicle[], filters: Partial<Filters>) => {
  // Get vehicles limited by the selected TYPE (such as "Passenger" or "School")
  const vehiclesOfType = allVehicles.filter(
    (vehicle) => !filters.type || vehicle.type === filters.type,
  );

  // Get vehicles limited by the selected SUBTYPE
  const vehiclesOfSubtype = vehiclesOfType.filter(
    (vehicle) =>
      !filters.subtype || filters.subtype.length === 0 || filters.subtype.includes(vehicle.subtype),
  );

  // Get vehicles limited by the selected STATUS (such as "Used" or "New")
  const vehiclesOfStatus = vehiclesOfSubtype.filter(
    (vehicle) => !filters.vehicleStatus || filters.vehicleStatus === vehicle.status,
  );

  const vehiclesOfMake = vehiclesOfStatus.filter(
    (vehicle) =>
      !filters.make ||
      filters.make === 'All' ||
      filters.make?.toLowerCase() === vehicle.make.toLowerCase(),
  );

  // Get a set of vehicles to show
  const visibleVehicles = vehiclesOfMake
    .filter((ev) => {
      if (filters.rangeMin && ev.totalRange < filters.rangeMin) {
        return false;
      }
      if (filters.priceMax && VehicleCost.afterIncentives(ev) > filters.priceMax) {
        return false;
      }
      if (filters.seatsMin && ev.seatsMin < filters.seatsMin) {
        return false;
      }
      if (
        filters.fuelType &&
        filters.fuelType.length > 0 &&
        !filters.fuelType.includes(ev.fuelType)
      ) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (!filters.sort) {
        return 0;
      }
      const valueA = a[filters.sort.field] || '';
      const valueB = b[filters.sort.field] || '';
      if (filters.sort.order === 'asc') {
        return valueA > valueB ? 1 : -1;
      }
      return valueA <= valueB ? 1 : -1;
    });

  // Get values for a TYPE dropdown
  // from the entire pool
  const types = uniq(allVehicles.map((vehicle) => vehicle.type)).sort((a, b) => a.localeCompare(b));

  // Get values for a SUBTYPE dropdown
  // from the TYPE-filtered vehicles
  const subtypes = uniq(vehiclesOfType.map((vehicle) => vehicle.subtype)).sort((a, b) =>
    a.localeCompare(b),
  );

  // Get values for a MAKE dropdown
  // from the TYPE-, SUBTYPE-, and
  // STATUS-filtered vehicles
  const makes = uniq(vehiclesOfStatus.map((vehicle) => vehicle.make)).sort((a, b) =>
    a.localeCompare(b),
  );

  // Get values for a MODEL dropdown
  // from the TYPE-, SUBTYPE-, STATUS-,
  // and MAKE-filtered vehicles
  const models: Record<string, string> = vehiclesOfMake.reduce((models, vehicle) => {
    return {
      ...models,
      [vehicle.handle]: vehicle.displayName,
    };
  }, {});

  return {
    visibleVehicles,
    types,
    subtypes,
    makes,
    models,
  };
};

export default filterVehicles;
