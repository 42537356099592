import { ElectricVehicle, EquivalentFossilVehicle } from '../../types';
import isBEV from './isBEV';
import isPHEV from './isPHEV';

const calcVehicleGasolineCost = (
  vehicle: EquivalentFossilVehicle | ElectricVehicle,
  electricMilesPortionForPhev: number,
  miles: number,
  gasolinePriceInCentsPerGal: number,
  milesPerGallonOverwrite?: number,
): number => {
  if (isBEV(vehicle)) return 0;

  const PHEVFuelSplitAdjustment =
    isPHEV(vehicle) && electricMilesPortionForPhev > 0 ? 1 - electricMilesPortionForPhev / 100 : 1;

  let mpg = milesPerGallonOverwrite
    ? milesPerGallonOverwrite
    : vehicle && vehicle.fossilFuelEfficiency && vehicle.fossilFuelEfficiency > 0
    ? vehicle.fossilFuelEfficiency
    : 0;

  let cost = (gasolinePriceInCentsPerGal / 100) * (1 / mpg) * PHEVFuelSplitAdjustment * miles;

  return isFinite(cost) ? cost : 0;
};

export default calcVehicleGasolineCost;
