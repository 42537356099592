import backgroundImg from './../../../../assets/images/charging-1253-cropped.jpg';
import { Box, Typography } from '@mui/material';
import LinkCardSet from '../../../../components/linkCard/LinkCardSet';
import Icon from '../../../../components/icon/Icon';
import { useIntl } from 'react-intl';

export default function BigPromise() {
  const { formatMessage } = useIntl();
  const LINK_CARDS = [
    {
      icon: <Icon variant="driving" />,
      link: '#HomepageVehicles',
      title: formatMessage({ id: 'homepage.intro.browse' }),
    },
    {
      icon: <Icon variant="savings" />,
      link: '#HomepageIncentives',
      title: formatMessage({ id: 'homepage.intro.discover' }),
    },
    {
      icon: <Icon variant="charging" />,
      link: '#HomepageRateComparison',
      title: formatMessage({ id: 'homepage.intro.compare' }),
    },
    {
      icon: <Icon variant="road" />,
      link: '/charging-stations',
      title: formatMessage({ id: 'homepage.intro.locate' }),
    },
    {
      icon: <Icon variant="lightning" />,
      link: '/ev-owners',
      title: formatMessage({ id: 'homepage.intro.existingEvOwners' }),
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0,0,0,.5)',
            textAlign: 'center',
            py: '120px',
            px: { xs: 3, sm: 5 },
            minHeight: '400px',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: 'white.100',
              textShadow: '2px 2px 3px hsl(0deg 0% 100% / 10%)',
            }}
          >
            {formatMessage({ id: 'homepage.intro.headline' })}
          </Typography>
          <Typography
            variant="h4"
            mt="1.5rem"
            sx={{
              color: 'white.100',
              textShadow: '2px 2px 3px hsl(0deg 0% 100% / 10%)',
            }}
          >
            {formatMessage({ id: 'homepage.intro.details' })}
          </Typography>
        </Box>
      </Box>
      <LinkCardSet mt="-70px" linkCards={LINK_CARDS} />
    </>
  );
}
