import { Box } from '@mui/material';
import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import Select from '../../../../components/select/Select';
import { PspsEvent } from '../../../../types';

export default function PspsEventList({ pspsEvents }: { pspsEvents?: PspsEvent[] }): JSX.Element {
  const [languageKey, setLanguageKey] = useState<string>();

  if (!pspsEvents?.length) {
    return <></>;
  }

  // Get a dictionary of all possible languages and their display values
  const languages = pspsEvents.reduce((dictionary: Record<string, string>, pspsEvent) => {
    Object.keys(pspsEvent.content).forEach((key) => {
      dictionary[key] = pspsEvent.content[key].name;
    });
    return dictionary;
  }, {});

  const currentLanguageKey = languageKey || 'en';
  const eventsInCurrentLanguage = pspsEvents?.filter(
    (pspsEvent) => pspsEvent.content[currentLanguageKey],
  );

  return (
    <Box mb={2}>
      {eventsInCurrentLanguage.map(({ id, content }) => (
        <React.Fragment key={id}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content[currentLanguageKey].header),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content[currentLanguageKey].htmlDescription),
            }}
          />
        </React.Fragment>
      ))}
      <Box>
        <Select
          label="Language"
          value={currentLanguageKey}
          onChange={(value) => setLanguageKey(value)}
          options={Object.keys(languages).map((languageKey) => ({
            value: languageKey,
            label: languages[languageKey],
          }))}
        />
      </Box>
    </Box>
  );
}
