import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { useIntl } from 'react-intl';
import calcCharging from '../../../../calculations/vehicle/Charging/calcCharging';
import Card from '../../../../components/card/Card';
import { ElectricVehicle } from '../../../../types';
import { DCFC_CHARGING_RATE } from '../../../../utils/assumptions';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatAsTime, formatVehicleDisplayName } from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle;
  vehicle2: ElectricVehicle;
};

const getChartOptions = (isLargeScreen: boolean): ChartOptions => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  layout: {
    padding: {
      right: isLargeScreen ? 75 : 35,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets as any[];
        if (ctx.datasetIndex === datasets.length - 1) {
          let sum = 0;
          datasets.forEach((dataset) => {
            sum += dataset.data[ctx.dataIndex];
          });
          return `${Math.round(sum)} miles`;
        } else {
          return '';
        }
      },
      align: 'end',
      anchor: 'end',
      color: '#333333',
      font: {
        weight: 700,
      },
    },
    tooltip: {
      ...baseTooltipStyles,
      callbacks: {
        label: (item) => formatAsTime(item.raw as number),
      },
    },
  },
});

export default function FastChargingChart({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const vehicle1Miles = calcCharging.milesPerHalfHourOfFastCharge(
    vehicle1.electricEfficiency,
    Math.min(DCFC_CHARGING_RATE, vehicle1.dcChargingPower || Number.POSITIVE_INFINITY),
    vehicle1.electricRange,
  );

  const vehicle2Miles = calcCharging.milesPerHalfHourOfFastCharge(
    vehicle2.electricEfficiency,
    Math.min(DCFC_CHARGING_RATE, vehicle2.dcChargingPower || Number.POSITIVE_INFINITY),
    vehicle2.electricRange,
  );

  const chartData = {
    labels: [formatVehicleDisplayName(vehicle1), formatVehicleDisplayName(vehicle2)],
    datasets: [
      {
        backgroundColor: theme.palette.blue[300],
        data: [vehicle1Miles, vehicle2Miles],
        barPercentage: 0.6,
      },
    ],
  };

  const isSameMiles = vehicle1Miles === vehicle2Miles;
  const isVehicle1More = vehicle1Miles > vehicle2Miles;

  const title = isSameMiles
    ? formatMessage(
        { id: 'compare.charts.fastChargingSameMiles' },
        {
          vehicle1: formatVehicleDisplayName(vehicle1),
          vehicle2: formatVehicleDisplayName(vehicle2),
        },
      )
    : formatMessage(
        { id: 'compare.charts.fastChargingMoreMiles' },
        {
          vehicle: formatVehicleDisplayName(isVehicle1More ? vehicle1 : vehicle2),
        },
      );

  return (
    <Card>
      <Box>
        <Typography textAlign="center" fontWeight="bold" mb={1}>
          {title}
        </Typography>
        <Box height={200}>
          <ReactChart
            height={200}
            type="bar"
            options={getChartOptions(isLargeScreen)}
            data={chartData}
          />
        </Box>
      </Box>
    </Card>
  );
}
