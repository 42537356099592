import { Box, Typography } from '@mui/material';
import { ReactChart } from 'chartjs-react';
import { toNumber } from 'lodash';
import { useIntl, IntlFormatters } from 'react-intl';
import useElectricVehicleDetails from '../../../../api/hooks/useElectricVehicleDetails';
import genRateCompData, {
  RateCompData,
} from '../../../../calculations/genRateCompData/genRateCompData';
import Loading from '../../../../components/loading/Loading';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatAsDollars } from '../../../../utils/formatters';
import { ChartData } from 'chart.js';
import type { UserPrefs } from '../../../../types/userPrefs';

function generateChartData(
  graphData: RateCompData,
  userPrefs: UserPrefs,
  formatMessage: IntlFormatters['formatMessage'],
): ChartData {
  const labels = [...graphData.rateOptions.labels];

  const output = {
    home: [...graphData.rateOptions.home],
    ev: [...graphData.rateOptions.ev],
    gasoline: [...graphData.rateOptions.gasoline],
  };

  // Set datasets in order: Home (if selected), Electricity for EV, then
  // Gasoline (if selected)
  let datasets = userPrefs.showHomeElectricityCost
    ? [
        {
          label: formatMessage({ id: 'rates.chart.homeElec' }),
          data: output.home,
          backgroundColor: '#3C87BF',
        },
      ]
    : [];

  datasets = [
    ...datasets,
    {
      label: formatMessage({
        id: userPrefs.hasSolarAtHome ? 'rates.chart.evPlusSolar' : 'rates.chart.ev',
      }),
      data: output.ev,
      backgroundColor: '#FBBB36',
    },
  ];

  if (userPrefs.showGasolineCost) {
    datasets = [
      ...datasets,
      {
        label: formatMessage({ id: 'rates.chart.gas' }),
        data: output.gasoline,
        backgroundColor: '#333333',
      },
    ];
  }

  return {
    labels,
    datasets,
  };
}

type AllRatesParams = {
  historicalLoadProfile: Array<number> | null;
  usingHistorical: boolean;
};

export default function AllRates({ historicalLoadProfile, usingHistorical }: AllRatesParams) {
  const { formatMessage } = useIntl();

  const { userPrefs } = useUserPrefs();
  const { vehicle, status } = useElectricVehicleDetails(userPrefs.comparedVehicleId1);

  if (!userPrefs.comparedVehicleId1)
    return <Box>{formatMessage({ id: 'rates.allRates.noVehicleMessage' })}</Box>;
  if (status === 'loading') return <Loading />;
  if (!vehicle) return <Box>{formatMessage({ id: 'rates.allRates.noVehicleMessage' })}</Box>;

  if (usingHistorical && !historicalLoadProfile) {
    return <Loading />;
  }

  const graphData = genRateCompData(userPrefs, vehicle, historicalLoadProfile || undefined);
  if (graphData == null) return null;

  const currentTotal =
    graphData.currentRate.home + graphData.currentRate.ev + graphData.currentRate.gasoline;

  const data = generateChartData(graphData, userPrefs, formatMessage);

  return (
    <Box>
      <Typography variant="h5" pb={2}>
        {formatMessage({
          id: usingHistorical ? 'rates.allRates.historicalTitle' : 'rates.allRates.title',
        })}
      </Typography>
      {formatMessage({
        id: userPrefs.hasSolarAtHome
          ? 'rates.allRates.subheaderWithSolar'
          : 'rates.allRates.subheader',
      })}
      <Box>
        <ReactChart
          type="bar"
          data={data}
          width={100}
          height={400}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                top: 20,
              },
            },
            scales: {
              x: {
                stacked: true,
                ticks: {
                  font: {
                    size: 14,
                  },
                },
              },
              y: {
                stacked: true,
                ticks: {
                  callback: (value) => formatAsDollars(toNumber(value)),
                  font: {
                    size: 14,
                  },
                },
                title: {
                  display: true,
                  text: formatMessage({ id: 'rates.allRates.costLabel' }),
                  font: {
                    size: 14,
                  },
                },
              },
            },
            plugins: {
              legend: {
                position: 'bottom',
                title: {
                  display: false,
                },
                onHover: (evt, item, legend) => {
                  if (Array.isArray(legend.chart.data.datasets[0].backgroundColor)) {
                    legend.chart.data.datasets[0].backgroundColor?.forEach(
                      (color, index, colors) => {
                        colors[index] =
                          index === item.datasetIndex || color.length === 9 ? color : color + '4D';
                      },
                    );
                    legend.chart.update();
                  }
                },
                onLeave: (evt, item, legend) => {
                  if (Array.isArray(legend.chart.data.datasets[0].backgroundColor)) {
                    legend.chart.data.datasets[0].backgroundColor.forEach(
                      (color, index, colors) => {
                        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
                      },
                    );
                    legend.chart.update();
                  }
                },
              },
              datalabels: {
                formatter: (_value, ctx) => {
                  let datasets = ctx.chart.data.datasets;
                  if (ctx.datasetIndex === datasets.length - 1) {
                    let sum = 0;
                    datasets.forEach((dataset) => {
                      const value = dataset.data[ctx.dataIndex];
                      if (value && typeof value === 'number') {
                        sum += value;
                      }
                    });

                    if (userPrefs.showGasolineCost && userPrefs.showHomeElectricityCost) {
                      if (sum < currentTotal) {
                        return formatMessage(
                          { id: 'rates.allRates.savings' },
                          { dollars: formatAsDollars(currentTotal - sum) },
                        );
                      } else {
                        return '';
                      }
                    } else {
                      return '';
                    }
                  } else {
                    return '';
                  }
                },
                align: 'end' as const,
                anchor: 'end',
                color: '#7D9C3B',
                font: {
                  weight: 700,
                  size: 14,
                },
              },
              tooltip: {
                ...baseTooltipStyles,
                callbacks: {
                  label: (value) =>
                    `${value.dataset.label}: ${formatAsDollars(value.raw as number)}`,
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}
