import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import isElectricVehicle from '../../../calculations/vehicle/isElectricVehicle';
import Link from '../../../components/link/Link';
import { Vehicle } from '../../../types';
import { formatVehicleDisplayName } from '../../../utils/formatters';

type Props = {
  vehicle1: Vehicle;
  vehicle2: Vehicle;
};

const VehicleLink = ({ vehicle }: { vehicle: Vehicle }) => {
  if (!isElectricVehicle(vehicle)) {
    return <span>{formatVehicleDisplayName(vehicle)}</span>;
  }

  return (
    <Link to={`/vehicles/${vehicle.handle}`} sx={{ color: 'blue.300' }}>
      {formatVehicleDisplayName(vehicle)}
    </Link>
  );
};

export default function CompareCta({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Typography variant="h2" fontWeight="bold" fontSize="2.5rem" mb={3.5}>
      {formatMessage(
        { id: 'compare.charts.header' },
        {
          vehicle1Link: <VehicleLink vehicle={vehicle1} />,
          vehicle2Link: <VehicleLink vehicle={vehicle2} />,
        },
      )}
    </Typography>
  );
}
