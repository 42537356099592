import { Box } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import useElectricVehicleDetails from '../../api/hooks/useElectricVehicleDetails';
import Loading from '../../components/loading/Loading';
import Costs from './components/costs/Costs';
import Header from './components/header/Header';
import Incentives from './components/incentives/Incentives';
import Specs from './components/specs/Specs';
import Tabs from '../../components/tabs/Tabs';
import ChargingStationsMap from '../../components/maps/components/chargingStationsMap/ChargingStationsMap';
import DealersMap from '../../components/maps/components/dealersMap/DealersMap';
import TravelRadiusMap from '../../components/maps/components/TravelRadiusMap/TravelRadiusMap';
import TravelRouteMap from '../../components/maps/components/TravelRouteMap/TravelRouteMap';
import { useEffect, useState } from 'react';
import smoothScroll from '../../utils/smoothScroll';
import { useIntl } from 'react-intl';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import Page from '../../components/page/Page';

export default function VehicleDetails(): JSX.Element | null {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [activeMap, setActiveMap] = useState<number>();
  const match = useRouteMatch<{ id: string }>();
  const { vehicle, status: evStatus } = useElectricVehicleDetails(match.params.id);

  useEffect(() => {
    if (vehicle && userPrefs.comparedVehicleId1 !== vehicle.handle) {
      setUserPrefs({ comparedVehicleId1: vehicle.handle, vehicleMakeFilter: vehicle.make });
    }
  }, [setUserPrefs, userPrefs.comparedVehicleId1, vehicle]);

  if (evStatus === 'error') {
    return <Box p="4rem">{formatMessage({ id: 'vehicleDetails.notFound' })}</Box>;
  } else if (evStatus === 'loading') {
    return <Loading />;
  } else if (!vehicle) {
    return null;
  }

  return (
    <Page>
      <Box width="100%">
        <Header
          vehicle={vehicle}
          onShowDealerMap={() => {
            setActiveMap(3);
            smoothScroll('tabbed-maps-container');
          }}
        />
        <Specs vehicle={vehicle} />
        <Costs vehicle={vehicle} />
        <Incentives vehicle={vehicle} />
        <Tabs
          ariaLabel='Map Features'
          id="tabbed-maps-container"
          activeTabIndex={activeMap}
          tabs={[
            {
              label: formatMessage({ id: 'vehicleDetails.maps.chargingStations' }),
              content: <ChargingStationsMap />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.rangeMap' }),
              content: <TravelRadiusMap showVehicleSelector={false} />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.routePlanner' }),
              content: <TravelRouteMap />,
            },
            {
              label: formatMessage({ id: 'vehicleDetails.maps.dealerMap' }),
              content: <DealersMap oems={[vehicle.make]} />,
            },
          ]}
        />
      </Box>
    </Page>
  );
}
