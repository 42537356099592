import selectRepresentativeLoadProfile from './selectRepresentativeLoadProfile';
import type { UserPrefs, RateInterface } from '../../types';

import selectRate from '../rate/selectRate';

const genHomeLoadProfile = (userPrefs: UserPrefs) => {
  const rate = selectRate(userPrefs.currentElectricRate, userPrefs.zipcode);

  const representativeLoadProfile = selectRepresentativeLoadProfile(userPrefs);

  return representativeLoadProfile
    .scale({ debug: false })
    .toAverageMonthlyBill(userPrefs.homeElectricBillMonthlyAvg, rate as RateInterface);
};

export default genHomeLoadProfile;
