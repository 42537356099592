import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import smoothScroll from '../../utils/smoothScroll';

type Props = {
  title: ReactNode;
  icon: ReactNode;
  link: string;
};

export default function LinkCard(props: Props): JSX.Element {
  const { icon, title, link } = props;

  const content = (
    <Paper
      sx={{
        cursor: 'pointer',
        py: { xs: '0.75rem', sm: '1rem' },
        px: { xs: '0.75rem', sm: '1.1rem' },
        height: '100%',
        width: '150px',
        minHeight: '120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        boxShadow: 2,
        '&:hover': {
          boxShadow: 5,
        },
      }}
    >
      <Box width="50px">{icon}</Box>
      <Box fontWeight="500" fontSize="0.85rem">
        {title}
      </Box>
    </Paper>
  );

  return link[0] === '#' ? (
    <Box component="a" onClick={() => smoothScroll(link)}>
      {content}
    </Box>
  ) : (
    <Link to={props.link || ''} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  );
}
