import type { ReactNode } from 'react';
import { Button as MuiButton, SxProps, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { buttonStyles, sizeStyles } from '../button/Button';
import GaTracker from '../../utils/GaTracker/GaTracker';

type Variant = 'outlined' | 'contained' | 'text';
type Size = 'large' | 'medium' | 'small';

export default function ButtonLink({
  variant = 'contained',
  children,
  fullWidth,
  external,
  label,
  to,
  onClick,
  size = 'medium',
  sx = {},
}: {
  variant?: Variant;
  onClick?: () => void;
  fullWidth?: boolean;
  label?: string;
  to: string;
  external?: boolean;
  children: ReactNode;
  size?: Size;
  sx?: SxProps<Theme>;
}) {
  const style: SxProps<Theme> = Object.assign({}, buttonStyles[variant], sizeStyles[size], sx);

  return (
    <MuiButton
      component={RouterLink}
      {...(external
        ? {
            rel: 'noopener noreferrer',
            target: '_blank',
            to: { pathname: to },
          }
        : {
            to: to,
          })}
      fullWidth={fullWidth}
      size={size}
      title={label}
      onClick={() => {
        GaTracker.trackEvent({
          category: 'Link',
          action: 'Click',
          label: to,
        });
        onClick?.();
      }}
      sx={style}
    >
      {children}
    </MuiButton>
  );
}
