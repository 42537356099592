import type { ElectricVehicle, UserPrefs } from '../../types';
import calcVehicleElectricityUsage from './calcVehicleElectricityUsage';

const calcPublicChargingUsage = (
  userPrefs: UserPrefs,
  electricVehicle: ElectricVehicle,
): number => {
  const usage = calcVehicleElectricityUsage(
    electricVehicle,
    userPrefs.milesDrivenAnnually,
    userPrefs.electricMilesPortionForPhev,
  );

  return usage * (userPrefs.publicChargingPercentage / 100);
};

export default calcPublicChargingUsage;
