import { Box, Typography, useMediaQuery, useTheme, Grid } from '@mui/material';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import Link from '../../components/link/Link';
import Loading from '../../components/loading/Loading';
import Select from '../../components/select/Select';
import FilterControls from './components/filterControls/FilterControls';
import VehicleCard from './components/vehicleCard/VehicleCard';
import useFilteredVehiclesForCatalog from '../../api/hooks/useFilteredVehiclesForCatalog';
import { isSortOrder, isSortField } from '../../types';
import Page from '../../components/page/Page';
import { useState } from 'react';
import Modal from '../../components/modal/Modal';
import { useIntl } from 'react-intl';
import { groupBy } from 'lodash';
import UsedVehicleCard from './components/usedVehicleCard/UsedVehicleCard';
import { USER_PREF_PRESETS } from '../../constants/userPrefs';

const DEFAULT_SHOW_COUNT = USER_PREF_PRESETS['vehicleFilters']['showCount'];

export default function VehicleCatalog(): JSX.Element {
  const { formatMessage } = useIntl();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const { status, vehicles, sort, setSort, filters, setFilters, showCount } =
    useFilteredVehiclesForCatalog();

  if (status === 'loading') {
    return <Loading />;
  }

  const showMore = () => {
    setFilters({ showCount: showCount + DEFAULT_SHOW_COUNT });
  };

  // group together vehicles whose only difference is model year
  const usedVehicleGroups = Object.values(groupBy(vehicles, (vehicle) => vehicle.modelId));
  const isShowingUsed = filters.vehicleStatus === 'used';
  const numVehicles = isShowingUsed ? usedVehicleGroups.length : vehicles.length;

  return (
    <Page>
      <Box p={{ xs: 2, md: 4 }} bgcolor="grey.100">
        <Box display="flex" flexDirection="column" gap="1rem" maxWidth="1140px" m="auto">
          <Typography variant="h1" component="h1">
            {formatMessage({ id: 'vehicleCatalog.header' })}
          </Typography>
          <Typography fontSize="1.25rem" fontWeight="300" mb="0.5rem">
            {formatMessage({ id: 'vehicleCatalog.description' })}
          </Typography>
          <Box
            display="flex"
            gap="1.5rem"
            justifyContent="space-around"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Box flex="1 1" minWidth="250px" display={{ xs: 'none', md: 'block' }}>
              <Card>
                <FilterControls filters={filters} onChange={setFilters} />
              </Card>
            </Box>
            <Box display={{ xs: 'block', md: 'none' }} width="100%">
              <Button fullWidth onClick={() => setFiltersModalOpen(true)}>
                {formatMessage({ id: 'vehicleCatalog.filters' })}
              </Button>
            </Box>
            <Box flex="3 1" minWidth="300px">
              <Box textAlign={{ xs: 'left', md: 'right' }} mb="1rem">
                <Select
                  fullWidth={!isLargeScreen}
                  inline={isLargeScreen}
                  label={formatMessage({ id: 'vehicleCatalog.sortBy' })}
                  value={sort.field + '-' + sort.order}
                  sx={{
                    '& .MuiInputLabel-root .MuiTypography-root ': {
                      color: 'grey.600',
                    },
                  }}
                  options={[
                    {
                      label: formatMessage({ id: 'vehicleCatalog.sortOptions.range' }),
                      value: 'electricRange-desc',
                    },
                    {
                      label: formatMessage({ id: 'vehicleCatalog.sortOptions.alphabetical' }),
                      value: 'make-asc',
                    },
                    {
                      label: formatMessage({ id: 'vehicleCatalog.sortOptions.priceLow' }),
                      value: 'msrp-asc',
                    },
                    {
                      label: formatMessage({ id: 'vehicleCatalog.sortOptions.priceHigh' }),
                      value: 'msrp-desc',
                    },
                  ]}
                  onChange={(value) => {
                    const [field, order] = value.split('-');
                    if (isSortField(field) && isSortOrder(order)) {
                      setSort({ field, order });
                    }
                  }}
                />
              </Box>
              <Grid container spacing={1.5} minWidth="300px">
                {isShowingUsed
                  ? usedVehicleGroups.slice(0, showCount).map((usedVehicleGroup) => (
                      <Grid item xs={6} sm={4} key={usedVehicleGroup[0].modelId}>
                        <Link to={`/used-vehicles/${usedVehicleGroup[0].modelId}`}>
                          <UsedVehicleCard evs={usedVehicleGroup} />
                        </Link>
                      </Grid>
                    ))
                  : vehicles.slice(0, showCount).map((vehicle) => (
                      <Grid item xs={6} sm={4} key={vehicle.id}>
                        <Link to={`/vehicles/${vehicle.handle}`}>
                          <VehicleCard ev={vehicle} />
                        </Link>
                      </Grid>
                    ))}
              </Grid>

              {showCount < numVehicles && (
                <Box mt="2rem" textAlign="center">
                  <Button variant="outlined" onClick={showMore}>
                    {formatMessage({ id: 'vehicleCatalog.showMore' })}
                  </Button>
                  <Box>
                    {formatMessage(
                      { id: 'vehicleCatalog.numVehiclesShown' },
                      {
                        shown: showCount < numVehicles ? showCount : numVehicles,
                        total: numVehicles,
                      },
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={filtersModalOpen}
        onClose={() => setFiltersModalOpen(false)}
        title={formatMessage({ id: 'vehicleCatalog.filters' })}
      >
        <Box p={{ xs: 1, sm: 2 }}>
          <FilterControls filters={filters} onChange={setFilters} />
        </Box>
      </Modal>
    </Page>
  );
}
