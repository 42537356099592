import { Box, Typography } from '@mui/material';

export default function Level2Installation() {
  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Follow the steps below to guide your EV charger installation.
      </Typography>
      <p>
        Get help with choosing and installing the right charging station for your home.{' '}
        <a
          href="https://www.pge.com/pge_global/common/pdfs/solar-and-vehicles/options/clean-vehicles/electric/EV-Charger-Install.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          View the charger installation checklist (PDF, 277 KB) PDF
        </a>
        .
      </p>
      <p>
        <i>
          The average cost for installing a Level 2 charging station ranges from $400 to $1,200
          excluding charger cost. PLEASE NOTE: Installation costs will vary depending on electrical
          upgrades, cable length, and other features identified below.
        </i>
      </p>

      <Typography variant="h4">1. Get an electrical assessment of your home.</Typography>
      <p>
        Talk with a qualified electrician to assess whether your electrical panel has capacity for a
        Level 2 charger.
      </p>
      <ul>
        <li>Upgrades and permits at your expense may be necessary.</li>
        <li>The EV manufacturer may also offer a home assessment as part of your purchase.</li>
        <li>
          The electrician can also install a dedicated 240-volt circuit (similar outlet used for
          electric clothes dryer) to serve the Level 2 charger if your panel does not have the
          necessary capacity.
        </li>
      </ul>
      <p>Be sure to discuss the following when consulting with an electrician.</p>
      <ul>
        <li>Upgrades to your electrical panel</li>
        <li>Desired location of charger</li>
        <li>Cost of installation</li>
        <li>Length of charging cord</li>
        <li>Type of charger you have or want</li>
        <li>Permitting and inspections (if required by your city)</li>
        <li>Timeline for job completion</li>
      </ul>

      <Typography variant="h4">
        2. Determine which electric rate and meter system works for you.
      </Typography>
      <p>
        Select the PG&amp;E electric rate that is best for your charging needs. You can enroll in
        any residential rate. The EV2-A rate is often best for EV owners because electricity costs
        less when you charge at certain times of day or overnight. The EV-B rate may be useful for
        people who want to have one electric rate for their whole house and a separate EV rate for
        their electric car charging.
      </p>
      <ul>
        <li>Single existing meter: You can enroll in EV2-A, E-1 or E-TOUA or E-TOUB rates.</li>
        <li>
          Dual meter: If you want to install a second meter and electric panel for EV charging,
          you'll enroll in the EV-B rate.
        </li>
      </ul>
      <p>
        PLEASE NOTE: A PG&amp;E representative may schedule a visit to determine whether your
        current electric service can support an EV. You may have to upgrade your service or your
        panel, or add a second electric panel. Service upgrades are necessary when the service wire
        to your home doesn't meet your capacity needs. PG&amp;E charges a $100 service fee for a
        second meter. You're responsible for the installation costs to support an additional meter.
        The costs are generally around $2,000, but can be as high as $8,000 or more.
      </p>

      <Typography variant="h4">
        3. Contact PG&amp;E to start your "change of service" application.
      </Typography>
      <p>
        After you determine which EV charging system is right for you, contact PG&amp;E. You must
        complete an application to notify us of the change of service including the following
        information:
      </p>
      <ul>
        <li>Rate option: Choose the residential rate you'll use to charge your EV.</li>
        <li>Charging level: Will you use a Level 1 or Level 2 charging station.</li>
        <li>
          Charging load: Load amount from your EV supply equipment. This is based on the charging
          system's voltage and amperage. An electrician can help you determine this information.
        </li>
        <li>Panel upgrade: Does the dedicated circuit require a panel upgrade.</li>
      </ul>
      <p>
        Apply online for change of service:{' '}
        <a
          href="https://www.pge.com/en_US/residential/customer-service/home-services/renovating-and-building/customer-connections-online/customer-connections-online.page"
          target="_blank"
          rel="noopener noreferrer"
        >
          Customer Connections Online
        </a>
        . Apply via phone: <a href="tel:18777437782">1-877-743-7782</a>. We're available M-F, 8
        a.m.-5 p.m.
      </p>
    </Box>
  );
}
