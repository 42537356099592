import React, { useState } from 'react';

type Mode = 'screen' | 'print';
type ContextType = {
  mode: Mode;
  setMode: (mode: Mode) => void;
};

const DisplayContext = React.createContext<ContextType>({ mode: 'screen', setMode: (mode) => {} });

type Props = {
  children: JSX.Element;
};

export default function DisplayProvider({ children }: Props) {
  const [mode, setMode] = useState<Mode>('screen');

  return <DisplayContext.Provider value={{ mode, setMode }}>{children}</DisplayContext.Provider>;
}

export const useDisplay = () => React.useContext(DisplayContext);
