import { Box } from '@mui/material';
import type { Incentive } from '../../../../types/incentive';
import IncentiveCard from './IncentiveCard';

type Props = {
  incentives: Array<Incentive>;
};

const IncentiveCatalog = ({ incentives }: Props): JSX.Element | null => {
  if (incentives.length === 0) return null;

  const renderCards = incentives.map((incentive, index) => {
    return <IncentiveCard incentive={incentive} key={index} />;
  });

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        gridTemplateRows={{ xs: 'repeat(2, 1fr)', md: '1fr' }}
        gap="16px"
        m="0 auto 30px auto"
        maxWidth="990px"
      >
        {renderCards}
      </Box>
    </Box>
  );
};

export default IncentiveCatalog;
