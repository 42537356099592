import { ChargingStation, TomTomChargingStation } from '../../types';

export const transformChargingStationAvailability = (
  apiStation: TomTomChargingStation,
): ChargingStation => {
  const dcfc = apiStation.chargingPark?.connectors.find((connector) => {
    return connector.type === 'DC';
  });

  const availabilityId = apiStation.dataSources?.chargingAvailability?.id;

  return {
    id: apiStation.id,
    lat: apiStation.position.lat,
    lng: apiStation.position.lon,
    evDcFastNum: dcfc?.ratedPowerKW || 0,
    accessCode: '',
    stationName: apiStation.poi.name,
    streetAddress: `${apiStation.address.streetNumber || ''} ${
      apiStation.address.streetName || ''
    }`,
    city: apiStation.address.municipality,
    state: apiStation.address.countrySubdivision,
    zip: apiStation.address.postalCode,
    stationPhone: apiStation.phone,
    evNetwork: 'See details',
    evNetworkWeb: `/charging-station-availability/${apiStation.id}`,
    availabilityStatus: availabilityId ? 'available' : 'unavailable',
    availabilityId: availabilityId,
  };
};
