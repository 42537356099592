import type { ElectricVehicle } from '../../../../types';
import { useEffect, useState } from 'react';
import { sampleSize } from 'lodash';
import { Box, Typography } from '@mui/material';
import Link from '../../../../components/link/Link';
import Button from '../../../../components/button/Button';
import useElectricVehicles from '../../../../api/hooks/useElectricVehicles';
import EvCard from './EvCard';
import Loading from '../../../../components/loading/Loading';
import CostsChartArea from './CostsChartArea';
import Card from '../../../../components/card/Card';
import { useIntl } from 'react-intl';

const sampleEvsForMsrp = (vehicles: Array<ElectricVehicle>): Array<ElectricVehicle> => {
  const msrpFilter = 35000;
  let filterBelowMsrp = 2;
  let filterAboveMsrp = 2;

  const sortedByMsrp = vehicles.reduce(
    (acc, ev) => {
      if (ev.msrp <= msrpFilter) {
        return {
          ...acc,
          belowMsrp: [...acc.belowMsrp, ev],
        };
      }
      return {
        ...acc,
        aboveMsrp: [...acc.aboveMsrp, ev],
      };
    },
    { belowMsrp: [] as Array<ElectricVehicle>, aboveMsrp: [] as Array<ElectricVehicle> },
  );

  if (
    sortedByMsrp.belowMsrp.length < filterBelowMsrp ||
    sortedByMsrp.aboveMsrp.length < filterAboveMsrp
  ) {
    return [];
  }

  return [...sampleSize(sortedByMsrp.belowMsrp, 2), ...sampleSize(sortedByMsrp.aboveMsrp, 2)];
};

export default function ElectricVehicles(): JSX.Element {
  const { formatMessage } = useIntl();
  const { vehicles, status } = useElectricVehicles();
  const [selectedEv, setSelectedEv] = useState(1);
  const [sampleEvs, setSampleEvs] = useState<Array<ElectricVehicle>>();

  useEffect(() => {
    if (vehicles && !sampleEvs) {
      setSampleEvs(sampleEvsForMsrp(vehicles));
    }
  }, [sampleEvs, vehicles]);

  if (status === 'loading') return <Loading />;

  if (!vehicles || vehicles.length <= 3) return <></>;

  const vehicleCount = vehicles ? vehicles.length : 'many';

  return (
    <Box>
      <Box mb="3rem" textAlign="center">
        <Link to="/vehicles">
          <Button>{formatMessage({ id: 'homepage.evCosts.seeAllEvsButton' })}</Button>
        </Link>
      </Box>
      <Box textAlign="center" mb="1.25rem">
        <Typography component="h2" fontSize="2rem">
          {formatMessage({ id: 'homepage.evCosts.availableEvs' }, { vehicleCount })}
        </Typography>
        <Typography fontSize="1.25rem">
          {formatMessage({ id: 'homepage.evCosts.compareCosts' })}
        </Typography>
      </Box>
      <Box
        display="grid"
        gap="16px"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
        gridTemplateRows={{ xs: 'repeat(2, 1fr)', sm: '1fr' }}
      >
        {sampleEvs?.map((ev, i) => (
          <EvCard
            key={ev.id}
            ev={ev}
            selected={selectedEv === i}
            onClick={() => setSelectedEv(i)}
            urlBuilder={(ev) => `/vehicles/${ev.handle}`}
          />
        ))}
      </Box>
      {sampleEvs?.[selectedEv] && (
        <Card
          sx={{
            display: { xs: 'none', sm: 'block' },
            marginTop: '1.5rem',
            position: 'relative',
            overflow: 'visible',
          }}
        >
          <Box
            className="arrow-up"
            sx={{
              left: selectedEv * 25 + 11 + '%',
              top: 'calc(-0.75rem - 1px)',
              position: 'absolute',
              display: 'block',
              width: '1.5rem',
              height: '1.5rem',
              backgroundColor: (theme) => theme.palette.white[100],
              borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
              borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
              transform: 'rotate(45deg)',
            }}
          />
          <CostsChartArea
            car={sampleEvs[selectedEv]}
            hasExtraPadding
            displayEvCostBreakdownOption
            forceUserPrefsPresets
          />
        </Card>
      )}
      <Box mt="2rem" textAlign="center">
        <Link to="/vehicles">
          <Button>{formatMessage({ id: 'homepage.evCosts.findEvButton' })}</Button>
        </Link>
      </Box>
    </Box>
  );
}
