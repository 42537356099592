import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import useElectricVehicleDetails from '../../api/hooks/useElectricVehicleDetails';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal/Modal';
import Page from '../../components/page/Page';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { VehicleIncentive } from '../../types/vehicleIncentive';
import { formatAsDollars } from '../../utils/formatters';
import { isValidZipcode } from '../../utils/zipcodes';
import EligibilityFilters from './components/EligibilityFilters';
import IncentiveCard from './components/IncentiveCard';
import VehicleFilters from './components/VehicleFilters';
import prioritizeIncentives from '../../calculations/incentives/Sort/prioritizeIncentives';
import useElectricVehicles from '../../api/hooks/useElectricVehicles';

const groupByEligibility = (incentives?: VehicleIncentive[]) => {
  let eligibleTotalAmount = 0;
  const eligible: Array<VehicleIncentive> = [];
  const maybe: Array<VehicleIncentive> = [];
  const ineligible: Array<VehicleIncentive> = [];

  incentives?.forEach((incentive) => {
    if (incentive.evaluation.eligibility === 'eligible') {
      if (incentive.evaluation.amountInPurchase > 0) {
        eligible.push(incentive);
        eligibleTotalAmount += incentive.evaluation.amountInPurchase;
      } else {
        maybe.push(incentive);
      }
    } else {
      ineligible.push(incentive);
    }
  });

  return {
    eligibleTotalAmount,
    eligible,
    maybe,
    ineligible,
  };
};

export default function Incentives() {
  const { formatMessage } = useIntl();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const { userPrefs } = useUserPrefs();
  const { vehicle, status } = useElectricVehicleDetails(userPrefs.comparedVehicleId1);

  const { usedElectricVehicles } = useElectricVehicles();

  const usedIncentives =
    vehicle &&
    usedElectricVehicles?.length &&
    usedElectricVehicles.find((usedVehicle) => usedVehicle.handle === vehicle.handle)?.incentives;

  const {
    eligibleTotalAmount,
    eligible: eligibleUnsorted,
    maybe: maybeUnsorted,
    ineligible: ineligibleUnsorted,
  } = groupByEligibility(usedIncentives || vehicle?.incentives);

  const eligible = prioritizeIncentives(eligibleUnsorted);
  const maybe = prioritizeIncentives(maybeUnsorted);
  const ineligible = prioritizeIncentives(ineligibleUnsorted);

  const hasValidZipcode = isValidZipcode(userPrefs.zipcode);

  return (
    <Page>
      <Box bgcolor="grey.100">
        <Box maxWidth="1140px" mx="auto" p="60px 15px">
          <Typography variant="h1" component="h1">
            {formatMessage({ id: 'incentives.headline' })}
          </Typography>
          <Typography mb={6}>{formatMessage({ id: 'incentives.description' })}</Typography>
          <Box display={{ xs: 'block', md: 'none' }} width="100%" my={2}>
            <Button fullWidth onClick={() => setFiltersModalOpen(true)}>
              {formatMessage({ id: 'incentives.filters' })}
            </Button>
          </Box>
          <Box mt="1rem" display="flex" gap="2rem" flexWrap="wrap">
            <Box
              width="260px"
              display={{ xs: 'none', md: 'flex' }}
              flexDirection="column"
              gap="1rem"
            >
              <Card>
                <VehicleFilters />
              </Card>
              <Card>
                <EligibilityFilters />
              </Card>
            </Box>
            <Box flex="4 1" minWidth="264px">
              {!hasValidZipcode && <Box>{formatMessage({ id: 'incentives.zipcodeError' })}</Box>}

              {!vehicle && status !== 'loading' && hasValidZipcode && (
                <>
                  <Box display={{ xs: 'none', md: 'block' }}>
                    {formatMessage({ id: 'incentives.selectVehicleError' })}
                  </Box>
                  <Box display={{ md: 'none' }}>
                    {' '}
                    {formatMessage({ id: 'incentives.selectVehicleErrorSmallScreen' })}
                  </Box>
                </>
              )}
              {status === 'loading' && <Loading />}
              {eligible.length > 0 && (
                <Box mb="3rem">
                  <Typography mb="2rem" variant="h4">
                    {formatMessage(
                      { id: 'incentives.eligibleHeadline' },
                      {
                        eligibleAmount: (
                          <Typography
                            variant="h4"
                            component="span"
                            fontWeight={700}
                            sx={{ color: (theme) => theme.palette.green[500] }}
                          >
                            {formatAsDollars(eligibleTotalAmount)}
                          </Typography>
                        ),
                      },
                    )}
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="0.75rem 1rem"
                    justifyContent="space-around"
                    alignItems="stretch"
                  >
                    {eligible.map((incentive) => {
                      return (
                        <Box
                          key={incentive.name}
                          flex="1 1"
                          minWidth={{ xs: '160px', sm: '220px' }}
                          maxWidth={{ xs: '48%', sm: '264px' }}
                        >
                          <IncentiveCard incentive={incentive} />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
              {maybe.length > 0 && (
                <Box mb="3rem">
                  <Typography mb="2rem" variant="h4">
                    {formatMessage({ id: 'incentives.maybeHeadline' })}:
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="0.75rem 1rem"
                    justifyContent="space-around"
                    alignItems="stretch"
                  >
                    {maybe.map((incentive) => {
                      return (
                        <Box
                          key={incentive.name}
                          flex="1 1"
                          minWidth={{ xs: '160px', sm: '220px' }}
                          maxWidth={{ xs: '48%', sm: '264px' }}
                        >
                          <IncentiveCard incentive={incentive} />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
              {ineligible.length > 0 && (
                <Box>
                  <Typography mb="2rem" variant="h4">
                    {formatMessage({ id: 'incentives.ineligibleHeadline' })}:
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="0.75rem 1rem"
                    justifyContent="space-around"
                    alignItems="stretch"
                  >
                    {ineligible.map((incentive) => {
                      return (
                        <Box
                          key={incentive.name}
                          flex="1 1"
                          minWidth={{ xs: '160px', sm: '220px' }}
                          maxWidth={{ xs: '48%', sm: '264px' }}
                        >
                          <IncentiveCard incentive={incentive} />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Modal
          open={filtersModalOpen}
          onClose={() => setFiltersModalOpen(false)}
          title={formatMessage({ id: 'incentives.filters' })}
        >
          <Box p={{ xs: 1, sm: 2 }}>
            <Box mb={4}>
              <VehicleFilters />
            </Box>
            <EligibilityFilters />
          </Box>
        </Modal>
      </Box>
    </Page>
  );
}
