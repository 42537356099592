export type RateSchedule = {
  charge: number[];
  units: string;
};

export type RateKey = 'E-1' | 'E-ELEC' | 'EV2-A' | 'E-TOU-B' | 'E-TOU-C' | 'E-TOU-D' | 'EV-B';

export const isRateKey = (key: string): key is RateKey => {
  return ['E-1', 'E-ELEC', 'EV2-A', 'E-TOU-B', 'E-TOU-C', 'E-TOU-D', 'EV-B'].includes(key);
};

export type ApiRateType = {
  name: RateKey;
  title: string;
  tariff_elements?: {
    delivery_charge?: {
      charge: number;
      units: string;
    };
    california_clean_climate_credit: RateSchedule;
    blocked_tier_by_territory_in_days_per_kwh?: {
      blocks: RateSchedule;
      allowance: Record<string, Array<number[]>>;
    };
    tou_energy?: {
      periods: {
        peak: RateSchedule;
        offpeak: RateSchedule;
        partialpeak?: RateSchedule;
      };
      definitions: {
        weekdays: Array<'peak' | 'offpeak' | 'partialpeak'>;
        weekends_and_holidays: Array<'peak' | 'offpeak' | 'partialpeak'>;
      };
    };
  };
};

export type RateType = {
  name: RateKey;
  title: string;
  tariffElements?: {
    deliveryCharge?: {
      charge: number;
      units: string;
    };
    californiaCleanClimateCredit: RateSchedule;
    blockedTierByTerritoryInDaysPerKwh?: {
      blocks: RateSchedule;
      allowance: Record<string, Array<number[]>>;
    };
    touEnergy?: {
      periods: {
        peak: RateSchedule;
        offpeak: RateSchedule;
        partialpeak?: RateSchedule;
      };
      definitions: {
        weekdays: Array<'peak' | 'offpeak' | 'partialpeak'>;
        weekendsAndHolidays: Array<'peak' | 'offpeak' | 'partialpeak'>;
      };
    };
  };
};
