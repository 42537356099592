const CHARGING_SPEEDS_IN_KW = {
  no_charger: 0,
  level_1: 1.5,
  level_2: 7.7,
};

type ChargerLevel = keyof typeof CHARGING_SPEEDS_IN_KW;

const calcCharging = {
  speedInKw: function (chargerLevel: ChargerLevel) {
    return chargerLevel && CHARGING_SPEEDS_IN_KW[chargerLevel]
      ? CHARGING_SPEEDS_IN_KW[chargerLevel]
      : 0;
  },

  timeForFullBattery: function (
    batteryCapacityInKwh: number,
    maxCurrentIntake: number,
    chargerLevel: ChargerLevel,
  ) {
    if (!batteryCapacityInKwh) return 0;
    const chargeSpeed =
      chargerLevel === 'level_2'
        ? Math.min(maxCurrentIntake, this.speedInKw(chargerLevel))
        : this.speedInKw(chargerLevel);

    return batteryCapacityInKwh / chargeSpeed;
  },
  timeForDailyCommute: function (
    milesDrivenDaily: number,
    kWhPer100Mi: number,
    chargerLevel: ChargerLevel,
  ) {
    if (!milesDrivenDaily) return 0;
    const chargeSpeed = this.speedInKw(chargerLevel);

    return (kWhPer100Mi * milesDrivenDaily) / (100 * chargeSpeed);
  },
  milesPerHalfHourOfFastCharge: function (
    kWhPer100Mi: number,
    dcfcSpeedInKw: number,
    maxElectricRange: number,
  ) {
    if (!kWhPer100Mi) return 0;

    const milesPerHourOfCharge = dcfcSpeedInKw / (kWhPer100Mi / 100);

    return Math.min(milesPerHourOfCharge / 2, maxElectricRange);
  },
};

export default calcCharging;
