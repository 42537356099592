import { Box, Container } from '@mui/material';
import { useIntl } from 'react-intl';
import calcCharging from '../../../../calculations/vehicle/Charging/calcCharging';
import calcEmissions from '../../../../calculations/vehicle/Emissions/calcEmissions';
import VehicleImageGallery from '../../../../components/imageGallery/ImageGallery';
import Spec from '../../../../components/spec/Spec';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ElectricVehicle } from '../../../../types';
import { formatAsThousands, formatAsTime, titleCase } from '../../../../utils/formatters';

const FUEL_TYPE_DISPLAY_KEYS = {
  GAS: 'vehicleDetails.specs.fuelTypes.gasoline',
  PHEV: 'vehicleDetails.specs.fuelTypes.electricAndGasoline',
  BEV: 'vehicleDetails.specs.fuelTypes.electric',
};

const TYPE_DISPLAY_KEYS: Record<string, string> = {
  Sedan: 'vehicleCatalog.filterControls.typeOptions.sedan',
  Hatchback: 'vehicleCatalog.filterControls.typeOptions.hatchback',
  Coupe: 'vehicleCatalog.filterControls.typeOptions.coupe',
  Minivan: 'vehicleCatalog.filterControls.typeOptions.minivan',
  'Station Wagon': 'vehicleCatalog.filterControls.typeOptions.wagon',
  pickup_truck: 'vehicleCatalog.filterControls.typeOptions.truck',
  SUV: 'vehicleCatalog.filterControls.typeOptions.suv',
  Crossover: 'vehicleCatalog.filterControls.typeOptions.suv',
};

const DRIVETRAIN_DISPLAY_KEYS: Record<string, string> = {
  '4-Wheel Drive': 'vehicleDetails.specs.drivetrainTypes.4wheelDrive',
  'Rear-Wheel Drive': 'vehicleDetails.specs.drivetrainTypes.rearwheelDrive',
  'All-Wheel Drive': 'vehicleDetails.specs.drivetrainTypes.allwheelDrive',
  'Front-Wheel Drive': 'vehicleDetails.specs.drivetrainTypes.frontwheelDrive',
};

/**
 * This maps values given by the API
 * to known-good translation keys.
 * If anything isn't found in here,
 * we'll fall back to the value
 * itself, or "Coming soon!"
 * translated.
 */
const MapValue = function ({ value, map }: { value: string; map: Record<string, string> }) {
  const { formatMessage } = useIntl();

  if (map.hasOwnProperty(value)) {
    return <>{formatMessage({ id: map[value] })}</>;
  } else if (isUnsightlyValue(value)) {
    return <>{formatMessage({ id: 'vehicleDetails.specs.comingSoon' })}</>;
  } else {
    // This is a fallback in case the API
    // gives us something new
    console?.error?.(`Encountered a new value not in [${Object.keys(map).join(', ')}]: '${value}'`);
    return <>{titleCase(value.replaceAll('_', ' '))}</>;
  }
};

const Seats = ({ seatsMin, seatsMax }: { seatsMin: number; seatsMax: number }) => {
  const { formatMessage } = useIntl();

  if (isUnsightlyValue(seatsMin) || isUnsightlyValue(seatsMax)) {
    return <>{formatMessage({ id: 'vehicleDetails.specs.comingSoon' })}</>;
  }

  return (
    <>
      {seatsMax > seatsMin
        ? formatMessage(
            { id: 'vehicleDetails.specs.seatsRange' },
            { low: seatsMin, high: seatsMax },
          )
        : seatsMin}
    </>
  );
};

/**
 * A helper function to determine if a value
 * should be replaced by "coming soon"
 */
const isUnsightlyValue = (val: number | string | null) => {
  return (
    (typeof val === 'number' && (val <= 0 || val >= Infinity || isNaN(val))) ||
    (typeof val === 'string' && val.toUpperCase() === 'N/A') ||
    val === '' ||
    val === null
  );
};

/**
 * A component that renders a value, or a
 * "coming soon" message if the value is
 * invalid.
 */
const Value = ({ msg, val }: { msg: string; val: number | string | null }) => {
  const { formatMessage } = useIntl();
  if (isUnsightlyValue(val)) {
    return <>{formatMessage({ id: 'vehicleDetails.specs.comingSoon' })}</>;
  }
  return (
    <>
      {formatMessage({ id: msg }, { val: typeof val !== 'number' ? val : formatAsThousands(val) })}
    </>
  );
};

type Props = {
  vehicle: ElectricVehicle;
};

export default function Specs({ vehicle }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: { milesDrivenAnnually, electricMilesPortionForPhev },
  } = useUserPrefs();

  const chargeTimeL2 = formatAsTime(
    calcCharging.timeForFullBattery(vehicle.batteryCapacity, vehicle.acChargingPower, 'level_2'),
  );

  return (
    <Container>
      <Box display="flex" m="5rem auto">
        <Box flex="1 1" display="flex" gap="1rem">
          <Box flex="1 1" display="flex" flexDirection="column" gap="1rem">
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.type' })} size="sm">
              <MapValue value={vehicle.subtype} map={TYPE_DISPLAY_KEYS} />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.fuelType' })} size="sm">
              <MapValue value={vehicle.fuelType} map={FUEL_TYPE_DISPLAY_KEYS} />
            </Spec>
            <Spec
              label={formatMessage({ id: 'vehicleDetails.specs.milesPerThirtyCharge' })}
              size="sm"
            >
              <Value
                msg="vehicleDetails.specs.estMiles"
                val={calcCharging.milesPerHalfHourOfFastCharge(
                  vehicle.electricEfficiency,
                  vehicle.dcChargingPower || 0,
                  vehicle.electricRange,
                )}
              />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.batterySize' })} size="sm">
              <Value msg="vehicleDetails.specs.kwh" val={vehicle.batteryCapacity} />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.treesPlanted' })} size="sm">
              <Value
                msg="vehicleDetails.specs.trees"
                val={calcEmissions.treesPlanted(
                  vehicle,
                  milesDrivenAnnually,
                  electricMilesPortionForPhev,
                )}
              />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.zeroToSixty' })} size="sm">
              <Value msg="vehicleDetails.specs.seconds" val={vehicle.acceleration} />
            </Spec>
          </Box>
          <Box flex="1 1" display="flex" flexDirection="column" gap="1rem">
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.seats' })} size="sm">
              <Seats seatsMin={vehicle.seatsMin} seatsMax={vehicle.seatsMax} />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.range' })} size="sm">
              <Value msg="vehicleDetails.specs.miles" val={vehicle.electricRange} />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.chargeTimeL2' })} size="sm">
              {chargeTimeL2
                ? `~ ${chargeTimeL2}`
                : formatMessage({ id: 'vehicleDetails.specs.comingSoon' })}
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.co2Reduction' })} size="sm">
              <Value
                msg="vehicleDetails.specs.lbsPerYr"
                val={calcEmissions.reductionInLbs(
                  vehicle,
                  milesDrivenAnnually,
                  electricMilesPortionForPhev,
                )}
              />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.gasSaved' })} size="sm">
              <Value
                msg="vehicleDetails.specs.galPerYr"
                val={calcEmissions.gallonsSaved(
                  vehicle,
                  milesDrivenAnnually,
                  electricMilesPortionForPhev,
                )}
              />
            </Spec>
            <Spec label={formatMessage({ id: 'vehicleDetails.specs.drivetrain' })} size="sm">
              <MapValue value={vehicle.drivetrain} map={DRIVETRAIN_DISPLAY_KEYS} />
            </Spec>
          </Box>
        </Box>
        <Box flex="1 1" maxWidth="50%" display={{ xs: 'none', md: 'block' }}>
          <VehicleImageGallery images={vehicle.images.filter((i) => i.urlFull && i.urlThumbnail)} />
        </Box>
      </Box>
    </Container>
  );
}
