import ELECTRIC_VEHICLE_LOAD_PROFILES, {
  YEAR,
} from '../../data/loadProfiles/electricVehicleLoadProfiles';
import calcVehicleElectricityUsage from '../vehicle/calcVehicleElectricityUsage';
import calcPublicChargingUsage from '../vehicle/calcPublicChargingUsage';
import { addDecimals, multiplyDecimals } from '../../utils/Helpers/Decimals';
import type { ElectricVehicle, UserPrefs } from '../../types';
import isBEV from '../vehicle/isBEV';
import { LoadProfile } from '@bellawatt/electric-rate-engine';

const genElectricVehicleLoadProfile = (userPrefs: UserPrefs, electricVehicle: ElectricVehicle) => {
  const usage = calcVehicleElectricityUsage(
    electricVehicle,
    userPrefs.milesDrivenAnnually,
    isBEV(electricVehicle) ? 100 : userPrefs.electricMilesPortionForPhev,
  );

  const publicChargingUsage = calcPublicChargingUsage(userPrefs, electricVehicle);

  const usageLessPublicCharging = usage - publicChargingUsage;

  const repVehicleLoadProfile =
    ELECTRIC_VEHICLE_LOAD_PROFILES[userPrefs.vehicleChargingPattern] || [];

  const totalRepVehicleLoadProfile = repVehicleLoadProfile.reduce(addDecimals, 0);

  const scaler = usageLessPublicCharging / totalRepVehicleLoadProfile;

  return new LoadProfile(
    repVehicleLoadProfile.map((hour) => multiplyDecimals(hour, scaler)),
    { year: YEAR },
  );
};

export default genElectricVehicleLoadProfile;
