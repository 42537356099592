import { Box } from '@mui/material';
import { useState } from 'react';
import Image from '../image/Image';

type ImageType = {
  altText: string;
  urlThumbnail: string;
  urlFull: string;
};

const THUMBNAIL_MAX_HEIGHT = '70px';

export default function VehicleImageGallery({ images }: { images: Array<ImageType> }): JSX.Element {
  const [activeImage, setActiveImage] = useState<ImageType>(images[0]);

  return (
    <Box>
      <Box p={3}>
        <Image src={activeImage.urlFull} alt={activeImage.altText} sx={{ width: '100%' }} />
      </Box>
      <Box
        display="flex"
        sx={{ overflowX: 'auto', overflowY: 'hidden', maxHeight: THUMBNAIL_MAX_HEIGHT }}
      >
        {images.map((image) => {
          return (
            <Box
              key={image.urlThumbnail}
              component="button"
              onClick={() => setActiveImage(image)}
              m={0.25}
              sx={{
                border: 'none',
                cursor: 'pointer',
                maxHeight: '100%',
                maxWidth: '92px',
              }}
            >
              <Image
                src={image.urlThumbnail}
                alt={image.altText}
                sx={{ maxHeight: THUMBNAIL_MAX_HEIGHT }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
