import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

type Props = {
  errorMessage: ReactNode;
  children?: ReactNode;
};

export default function NotFound({ children, errorMessage }: Props) {
  return (
    <Box bgcolor="grey.100">
      <Box maxWidth="1140px" mx="auto" p="60px 15px">
        <Typography variant="h1">Page Not Found</Typography>
        {children}
      </Box>
    </Box>
  );
}
