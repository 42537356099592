import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { useIntl } from 'react-intl';
import calcCharging from '../../../../calculations/vehicle/Charging/calcCharging';
import Card from '../../../../components/card/Card';
import { ElectricVehicle } from '../../../../types';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatAsTime, formatVehicleDisplayName } from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle;
  vehicle2: ElectricVehicle;
};

const getChartOptions = (isLargeScreen: boolean): ChartOptions => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        callback: (value) => `${value} hrs`,
      },
    },
    y: {
      stacked: true,
    },
  },
  layout: {
    padding: {
      right: isLargeScreen ? 75 : 35,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets as any[];
        if (ctx.datasetIndex === datasets.length - 1) {
          let sum = 0;
          datasets.forEach((dataset) => {
            sum += dataset.data[ctx.dataIndex];
          });
          return `${formatAsTime(sum)}`;
        } else {
          return '';
        }
      },
      align: 'end',
      anchor: 'end',
      color: '#333333',
      font: {
        weight: 700,
      },
    },
    tooltip: {
      ...baseTooltipStyles,
      callbacks: {
        label: (item) => formatAsTime(item.raw as number),
      },
    },
  },
});

export default function Level2ChargingChart({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const vehicle1TimeToFull = calcCharging.timeForFullBattery(
    vehicle1.batteryCapacity,
    vehicle1.acChargingPower,
    'level_2',
  );

  const vehicle2TimeToFull = calcCharging.timeForFullBattery(
    vehicle2.batteryCapacity,
    vehicle2.acChargingPower,
    'level_2',
  );

  const chartData = {
    labels: [formatVehicleDisplayName(vehicle1), formatVehicleDisplayName(vehicle2)],
    datasets: [
      {
        backgroundColor: theme.palette.blue[300],
        data: [vehicle1TimeToFull, vehicle2TimeToFull],
        barPercentage: 0.6,
      },
    ],
  };

  const isSameTime = vehicle1TimeToFull === vehicle2TimeToFull;
  const isVehicle1Faster = vehicle1TimeToFull < vehicle2TimeToFull;

  const title = isSameTime
    ? formatMessage(
        { id: 'compare.charts.level2ChargingSameRate' },
        {
          vehicle1: formatVehicleDisplayName(vehicle1),
          vehicle2: formatVehicleDisplayName(vehicle2),
        },
      )
    : formatMessage(
        { id: 'compare.charts.level2ChargingFaster' },
        {
          vehicle: formatVehicleDisplayName(isVehicle1Faster ? vehicle1 : vehicle2),
        },
      );

  return (
    <Card>
      <Box>
        <Typography textAlign="center" fontWeight="bold" mb={1}>
          {title}
        </Typography>
        <Box height={200}>
          <ReactChart
            height={200}
            type="bar"
            options={getChartOptions(isLargeScreen)}
            data={chartData}
          />
        </Box>
      </Box>
    </Card>
  );
}
