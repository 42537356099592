// Code to track pageviews (presumably?) for PG&E marketing.
// Internet research indicates this tracker is for Rocket Fuel, an AI marketing
// company, but its website doesn't even load, so I couldn't find much documentation.
// This is the script, broken into pieces that will work with React Router.
// No guarantees whether this actually does what it is supposed to do.
//
// NOTE: There is also a <noscript /> tag with an iframe added in index.html.
// To completely remove this tracker, that should be deleted as well.
const ENABLED_ENVIRONMENTS = ['production'];

const RocketFuelTracker = {
  initialize() {
    if (!ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return;
    }

    var d = document;
    var s;
    try {
      s = d.createElement('script');
      s.setAttribute('async', 'true');
      s.setAttribute('type', 'text/javascript');
      s.setAttribute('src', '//c1.rfihub.net/js/tc.min.js');
      var f = d.getElementsByTagName('script')[0];
      f.parentNode.insertBefore(s, f);
    } catch (e) {
      console.log('RocketFuelTracker throws an error:', e);
      return;
    }
  },
  track() {
    if (!ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return;
    }

    let w = window;
    if (typeof w['_rfi'] !== 'function') {
      w['_rfi'] = function () {
        w['_rfi'].commands = w['_rfi'].commands || [];
        w['_rfi'].commands.push(arguments);
      };
    }
    w._rfi('setArgs', 'ver', '9');
    w._rfi('setArgs', 'rb', '41125');
    w._rfi('setArgs', 'ca', '20826658');
    w._rfi('setArgs', '_o', '41125');
    w._rfi('setArgs', '_t', '20826658');
    w._rfi('track');
  },
};

export default RocketFuelTracker;
