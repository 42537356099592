import { IntlProvider } from 'react-intl';
import { messages } from './reactIntlConfig';
import { merge } from 'lodash';
import React, { useState, useEffect } from 'react';

type LanguageChoiceContextType = {
  language: string;
  zrLanguage: string;
  setLanguage: (langauge: string) => void;
};

const LanguageChoiceContext = React.createContext<LanguageChoiceContextType>({
  language: '',
  zrLanguage: 'en',
  setLanguage: (language) => {},
});

const zrLanguageMap: Record<string, string> = {
  en: '',
  es: 'es',
  zh: 'zh-Hant',
};

type Props = {
  children: React.ReactNode;
  defaultLocale?: string;
};

/**
 * Get the messages for a given language. If any messages are missing, merge them in from the
 * fallback language. If the whole language is missing, just use the fallback.
 */
const getMessages = (lng: string, fallback: string) =>
  messages[lng] ? merge({}, messages[fallback], messages[lng]) : messages[fallback];

/**
 * Get an object of all the keys mapped to... also the same keys.
 */
const getMessageCodes = (lng: string) => {
  return Object.keys(messages[lng]).reduce((codes, key) => ({ ...codes, [key]: key }), {});
};

export default function ReactIntlProvider({ children, defaultLocale = 'en' }: Props) {
  // Grab just the first part of the Language Code from the browser.
  const browserLanguage = navigator.language?.replace(/-.*/, '');

  const [language, setLanguage] = useState<string>(
    localStorage.getItem('language') || browserLanguage,
  );

  const zrLanguage = zrLanguageMap[language] || '';

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const currentMessages =
    language === 'xx' ? getMessageCodes(defaultLocale) : getMessages(language, defaultLocale);

  return (
    <LanguageChoiceContext.Provider value={{ language, zrLanguage, setLanguage }}>
      <IntlProvider locale={language} key={language} messages={currentMessages}>
        {children}
      </IntlProvider>
    </LanguageChoiceContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageChoiceContext);
