import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import useElectricVehicleDetails from '../../../../../api/hooks/useElectricVehicleDetails';
import genRateCompData from '../../../../../calculations/genRateCompData/genRateCompData';
import Icon from '../../../../../components/icon/Icon';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';
import { formatAsDollars, formatVehicleName } from '../../../../../utils/formatters';

export default function SummaryStep(): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs } = useUserPrefs();
  const { vehicle } = useElectricVehicleDetails(userPrefs.comparedVehicleId1 || '');

  if (!vehicle || !userPrefs) return <></>;

  const graphData = genRateCompData(userPrefs, vehicle);
  const totalBillSavings =
    graphData.currentRate.home +
    graphData.currentRate.ev +
    graphData.currentRate.gasoline -
    graphData.lowestRate.home -
    graphData.lowestRate.ev -
    graphData.lowestRate.gasoline;

  return (
    <Box textAlign="center" maxWidth="350px" mx="auto">
      <Typography variant="h5" id="modal-title">
        {totalBillSavings > 0 && <Icon variant="checkmark" sx={{ m: '1rem auto' }} />}
        {formatMessage(
          {
            id:
              totalBillSavings < 0
                ? 'rates.wizard.summary.extraSpend'
                : 'rates.wizard.summary.savings',
          },
          {
            amount: (
              <span style={{ color: '#7D9C3B', fontWeight: '700' }}>
                {formatAsDollars(Math.abs(totalBillSavings))}
              </span>
            ),
            rate: graphData.lowestRate.label,
            vehicle: formatVehicleName(vehicle),
          },
        )}
      </Typography>
      <Typography mt={2}>
        {formatMessage({
          id: 'rates.wizard.summary.historicalDataPrompt',
        })}
      </Typography>
    </Box>
  );
}
