import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import Modal from '../../../components/modal/Modal';
import CheckmarkIcon from '../../../assets/images/icons/CheckmarkIcon.tsx';
import Button from '../../../components/button/Button';
import pgeLogo from '../../../assets/images/pge_spot_full_rgb_rev_md.png';
import { YEAR } from '../../../utils/assumptions';
import GaTracker from '../../../utils/GaTracker/GaTracker';

function CheckmarkedList({ items }) {
  return (
    <List
      sx={{
        mb: 2,
        '& .MuiListItem-root': {
          py: 0,
        },
      }}
    >
      {items.map((text) => (
        <ListItem key={text}>
          <ListItemIcon>
            <CheckmarkIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{text}</Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const PGE_SMD_CLIENT_ID = '51528f73afbc4443a3ba3fe8e4ea9cfc';
const PGE_SMD_REDIRECT_URI =
  'https://58k5ovoz46.execute-api.us-west-2.amazonaws.com/pre_oauth_redirect';

export default function HistoricalDataModal({ open, onClose }) {
  const { formatMessage } = useIntl();
  const {
    palette: {
      blue: { 700: blue700 },
    },
  } = useTheme();

  const HOW_TO_SHARE_ITEMS = [
    formatMessage({ id: 'rates.historicalDataModal.howToSteps.step1' }),
    formatMessage({ id: 'rates.historicalDataModal.howToSteps.step2' }),
    formatMessage({ id: 'rates.historicalDataModal.howToSteps.step3' }),
  ];

  const SCOPE_OF_DATA_ITEMS = [
    formatMessage({ id: 'rates.historicalDataModal.scopeOfData.step1' }),
    formatMessage({ id: 'rates.historicalDataModal.scopeOfData.step2' }),
  ];

  // PG&E's SMD seems to be picky about the format here. Their 'scope' parameter delimits with a semicolon
  // and uses = to separate key/value pairs. This format works empirically.
  const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
  const stateParamString = `environment=${environment};year=${YEAR}`;
  const smdURL = `https://sharemydata.pge.com/myAuthorization?client_id=${PGE_SMD_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    PGE_SMD_REDIRECT_URI,
  )}&response_type=code&state=${stateParamString}`;

  const handleClickShareMyData = () => {
    GaTracker.trackEvent({
      category: 'Share My Data',
      action: 'Visited PG&E Share My Data Page',
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Box
          mb={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <img src={pgeLogo} alt="PG&E Logo" style={{ height: '3em' }} />
          <Typography variant="h2" sx={{ textAlign: 'center', flexGrow: 1 }}>
            {formatMessage({ id: 'rates.historicalDataModal.historicalData' })}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          {formatMessage({ id: 'rates.historicalDataModal.howToShareYourData' })}
        </Typography>
        <CheckmarkedList items={HOW_TO_SHARE_ITEMS} />
        <Typography sx={{ fontWeight: 'bold' }}>
          {formatMessage({ id: 'rates.historicalDataModal.scopeOfSharing' })}
        </Typography>
        <CheckmarkedList items={SCOPE_OF_DATA_ITEMS} />

        <Typography sx={{ fontWeight: 'bold' }}>
          {formatMessage({ id: 'rates.historicalDataModal.importantNote' })}
        </Typography>

        <Typography mb={1}>
          {formatMessage({ id: 'rates.historicalDataModal.multipleServiceIds' })}
        </Typography>
        <Typography mb={1}>
          {formatMessage({ id: 'rates.historicalDataModal.coolDownTime' })}
        </Typography>
        <Typography mb={2}>
          {formatMessage({ id: 'rates.historicalDataModal.forMoreInformation' })}{' '}
          <Link
            href="https://www.pge.com/en_US/small-medium-business/energy-alternatives/alternatives-to-pge/third-party-data-access.page"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'blue.700', fontWeight: '400', textDecorationColor: blue700 }}
          >
            {formatMessage({ id: 'rates.historicalDataModal.pgeHelpCenter' })}
          </Link>
        </Typography>
        <Button
          component={Link}
          variant="contained"
          href={smdURL}
          fullWidth
          onClick={handleClickShareMyData}
        >
          {formatMessage({ id: 'rates.historicalDataModal.shareMyData' })}
        </Button>
      </Box>
    </Modal>
  );
}
