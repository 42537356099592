import { useState, useRef } from 'react';
import { transformChargingStationAvailability } from '../../../api/transformers/transformChargingStationAvailibility';
import { useCachedFetch } from '../../../context/apiCacheProvider';
import { Bounds, ChargingStation, TomTomChargingStation } from '../../../types';

const CHARGER_URL = 'https://api.tomtom.com/search/2/poiSearch/"charger".json';

export default function useChargingStationAvailability() {
  const { fetchRecords } = useCachedFetch();
  const [loading, setLoading] = useState(false);
  const [chargingStations, setChargingStations] = useState<Array<ChargingStation>>([]);
  const [error, setError] = useState('');

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchChargingStations = async (bounds: Bounds) => {
    if (!bounds) return;
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    try {
      abortControllerRef.current = new window.AbortController();
      setLoading(true);

      const json = await fetchRecords<{ results: Array<TomTomChargingStation> }>(CHARGER_URL, {
        key: process.env.REACT_APP_TOMTOM_API_KEY || '',
        limit: '100',
        topLeft: `${bounds.top},${bounds.left}`,
        btmRight: `${bounds.bottom},${bounds.right}`,
      });
      // const json = await response.json();

      abortControllerRef.current = null;

      setLoading(false);

      if (json) {
        setChargingStations(json.results.map(transformChargingStationAvailability));
      }
    } catch (error: any) {
      setLoading(false);

      if (error.name !== 'AbortError') {
        setError(error);
      }
      abortControllerRef.current = null;
    }
  };

  return {
    error,
    chargingStations,
    fetchChargingStations,
    loading,
  };
}
