import PhoneIcon from '../../../../assets/images/icons/icn_phone.svg';
import LinkIcon from '../../../../assets/images/icons/icn_link.svg';
import GaTracker from '../../../../utils/GaTracker/GaTracker';
import type { OemImage } from '../../../../types';
import { Box, Typography } from '@mui/material';
import Link from '../../../link/Link';
import Card from '../../../card/Card';
import Button from '../../../button/Button';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  street: string;
  city: string;
  region: string;
  postcode: string;
  phone: string;
  website: string;
  oemImage?: OemImage;
};

export default function DealerCard({
  name,
  street,
  city,
  region,
  postcode,
  phone,
  website,
  oemImage,
}: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const secondLineAddress = [city, region, postcode].filter((el) => el).join(', ');

  const handleClickWebsite = () => {
    GaTracker.trackEvent({
      category: 'Dealers',
      action: 'Clicked Website Link',
      label: name,
    });
  };

  const handleClickPhone = () => {
    GaTracker.trackEvent({
      category: 'Dealers',
      action: 'Clicked Phone Link',
      label: name,
    });
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {oemImage && <img src={oemImage.imageUrl} alt={oemImage.name} />}
      <Typography variant="h6" fontSize="0.875rem">
        {name || ''}
      </Typography>
      <Box py="0.5rem" fontSize="0.875rem" flexGrow={1}>
        {street || ''}
        <br />
        {secondLineAddress || ''}
      </Box>
      <Box mt="1rem" display="flex" gap="0.5rem" flexDirection="column">
        {phone && (
          <>
            <Link
              to={`tel:${phone}`}
              onClick={handleClickPhone}
              external
              sx={{ display: { xs: 'block', sm: 'none' } }}
            >
              <Button variant="outlined" fullWidth>
                <Box display="flex" gap="1rem">
                  {formatMessage({ id: 'maps.dealerMap.contact' })}
                  <img src={PhoneIcon} alt="" />
                </Box>
              </Button>
            </Link>

            <Box display={{ xs: 'none', sm: 'block' }}>
              <Typography>Phone: {phone}</Typography>
            </Box>
          </>
        )}
        {website && (
          <Link to={website} onClick={handleClickWebsite} external>
            <Button variant="outlined" fullWidth>
              <Box display="flex" gap="1rem">
                {formatMessage({ id: 'maps.dealerMap.website' })}
                <img src={LinkIcon} alt="" />
              </Box>
            </Button>
          </Link>
        )}
      </Box>
    </Card>
  );
}
