import { Box } from '@mui/material';
import Section from '../../components/section/Section';
import AffordableEv from './components/affordableEv/AffordableEv';
import BigPromise from './components/bigPromise/BigPromise';
import ExistingEvOwners from './components/existingEvOwners/ExistingEvOwners';
import ElectricVehicles from './components/electricVehicles/ElectricVehicles';
import HomepageIncentives from './components/incentives/HomepageIncentives';
import RateCompareLauncher from './components/rateCompareLauncher/RateCompareLauncher';
import WhyElectric from './components/whyElectric/WhyElectric';

const Homepage = () => {
  return (
    <Box bgcolor="grey.100">
      <BigPromise />
      <Section>
        <WhyElectric />
      </Section>
      <ExistingEvOwners />
      <Section id="HomepageVehicles" sx={{ maxWidth: '990px' }}>
        <ElectricVehicles />
      </Section>
      <Section
        id="HomepageRateComparison"
        sx={{
          backgroundColor: 'blue.500',
          color: 'white.100',
        }}
      >
        <RateCompareLauncher />
      </Section>
      <Section id="HomepageIncentives">
        <HomepageIncentives />
      </Section>
      <Section>
        <AffordableEv />
      </Section>
    </Box>
  );
};

export default Homepage;
