import { Box, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import getPaymentDetails from '../../../../calculations/vehicle/getPaymentDetails';
import Button from '../../../../components/button/Button';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';
import Card from '../../../../components/card/Card';
import { LeftArrowIcon } from '../../../../components/icon/Vectors';
import Image from '../../../../components/image/Image';
import Link from '../../../../components/link/Link';
import Select from '../../../../components/select/Select';
import Spec from '../../../../components/spec/Spec';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import type { ElectricVehicle } from '../../../../types';
import { formatAsDollars, formatVehicleDisplayName } from '../../../../utils/formatters';

const TabButton = ({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: '#FFF',
      border: 'none',
      borderBottom: (theme) => `3px solid ${isActive ? theme.palette.orange[500] : 'transparent'}`,
      borderRadius: 0,
      '&:hover': {
        border: 'none',
        borderBottom: (theme) =>
          `3px solid ${isActive ? theme.palette.orange[500] : 'transparent'}`,
      },
    }}
  >
    {children}
  </Button>
);

type Props = {
  vehicle: ElectricVehicle;
  onShowDealerMap?: () => void;
  vehicleYears?: number[];
  onChangeVehicleYear?: (year: number) => void;
};

export default function Header({
  vehicle,
  onShowDealerMap,
  vehicleYears,
  onChangeVehicleYear,
}: Props): JSX.Element | null {
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState(0);

  const image = vehicle.images?.find((i) => i.urlFull && i.urlThumbnail && i.altText);

  const { userPrefs } = useUserPrefs();
  const monthsOfOwnership = userPrefs.monthsOfOwnership;
  const interestRateAsBasisPoints = userPrefs.interestRateAsBasisPoints;
  const milesDrivenAnnually = userPrefs.milesDrivenAnnually;

  const paymentDetails = getPaymentDetails(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    milesDrivenAnnually,
  );

  const hasIncentives = vehicle.incentives.length > 0;

  const paymentData = [
    [
      {
        label: formatMessage({
          id: !hasIncentives
            ? 'vehicleDetails.header.msrpStar'
            : vehicle.status === 'used'
            ? 'vehicleDetails.header.usedAfterIncentives'
            : 'vehicleDetails.header.afterIncentives',
        }),
        value:
          paymentDetails.afterIncentives <= 500
            ? formatMessage({ id: 'vehicleDetails.header.atOrNearZero' })
            : formatAsDollars(paymentDetails.afterIncentives),
      },
      {
        label: formatMessage({
          id:
            vehicle.status === 'used'
              ? 'vehicleDetails.header.usedMsrp'
              : 'vehicleDetails.header.msrp',
        }),
        value: formatAsDollars(paymentDetails.msrp),
      },
      {
        label: formatMessage({ id: 'vehicleDetails.header.estimatedIncentives' }),
        value: formatAsDollars(paymentDetails.totalIncentivesForPurchase),
      },
    ],
    [
      {
        label: formatMessage({ id: 'vehicleDetails.header.loanPayment' }),
        value: formatMessage(
          { id: 'vehicleDetails.header.perMonth' },
          { val: formatAsDollars(paymentDetails.loanAmountPerMonth) },
        ),
      },
      {
        label: formatMessage({ id: 'vehicleDetails.header.downPayment' }),
        value: formatAsDollars(paymentDetails.downPayment),
      },
      {
        label: formatMessage({ id: 'vehicleDetails.header.estimatedIncentives' }),
        value: formatAsDollars(paymentDetails.totalIncentivesForPurchase),
      },
    ],
    [
      {
        label: formatMessage({ id: 'vehicleDetails.header.leasePayment' }),
        value: `${formatAsDollars(paymentDetails.leaseAmountPerMonth)} / month`,
      },
      {
        label: formatMessage({ id: 'vehicleDetails.header.firstLeasePayment' }),
        value: formatAsDollars(paymentDetails.leaseFirstPayment),
      },
      {
        label: formatMessage({ id: 'vehicleDetails.header.incentivesForLease' }),
        value: formatAsDollars(paymentDetails.totalIncentivesForLease),
      },
    ],
  ];

  if (!image) return null;
  return (
    <Box bgcolor="grey.100">
      <Box maxWidth="1140px" p="2rem" mx="auto">
        <Link to="/vehicles" sx={{ color: '#000' }}>
          <Box display="flex">
            <LeftArrowIcon />
            <Typography textTransform="uppercase" fontWeight="bold" ml={2} mb={1}>
              {formatMessage({ id: 'vehicleDetails.header.allVehicles' })}
            </Typography>
          </Box>
        </Link>
        <Typography fontWeight="500" fontSize="2.5rem">
          {vehicle.status === 'used'
            ? formatMessage({ id: 'vehicleDetails.header.used' })
            : vehicle.modelYear}{' '}
          {formatVehicleDisplayName(vehicle)}
        </Typography>
        <Box display="flex" alignItems="flex-start" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box flex="1 1" p={{ xs: '1rem', sm: '1rem 5rem' }} sx={{ width: '100%' }}>
            <Image src={image.urlFull} alt={image.altText} sx={{ width: '100%' }} />
          </Box>
          <Box width={{ xs: '100%', md: 'auto' }}>
            <Card sx={{ p: 0, minWidth: '320px', color: 'grey.700' }}>
              {vehicle.status === 'used' && vehicleYears && onChangeVehicleYear && (
                <Box
                  display="flex"
                  justifyContent="center"
                  px={3}
                  py={2}
                  sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}` }}
                >
                  <Select
                    sx={{ width: '200px' }}
                    value={`${vehicle.modelYear}`}
                    onChange={(yearStr) => onChangeVehicleYear?.(+yearStr)}
                    options={
                      vehicleYears?.map((year) => ({ value: `${year}`, label: `${year}` })) || []
                    }
                    label={formatMessage({ id: 'vehicleDetails.header.year' })}
                  />
                </Box>
              )}
              {vehicle.status !== 'used' && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  fontWeight="500"
                  fontSize="1rem"
                  px={3}
                  pt={1}
                  sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}` }}
                >
                  <TabButton onClick={() => setActiveTab(0)} isActive={activeTab === 0}>
                    {formatMessage({ id: 'common.purchaseMethods.cash' })}
                  </TabButton>
                  <TabButton onClick={() => setActiveTab(1)} isActive={activeTab === 1}>
                    {formatMessage({ id: 'common.purchaseMethods.loan' })}
                  </TabButton>
                  <TabButton onClick={() => setActiveTab(2)} isActive={activeTab === 2}>
                    {formatMessage({ id: 'common.purchaseMethods.lease' })}
                  </TabButton>
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                py="2rem"
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}` }}
              >
                <Box textAlign="left">
                  <Spec label={paymentData[activeTab][0].label} size="lg">
                    {paymentData[activeTab][0].value}
                  </Spec>
                </Box>
              </Box>
              {hasIncentives && (
                <Box
                  display="flex"
                  p={3}
                  sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}` }}
                >
                  <Box flex="1 1">
                    <Spec label={paymentData[activeTab][1].label} size="md">
                      {paymentData[activeTab][1].value}
                    </Spec>
                  </Box>
                  <Box flex="1 1">
                    <Spec label={paymentData[activeTab][2].label} size="md">
                      {paymentData[activeTab][2].value}
                    </Spec>
                  </Box>
                </Box>
              )}
            </Card>
            <Box display="flex" justifyContent="center" gap="0.5rem" p="1rem">
              <ButtonLink to="/vehicles" variant="outlined" sx={{ backgroundColor: '#FFF' }}>
                {formatMessage({ id: 'vehicleDetails.header.allCarsLink' })}
              </ButtonLink>
              <Button onClick={onShowDealerMap}>
                {formatMessage({ id: 'vehicleDetails.header.findDealersLink' })}
              </Button>
            </Box>
          </Box>
        </Box>
        {vehicle.status === 'used' && (
          <Typography color="grey.600">
            {formatMessage(
              {
                id: 'vehicleDetails.header.usedDisclaimer',
              },
              {
                link: (
                  <a
                    href="https://www.pge.com/pge_global/common/pdfs/solar-and-vehicles/options/clean-vehicles/electric/EV-Checklist.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatMessage({ id: 'vehicleDetails.header.usedDisclaimerLink' })}
                  </a>
                ),
              },
            )}
          </Typography>
        )}
        {!hasIncentives && (
          <Box sx={{ textAlign: 'center' }}>
            {formatMessage(
              { id: 'vehicleDetails.header.noIncentivesExplainer' },
              {
                link: <Link to="/incentives">{formatMessage({ id: 'incentives.headline' })}</Link>,
              },
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
