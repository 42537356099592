import { Box, Typography } from '@mui/material';
import type { ElectricVehicle } from '../../../../types';
import FuelTypeIcon from '../../../../components/fuelTypeIcon/FuelTypeIcon';
import Image from '../../../../components/image/Image';
import Link from '../../../../components/link/Link';
import Card from '../../../../components/card/Card';
import Button from '../../../../components/button/Button';
import { useIntl } from 'react-intl';

type Props = {
  ev: ElectricVehicle;
  urlBuilder: (ev: ElectricVehicle) => string;
  selected?: boolean;
  onClick?: () => void;
};

export default function EvCard({ ev, selected, onClick, urlBuilder }: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const imgSrc = ev.images?.find((image) => image.urlThumbnail)?.urlThumbnail;

  return (
    <Card
      onClick={onClick}
      sx={{
        p: 0,
        cursor: onClick ? 'pointer' : 'inherit',
        borderRadius: '4px',
        height: 'fit-content',
        ...(selected ? { boxShadow: (theme) => `0 0 0 4px ${theme.palette.blue[300]}` } : {}),
      }}
    >
      <Box>
        <Box p="15px 15px 10px 15px">
          <Box display="flex" justifyContent="space-between">
            <Typography component="h4">{ev.make}</Typography>
            <FuelTypeIcon fuelType={ev.fuelType} />
          </Box>
          <Typography component="h6" height="50px">
            {ev.displayName}
          </Typography>
          {imgSrc && <Image src={imgSrc} alt={'Go to ' + ev.displayName} sx={{ width: '100%' }} />}
        </Box>
        {selected && (
          <Box m="0 auto 15px auto" px={{ xs: 1, md: 0 }} textAlign="center">
            <Link to={urlBuilder(ev)}>
              <Button>{formatMessage({ id: 'homepage.evCosts.seeEvButton' })}</Button>
            </Link>
          </Box>
        )}
      </Box>
    </Card>
  );
}
