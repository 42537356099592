import iconCharging from '../../../../assets/images/icons/icon-charging.png';
import iconParking from '../../../../assets/images/icons/icon-parking.png';
import iconPollution from '../../../../assets/images/icons/icon-pollution.png';
import iconRoad from '../../../../assets/images/icons/icon-road.png';
import iconSavings from '../../../../assets/images/icons/icon-savings.png';
import Link from '../../../../components/link/Link';
import Card from '../../../../components/card/Card';
import { Box, Divider, Typography } from '@mui/material';
import type { Incentive } from '../../../../types/incentive';
import GaTracker from '../../../../utils/GaTracker/GaTracker';

type Props = {
  incentive: Incentive;
};

const IncentiveCard = ({ incentive }: Props) => {
  const { grantor = '', type = '', name = '', description = '', typicalAmount = '' } = incentive;

  const handleClickIncentive = () => {
    GaTracker.trackEvent({
      category: 'Incentives',
      action: 'Clicked Incentive Card',
      label: `${name} from ${grantor}`,
    });
  };

  // Source: https://docs.google.com/spreadsheets/d/1PzIwWxazz8MsT67AkAhXJsI9OPOAPlL1AA6PHV6WQDs
  let titleIcon = iconSavings; // Default for Financing, Insurance discount, Rebate, Tax credit
  let titleIconAlt = 'Icon of Saving';
  switch (type) {
    case 'Parking exemption':
      titleIcon = iconParking;
      titleIconAlt = 'Icon of Parking';
      break;
    case 'charging_network_rebate':
    case 'discounted_electric_rate':
    case 'Public Charging':
      titleIcon = iconCharging;
      titleIconAlt = 'Icon of Charging';
      break;
    case 'vehicle_retirement':
      titleIcon = iconPollution;
      titleIconAlt = 'Icon of Pollution';
      break;
    case 'HOV lane':
    case 'Toll discount':
      titleIcon = iconRoad;
      titleIconAlt = 'Icon of a Road';
      break;
    default:
  }

  const title =
    typicalAmount !== 'N/A' ? (
      typicalAmount.replace('-', '- ')
    ) : (
      <img src={titleIcon} alt={titleIconAlt} height="60" width="60" />
    );
  const isIneligible = 'ineligible';

  return (
    <Link
      external
      to={incentive.detailsUrl}
      onClick={handleClickIncentive}
      sx={isIneligible ? {} : {}}
    >
      <Card
        sx={{
          p: 0,
          height: '100%',
          borderRadius: '4px',
          boxShadow: 0,
          ':hover': {
            boxShadow: 5,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m="7px"
          fontSize="14px"
          fontWeight="400"
          height="30px"
          sx={{
            color: (theme) => theme.palette.white[100],
            backgroundColor: (theme) => theme.palette.blue[400],
            borderRadius: '4px',
          }}
        >
          {grantor}
        </Box>
        <Box textAlign="center" m="19px">
          <Typography component="h5" fontWeight="500" fontSize="1.75rem" m="15px 0 30px 0">
            {title}
          </Typography>
          <Typography component="h6" fontWeight="500">
            {name}
          </Typography>
        </Box>
        <Divider />
        <Box
          p={{ xs: '8px', sm: '19px' }}
          textAlign="center"
          fontWeight="300"
          fontSize={{ xs: '0.875rem', sm: '1rem' }}
        >
          {description}
        </Box>
      </Card>
    </Link>
  );
};

export default IncentiveCard;
