import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Button from '../../../../../components/button/Button';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';

export default function ChargingTimeStep(): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  return (
    <>
      <Box>
        <Typography variant="h5" textAlign="center" id="modal-title">
          {formatMessage({ id: 'rates.wizard.chargingTime.chargingTimeTitle' })}
        </Typography>
        <Typography textAlign="center">
          {formatMessage({ id: 'rates.wizard.chargingTime.chargingTimeHelper' })}
        </Typography>
      </Box>
      <Box>
        <Box role="group" display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap="8px">
          {[
            {
              label: (
                <Box lineHeight={1.3}>
                  <Typography fontSize="1.5rem">$</Typography>
                  {formatMessage({
                    id: 'common.chargingPeriods.afterMidnightBefore3pm',
                  })}
                </Box>
              ),
              value: 'afterMidnightBefore3pm',
            },
            {
              label: (
                <Box lineHeight={1.3}>
                  <Typography fontSize="1.5rem">$$</Typography>
                  {formatMessage({
                    id: 'common.chargingPeriods.after9pmBefore4pm',
                  })}
                </Box>
              ),
              value: 'after9pmBefore4pm',
            },
            {
              label: (
                <Box lineHeight={1.3}>
                  <Typography fontSize="1.5rem">$$$</Typography>
                  {formatMessage({
                    id: 'common.chargingPeriods.after4pmBefore9pm',
                  })}
                </Box>
              ),
              value: 'after4pmBefore9pm',
            },
            {
              label: (
                <Box lineHeight={1.3}>
                  <Typography fontSize="1.5rem">$?</Typography>
                  <br />
                  {formatMessage({ id: 'common.chargingPeriods.all' })}
                  <br />{' '}
                </Box>
              ),
              value: 'unpredictable',
            },
          ].map((option) => {
            const checked = userPrefs.vehicleChargingPattern === option.value;

            return (
              <Button
                key={option.value}
                fullWidth
                onClick={() => {
                  !checked && setUserPrefs({ vehicleChargingPattern: option.value });
                }}
                variant={checked ? 'contained' : 'outlined'}
              >
                {option.label}
              </Button>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
