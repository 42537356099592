import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import Slider from '../../../../../components/slider/Slider';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';

export default function MonthlyBillStep(): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  return (
    <>
      <Box>
        <Typography variant="h5" textAlign="center" id="modal-title">
          {formatMessage({ id: 'rates.wizard.monthlyBill.monthlyElectricBillTitle' })}
        </Typography>
      </Box>
      <Box>
        <Slider
          label={formatMessage({ id: 'rates.wizard.monthlyBill.monthlyElectricBillLabel' })}
          valuePrefix={formatMessage({ id: 'rates.wizard.monthlyBill.dollarSign' })}
          valueSuffix={formatMessage({ id: 'rates.wizard.monthlyBill.monthlyElectricBillSuffix' })}
          min={0}
          max={2000}
          value={userPrefs.homeElectricBillMonthlyAvg}
          onChange={(value) => setUserPrefs({ homeElectricBillMonthlyAvg: value })}
        />
      </Box>
    </>
  );
}
