import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import Link from '../../components/link/Link';
import Page from '../../components/page/Page';
import TextInput from '../../components/textInput/TextInput';
import VehicleCard from '../vehicleCatalog/components/vehicleCard/VehicleCard';
import { useVehicleSearch } from './hooks/useVehicleSearch';
import { useIncentiveSearch } from './hooks/useIncentiveSearch';
import IncentiveCard from '../incentives/components/IncentiveCard';
import Card from '../../components/card/Card';
import ZipcodeInputWithButton from '../../components/zipcodeInputWithButton/ZipcodeInputWithButton';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { useIntl } from 'react-intl';
import GaTracker from '../../utils/GaTracker/GaTracker';

export default function Search(): JSX.Element {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [searchInput, changeSearchInput] = useState<string>('');
  // Seperating search input from search phrase allows
  // - delay search until user stops typing so the page isn't jumpy
  const [searchPhrase, changeSearchPhrase] = useState<string>('');
  const { vehicles } = useVehicleSearch(searchPhrase);
  const { incentives } = useIncentiveSearch(searchPhrase);

  const [showMoreVehicles, changeShowMoreVehicles] = useState(false);
  const toggleShowMoreVehicles = () => changeShowMoreVehicles((currentValue) => !currentValue);
  const [showMoreIncentives, changeShowMoreIncentives] = useState(false);
  const toggleShowMoreIncentives = () => changeShowMoreIncentives((currentValue) => !currentValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      GaTracker.trackEvent({
        category: 'Search',
        action: 'Search term change',
        label: searchInput,
      });
      changeSearchPhrase(searchInput);
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchInput]);

  return (
    <Page>
      <Box sx={{ backgroundColor: 'grey.100' }} height="100%">
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap="2rem"
          maxWidth="1140px"
          m="auto"
          p={{ xs: 2, md: 4 }}
        >
          {!isSmallScreen && (
            <Box
              display={{ xs: 'none', md: 'flex' }}
              flex="1 1"
              minWidth="240px"
              flexDirection="column"
              gap="1rem"
            >
              <Card>
                <Typography variant="h5">{formatMessage({ id: 'search.filterTitle' })}</Typography>
                <ZipcodeInputWithButton
                  zipcode={userPrefs.zipcode}
                  onSubmitZipcode={(value) => setUserPrefs({ zipcode: value })}
                />
              </Card>
            </Box>
          )}
          <Box flex="3 1" minWidth="300px" display="flex" flexDirection="column" gap="1.75rem">
            <TextInput
              fullWidth
              label={formatMessage({ id: 'search.searchInputLabel' })}
              value={searchInput}
              onChange={changeSearchInput}
              placeholder={formatMessage({ id: 'search.searchInputPlaceholder' })}
              sx={{ '& .MuiInputLabel-root .MuiTypography-root': { color: 'grey.600' } }}
            />
            {isSmallScreen && (
              <Card>
                <Typography variant="h5">{formatMessage({ id: 'search.filterTitle' })}</Typography>
                <ZipcodeInputWithButton
                  zipcode={userPrefs.zipcode}
                  onSubmitZipcode={(value) => setUserPrefs({ zipcode: value })}
                />
              </Card>
            )}
            <Box display="flex" gap="1rem" alignItems="center">
              <Typography variant="h4">{formatMessage({ id: 'search.vehiclesTitle' })}</Typography>
              <Box p={1} fontSize="0.75rem" sx={{ backgroundColor: 'grey.200' }}>
                {formatMessage({ id: 'search.resultsCount' }, { count: vehicles.length })}
              </Box>
              {vehicles.length > 3 && (
                <Box flex="1 1" textAlign="right">
                  <Button variant="text" onClick={toggleShowMoreVehicles}>
                    {formatMessage({
                      id: showMoreVehicles ? 'search.viewLess' : 'search.viewMore',
                    })}
                  </Button>
                </Box>
              )}
            </Box>
            {vehicles && vehicles.length > 0 ? (
              <Grid container spacing={2} minWidth="300px">
                {(showMoreVehicles ? vehicles : vehicles.slice(0, 3)).map((vehicle) => (
                  <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
                    <Link to={`/vehicles/${vehicle.handle}`}>
                      <VehicleCard ev={vehicle} />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>
                {formatMessage({ id: 'search.noMatchingVehicles' })}{' '}
                <Link to="/vehicles">{formatMessage({ id: 'search.linkAllVehicles' })}</Link>
              </Typography>
            )}
            <hr />
            <Box display="flex" gap="1rem" alignItems="center">
              <Typography variant="h4">
                {formatMessage({ id: 'search.incentivesTitle' })}
              </Typography>
              <Box p={1} fontSize="0.75rem" sx={{ backgroundColor: 'grey.200' }}>
                {formatMessage({ id: 'search.resultsCount' }, { count: incentives.length })}
              </Box>
              {incentives.length > 3 && (
                <Box flex="1 1" textAlign="right">
                  <Button variant="text" onClick={toggleShowMoreIncentives}>
                    {formatMessage({
                      id: showMoreIncentives ? 'search.viewLess' : 'search.viewMore',
                    })}
                  </Button>
                </Box>
              )}
            </Box>
            {incentives && incentives.length > 0 ? (
              <Grid container spacing={2} minWidth="300px">
                {(showMoreIncentives ? incentives : incentives.slice(0, 3)).map((incentive) => (
                  <Grid item xs={12} sm={6} md={4} key={incentive.id}>
                    <IncentiveCard incentive={incentive} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>
                {formatMessage({ id: 'search.noMatchingIncentives' })}{' '}
                <Link to="/incentives">{formatMessage({ id: 'search.linkAllIncentives' })}</Link>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
