import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { VehicleTCO } from '../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import Card from '../../../components/card/Card';
import { GasIcon, HybridPlusIcon, LightningIcon } from '../../../components/icon/Vectors';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle, Vehicle } from '../../../types';
import { formatAsDollars, formatVehicleDisplayName } from '../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

type FuelCostGraphicProps = {
  variant: 'large' | 'small';
  fuelType: Vehicle['fuelType'];
  costDisplay: string;
};

const FuelCostGraphic = ({ variant, costDisplay, fuelType }: FuelCostGraphicProps) => (
  <Box height={150} width={150} display="flex" alignItems="center" justifyContent="center">
    <Box
      height={variant === 'large' ? 150 : 90}
      width={variant === 'large' ? 150 : 90}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      bgcolor={fuelType === 'BEV' ? 'orange.400' : fuelType === 'PHEV' ? 'blue.700' : 'grey.600'}
    >
      {fuelType === 'BEV' ? (
        <LightningIcon fill="#000" />
      ) : fuelType === 'PHEV' ? (
        <HybridPlusIcon />
      ) : (
        <GasIcon fill="#FFF" />
      )}
      <Typography color={fuelType === 'BEV' ? 'common.black' : 'common.white'} fontWeight="bold">
        {costDisplay}
      </Typography>
    </Box>
  </Box>
);

export default function MonthlyFuelComparison({
  vehicle1,
  vehicle2,
  vehicle1Tco,
  vehicle2Tco,
}: Props) {
  const { formatMessage } = useIntl();

  const vehicle1Fuel = vehicle1Tco.electricity.perMonth + vehicle1Tco.gasoline.perMonth;
  const vehicle2Fuel = vehicle2Tco.electricity.perMonth + vehicle2Tco.gasoline.perMonth;

  const monthlyFuelCostDelta = vehicle1Fuel - vehicle2Fuel;

  return (
    <Card>
      <Box py={2} display="flex" justifyContent="center">
        <Box width="560px" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" mb={6}>
            {formatMessage(
              {
                id:
                  monthlyFuelCostDelta > 0
                    ? 'compare.charts.fuelCostComparisonExpensive'
                    : 'compare.charts.fuelCostComparisonCheap',
              },
              {
                vehicle: formatVehicleDisplayName(vehicle1),
                costDelta: formatAsDollars(Math.abs(monthlyFuelCostDelta)),
              },
            )}
          </Typography>
          <Box display="flex" width="100%">
            <Box flex="1" display="flex" flexDirection="column" alignItems="center">
              <FuelCostGraphic
                variant={monthlyFuelCostDelta >= 0 ? 'large' : 'small'}
                fuelType={vehicle1.fuelType}
                costDisplay={formatAsDollars(vehicle1Fuel)}
              />
              <Typography mt={1.5} fontSize="0.875rem">
                {formatVehicleDisplayName(vehicle1)}
              </Typography>
            </Box>
            <Box flex="1" display="flex" flexDirection="column" alignItems="center">
              <FuelCostGraphic
                variant={monthlyFuelCostDelta > 0 ? 'small' : 'large'}
                fuelType={vehicle2.fuelType}
                costDisplay={formatAsDollars(vehicle2Fuel)}
              />
              <Typography mt={1.5} fontSize="0.875rem">
                {formatVehicleDisplayName(vehicle2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
