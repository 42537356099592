import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { GeneralEmergency } from '../../../../components/icon/Vectors';
import Link from '../../../../components/link/Link';
import { PspsEvent } from '../../../../types';

export default function PspsEventsBanner({
  pspsEvents,
  pspsError,
}: {
  pspsEvents?: PspsEvent[];
  pspsError?: string;
}): JSX.Element {
  const { formatMessage } = useIntl();

  if (!pspsEvents?.length && !pspsError) {
    return <></>;
  }

  return (
    <Box py="1rem" px={{ xs: 2, md: 4 }} sx={{ color: 'white.100', backgroundColor: 'red.500' }}>
      <Box display="flex" gap="1rem" maxWidth="1140px" m="auto">
        <GeneralEmergency width="24px" height="24px" fill="#ffffff" />
        <Typography flex="1 1" fontWeight="700">
          {formatMessage(
            { id: pspsError ? 'maps.pspsBannerError' : 'maps.pspsBanner' },
            {
              link: (
                <Link external to="https://pgealerts.alerts.pge.com/">
                  <Typography
                    color="white.100"
                    fontWeight="700"
                    display="inline-block"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {formatMessage({
                      id: pspsError ? 'maps.pspsBannerErrorLink' : 'maps.pspsBannerLink',
                    })}
                  </Typography>
                </Link>
              ),
            },
          )}
        </Typography>
      </Box>
    </Box>
  );
}
