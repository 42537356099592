import { useState, useRef } from 'react';

const useMappedZipcode = ({ zoom }: { zoom: number }) => {
  const [zipcode, setZipcode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [map, setMap] = useState<google.maps.Map>();

  const geocoderRef = useRef<google.maps.Geocoder>();

  const fetchZipcode = (newZipcode: string) => {
    setLoading(true);
    setError(undefined);

    // Mock out the known default zipcode to cut down on the Gooogle Maps Geocoder bill
    if (newZipcode === '94108') {
      setZipcode(newZipcode);
      map?.setCenter({
        lat: 37.7909427,
        lng: -122.4084994,
      });
      map?.setZoom(zoom || 15);
      setLoading(false);
    } else {
      geocoderRef.current?.geocode(
        {
          address: newZipcode,
        },
        (results, status) => {
          if (results && status === 'OK') {
            setZipcode(newZipcode);

            map?.setCenter(results[0].geometry.location);
            map?.setZoom(zoom || 15);
          } else {
            setError('We could not find that zipcode. Please check it and try again');
          }

          setLoading(false);
        },
      );
    }
  };

  const registerMappedZipcodeListeners = (map: google.maps.Map, initialZipcode: string) => {
    setMap(map);
    geocoderRef.current = new google.maps.Geocoder();
    fetchZipcode(initialZipcode);
  };

  return {
    zipcode,
    fetchZipcode,
    loading,
    error,
    registerMappedZipcodeListeners,
  };
};

export default useMappedZipcode;
