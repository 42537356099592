import { ReactNode, useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Page from '../../components/page/Page';
import levelOneImg from '../../assets/images/ev-charger-level-1.jpg';
import levelTwoImg from '../../assets/images/ev-charger-level-2.jpg';
import levelThreeImg from '../../assets/images/ev-charger-level-3.jpg';
import RightChargingStation from './RightChargingStation';
import Level2Purchase from './Level2Purchase';
import Level2Installation from './Level2Installation';
import LocalInstallation from './LocalInstallation';
import MultiUnitInstallation from './MultiUnitInstallation';
import Select from '../../components/select/Select';

const STYLES = {
  lineHeight: '26px',
  '& a': {
    color: 'blue.400',
    '&:not(:hover)': {
      textDecoration: 'none',
    },
  },
  '& p:last-child': {
    marginBottom: 0,
  },
  '& .MuiTableCell-root': {
    fontSize: '1rem',
  },
};

type Tab = {
  title: string;
  component: ReactNode;
};

const TABS: Tab[] = [
  { title: 'The Right Charging Station For You', component: <RightChargingStation /> },
  { title: 'Level 2 Purchasing Considerations', component: <Level2Purchase /> },
  { title: 'Level 2 Installation Checklist', component: <Level2Installation /> },
  { title: 'Local Charging Station Installers', component: <LocalInstallation /> },
  { title: 'Installing At a Multi-Unit Dwelling', component: <MultiUnitInstallation /> },
];

const ChargingImage = ({ src }: { src: string }) => (
  <Box
    display="flex"
    flexDirection="column"
    component="img"
    src={src}
    alt=""
    maxWidth={{ xs: '80%', md: '100%' }}
    mx="auto"
    height="auto"
  />
);

export default function ChargingFAQ() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Page>
      <Box bgcolor="grey.100">
        <Container maxWidth="md">
          <Typography variant="h1" py={8}>
            Charging your electric vehicle
          </Typography>
        </Container>
      </Box>
      <Box my={6} sx={STYLES}>
        <Container maxWidth="md">
          <Typography variant="h2" mb={1}>
            Choosing and installing a charging station
          </Typography>
          <p>
            Whether you already drive an electric vehicle (EV) or are thinking of getting one,
            charging plays a critical role in driving an EV. Learn more about the available charging
            options and get help with choosing and installing the right charging station for your
            home.
          </p>
          <p>
            There are three types of charging stations for electric vehicles (EVs): Level 1, Level 2
            and Direct Current (DC).
          </p>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <p className="h5">Level 1 charging station</p>
              <ChargingImage src={levelOneImg} />
              <p>Good for: Plug-in hybrid, such as the Kia Niro</p>
              <p>Miles/charge time: 5 miles per hour of charge</p>
              <p>Voltage: 110V</p>
            </Grid>
            <Grid item xs={12} md={4}>
              <p className="h5">Level 2 charging station</p>
              <ChargingImage src={levelTwoImg} />
              <p>Good for: Battery EVs, such as the Tesla Model 3</p>
              <p>Miles/charge time: 13 to 25 miles per hour of charge</p>
              <p>Voltage: 240V</p>
            </Grid>
            <Grid item xs={12} md={4}>
              <p className="h5">DC fast-charging station</p>
              <ChargingImage src={levelThreeImg} />
              <p>Good for: Most battery EVs. Check your manufacturer details.</p>
              <p>Miles/charge time: 10 to 30 minutes for a full charge</p>
              <p>Voltage: 480V-500V</p>
            </Grid>
          </Grid>

          <Box display={{ xs: 'none', md: 'flex' }} justifyContent="space-between" mt={4} mb={3}>
            {TABS.map(({ title }, idx) => (
              <Button
                key={title}
                onClick={() => setActiveTab(idx)}
                sx={{
                  backgroundColor: activeTab === idx ? 'blue.400' : 'grey.500',
                  color: '#FFF',
                  fontSize: '1rem',
                  textTransform: 'none',
                  flex: 1,
                  '&:hover': {
                    backgroundColor: activeTab === idx ? 'blue.400' : 'grey.400',
                  },
                  '&:not(:last-child)': {
                    mr: 4,
                  },
                }}
              >
                {title}
              </Button>
            ))}
          </Box>

          <Box display={{ xs: 'flex', md: 'none' }} mt={4} mb={3} justifyContent="center">
            <Select
              label="Pick a Topic"
              value={TABS[activeTab].title}
              options={TABS.map(({ title }) => ({
                label: title,
                value: title,
              }))}
              onChange={(value) => setActiveTab(TABS.findIndex(({ title }) => title === value))}
            />
          </Box>

          <Box>{TABS[activeTab].component}</Box>
        </Container>
      </Box>
    </Page>
  );
}
