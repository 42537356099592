import { useMemo, useState, useEffect } from 'react';
import { sampleSize } from 'lodash';
import { Box, Typography } from '@mui/material';
import Link from '../../../../components/link/Link';
import Button from '../../../../components/button/Button';
import useElectricVehicles from '../../../../api/hooks/useElectricVehicles';
import EvCard from './EvCard';
import Loading from '../../../../components/loading/Loading';
import { useIntl } from 'react-intl';

export default function FindADealership(): JSX.Element {
  const { formatMessage } = useIntl();
  const { newElectricVehicles, status } = useElectricVehicles();
  const [selectedEv, setSelectedEv] = useState('');
  const sampleEvs = useMemo(() => {
    return newElectricVehicles ? sampleSize(newElectricVehicles, 4) : null;
  }, [newElectricVehicles]);

  useEffect(() => {
    if (sampleEvs?.[1] && selectedEv === '') {
      setSelectedEv(sampleEvs[1].id);
    }
  }, [sampleEvs, setSelectedEv, selectedEv]);

  if (status === 'loading') return <Loading />;

  return (
    <Box mx="auto" textAlign="center" maxWidth="1057px">
      <Box textAlign="center" mb="1.25rem">
        <Typography component="h2" fontSize="2rem">
          {formatMessage({ id: 'evOwners.findADealership.headline1' })}
        </Typography>
        <Typography component="h2" fontSize="2rem">
          {formatMessage({ id: 'evOwners.findADealership.headline2' })}
        </Typography>
        <Typography fontSize="1.25rem">
          {formatMessage({ id: 'evOwners.findADealership.description' })}
        </Typography>
      </Box>
      <Box
        display="grid"
        gap="16px"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        gridTemplateRows={{ xs: 'repeat(2, 1fr)', md: '1fr' }}
      >
        {sampleEvs?.map((ev) => (
          <EvCard
            ev={ev}
            selected={selectedEv === ev.id}
            key={ev.id}
            onClick={() => setSelectedEv(ev.id)}
          />
        ))}
      </Box>
      <Box mt="2rem" textAlign="center">
        <Link to="/dealers">
          <Button>{formatMessage({ id: 'evOwners.findADealership.button' })}</Button>
        </Link>
      </Box>
    </Box>
  );
}
