/**
 * ------------------
 * Rate Engine Types
 * ------------------
 * These types should be imported from the rate engine library, but it doesn't export them yet.
 */
import { LoadProfile } from '@bellawatt/electric-rate-engine';

export interface RateCalculatorInterface {
  name: string;
  utilityName?: string;
  applicability?: string;
  minimumBillAmount?: number;
  rateElements: Array<RateElementInterface>;
  loadProfile: LoadProfile;
}

export interface RateElementInterface {
  id?: string;
  rateElementType: RateElementType;
  rateComponents?: Array<RateComponentInterface>;
  name: string;
  billingCategory?: BillingCategory;
  priceProfile?: Array<number> | PriceProfile;
}

export type RateElementType =
  | 'EnergyTimeOfUse'
  | 'BlockedTiersInDays'
  | 'BlockedTiersInMonths'
  | 'FixedPerDay'
  | 'FixedPerMonth'
  | 'MonthlyEnergy'
  | 'MonthlyDemand'
  | 'AnnualDemand'
  | 'SurchargeAsPercent'
  | 'HourlyEnergy'
  | 'DemandTimeOfUse'
  | 'DemandPerDay'
  | 'DemandTiersInMonths';

export type RateComponentInterface = BillingDeterminantFactoryInterface &
  RateElementFilterArgs & {
    charge: number | Array<number>;
    name: string;
  };

export type BillingDeterminantFactoryInterface =
  | EnergyTimeOfUseArgs
  | BlockedTiersArgs
  | SurchargeAsPercentArgs
  | HourlyEnergyArgs
  | {};

export interface RateElementFilterArgs {
  ids?: Array<string>;
  classifications?: Array<RateElementClassification>;
  billingCategories?: Array<BillingCategory>;
}

export enum RateElementClassification {
  ENERGY = 'energy',
  DEMAND = 'demand',
  FIXED = 'fixed',
  SURCHARGE = 'surcharge',
}

export enum BillingCategory {
  TAX = 'tax',
  SUPPLY = 'supply',
  DELIVERY = 'delivery',
}

export type PriceProfile = any;

export interface EnergyTimeOfUseArgs {
  months: Array<number>;
  daysOfWeek?: Array<number>;
  hourStarts: Array<number>;
  onlyOnDays: Array<string>;
  exceptForDays: Array<string>;
}

export interface LoadProfileFilterArgs {
  months?: Array<number>;
  daysOfWeek?: Array<number>;
  hourStarts?: Array<number>;
  onlyOnDays?: Array<string>;
  exceptForDays?: Array<string>;
  hoursOfYear?: Array<number>;
}

export interface BlockedTiersArgs extends LoadProfileFilterArgs {
  min: Array<number>;
  max: Array<number>;
}

export interface SurchargeAsPercentArgs {
  rateElement: RateElementInterface;
  percent: number;
}

export interface HourlyEnergyArgs {
  hourOfYear: number;
}

export interface RateInterface {
  name: string;
  title: string;
  rateElements: Array<RateElementInterface>;
}
