import { YEAR as ASSUMPTIONS_YEAR } from '../../utils/assumptions';

// We assume the user will charge their EV the same way every day all year.
// So here are the patterns: the loads for the 24 hours of any given day.
// Below, we automatically repeat them for every day in the year.

const afterMidnightBefore3pm = [
  1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 0, 0, 0, 0, 0, 0, 0, 0,
  0,
];
const after9pmBefore4pm = [
  1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 0, 0, 0, 0, 0,
  1.1, 1.1, 1.1,
];
const after4pmBefore9pm = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1.1, 1.1, 1.1, 1.1, 1.1, 0, 0, 0,
];
const all = [
  1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1,
  1.1, 1.1, 1.1, 1.1, 1.1,
];

const repeat = <T>(array: Array<T>, n: number) => {
  return new Array(n)
    .fill(null)
    .map((n) => array)
    .flat();
};

export const YEAR = ASSUMPTIONS_YEAR;

const days = YEAR % 4 === 0 ? 366 : 365;

const ELECTRIC_VEHICLE_LOAD_PROFILES: Record<string, Array<number>> = {
  afterMidnightBefore3pm: repeat(afterMidnightBefore3pm, days),
  after9pmBefore4pm: repeat(after9pmBefore4pm, days),
  after4pmBefore9pm: repeat(after4pmBefore9pm, days),
  unpredictable: repeat(all, days),
};

export default ELECTRIC_VEHICLE_LOAD_PROFILES;
