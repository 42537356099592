import { Box, Collapse, SxProps, Theme } from '@mui/material';
import { ReactNode, useState } from 'react';
import Icon from '../icon/Icon';

type Props = {
  title: ReactNode;
  children: ReactNode;
  startOpen?: boolean;
  sx?: SxProps<Theme>;
};

export default function Accordion({ title, children, startOpen, sx }: Props) {
  const [open, setOpen] = useState<boolean>(startOpen ? true : false);

  return (
    <Box sx={sx}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        onKeyDown={(event) => {
          if (['Enter', ' ', open ? 'ArrowUp' : 'ArrowDown'].includes(event.key)) {
            setOpen(!open);
          }
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Box flex="1 1">{title}</Box>
        <Box width="20px">
          <Icon
            variant="caret"
            sx={{
              transform: `translateY(-2px) scaleY(${open ? '-' : ''}1)`,
              transformOrigin: 'center',
            }}
          />
        </Box>
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
}
