import type { VehicleIncentive } from '../../types/vehicleIncentive';

const hasAllConditions = (incentive: VehicleIncentive) =>
  incentive && incentive.name && incentive.type && incentive.grantor && incentive.evaluation;
const isCvrp = (incentive: VehicleIncentive) => incentive.name === 'Clean Vehicle Rebate';

const isPgeAreaIncentive = (incentive: VehicleIncentive) =>
  incentive.type.toUpperCase() === 'REBATE' && incentive.grantor.toUpperCase() === 'PG&E';
// incentive.eligibility_factors.length === 1 &&
// incentive.eligibility_factors[0].toUpperCase() === "ZIPCODE";

const isFedTaxCredit = (incentive: VehicleIncentive) =>
  incentive.type.toUpperCase() === 'TAX CREDIT' && incentive.grantor.toUpperCase() === 'FEDERAL';

const isCustomPrimary = (incentive: VehicleIncentive) => {
  // Guard clauses to short circuit the logic
  if (!hasAllConditions(incentive)) {
    return false;
  }
  if (incentive.evaluation.eligibility === 'ineligible') {
    return false;
  }

  return isCvrp(incentive) || isPgeAreaIncentive(incentive) || isFedTaxCredit(incentive);
};
export default isCustomPrimary;
