import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../components/button/Button';
import ButtonGroup from '../../../../components/buttonGroup/ButtonGroup';
import Icon from '../../../../components/icon/Icon';
import Modal from '../../../../components/modal/Modal';
import Select from '../../../../components/select/Select';
import Slider from '../../../../components/slider/Slider';
import TextInput from '../../../../components/textInput/TextInput';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { isValidZipcode } from '../../../../utils/zipcodes';

type FormValues = {
  zipcode: string;
  taxFilingStatus: string;
  householdSize: number;
  householdIncome: number;
};

type Props = {
  open?: boolean;
  onClose: () => void;
};

export default function IncentivesModal({ open, onClose }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [values, _setValues] = useState<FormValues>({
    zipcode: userPrefs.zipcode,
    taxFilingStatus: userPrefs.taxFilingStatus,
    householdSize: userPrefs.householdSize,
    householdIncome: userPrefs.householdIncome,
  });

  const setValue = (newValue: Partial<FormValues>) => {
    _setValues({ ...values, ...newValue });
  };

  const canUpdateEligibility = isValidZipcode(values.zipcode);

  return (
    <Modal
      open={!!open}
      onClose={onClose}
      title={formatMessage({ id: 'vehicleCatalog.incentivesModal.headline' })}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Typography variant="h5">
          {formatMessage({ id: 'vehicleCatalog.incentivesModal.location' })}
        </Typography>
        <TextInput
          label={formatMessage({ id: 'vehicleCatalog.incentivesModal.zipCodeLabel' })}
          value={values.zipcode}
          startIcon={<Icon variant="pin" />}
          onChange={(value) => setValue({ zipcode: value })}
          errorMessage={
            isValidZipcode(values.zipcode)
              ? undefined
              : formatMessage({ id: 'common.zipInput.error' })
          }
        />
        <ButtonGroup
          label={formatMessage({ id: 'vehicleCatalog.incentivesModal.taxFilingLabel' })}
          value={values.taxFilingStatus}
          options={[
            {
              label: formatMessage({ id: 'vehicleCatalog.incentivesModal.statusOptions.single' }),
              value: 'single',
            },
            {
              label: formatMessage({ id: 'vehicleCatalog.incentivesModal.statusOptions.married' }),
              value: 'joint',
            },
            {
              label: formatMessage({
                id: 'vehicleCatalog.incentivesModal.statusOptions.headOfHousehold',
              }),
              value: 'head_of_household',
            },
          ]}
          onClick={(value) => setValue({ taxFilingStatus: value })}
        />
        <Select
          label={formatMessage({ id: 'vehicleCatalog.incentivesModal.householdSize' })}
          value={`${values.householdSize}`}
          options={new Array(10).fill('').map((_, index) => {
            const number = `${index + 1}`;
            return { value: number, label: number };
          })}
          onChange={(value: string) => setValue({ householdSize: parseInt(value) })}
        />
        <Slider
          label={formatMessage({ id: 'vehicleCatalog.incentivesModal.householdIncome' })}
          value={values.householdIncome}
          valuePrefix="$"
          min={0}
          max={350000}
          step={5000}
          onChange={(value) => setValue({ householdIncome: value })}
        />
        <Box mt={2} textAlign="center">
          <Button
            disabled={!canUpdateEligibility}
            onClick={() => {
              setUserPrefs(values);
              onClose();
            }}
          >
            {formatMessage({ id: 'vehicleCatalog.incentivesModal.updateButton' })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
