import genHomeLoadProfile from '../loadProfile/genHomeLoadProfile';
import genElectricVehicleLoadProfile from '../loadProfile/genElectricVehicleLoadProfile';
import genSolarLoadProfile from '../loadProfile/genSolarLoadProfile';
import calcVehicleGasolineCost from '../vehicle/calcVehicleGasolineCost';
import calcPublicChargingCost from '../vehicle/calcPublicChargingCost';
import { RATE_KEYS } from '../rate/selectRate';
import getElectricBill, { ElectricBill } from '../calcElectricBill/getElectricBill';
import { ElectricVehicle, isRateKey, UserPrefs } from '../../types';
import invertLoadProfile from '../../utils/invertLoadProfile';
import { YEAR, LOAD_PROFILE_HOURS } from '../../utils/assumptions';
import { LoadProfile } from '@bellawatt/electric-rate-engine';

const allZerosLoadProfile = new LoadProfile(
  Array.from({ length: LOAD_PROFILE_HOURS }, () => 0),
  { year: YEAR },
);

const genRateCompData: (
  userPrefs: UserPrefs,
  electricVehicle: ElectricVehicle,
  historicalLoadProfile?: Array<number>,
) => RateCompData = (
  userPrefs: UserPrefs,
  electricVehicle: ElectricVehicle,
  historicalLoadProfile?: Array<number>,
) => {
  const publicChargingCost = calcPublicChargingCost(userPrefs, electricVehicle);

  const homeLoadProfile = historicalLoadProfile
    ? new LoadProfile(historicalLoadProfile, { year: YEAR })
    : genHomeLoadProfile(userPrefs);

  const electricVehicleLoadProfile = genElectricVehicleLoadProfile(userPrefs, electricVehicle);
  const solarLoadProfile = userPrefs.hasSolarAtHome
    ? genSolarLoadProfile(userPrefs)
    : allZerosLoadProfile;

  const totalLoadProfile = homeLoadProfile
    .aggregate(electricVehicleLoadProfile)
    .aggregate(invertLoadProfile(solarLoadProfile));

  const rateCosts = RATE_KEYS.reduce((acc: ElectricBill[], rateKey) => {
    if (isRateKey(rateKey) && totalLoadProfile) {
      return [...acc, getElectricBill(rateKey, homeLoadProfile, totalLoadProfile, userPrefs)];
    }
    return acc;
  }, []);

  const vehicleGasolineCosts = calcVehicleGasolineCost(
    electricVehicle,
    userPrefs.electricMilesPortionForPhev,
    userPrefs.milesDrivenAnnually,
    userPrefs.gasolinePriceInCentsPerGal,
  );
  const equivalentGasVehicleGasolineCosts = calcVehicleGasolineCost(
    electricVehicle.equivalentGasVehicle,
    userPrefs.electricMilesPortionForPhev,
    userPrefs.milesDrivenAnnually,
    userPrefs.gasolinePriceInCentsPerGal,
    userPrefs.equivalentMilesPerGallon,
  );

  const currentRate = rateCosts.find((rate) => rate && rate.name === userPrefs.currentElectricRate);

  // PG&E often phases out rates, which means the rate can be selected as a current rate,
  // but should not be shown as an output because customers can't switch into it anymore.
  const filteredRateCosts = rateCosts.filter((rate) => {
    if (rate && rate.name !== 'E-TOU-B') {
      return true;
    }
    return false;
  });

  let lowestRate = filteredRateCosts[0];
  for (let i = 0; i < filteredRateCosts.length; i++) {
    if (
      filteredRateCosts[i] &&
      filteredRateCosts[i].total &&
      filteredRateCosts[i].total < lowestRate.total
    ) {
      lowestRate = filteredRateCosts[i];
    }
  }

  const sortedRateCosts = filteredRateCosts.sort((a, b) => {
    return a.total - b.total;
  });

  return {
    currentRate: {
      home: currentRate ? Math.round(currentRate.home) : 0,
      ev: 0,
      gasoline: Math.round(equivalentGasVehicleGasolineCosts),
      label: currentRate?.name || '',
    },
    lowestRate: {
      home: Math.round(lowestRate.home),
      ev: Math.round(publicChargingCost + lowestRate.ev),
      gasoline: Math.round(vehicleGasolineCosts),
      label: lowestRate.name,
    },
    rateOptions: {
      home: sortedRateCosts.map((rate) => Math.round(rate.home)),
      ev: sortedRateCosts.map((rate) => Math.round(publicChargingCost + rate.ev)),
      gasoline: sortedRateCosts.map((rate) => Math.round(vehicleGasolineCosts)),
      labels: sortedRateCosts.map((rate) => rate.name),
    },
  };
};

export default genRateCompData;

export type RateData = {
  home: number;
  ev: number;
  gasoline: number;
  label: string;
};

export type RateOptionsData = {
  home: number[];
  ev: number[];
  gasoline: number[];
  labels: string[];
};

export type RateCompData = {
  currentRate: RateData;
  lowestRate: RateData;
  rateOptions: RateOptionsData;
};
