import type { VehicleIncentive } from '../../../types/vehicleIncentive';

const capitalizedInLease = (incentives: VehicleIncentive[]) => {
  let incentivesArray = incentives || [];

  return incentivesArray.reduce((acc, incentive) => {
    let incentiveAmount =
      incentive &&
      incentive.evaluation &&
      incentive.evaluation.applicableToLease &&
      incentive.evaluation.capitalizedInLease &&
      !isNaN(incentive.evaluation.amountInLease)
        ? incentive.evaluation.amountInLease
        : 0;

    return acc + incentiveAmount;
  }, 0);
};

export default capitalizedInLease;
