import { ApiPspsEvent, PspsEvent } from '../../types';

export const transformPspsEvent = (apiEvent: ApiPspsEvent): PspsEvent => {
  return {
    id: apiEvent.id,
    content: Object.keys(apiEvent.content).reduce((acc: PspsEvent['content'], key) => {
      const languageContent = apiEvent.content[key];
      return {
        ...acc,
        [key]: {
          id: languageContent.id.toString(),
          loggedAt: languageContent.logged_at,
          name: languageContent.name,
          header: languageContent.header,
          htmlDescription: languageContent.html_description,
        },
      };
    }, {}),
    fileUrls: [...apiEvent.file_urls],
  };
};
