import { Box } from '@mui/material';
import type { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

export default function MapLegend({ children }: Props): JSX.Element {
  if (!children) return <></>;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.25rem"
      position="absolute"
      p="0.5rem"
      bottom={0}
      left={0}
      fontSize="0.75rem"
      sx={{
        color: 'white.100',
        backgroundColor: 'grey.900',
      }}
    >
      {children}
    </Box>
  );
}
