const setupGoogleMapsAutocomplete = (
  id: string,
  callback: () => void,
): google.maps.places.Autocomplete | null => {
  const input = document.getElementById(id) as HTMLInputElement | null;
  if (!input) return null;
  const autocomplete = new google.maps.places.Autocomplete(input, {
    types: ['geocode'],
  });
  autocomplete.setComponentRestrictions({
    country: ['us'],
  });

  // From https://stackoverflow.com/a/33357188
  // Don't respond to enter keypress if the user is interacting with the
  // autocomplete dropdown menu
  google.maps.event.addDomListener(input, 'keydown', (e: KeyboardEvent) => {
    // If it's Enter
    if (e.keyCode === 13) {
      // Select all Google's dropdown DOM nodes (can be multiple)
      const googleDOMNodes = document.getElementsByClassName(
        'pac-container',
      ) as HTMLCollectionOf<HTMLElement>;

      // Check if any of them are visible (using ES6 here for conciseness)
      const googleDOMNodeIsVisible = Array.from(googleDOMNodes).some(
        (node) => node.offsetParent !== null,
      );

      // If one is visible - preventDefault
      if (googleDOMNodeIsVisible) e.preventDefault();
    }
  });

  autocomplete.setFields(['formatted_address']);
  autocomplete.addListener('place_changed', callback);

  return autocomplete;
};

export default setupGoogleMapsAutocomplete;
