import { Box, FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material';
import type { ReactNode } from 'react';

type Props = {
  label: ReactNode;
  labelPlacement?: 'start' | 'end';
  checked: boolean;
  onChange: (checked: boolean) => void;
  fullWidth?: boolean;
};

export default function Switch({
  label,
  labelPlacement = 'start',
  checked,
  onChange,
  fullWidth,
}: Props) {
  return (
    <FormGroup>
      <Box>
        <FormControlLabel
          label={label}
          labelPlacement={labelPlacement}
          sx={
            fullWidth
              ? {
                  justifyContent: 'space-between',
                  width: '100%',
                }
              : {}
          }
          control={
            <MuiSwitch
              checked={checked}
              onChange={(_, checked) => onChange(checked)}
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
              sx={(theme) => ({
                ...(labelPlacement === 'start' ? { marginLeft: '8px' } : { marginRight: '8px' }),
                width: 40,
                height: 24,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                  padding: 0,
                  margin: '1px',
                  transitionDuration: '300ms',
                  '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                      backgroundColor: theme.palette.orange[500],
                      opacity: 1,
                      border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.5,
                    },
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                  },
                  '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[100],
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.7,
                  },
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: 22,
                  height: 22,
                },
                '& .MuiSwitch-track': {
                  borderRadius: 24 / 2,
                  backgroundColor: theme.palette.grey[500],
                  opacity: 1,
                  transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                  }),
                },
              })}
            />
          }
        />
      </Box>
    </FormGroup>
  );
}
