import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/card/Card';
import ZipcodeInputWithButton from '../../../../components/zipcodeInputWithButton/ZipcodeInputWithButton';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';

export default function MapLauncher(): JSX.Element {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { userPrefs, setUserPrefs } = useUserPrefs();

  // If the user has updated the zipcode before,
  // then the map launcher will use the one they
  // used last time.
  // But if they never have updated a zipcode,
  // then we think this one is a better default.
  const zipcode = userPrefs.zipcodeIsDefault ? '95110' : userPrefs.zipcode;

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      flexWrap="wrap"
      gap="2rem"
      maxWidth="990px"
      mx="auto"
    >
      <Box flex="3 1">
        <Typography component="h2" fontSize="2rem" fontWeight="500" lineHeight="1.2" mb="0.5rem">
          {formatMessage({ id: 'incomeQualified.mapLauncher.headline' })}
        </Typography>
        <Typography fontSize="1.25rem" fontWeight="300">
          {formatMessage({ id: 'incomeQualified.mapLauncher.description' })}
        </Typography>
      </Box>
      <Box flex="1 1" minWidth="300px">
        <Card>
          <Box display="flex" flexDirection="column" gap="16px">
            <ZipcodeInputWithButton
              buttonText={formatMessage({ id: 'incomeQualified.mapLauncher.startButton' })}
              zipcode={zipcode}
              onSubmitZipcode={(zipcode) => {
                setUserPrefs({ zipcode });
                history.push('/charging-stations');
              }}
            />
            <Box fontSize="0.85rem">
              {formatMessage({ id: 'incomeQualified.mapLauncher.formDetails' })}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
