import { Box } from '@mui/material';
import Section from '../../components/section/Section';
import BigPromise from './components/bigPromise/BigPromise';
import Incentives from './components/incentives/Incentives';
import ChargerMapLauncher from './components/chargerMapLauncher/ChargerMapLauncher';
import CheckHistorical from './components/checkHistorical/CheckHistorical';
import FindADealership from './components/findADealership/FindADealership';

const EvOwners = () => {
  return (
    <Box bgcolor="grey.100">
      <BigPromise />
      <Section id="Incentives">
        <Incentives />
      </Section>
      <Section
        id="ChargerMap"
        sx={{
          backgroundColor: 'white.100',
        }}
      >
        <ChargerMapLauncher />
      </Section>
      <Section id="RateCheck">
        <CheckHistorical />
      </Section>
      <Section
        id="FindADealership"
        sx={{
          backgroundColor: 'blue.500',
          color: 'white.100',
        }}
      >
        <FindADealership />
      </Section>
    </Box>
  );
};

export default EvOwners;
