import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import Card from '../../../../components/card/Card';
import CollapseToggleButton from '../../../../components/collapseToggleButton/CollapseToggleButton';
import Tabs from '../../../../components/tabs/Tabs';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import CashPurchaseMethodTable from './CashPurchaseMethodTable';
import ElectricityTable from './ElectricityTable';
import GasolineTable from './GasolineTable';
import InsuranceTable from './InsuranceTable';
import LeasePurchaseMethodTable from './LeasePurchaseMethodTable';
import LoanPurchaseMethodTable from './LoanPurchaseMethodTable';
import MaintenanceTable from './MaintenanceTable';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

export default function CalcTables({ vehicle1, vehicle1Tco, vehicle2, vehicle2Tco }: Props) {
  const { formatMessage } = useIntl();

  const { userPrefs } = useUserPrefs();
  const { purchaseMethod } = userPrefs;

  const tabData = [
    {
      label: formatMessage({ id: 'compare.table.purchase.title' }),
      content: (
        <>
          {purchaseMethod === 'cash' && (
            <CashPurchaseMethodTable
              vehicle1={vehicle1}
              vehicle2={vehicle2}
              vehicle1Tco={vehicle1Tco}
              vehicle2Tco={vehicle2Tco}
            />
          )}
          {purchaseMethod === 'loan' && (
            <LoanPurchaseMethodTable
              vehicle1={vehicle1}
              vehicle2={vehicle2}
              vehicle1Tco={vehicle1Tco}
              vehicle2Tco={vehicle2Tco}
            />
          )}
          {purchaseMethod === 'lease' && (
            <LeasePurchaseMethodTable
              vehicle1={vehicle1}
              vehicle2={vehicle2}
              vehicle1Tco={vehicle1Tco}
              vehicle2Tco={vehicle2Tco}
            />
          )}
        </>
      ),
    },
    {
      label: formatMessage({ id: 'compare.table.electricity.title' }),
      content: (
        <ElectricityTable
          vehicle1={vehicle1}
          vehicle2={vehicle2}
          vehicle1Tco={vehicle1Tco}
          vehicle2Tco={vehicle2Tco}
        />
      ),
    },
    {
      label: formatMessage({ id: 'compare.table.gasoline.title' }),
      content: (
        <GasolineTable
          vehicle1={vehicle1}
          vehicle2={vehicle2}
          vehicle1Tco={vehicle1Tco}
          vehicle2Tco={vehicle2Tco}
        />
      ),
    },
    {
      label: formatMessage({ id: 'compare.table.maintenance.title' }),
      content: (
        <MaintenanceTable
          vehicle1={vehicle1}
          vehicle2={vehicle2}
          vehicle1Tco={vehicle1Tco}
          vehicle2Tco={vehicle2Tco}
        />
      ),
    },
    {
      label: formatMessage({ id: 'compare.table.insurance.title' }),
      content: (
        <InsuranceTable
          vehicle1={vehicle1}
          vehicle2={vehicle2}
          vehicle1Tco={vehicle1Tco}
          vehicle2Tco={vehicle2Tco}
        />
      ),
    },
  ];

  return (
    <CollapseToggleButton
      openText={formatMessage({ id: 'compare.table.showSources' })}
      closeText={formatMessage({ id: 'compare.table.hideSources' })}
      buttonPosition="center"
    >
      <Box
        mt={2}
        sx={{
          '& a': {
            color: 'blue.400',
            '&:not(:hover)': {
              textDecoration: 'none',
            },
          },
          '& .MuiTableCell-root': {
            fontSize: '1rem',
            color: 'grey.900',
            '&:not(th):not(:last-child)': {
              textAlign: 'center',
            },
            '&:not(:last-child)': {
              borderRight: '1px solid',
              borderColor: 'grey.200',
            },
          },
          '& .MuiTableRow-head .MuiTableCell-root': {
            textAlign: 'center',
          },
          '& .MuiTableRow-footer .MuiTableCell-root': {
            fontWeight: 'bold',
          },
        }}
      >
        <Card>
          <Tabs tabs={tabData} ariaLabel={formatMessage({ id: 'compare.table.ariaLabel' })} />
        </Card>
      </Box>
    </CollapseToggleButton>
  );
}
