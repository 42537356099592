import { Box, Container, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import type { ElectricVehicle } from '../../../../types';
import { formatAsDollars } from '../../../../utils/formatters';
import IncentiveCard from './IncentiveCard';
import prioritizeIncentives from '../../../../calculations/incentives/Sort/prioritizeIncentives';

type Props = {
  vehicle: ElectricVehicle;
};

export default function Incentives({ vehicle }: Props) {
  const { formatMessage } = useIntl();

  const eligible = vehicle.incentives.filter(
    (incentive) => incentive.evaluation.eligibility === 'eligible',
  );

  if (eligible.length === 0) return <></>;

  const incentiveTotal = eligible.reduce((acc, incentive) => {
    return acc + incentive.evaluation.amountInPurchase;
  }, 0);

  return (
    <Container>
      <Box py={{ xs: '4rem', md: '8rem' }}>
        <Typography mb="2rem" variant="h4">
          {formatMessage(
            { id: 'vehicleDetails.incentivesHeader' },
            {
              amount: (
                <Typography variant="h4" component="span" fontWeight={700} color="green.500">
                  {formatAsDollars(incentiveTotal)}
                </Typography>
              ),
              vehicle: `${vehicle.make} ${vehicle.model}`,
            },
          )}
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          gap="0.75rem 1rem"
          justifyContent="space-around"
          alignItems="stretch"
        >
          {prioritizeIncentives(eligible).map((incentive) => {
            return (
              <Box
                flex="1 1"
                minWidth={{ xs: '160px', sm: '220px' }}
                maxWidth={{ xs: '48%', sm: '264px' }}
                key={incentive.name}
              >
                <IncentiveCard incentive={incentive} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
}
