import { omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

const CACHE_KEY = 'PgeApiCache';
const CACHE_TIME = 1000 * 60 * 15; // 15 minutes

type ApiData = Record<string, any>;
type ApiCache = {
  timestamp: number;
  data: ApiData;
};

type ApiCacheContextType = {
  apiCache: Record<string, ApiCache>;
  setApiCache: (url: string, data: ApiData | null) => void;
  getCachedApiData: (url: string) => ApiData | null;
};

const ApiCacheContext = React.createContext<ApiCacheContextType>({
  apiCache: {},
  setApiCache: (url: string, data: ApiData | null) => {},
  getCachedApiData: (url: string) => null,
});

type Props = {
  children: JSX.Element;
};

export default function ApiCacheProvider({ children }: Props) {
  const [apiCache, _setApiCache] = useState<Record<string, ApiCache>>(
    JSON.parse(localStorage.getItem(CACHE_KEY) || '{}'),
  );

  useEffect(() => {
    if (Object.keys(apiCache).some((url) => apiCache[url].timestamp + CACHE_TIME < Date.now())) {
      const cleanedCache = Object.keys(apiCache).reduce((acc: Record<string, ApiCache>, url) => {
        if (apiCache[url].timestamp + CACHE_TIME > Date.now()) {
          acc[url] = apiCache[url];
        }
        return acc;
      }, {});
      _setApiCache(cleanedCache);
    } else {
      localStorage.setItem(CACHE_KEY, JSON.stringify(apiCache));
    }
  }, [apiCache]);

  const setApiCache = (url: string, data: ApiData | null) => {
    if (data) {
      _setApiCache({
        ...apiCache,
        [url]: { timestamp: Date.now(), data },
      });
    } else {
      _setApiCache(omit(apiCache, url));
    }
  };

  const getCachedApiData = (url: string) => {
    const cachedApiData = apiCache[url];
    if (cachedApiData) {
      const { timestamp, data } = cachedApiData;
      const now = Date.now();
      if (now - timestamp < CACHE_TIME) {
        return data;
      }
    }
    return null;
  };

  return (
    <ApiCacheContext.Provider value={{ apiCache, setApiCache, getCachedApiData }}>
      {children}
    </ApiCacheContext.Provider>
  );
}

type StatusType = 'idle' | 'loading' | 'error';
export const useApiCache = () => React.useContext(ApiCacheContext);

type ReturnType = {
  fetchRecords: <T>(url: string, params: { [key: string]: string }) => Promise<T>;
  status: StatusType;
};

export function useCachedFetch(): ReturnType {
  const { apiCache, setApiCache, getCachedApiData } = useApiCache();
  const [status, setStatus] = useState<StatusType>('idle');

  const fetchRecords = useCallback(
    async (baseUrl: string, params: { [key: string]: string } = {}) => {
      let url = new URL(baseUrl);

      // Remove null params
      Object.keys(params).forEach((key) => params[key] == null && delete params[key]);

      let searchParams = new URLSearchParams(params);

      url.search = searchParams.toString();

      const fullUrl = url.toString();

      const cachedData = getCachedApiData(fullUrl);

      if (cachedData) {
        process.env.NODE_ENV === 'development' && console.log('Using cached data for', fullUrl);
        return apiCache[fullUrl].data;
      } else {
        process.env.NODE_ENV === 'development' && console.log('No cached data for', fullUrl);
        setStatus('loading');
      }

      try {
        const response = await window.fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        setStatus('idle');
        const json = await response.json();
        setApiCache(fullUrl, json);
        return json;
      } catch {
        setApiCache(fullUrl, null);
        setStatus('error');
      }
      return Promise.reject();
    },
    [apiCache, getCachedApiData, setApiCache],
  );

  return {
    fetchRecords,
    status,
  };
}
