import React from 'react';
import teslaImg from './../../../../assets/images/tesla87-v2.png';
import Button from '../../../../components/button/Button';
import { Box, Typography } from '@mui/material';
import Link from '../../../../components/link/Link';
import { useIntl } from 'react-intl';

export default function AffordableEv() {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Box maxWidth="1140px" mx="auto">
        <Box mb="2rem" maxWidth={{ xs: '100%', sm: '66.7%' }}>
          <Typography fontWeight="400" fontSize="1.5rem" fontStyle="italic">
            "{formatMessage({ id: 'homepage.affordableEv.quote' })}"
          </Typography>
          <Typography fontWeight="500" fontSize="1.5rem">
            -{' '}
            <Link
              external
              to="https://www.nature.com/articles/nenergy2016112"
              sx={{
                color: (theme) => theme.palette.blue[700],
                '&:visited': {
                  color: (theme) => theme.palette.blue[700],
                },
              }}
            >
              {formatMessage({ id: 'homepage.affordableEv.source' })}
            </Link>
          </Typography>
        </Box>
        <Box>
          <img src={teslaImg} style={{ maxWidth: '100%' }} alt="Tesla with 87%" />
        </Box>
        <Box textAlign="center">
          <Link to="/vehicles">
            <Button>{formatMessage({ id: 'homepage.affordableEv.button' })}</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
