import { Box, Button, Link as MuiLink, Popover, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLanguage } from '../../utils/ReactIntlProvider';
import Search from './../../assets/images/icons/search.svg';

type MenuItemProps = {
  href: string;
  onClick?: (event: React.MouseEvent) => void;
  children: ReactNode;
};

const MenuItem = ({ href, onClick, children }: MenuItemProps) => (
  <MuiLink
    href={href}
    onClick={(event: React.MouseEvent) => {
      href || event.preventDefault();
      onClick && onClick(event);
    }}
    sx={{
      textDecoration: 'none',
      color: 'white.100',
    }}
  >
    <Typography fontWeight="bold" display="block" mb={2}>
      {children}
    </Typography>
  </MuiLink>
);

type SubMenuProps = { title: string; children: ReactNode };

const SubMenu = ({ title, children }: SubMenuProps) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setSubMenuOpen((prev) => !prev)}
        sx={{
          display: 'block',
          textTransform: 'none',
          color: 'white.100',
          border: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          p: 0,
          minWidth: '0',
          '&::after': {
            display: 'inline-block',
            marginLeft: '0.255em',
            verticalAlign: '0.255em',
            content: '""',
            borderTop: '0.3em solid',
            borderRight: '0.3em solid transparent',
            borderBottom: '0',
            borderLeft: '0.3em solid transparent',
          },
          marginBottom: subMenuOpen ? '0' : '16px',
        }}
      >
        {title}
      </Button>
      {subMenuOpen && (
        <Box ml={2} mt={1}>
          {children}
        </Box>
      )}
    </>
  );
};

type MobileNavMenuProps = {
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
};

function MobileNavMenu({ anchorEl, onClose, open }: MobileNavMenuProps) {
  const { formatMessage } = useIntl();

  const { setLanguage } = useLanguage();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="mobile-nav-menu"
      marginThreshold={0}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: '100%',
          maxWidth: '100%',
          left: 0,
          right: 0,
          px: 3,
          py: 3,
          backgroundColor: 'blue.600',
        },
      }}
    >
      <MenuItem href="/">{formatMessage({ id: 'nav.home' })}</MenuItem>
      <SubMenu title={formatMessage({ id: 'nav.incentives' })}>
        <MenuItem href="/income-qualified">{formatMessage({ id: 'nav.incomeQualified' })}</MenuItem>
        <MenuItem href="/incentives">{formatMessage({ id: 'nav.allOtherIncentives' })}</MenuItem>
      </SubMenu>
      <SubMenu title={formatMessage({ id: 'nav.vehiclesAndRates' })}>
        <MenuItem href="/vehicles">{formatMessage({ id: 'nav.vehicles' })}</MenuItem>
        <MenuItem href="/rates">{formatMessage({ id: 'nav.rates' })}</MenuItem>
      </SubMenu>
      <MenuItem href="/compare">{formatMessage({ id: 'nav.compare' })}</MenuItem>
      <SubMenu title={formatMessage({ id: 'nav.maps' })}>
        <MenuItem href="/charging-stations">{formatMessage({ id: 'nav.stations' })}</MenuItem>
        <MenuItem href="/charging-station-availability">
          {formatMessage({ id: 'nav.availability' })}
        </MenuItem>
        <MenuItem href="/travel-range">{formatMessage({ id: 'nav.range' })}</MenuItem>
        <MenuItem href="/map-a-route">{formatMessage({ id: 'nav.route' })}</MenuItem>
        <MenuItem href="/dealers">{formatMessage({ id: 'nav.dealer' })}</MenuItem>
      </SubMenu>
      <MenuItem href="/search">
        <img src={Search} alt="Search" />
      </MenuItem>
      <SubMenu title={formatMessage({ id: 'languageName' })}>
        <MenuItem href="" onClick={() => setLanguage('en')}>
          English
        </MenuItem>
        <MenuItem href="" onClick={() => setLanguage('es')}>
          Español
        </MenuItem>
        <MenuItem href="" onClick={() => setLanguage('zh')}>
          中文
        </MenuItem>
      </SubMenu>
    </Popover>
  );
}

export default MobileNavMenu;
