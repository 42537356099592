import { Box, Typography } from '@mui/material';
import useIncentives from '../../../../api/hooks/useIncentives';
import Loading from '../../../../components/loading/Loading';
import IncentiveCatalog from './IncentiveCatalog';
import Link from '../../../../components/link/Link';
import Button from '../../../../components/button/Button';
import { useIntl } from 'react-intl';

const HomepageIncentives = () => {
  const { formatMessage } = useIntl();
  const { incentives, status } = useIncentives();

  const firstFour = incentives
    ? (incentives.allEligible.length > 1 ? incentives.allEligible : incentives.allIncentives).slice(
        0,
        4,
      )
    : [];
  return (
    <Box>
      <Box textAlign="center">
        <Typography variant="h2" mb="0.5rem">
          {formatMessage({ id: 'homepage.incentives.headline' })}
        </Typography>
        <Typography whiteSpace="pre-line" fontSize="1.25rem" fontWeight="300" mb="1rem">
          {formatMessage({ id: 'homepage.incentives.details' })}
        </Typography>
      </Box>
      {status === 'loading' && <Loading />}
      {status === 'idle' && incentives && <IncentiveCatalog incentives={firstFour} />}
      <Box textAlign="center">
        <Link to="/incentives">
          <Button>{formatMessage({ id: 'homepage.incentives.personalizeButton' })}</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default HomepageIncentives;
