import type { ElectricVehicle, GasVehicle } from '../../types';
import VehicleCost from './CostOfOwnership/calcs/VehicleCost';
import capitalizedInPurchase from '../incentives/capitalization/capitalizedInPurchase';
import capitalizedInLease from '../incentives/capitalization/capitalizedInLease';
import nonCapitalizedInLease from '../incentives/capitalization/nonCapitalizedInLease';

const getPaymentDetails = (
  vehicle: ElectricVehicle | GasVehicle,
  monthsOfOwnership: number,
  interestRateAsBasisPoints: number,
  milesDrivenAnnually: number,
) => {
  const incentivesForPurchase = capitalizedInPurchase(vehicle.incentives);
  const incentivesForLease =
    capitalizedInLease(vehicle.incentives) + nonCapitalizedInLease(vehicle.incentives);

  const afterIncentives = VehicleCost.afterIncentives(vehicle);
  const loanDownPayment = VehicleCost.downPayment(vehicle);
  const loanAmountPerMonth = VehicleCost.monthlyLoanPayment(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
  );
  const leaseFirstPayment = VehicleCost.firstLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
  );
  const leaseAmountPerMonth = VehicleCost.monthlyLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
  );

  const yearsOfOwnership = monthsOfOwnership / 12;

  return {
    afterIncentives: afterIncentives,
    loanAmountPerMonth: loanAmountPerMonth,
    downPayment: loanDownPayment,
    leaseAmountPerMonth: leaseAmountPerMonth,
    leaseFirstPayment: leaseFirstPayment,
    msrp: vehicle.msrp,
    interestRateAsBasisPoints: interestRateAsBasisPoints,
    yearsOfOwnership: yearsOfOwnership,
    totalIncentivesForPurchase: incentivesForPurchase,
    totalIncentivesForLease: incentivesForLease,
  };
};

export default getPaymentDetails;
