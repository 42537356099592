import useElectricVehicles from './useElectricVehicles';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import useSubState from './useSubState';
import filterVehicles from '../../utils/filterVehicles';

const useFilteredVehiclesForIncentives = () => {
  /**
   * First we need all the user's preferences
   * and all the cars we can find.
   */
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [filters, setFilters] = useSubState(userPrefs, setUserPrefs, 'vehicleFilters');
  const { allElectricVehicles } = useElectricVehicles();

  /**
   * Now we need to filter the cars that match
   * the user's preferences. For this page, we
   * only use a few filters.
   * Along with the vehicles, we need to get
   * the array of `makes` for the page's
   * Select element.
   */
  const { visibleVehicles, makes } = filterVehicles(allElectricVehicles || [], {
    make: userPrefs.vehicleMakeFilter,
    type: filters.type,
    vehicleStatus: filters.vehicleStatus === 'new' ? 'new' : 'used',
  });

  return {
    vehicles: visibleVehicles,
    makes,
    filters,
    setFilters,
  };
};

export default useFilteredVehiclesForIncentives;
