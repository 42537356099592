import { ElectricVehicle } from '../../../../types';
import { Box, Typography } from '@mui/material';
import FuelTypeIcon from '../../../../components/fuelTypeIcon/FuelTypeIcon';
import Image from '../../../../components/image/Image';
import Card from '../../../../components/card/Card';
import { formatAsDollars, formatVehicleDisplayName } from '../../../../utils/formatters';
import Spec from '../../../../components/spec/Spec';
import { useIntl } from 'react-intl';
import { mean } from 'lodash';
import { VehicleCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';

interface Props {
  evs: ElectricVehicle[];
}

export default function UsedVehicleCard({ evs }: Props) {
  const { formatMessage } = useIntl();

  const ev = evs[0];

  const availableYears = evs.map((vehicle) => vehicle.modelYear);
  const years =
    evs.length > 1
      ? `${Math.min(...availableYears)}-${Math.max(...availableYears)}`
      : availableYears[0];

  const averageRange = Math.floor(mean(evs.map((vehicle) => vehicle.totalRange)));
  const averageCost = mean(evs.map((vehicle) => vehicle.msrp));
  const averageCostAfterIncentives = mean(
    evs.map((vehicle) => VehicleCost.afterIncentives(vehicle)),
  );

  const imgSrc = ev.images?.find((image) => image.urlThumbnail)?.urlThumbnail;

  return (
    <Card
      sx={{
        p: 0,
        borderRadius: '4px',
        height: 'fit-content',
        boxShadow: 0,
        ':hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box>
        <Box p="15px 15px 10px 15px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3" component="h4">
              {ev.make}
            </Typography>
            <FuelTypeIcon fuelType={ev.fuelType} />
          </Box>
          <Typography variant="h6" component="h6" height="50px">
            {formatVehicleDisplayName(ev)}
          </Typography>
          {imgSrc && (
            <Image
              src={imgSrc}
              alt={'Go to ' + ev.make + ' ' + ev.model + ' ' + ev.type}
              sx={{ width: '100%' }}
            />
          )}
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.yearRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {years}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.averageRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {formatMessage(
                  { id: 'vehicleCatalog.vehicleCard.rangeMiles' },
                  { numMiles: averageRange },
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.estimatedCost' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="500" textAlign="right">
                {formatAsDollars(averageCost)}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          p="1rem"
          sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}` }}
        >
          <Spec
            size="sm"
            label={formatMessage({ id: 'vehicleCatalog.vehicleCard.afterIncentives' })}
          >
            {averageCostAfterIncentives <= 500
              ? formatMessage({ id: 'vehicleCatalog.vehicleCard.atOrNearZero' })
              : formatAsDollars(averageCostAfterIncentives)}
          </Spec>
        </Box>
      </Box>
    </Card>
  );
}
