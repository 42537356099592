import { useEffect } from 'react';
import { useVehicleCache } from '../../context/VehicleCacheProvider';
import type { ApiGasVehicle } from '../../types';
import { fetchRecords } from '../../utils/fetch';
import { transformGasVehicle } from '../transformers/transformGasVehicle';

const path = '/vehicles';
const defaultParams = {
  postcode: '94108',
  tax_filing_type: 'single',
  household_size: '1',
  household_income: '75000',
  turn_in_clunker: 'true',
  fuel_type: 'gas',
};

const fetchGasVehicles = (
  params: Record<string, string> = {},
): Promise<{ vehicles: Array<ApiGasVehicle> }> => {
  return fetchRecords(path, {
    ...defaultParams,
    ...params,
  });
};

export default function useGasVehicles() {
  const { gasVehicles, status, error, fetchVehicles } = useVehicleCache();

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchGasVehicles();
      return data.vehicles.map(transformGasVehicle);
    };

    fetchVehicles(fetch, 'gas');
  }, [fetchVehicles]);

  return {
    vehicles: gasVehicles,
    status,
    error,
  };
}
