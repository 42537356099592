import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Slider from '../../../../../components/slider/Slider';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';

export default function AnnualMilesStep(): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  return (
    <>
      <Box>
        <Typography variant="h5" textAlign="center" id="modal-title">
          {formatMessage({ id: 'rates.wizard.annualMiles.milesPerYearTitle' })}
        </Typography>
        <Typography textAlign="center">
          {formatMessage({ id: 'rates.wizard.annualMiles.milesPerYearHelper' })}
        </Typography>
      </Box>
      <Box>
        <Slider
          label={formatMessage({ id: 'rates.wizard.annualMiles.milesPerYearLabel' })}
          valueSuffix={` ${formatMessage({ id: 'rates.wizard.annualMiles.milesPerYearSuffix' })}`}
          min={1000}
          max={100000}
          step={1000}
          value={userPrefs.milesDrivenAnnually}
          onChange={(value) => setUserPrefs({ milesDrivenAnnually: value })}
        />
      </Box>
    </>
  );
}
