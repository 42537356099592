import { useEffect, useState } from 'react';
import useMapBounds from '../../hooks/useMapBounds';
import useSelectedStation from '../../hooks/useSelectedStation';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import useMappedZipcode from '../../hooks/useMappedZipcode';
import BaseGoogleMap from '../baseGoogleMap/BaseGoogleMap';
import ChargingStationsList from '../chargingStationsList/ChargingStationsList';
import useGeoJsonUrls from '../../hooks/useGeojsonUrls';
import MapLegend from '../mapLegend/MapLegend';
import PspsEventLegend from '../pspsEventLegend/PspsEventLegend';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import ZipcodeInputWithButton from '../../../zipcodeInputWithButton/ZipcodeInputWithButton';
import type { ChargingStation, PspsEvent } from '../../../../types';
import { Box } from '@mui/material';
import useChargingStationAvailability from '../../hooks/useChargingStationAvailability';
import ChargingStationAvailabilityMapMarker from '../chargingStationAvailabilityMapMarker copy/ChargingStationsMapMarker';
import { useIntl } from 'react-intl';
import { WarningCircle } from '../../../icon/Vectors';
import Link from '../../../link/Link';
import Card from '../../../card/Card';
import SelectedChargingStationCard from '../selectedChargingStationCard/SelectedChargingStationCard';
import MapMarkerLegend from './mapMarkerLegend/MapMarkerLegend';

type Props = {
  chargingStationsFilterFn?: (station: ChargingStation) => boolean;
  isVisible?: boolean;
  pspsEvents?: Array<PspsEvent>;
};

export default function ChargingStationAvailabilityMap({
  chargingStationsFilterFn,
  isVisible = true,
  pspsEvents = [],
}: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { bounds, registerMapBoundsListeners, filterWithinBounds } = useMapBounds();
  const { chargingStations, fetchChargingStations } = useChargingStationAvailability();
  const [hoveredStation, setHoveredStation] = useState<ChargingStation | null>(null);
  const { selectedStation, selectStation, deselectStation } = useSelectedStation(chargingStations);
  const {
    zipcode: geocodedZipcode,
    loading: isFetchingZipcode,
    error: zipcodeError,
    fetchZipcode,
    registerMappedZipcodeListeners,
  } = useMappedZipcode({
    zoom: 15,
  });

  const registerGeojsonUrlMap = useGeoJsonUrls(pspsEvents.flatMap((event) => event.fileUrls));

  useEffect(() => {
    if (geocodedZipcode && geocodedZipcode !== userPrefs.zipcode) {
      setUserPrefs({ zipcode: geocodedZipcode });
    }
  }, [geocodedZipcode, setUserPrefs, userPrefs.zipcode]);

  useDeepCompareEffect(() => {
    if (bounds) {
      fetchChargingStations(bounds);
    }
  }, [bounds]);

  const visibleChargingStations = filterWithinBounds(chargingStations);
  const filteredChargingStations = selectedStation
    ? [selectedStation]
    : chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const sidebarStation = hoveredStation || selectedStation;

  return (
    <Box>
      <Box
        display="flex"
        gap="8px"
        alignItems="center"
        fontSize="0.75rem"
        borderRadius="4px"
        p="10px"
        my="22px"
        sx={{ backgroundColor: 'blue.100' }}
      >
        <WarningCircle />
        <Box>
          {formatMessage({ id: 'maps.availabilityMap.consent' })}
          <Link
            sx={{ textDecoration: 'underline' }}
            to="https://www.tomtom.com/en_us/thirdpartyproductterms/eula/"
            external
          >
            {formatMessage({ id: 'maps.availabilityMap.consentLink' })}
          </Link>
        </Box>
      </Box>
      <ZipcodeInputWithButton
        buttonText={formatMessage({ id: 'maps.availabilityMap.searchChargingStations' })}
        error={zipcodeError}
        isLoading={isFetchingZipcode}
        onSubmitZipcode={fetchZipcode}
        zipcode={userPrefs.zipcode}
        inline
      />
      <Box
        mt="1.75rem"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap="1rem"
        mb="1.75rem"
      >
        <Box flex="1 1" minWidth="270px">
          <Box>
            <MapLegend>{pspsEvents.length > 0 && <PspsEventLegend />}</MapLegend>
            {isVisible && (
              <BaseGoogleMap
                onLoad={(map) => {
                  registerMapBoundsListeners(map);
                  registerMappedZipcodeListeners(map, userPrefs.zipcode);
                  registerGeojsonUrlMap(map);
                }}
                onMapClick={() => {
                  if (selectedStation) {
                    deselectStation();
                  }
                }}
              >
                {filteredChargingStations.map((station) => (
                  <ChargingStationAvailabilityMapMarker
                    lat={station.lat}
                    lng={station.lng}
                    key={station.id}
                    station={station}
                    selected={selectedStation?.id === station.id}
                    onClick={() => {
                      if (selectedStation) {
                        deselectStation();
                      } else {
                        selectStation(station.id);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredStation(station);
                    }}
                    onMouseLeave={() => {
                      setHoveredStation(null);
                    }}
                  />
                ))}
              </BaseGoogleMap>
            )}
          </Box>
        </Box>
        <Box
          width={{ xs: '100%', sm: '240px' }}
          display="flex"
          flexDirection="column"
          gap="1.75rem"
        >
          <Card>
            <MapMarkerLegend />
          </Card>
          {sidebarStation && (
            <Card>
              <SelectedChargingStationCard station={sidebarStation} />
            </Card>
          )}
        </Box>
      </Box>
      <ChargingStationsList chargingStations={filteredChargingStations} />
    </Box>
  );
}
