import { ReactNode, useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Select from '../select/Select';
import Card from '../card/Card';

type TabItem = {
  label: ReactNode;
  content: ReactNode;
};

type Props = {
  id?: string;
  tabs: Array<TabItem>;
  activeTabIndex?: number;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  ariaLabel?: string;
};

export default function Tabs({
  id,
  tabs,
  activeTabIndex,
  variant = 'fullWidth',
  ariaLabel,
}: Props) {
  const [value, setValue] = useState<string>(activeTabIndex?.toString() || '0');

  useEffect(() => {
    activeTabIndex && setValue(activeTabIndex.toString());
  }, [activeTabIndex]);

  return (
    <Box id={id} sx={{ width: '100%', typography: 'body1' }}>
      <Box display={{ xs: 'block', sm: 'none' }} p="1rem">
        <Select
          value={value}
          label={ariaLabel || ''}
          ariaLabel={ariaLabel || undefined}
          onChange={(newValue: string) => {
            setValue(newValue);
          }}
          options={tabs.map((tab, index) => ({
            label: tab.label,
            value: index.toString(),
          }))}
        />
      </Box>
      <Card>
        <TabContext value={value}>
          <Box
            display={{ xs: 'none', sm: 'block' }}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList
              variant={variant}
              aria-label={ariaLabel || 'Tabbed content'}
              onChange={(_event: React.SyntheticEvent, newValue: string) => {
                setValue(newValue);
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={index.toString()}
                  sx={{ '&.Mui-selected': { color: 'orange.600' } }}
                />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={index.toString()}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Card>
    </Box>
  );
}
