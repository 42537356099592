import type { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import isBEV from '../../isBEV';
import isPHEV from '../../isPHEV';

const GasolineCost = {
  perMile: function (
    vehicle: ElectricVehicle | EquivalentFossilVehicle | GasVehicle,
    electricMilesPortionForPhev: number,
    gasolinePriceInCentsPerGal: number,
  ) {
    if (isBEV(vehicle) || !vehicle.fossilFuelEfficiency) return 0;

    const mpg = vehicle.fossilFuelEfficiency;

    const PHEVFuelSplitAdjustment =
      isPHEV(vehicle) && electricMilesPortionForPhev / 100 > 0
        ? 1 - electricMilesPortionForPhev / 100
        : 1;

    const cost = (gasolinePriceInCentsPerGal / 100) * (1 / mpg) * PHEVFuelSplitAdjustment;

    return isFinite(cost) ? cost : 0;
  },
};

export default GasolineCost;
