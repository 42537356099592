import { useEffect } from 'react';
import { Inputs, useInputs } from '@bellawatt/use-inputs';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import Homepage from './screens/homepage/Homepage';
import IncomeQualified from './screens/incomeQualified/IncomeQualified';
import EvOwners from './screens/evOwners/EvOwners';
import NotFound from './screens/error/NotFound';
import DevToolsRoutes from './screens/devtools/DevToolsRoutes';
import DisplayProvider from './context/DisplayProvider';
import ReactIntlProvider from './utils/ReactIntlProvider';
import UserPrefsProvider from './context/UserPrefsProvider';
import theme from './config/theme';
import VehicleDetails from './screens/vehicleDetails/VehicleDetails';
import UsedVehicleDetails from './screens/vehicleDetails/UsedVehicleDetails';
import VehicleCatalog from './screens/vehicleCatalog/VehicleCatalog';
import ChargingStationDetails from './screens/chargingDetails/ChargingStationDetails';
import Rates from './screens/rates/Rates';
import FAQ from './screens/faq/FAQ';
import ChargingFAQ from './screens/chargingFaq/ChargingFAQ';
import Incentives from './screens/incentives/Incentives';
import Compare from './screens/compare/Compare';
import ApiCacheProvider from './context/apiCacheProvider';
import Chart from 'chart.js/auto';
import GaTracker from './utils/GaTracker/GaTracker';
import FacebookTracker from './utils/FacebookTracker/FacebookTracker';
import RocketFuelTracker from './utils/RocketFuelTracker/RocketFuelTracker';
import { useUuid } from './api/hooks/useUuid';
import Map from './screens/map/Map';
import FeedbackFixedButton from './components/feedback/FeedbackFixedButton';
import VehicleCacheProvider from './context/VehicleCacheProvider';
import Search from './screens/Search/Search';
import { RateCalculator } from '@bellawatt/electric-rate-engine';
import DeployedBranchName from './components/deployedBranchName/DeployedBranchName';
import Honeybadger from '@honeybadger-io/js';
import useResetZipOnNav from './utils/hooks/useResetZipOnNav';
import InvalidZipcodeAlert from './components/invalidZipcodeAlert/InvalidZipcodeAlert';
import useScrollToTop from './utils/useScrollToTop';

RateCalculator.shouldValidate = process.env.RATE_CALCULATOR_VALIDATION === 'true';
RateCalculator.shouldLogValidationErrors = process.env.RATE_CALCULATOR_VALIDATION === 'true';

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT,
  revision: 'main',
  debug: false,
});

Chart.defaults.font.family = 'din';

export function AppProviders({ children }: { children: JSX.Element }) {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Inputs computed={{}} defaults={{}} ignore={[]}>
          <ApiCacheProvider>
            <ReactIntlProvider>
              <VehicleCacheProvider>
                <UserPrefsProvider>
                  <ErrorBoundary>
                    <DisplayProvider>{children}</DisplayProvider>
                  </ErrorBoundary>
                </UserPrefsProvider>
              </VehicleCacheProvider>
            </ReactIntlProvider>
          </ApiCacheProvider>
        </Inputs>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function AppRoutes() {
  useScrollToTop();

  const { loading, isResetting } = useInputs();
  const location = useLocation();
  useResetZipOnNav(location.pathname);

  const { uuid } = useUuid();

  useEffect(() => {
    GaTracker.initialize({
      gaOptions: {
        userId: uuid,
      },
    });
    FacebookTracker.initialize();
    RocketFuelTracker.initialize();
  }, [uuid]);

  useEffect(() => {
    FacebookTracker.track();
    RocketFuelTracker.track();
  }, [location, uuid]);

  return loading || isResetting ? (
    <Loading />
  ) : (
    <>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="stretch">
        <div style={{ display: 'none' }} />
        <Header />
        <Box
          flex="1 1"
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ overflowY: 'auto' }}
          id="main-scroll"
        >
          <Box component="main" id="main-content" flex="1 1">
            <Switch>
              <Route exact path="/news/:id">
                <Redirect to="/" />
              </Route>
              <Route exact path="/news">
                <Redirect to="/" />
              </Route>
              <Route exact path="/incentives" component={Incentives} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/charging-faq" component={ChargingFAQ} />
              <Route exact path="/rates" component={Rates} />
              <Route exact path="/vehicles/:id" component={VehicleDetails} />
              <Route exact path="/used-vehicles/:id" component={UsedVehicleDetails} />
              <Route exact path="/used-vehicles/:id/:year" component={UsedVehicleDetails} />
              <Route exact path="/vehicles" component={VehicleCatalog} />
              <Route exact path="/compare" component={Compare} />
              <Route exact path="/charging-stations" component={Map} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/charging-station-availability" component={Map} />
              <Route
                exact
                path="/charging-station-availability/:id"
                component={ChargingStationDetails}
              />
              <Route exact path="/map-a-route" component={Map} />
              <Route exact path="/travel-range" component={Map} />
              <Route exact path="/dealers" component={Map} />
              <Route exact path="/" component={Homepage} />
              <Route exact path="/income-qualified" component={IncomeQualified} />
              <Route exact path="/ev-owners" component={EvOwners} />
              <Route path="/dev">
                {/* avoid 404 for the /dev routes below (they break Switch) */}
              </Route>
              <Route path="*" component={NotFound} />
            </Switch>
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && <DevToolsRoutes />}
          </Box>
          <Footer />
        </Box>
      </Box>
      <FeedbackFixedButton />
      <InvalidZipcodeAlert />
      <DeployedBranchName />
    </>
  );
}

function App() {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
}

export default App;
