import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Link from '../../../../components/link/Link';
import Button from '../../../../components/button/Button';

export default function CheckHistorical(): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <Box maxWidth="1140px" mx="auto" textAlign="center">
      <Typography component="h2" fontSize="2rem" fontWeight="500">
        {formatMessage({ id: 'evOwners.checkHistorical.headline' })}
      </Typography>
      <Typography fontSize="24px" fontWeight="300" mb={3}>
        {formatMessage({ id: 'evOwners.checkHistorical.description' })}
      </Typography>
      <Typography fontSize="20px" fontWeight="300" mb={3}>
        {formatMessage({ id: 'evOwners.checkHistorical.paragraph' })}
      </Typography>
      <Box mb="3rem" textAlign="center">
        <Link to="/rates?open-historical=1">
          <Button>{formatMessage({ id: 'evOwners.checkHistorical.button' })}</Button>
        </Link>
      </Box>
    </Box>
  );
}
