import { Link as MuiLink } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import type { ReactNode } from 'react';
import GaTracker from '../../utils/GaTracker/GaTracker';

export default function Link({
  to,
  children,
  sx,
  external,
  fullWidth,
  onClick,
}: {
  to: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
  external?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}) {
  return (
    <MuiLink
      component={RouterLink}
      onClick={() => {
        GaTracker.trackEvent({
          category: 'Link',
          action: 'Click',
          label: to,
        });
        onClick?.();
      }}
      {...(external
        ? {
            rel: 'noopener noreferrer',
            target: '_blank',
            to: { pathname: to },
          }
        : {
            to: to,
          })}
      sx={{
        fontWeight: 'bold',
        textDecoration: 'unset',
        color: (theme) => theme.palette.blue[700],
        width: fullWidth ? '100%' : 'auto',
        ...(sx || {}),
      }}
    >
      {children}
    </MuiLink>
  );
}
