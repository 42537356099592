import { ApiElectricVehicle } from '../../types';
import {
  BatteryFactors,
  BATTERY_DEGRADATION_FACTORS,
  ELECTRIC_BATTERY_FACTORS,
} from '../../utils/assumptions';

const getVehicleAge = (vehicle: ApiElectricVehicle) => {
  return Math.max(new Date().getFullYear() - vehicle.model_year, 0);
};

export const calcUsedVehicleElectricRange = (vehicle: ApiElectricVehicle) => {
  const age = getVehicleAge(vehicle);
  return age < BATTERY_DEGRADATION_FACTORS.length
    ? vehicle.electric_range * BATTERY_DEGRADATION_FACTORS[age]
    : vehicle.electric_range * BATTERY_DEGRADATION_FACTORS[BATTERY_DEGRADATION_FACTORS.length - 1];
};

export const calcUsedVehicleTotalRange = (vehicle: ApiElectricVehicle) => {
  const usedElectricRange = calcUsedVehicleElectricRange(vehicle);
  const electricRangeDecrease = vehicle.electric_range - usedElectricRange;
  return vehicle.total_range - electricRangeDecrease;
};

export const calcUsedVehicleBatteryCapacity = (vehicle: ApiElectricVehicle) => {
  const age = getVehicleAge(vehicle);
  return age < BATTERY_DEGRADATION_FACTORS.length
    ? vehicle.battery_capacity * BATTERY_DEGRADATION_FACTORS[age]
    : vehicle.battery_capacity *
        BATTERY_DEGRADATION_FACTORS[BATTERY_DEGRADATION_FACTORS.length - 1];
};

export const calcUsedVehicleMsrp = (vehicle: ApiElectricVehicle) => {
  const age = getVehicleAge(vehicle);
  const coeff = ELECTRIC_BATTERY_FACTORS[vehicle.make as keyof BatteryFactors]
    ? ELECTRIC_BATTERY_FACTORS[vehicle.make as keyof BatteryFactors]
    : ELECTRIC_BATTERY_FACTORS.Average;

  return Math.max(Math.round((vehicle.msrp * Math.pow(Math.E, -1 * age * coeff)) / 1000) * 1000, 0);
};
