import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

const PspsEventLegend = () => {
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" gap="1rem" alignItems="center">
      <Box
        sx={{
          backgroundColor: 'rgba(209, 61, 61, 0.6)',
          border: '2px solid rgb(209,61,61)',
          display: 'inline-block',
          width: '1rem',
          height: '1rem',
        }}
      />
      <Box>{formatMessage({ id: 'maps.pspsEventArea' })}</Box>
    </Box>
  );
};

export default PspsEventLegend;
