export const GasIcon = ({ fill = 'black' }: { fill?: string }) => (
  <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.3919 14.3575L38.411 14.3383L31.281 7.20833L29.2493 9.24L33.2935 13.2842C31.4919 13.9742 30.2077 15.6992 30.2077 17.75C30.2077 20.395 32.3543 22.5417 34.9993 22.5417C35.6894 22.5417 36.3218 22.3883 36.916 22.1392V35.9583C36.916 37.0125 36.0535 37.875 34.9993 37.875C33.9452 37.875 33.0827 37.0125 33.0827 35.9583V27.3333C33.0827 25.225 31.3577 23.5 29.2493 23.5H27.3327V10.0833C27.3327 7.975 25.6077 6.25 23.4993 6.25H11.9993C9.89102 6.25 8.16602 7.975 8.16602 10.0833V40.75H27.3327V26.375H30.2077V35.9583C30.2077 38.6033 32.3543 40.75 34.9993 40.75C37.6444 40.75 39.791 38.6033 39.791 35.9583V17.75C39.791 16.4275 39.2544 15.22 38.3919 14.3575ZM23.4993 19.6667H11.9993V10.0833H23.4993V19.6667ZM34.9993 19.6667C33.9452 19.6667 33.0827 18.8042 33.0827 17.75C33.0827 16.6958 33.9452 15.8333 34.9993 15.8333C36.0535 15.8333 36.916 16.6958 36.916 17.75C36.916 18.8042 36.0535 19.6667 34.9993 19.6667Z"
      fill={fill}
    />
  </svg>
);

export const LightningIcon = ({ fill = 'black' }: { fill?: string }) => (
  <svg width="21" height="35" viewBox="0 0 21 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.58323 34.75H6.66656L8.58323 21.3333H1.87489C0.763226 21.3333 0.782392 20.72 1.14656 20.0683C1.51073 19.4167 1.24239 19.915 1.28073 19.8383C3.75323 15.4683 7.47156 8.95167 12.4166 0.25H14.3332L12.4166 13.6667H19.1249C20.0641 13.6667 20.1982 14.2992 20.0257 14.6442L19.8916 14.9317C12.3399 28.1375 8.58323 34.75 8.58323 34.75Z"
      fill={fill}
    />
  </svg>
);

export const HybridPlusIcon = ({
  height = '44',
  width = '62',
}: {
  height?: string;
  width?: string;
}) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 52 27"
  >
    <path fill="none" d="M0 0h51.3v27H0z" />
    <path fill="none" stroke="#fff" stroke-width="2" d="M20.58 10.95v7" />
    <path fill="none" stroke="#fff" stroke-width="2" d="M24.08 14.45h-7" />
    <path
      fill="#fff"
      fill-rule="nonzero"
      d="m50.52 7.3.02-.02L44.9 1.7l-1.6 1.59 3.2 3.17a3.74 3.74 0 0 0 1.34 7.24 3.9 3.9 0 0 0 1.52-.32V24.2a1.51 1.51 0 0 1-3.03 0v-6.75c0-1.65-1.37-3-3.03-3h-1.52V3.95c0-1.65-1.36-3-3.03-3h-9.1a3.02 3.02 0 0 0-3.02 3v24h15.15V16.7h2.27v7.5c0 2.07 1.7 3.75 3.8 3.75 2.08 0 3.78-1.68 3.78-3.75V9.95c0-1.03-.43-1.98-1.1-2.65Zm-11.77 4.15h-9.1v-7.5h9.1v7.5Zm9.1 0c-.84 0-1.52-.68-1.52-1.5a1.51 1.51 0 0 1 3.03 0c0 .82-.69 1.5-1.52 1.5Z"
    />
    <path
      fill="#fda536"
      fill-rule="nonzero"
      d="M6.32 27.95h-1.5l1.5-10.5H1.07c-.87 0-.85-.48-.57-.99.29-.51.08-.12.1-.18L9.33.95h1.5l-1.5 10.5h5.25c.74 0 .84.5.7.77l-.1.22-8.85 15.51Z"
    />
  </svg>
);

export const HamburgerMenuIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12.5H21"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6.5H21"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18.5H21"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuCloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 6L6 18"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LeftArrowIcon = () => (
  <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.25 10.0833H8.09083L14.9525 3.2025L12.25 0.5L0.75 12L12.25 23.5L14.9525 20.7975L8.09083 13.9167H35.25V10.0833Z"
      fill="black"
    />
  </svg>
);

export const AvailableChargingPlugIcon = ({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) => (
  <svg
    width={width || '33'}
    height={height || '44'}
    viewBox="0 0 33 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4059 30.5111C24.5746 30.5111 31.1966 23.8891 31.1966 15.7204C31.1966 7.55171 24.5746 0.929688 16.4059 0.929688C8.23726 0.929688 1.61523 7.55171 1.61523 15.7204C1.61523 23.8891 8.23726 30.5111 16.4059 30.5111Z"
      fill="#429538"
    />
    <path
      d="M16.4944 0.0810572C12.3505 0.0834252 8.3772 1.73123 5.44789 4.66221C2.51858 7.5932 0.873046 11.5675 0.873047 15.7113C0.873047 24.3392 16.4944 42.9973 16.4944 42.9973C16.4944 42.9973 32.1336 24.3392 32.1336 15.7113C32.1336 13.658 31.729 11.6248 30.9429 9.72782C30.1569 7.83088 29.0048 6.10742 27.5524 4.6559C26.1001 3.20438 24.376 2.05325 22.4786 1.26828C20.5812 0.483307 18.5477 0.0798839 16.4944 0.0810572ZM16.4944 28.814C13.9004 28.8157 11.3642 28.048 9.20663 26.608C7.04908 25.1679 5.36715 23.1203 4.37366 20.7241C3.38017 18.3279 3.11976 15.6909 3.62539 13.1466C4.13102 10.6024 5.37996 8.26535 7.21418 6.43112C9.0484 4.5969 11.3855 3.34796 13.9297 2.84233C16.4739 2.33671 19.111 2.59711 21.5072 3.5906C23.9033 4.58409 25.951 6.26602 27.391 8.42357C28.8311 10.5811 29.5988 13.1173 29.597 15.7113C29.597 17.432 29.2581 19.1358 28.5996 20.7255C27.9412 22.3152 26.976 23.7596 25.7593 24.9763C24.5427 26.193 23.0982 27.1581 21.5085 27.8166C19.9189 28.475 18.215 28.814 16.4944 28.814Z"
      fill="#429538"
    />
    <path
      d="M13.9493 8.12764C13.2424 8.12764 12.6641 8.706 12.6641 9.41289L12.6641 11.9834L15.2346 11.9834L15.2346 9.41289C15.2346 8.706 14.6562 8.12764 13.9493 8.12764Z"
      fill="white"
    />
    <path
      d="M17.8047 9.41289L17.8047 11.9834L20.3752 11.9834L20.3752 9.41289C20.3752 8.706 19.7968 8.12764 19.0899 8.12764C18.3831 8.12764 17.8047 8.706 17.8047 9.41289Z"
      fill="white"
    />
    <path
      d="M13.9494 19.6941L13.9494 22.2646L19.0904 22.2646L19.0904 19.6941C20.5042 19.6941 21.6609 18.5374 21.6609 17.1236L21.6609 13.2679L11.3789 13.2679L11.3789 17.1236C11.3789 18.5374 12.5356 19.6941 13.9494 19.6941Z"
      fill="white"
    />
    <path
      d="M17.8049 23.5507L17.8049 27.7147C17.8049 28.4246 17.2295 29 16.5196 29C15.8098 29 15.2344 28.4246 15.2344 27.7147L15.2344 23.5507L17.8049 23.5507Z"
      fill="white"
    />
  </svg>
);

export const ChargingPlugMarkerIcon = ({ height, width }: { height?: string; width?: string }) => (
  <svg
    width={width || '33'}
    height={height || '44'}
    viewBox="0 0 33 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4059 30.5111C24.5746 30.5111 31.1966 23.8891 31.1966 15.7204C31.1966 7.55171 24.5746 0.929688 16.4059 0.929688C8.23726 0.929688 1.61523 7.55171 1.61523 15.7204C1.61523 23.8891 8.23726 30.5111 16.4059 30.5111Z"
      fill="#6c757d"
    />
    <path
      d="M16.4944 0.0810572C12.3505 0.0834252 8.3772 1.73123 5.44789 4.66221C2.51858 7.5932 0.873046 11.5675 0.873047 15.7113C0.873047 24.3392 16.4944 42.9973 16.4944 42.9973C16.4944 42.9973 32.1336 24.3392 32.1336 15.7113C32.1336 13.658 31.729 11.6248 30.9429 9.72782C30.1569 7.83088 29.0048 6.10742 27.5524 4.6559C26.1001 3.20438 24.376 2.05325 22.4786 1.26828C20.5812 0.483307 18.5477 0.0798839 16.4944 0.0810572ZM16.4944 28.814C13.9004 28.8157 11.3642 28.048 9.20663 26.608C7.04908 25.1679 5.36715 23.1203 4.37366 20.7241C3.38017 18.3279 3.11976 15.6909 3.62539 13.1466C4.13102 10.6024 5.37996 8.26535 7.21418 6.43112C9.0484 4.5969 11.3855 3.34796 13.9297 2.84233C16.4739 2.33671 19.111 2.59711 21.5072 3.5906C23.9033 4.58409 25.951 6.26602 27.391 8.42357C28.8311 10.5811 29.5988 13.1173 29.597 15.7113C29.597 17.432 29.2581 19.1358 28.5996 20.7255C27.9412 22.3152 26.976 23.7596 25.7593 24.9763C24.5427 26.193 23.0982 27.1581 21.5085 27.8166C19.9189 28.475 18.215 28.814 16.4944 28.814Z"
      fill="#6c757d"
    />
    <path
      d="M13.9493 8.12764C13.2424 8.12764 12.6641 8.706 12.6641 9.41289L12.6641 11.9834L15.2346 11.9834L15.2346 9.41289C15.2346 8.706 14.6562 8.12764 13.9493 8.12764Z"
      fill="white"
    />
    <path
      d="M17.8047 9.41289L17.8047 11.9834L20.3752 11.9834L20.3752 9.41289C20.3752 8.706 19.7968 8.12764 19.0899 8.12764C18.3831 8.12764 17.8047 8.706 17.8047 9.41289Z"
      fill="white"
    />
    <path
      d="M13.9494 19.6941L13.9494 22.2646L19.0904 22.2646L19.0904 19.6941C20.5042 19.6941 21.6609 18.5374 21.6609 17.1236L21.6609 13.2679L11.3789 13.2679L11.3789 17.1236C11.3789 18.5374 12.5356 19.6941 13.9494 19.6941Z"
      fill="white"
    />
    <path
      d="M17.8049 23.5507L17.8049 27.7147C17.8049 28.4246 17.2295 29 16.5196 29C15.8098 29 15.2344 28.4246 15.2344 27.7147L15.2344 23.5507L17.8049 23.5507Z"
      fill="white"
    />
  </svg>
);

export const CurvyChargingPlugIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.33317C12 6.9665 11.7 6.6665 11.3333 6.6665H10V7.99984H11.3333C11.7 7.99984 12 7.69984 12 7.33317Z"
      fill="#323232"
    />
    <path
      d="M11.3333 9.33301H10V10.6663H11.3333C11.7 10.6663 12 10.3663 12 9.99967C12 9.63301 11.7 9.33301 11.3333 9.33301Z"
      fill="#323232"
    />
    <path
      d="M5.99935 7.33333H4.66602V10H5.99935C5.99935 10.7333 6.59935 11.3333 7.33268 11.3333H9.33268V6H7.33268C6.59935 6 5.99935 6.6 5.99935 7.33333Z"
      fill="#323232"
    />
    <path
      d="M1.33333 6.6665C1.33333 5.93317 1.93333 5.33317 2.66667 5.33317H3.66667C4.95333 5.33317 6 4.2865 6 2.99984C6 1.71317 4.95333 0.666504 3.66667 0.666504H1.33333C0.966667 0.666504 0.666667 0.966504 0.666667 1.33317C0.666667 1.69984 0.966667 1.99984 1.33333 1.99984H3.66667C4.22 1.99984 4.66667 2.4465 4.66667 2.99984C4.66667 3.55317 4.22 3.99984 3.66667 3.99984H2.66667C1.19333 3.99984 0 5.19317 0 6.6665C0 8.13984 1.19333 9.33317 2.66667 9.33317H4V7.99984H2.66667C1.93333 7.99984 1.33333 7.39984 1.33333 6.6665Z"
      fill="#323232"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
      fill="#004C75"
    />
  </svg>
);

export const XIcon = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none" />
    <path d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm37.65625,130.34375a7.99915,7.99915,0,1,1-11.3125,11.3125L128,139.3125l-26.34375,26.34375a7.99915,7.99915,0,0,1-11.3125-11.3125L116.6875,128,90.34375,101.65625a7.99915,7.99915,0,0,1,11.3125-11.3125L128,116.6875l26.34375-26.34375a7.99915,7.99915,0,0,1,11.3125,11.3125L139.3125,128Z" />
  </svg>
);

export const WarningCircle = ({ fill = '#004C75' }) => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 13h2v2H9v-2zm0-8h2v6H9V5zm.99-5C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      fill={fill}
    />
  </svg>
);

export const GeneralEmergency = ({ fill = '#005179', ...rest }) => (
  <svg
    width="66px"
    height="66px"
    viewBox="0 0 66 66"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="general_emergency" fill={fill} fillRule="nonzero">
        <path
          d="M33,0 L33.5449686,0.0044286 C51.49452,0.29634 66,14.982 66,33 C66,51.2 51.2,66 33,66 L33,66 C14.8,66 0,51.2 0,33 C0,14.8 14.8,0 33,0 Z M33,4.5 L32.5298799,4.5038301 C17.04528,4.75629 4.5,17.457 4.5,33 C4.5,48.7 17.3,61.5 33,61.5 L33,61.5 C48.7,61.5 61.5,48.7 61.4,33 C61.4,28.8 60.5,24.8 58.9,21.2 C54.4,11.3 44.5,4.5 33,4.5 Z M33.1,6.2 C33.7461538,6.2 34.2218935,6.4556213 34.6058716,7.04551661 L34.7,7.2 L56.1,44.7 C56.7758621,45.8586207 56.2398335,47.3901308 54.67193,47.4943745 L54.5,47.5 L11.5,47.5 C9.95517241,47.5 9.34256837,46.0084423 9.93221124,44.8254828 L10,44.7 L31.5,7.2 C31.9,6.5 32.3,6.2 33.1,6.2 Z M33,37.9 C31.5,37.9 30.3,39.1 30.3,40.6 C30.3,42.1 31.5,43.3 33,43.3 C34.5,43.3 35.8,42.1 35.7,40.6 C35.7,39.1 34.5,37.9 33,37.9 Z M31.3,18.8 C29.8,19.5 28.7,21.1 28.6,22.7 C28.5,23.6 28.8,24.5 29.1,25.4 C29.4,26.1 29.6,26.9 29.8,27.6 C30.2,29.1 30.6,30.6 31.1,32 C31.3,32.7 31.4,33.6 32.1,34.1 C32.8,34.6 33.9,34.4 34.4,33.7 C34.5666667,33.5333333 34.6638889,33.3666667 34.749537,33.1421296 L34.8,33 L35.115625,31.890625 C35.44375,30.79375 35.8,29.725 36.1,28.6 C36.5,27.1 37,25.6 37.4,24.1 C37.9,22.4 37.3,20.5 36.1,19.4 C34.7,18.3 32.8,18.1 31.3,18.8 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);
