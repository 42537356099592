// Source: https://codepen.io/enjay/pen/RgBoJN
const calcLoanPayment = {
  monthly: function (interestRateAsBasisPoints, months, principal) {
    let interest = parseFloat(interestRateAsBasisPoints) / 10000 / 12;
    let payments = parseFloat(months);

    let x = Math.pow(1 + interest, payments);
    let monthly = (principal * x * interest) / (x - 1);

    return monthly;
  },
  total: function (interestRateAsBasisPoints, months, principal) {
    return months * this.monthly(interestRateAsBasisPoints, months, principal);
  },
};

export default calcLoanPayment;
