import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const Reason = ({ reason, details }: { reason: string; details: string }): JSX.Element => {
  return (
    <Box px="15px" flex="1 1" mb={{ xs: 2, sm: 0 }}>
      <Typography component="h2" fontSize="1.5rem" fontWeight="500">
        {reason}
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="300">
        {details}
      </Typography>
    </Box>
  );
};

export default function WhyElectric(): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <Box maxWidth="1140px" mx="auto" textAlign="center">
      <Typography component="h2" fontSize="2rem" fontWeight="500">
        {formatMessage({ id: 'incomeQualified.whyElectric.headline' })}
      </Typography>
      <Box
        mt="1rem"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        sx={{
          '> *': {
            borderRight: { xs: 'none', sm: '1px solid' },
            borderColor: { xs: 'transparent', sm: 'grey.200' },
          },
          '> *:last-child': {
            borderRight: 'unset',
          },
        }}
      >
        <Reason
          reason={formatMessage({ id: 'incomeQualified.whyElectric.preOwnedEvs' })}
          details={formatMessage({ id: 'incomeQualified.whyElectric.preOwnedEvsDetails' })}
        />
        <Reason
          reason={formatMessage({ id: 'incomeQualified.whyElectric.lowerOperating' })}
          details={formatMessage({ id: 'incomeQualified.whyElectric.lowerOperatingDetails' })}
        />
        <Reason
          reason={formatMessage({ id: 'incomeQualified.whyElectric.programsAndIncentives' })}
          details={formatMessage({
            id: 'incomeQualified.whyElectric.programsAndIncentivesDetails',
          })}
        />
      </Box>
    </Box>
  );
}
