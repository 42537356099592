import { UserPrefs } from '../types/userPrefs';
import { EV2_A, E_TOU_C } from './rates';

export const USER_PREF_PRESETS: UserPrefs = {
  canTurnInClunker: true,
  chargerInstallAllowed: null,
  chargerLevel: 'level_2',
  closedExperienceSurveyAt: null,
  comparedVehicleId1: null,
  comparedVehicleId2: null,
  currentElectricRate: E_TOU_C,
  electricMilesPortionForPhev: 60,
  equivalentMilesPerGallon: 25,
  gasolinePriceInCentsPerGal: 459,
  hasCareDiscount: false,
  hasOvernightCharger: null,
  hasRegularPowerOutlet: null,
  hasSolarAtHome: false,
  solarPanelPower: 250,
  solarPanelCount: 15,
  homeElectricBillMonthlyAvg: 125,
  householdIncome: 75000,
  householdSize: 1,
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  maxBudget: 30000,
  milesDrivenAnnually: 20000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: '',
  publicChargingCostInCentsPerKwh: 20,
  publicChargingPercentage: 0,
  purchaseMethod: 'cash',
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: EV2_A,
  taxFilingStatus: 'single',
  vehicleChargingPattern: 'afterMidnightBefore3pm',
  vehicleMakeFilter: 'All',
  vehicleSortDirection: 'desc',
  vehicleSortType: 'match_score',
  zipcode: '94108',
  zipcodeLastValid: '94108',
  zipcodeError: false,
  zipcodeIsDefault: true,

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false,
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    'station wagon': false,
    truck: false,
  },

  vehicleFilters: {
    type: 'Passenger',
    subtype: [],
    rangeMin: 0,
    priceMax: 50000,
    seatsMin: 2,
    fuelType: ['BEV', 'PHEV'],
    vehicleStatus: 'new',
    sort: {
      field: 'electricRange',
      order: 'desc',
    },
    showCount: 24,
  },
};
