const ENABLED_ENVIRONMENTS = ['production', 'staging'];

const FacebookTracker = {
  initialize() {
    if (!ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return;
    }

    let f = window;
    let b = document;
    const e = 'script';
    const v = 'https://connect.facebook.net/en_US/fbevents.js';

    if (f.fbq) return;
    let n = (f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    });

    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';

    n.queue = [];
    let t;
    try {
      t = b.createElement(e);
      t.async = !0;

      t.src = v;
      let s = b.getElementsByTagName(e)[0];

      s.parentNode.insertBefore(t, s);
      f.fbq('init', '345298259811508');
      f.fbq('track', 'PageView');
    } catch (e) {
      console.log('FacebookTracker createElement throws an error:', e);
      return;
    }
  },
  track() {
    if (!ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT)) {
      return;
    }

    window.fbq('track', 'PageView');
  },
};

export default FacebookTracker;
