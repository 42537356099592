// esTranslationRateInfo.ts

export const EV2_A = `### Plan de tarifas: EV2-A

No nivelado; Tiempo de uso, para clientes residenciales con un vehículo eléctrico. [Más información.] (Https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/electric-vehicle-base-plan/electric-vehicle-base-plan.page)

Los costos en el hogar que cobran la tarifa EV2-A son más bajas de 12 a.m. a 3 p.m., todos los días, incluidos los fines de semana y las vacaciones cuando la demanda es más baja, lo que hace que este sea el mejor momento para cobrar su vehículo. La electricidad es más costosa durante los períodos de pico (4 - 9 p.m.) y pico parcial (3 - 4 p.m. y 9 p.m. a 12 a.m.).

Clientes que pueden beneficiarse

Este plan de tarifas funciona para los clientes que tienen un vehículo eléctrico (EV) y/o almacenamiento de baterías y pueden cargarse durante las horas de menor actividad de 12 a.m. a 3 p.m., además de cambiar otros usos de energía doméstica a horas de menor actividad.

#### Descripción

Este plan de tarifa se aplica tanto al uso de energía en el hogar como al vehículo eléctrico y/o la carga de la batería.

Ofrece precios más bajos en los momentos del día en que los costos de suministro de energía son menos costosos.

#### Cosas para considerar

Evite cargar durante las horas pico. Para maximizar los ahorros, considere cambiar otro uso de energía doméstica a horas menos costosas de demanda (ver gráfico, arriba).

Los clientes con alto uso de energía (más del 800% de asignación de línea de base) en los últimos 12 meses no son elegibles para esta tarifa.`;

export const EV_B = `### Plan de tarifas: EV-B

No nivelado; Tiempo de uso, para clientes residenciales con un vehículo eléctrico. Este plan de tarifas implica la instalación de otro medidor, que separa los costos de electricidad de su hogar. [Más información.] (Https://www.pge.com/tariffs/assets/pdf/tariffbook/elec_scheds_ev%20 (sch) .pdf)

Los costos en EV-B son más bajos a partir de las 11 p.m. a las 7 a.m. Cuando la demanda es más baja, lo que hace de este el mejor momento para cargar su vehículo. La electricidad es más costosa durante los períodos de pico (2 - 9 p.m.) y pico parcial (7 a.m. - 2 p.m. y 9 a 11 p.m.).

#### clientes que pueden beneficiarse

Los clientes que desean rastrear su carga EV por separado del consumo de energía doméstica con un medidor dedicado. Si puede reducir su uso de 7 a.m. a 11 p.m. de lunes a viernes y 3 p.m. a las 7 p.m. Los fines de semana y las vacaciones, este plan de tarifas puede ser mejor para usted.

#### Descripción

Dual metros: uno para su hogar y otro para la carga de vehículos eléctricos. El precio por el cobro varía durante todo el día. El uso de energía de su hogar se mide por separado.

#### Cosas para considerar

Evite cargar durante las horas pico. Los costos para la instalación del segundo metro pueden ser significativos. Tarifa única por $ 100 por metro, más costo para instalar la estación de carga y el segundo panel eléctrico. También se puede requerir una actualización del servicio. Los costos generalmente son de alrededor de $ 2,000 y pueden ser de hasta $ 8,000 dependiendo de las características del sitio.

Cargos

| Tasas de energía totales ($ por kWh) | Verano | Invierno |
| --- | --- | --- |
| Pico | $ 0.60057 | $ 0.41855 |
| Pico parcial | $ 0.35646 | $ 0.28654 |
| Fuera de Pico | $ 0.24391 | $ 0.21481 |

CARGA TOTAL DE MEDIDOR: $ 0.04928 por día.

Consulte [aquí](https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf) para definiciones y cargos de períodos de tiempo.`;

export const E_1 = `### Plan de tarifas: E-1

Plan de tarifas escalonadas de PG&E. [Más información.] (Https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/tiered-base-plan/tiered-base-plan.page)

Este plan de tasas tiene dos niveles de precios, conocidos como "niveles", que se basan
Sobre la cantidad de energía que usa. También incluye un alto recargo de uso cuando
Su uso supera cuatro veces su asignación de referencia, también conocida como Nivel 1.

#### Comprender el subsidio de referencia

El primer y nivel de menor precio se llama su subsidio de referencia. Eso
consiste en una asignación de energía basada en dónde vive, su calefacción
fuente, y la temporada: verano o invierno. Summer se ejecuta del 1 de mayo a octubre
31 e invierno se extiende del 1 de noviembre al 30 de abril.

- La energía utilizada dentro de su asignación de referencia se factura al precio más bajo.
- La energía utilizada por encima de la asignación de línea de base se considera nivel 2 y se factura a un precio más alto.
-Si su uso de energía excede cuatro veces la asignación de referencia durante su ciclo de facturación mensual, un recargo de uso de alto uso] (https://www.pge.com/en_us/residential/rate-plans/how-rates-work/rate- Se aplicarán cambios/High-Usage-Surcharge/High-Usage-Spurcharge.Page).

[Encuentre su Asignación de Referencia.] (https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/understanding-baseline-allowance.page)
`;

export const E_TOU_B = `### Plan de tarifas: E-Tou-B

Tiempo de uso (precios máximos de 4 a 9 p.m. de lunes a viernes). [Más información.] (Https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

Este plan de tarifas ofrece precios más bajos durante los períodos del día en que los costos de energía son más bajos, antes de las 4 p.m. y después de las 9 p.m. los días de semana y durante todas las horas los fines de semana y la mayoría de las vacaciones.

#### Cómo funciona:

El precio que paga cambia según la hora del día, el día de la semana y la temporada:

- Peak (precio más alto) - 4 p.m. a las 9 p.m. De lunes a viernes (excepto la mayoría de las vacaciones)
- Aft pico (precio más bajo)- antes de las 4 p.m. y después de las 9 p.m. De lunes a viernes y todas las horas los fines de semana y la mayoría de las vacaciones
- Ocho meses (octubre a mayo) tienen precios más bajos que los cuatro meses de verano (junio a septiembre)

A diferencia del plan de tarifas de tiempo de uso de 3 a 8 p.m., no hay asignación de referencia en este plan de tarifas.

#### Que se significa para usted:

- Tiene la oportunidad de ahorrar dinero si puede reducir su uso de energía en general y especialmente durante las horas de mayor precio (pico).
-Este plan de tarifa de tiempo de uso (E-Tou-B) puede ser más atractivo para los usuarios de mayor energía, porque el precio de la electricidad es más bajo que ETOU, una vez que su uso mensual de electricidad excede la cantidad de nivel 1 (asignación de referencia) .`;

export const E_TOU_C = `### Plan de tarifas: E-Tou-C

Tiempo de uso (precios máximos de 4 a 9 p.m. todos los días). [Más información.] (Https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

#### Cómo funciona:

El precio que paga los cambios según la hora del día

- Pico (precio más alto) - 4 p.m. a las 9 p.m. todos los días
- Aft pico (precio más bajo)- antes de las 4 p.m. y después de las 9 p.m. todos los días

#### Que se significa para usted:

- Puede ahorrar dinero si puede reducir su uso de energía en general y especialmente durante las horas de mayor precio (pico).

[Obtenga más información sobre el plan de tarifas de tiempo de uso (precio máximo de 4 a 9 p.m. todos los días) (https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/time-of -use-base-plan/tou-everyday.page)`;

export const E_TOU_D = `### Plan de tarifas: E-Tou-D

Tiempo de uso (precios máximos de 5 a 8 p.m. de lunes a viernes). [Más información.] (Https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

Este plan de tarifas ofrece precios más bajos durante los períodos del día en que los costos de energía son más bajos, antes de las 5 p.m. y después de las 8 p.m., los días de semana y durante todas las horas los fines de semana, y en la mayoría de los dias festivos.

#### Cómo funciona:

El precio que paga cambia según la hora cada día, el día de la semana, y la temporada

- Pico (precio más alto) -- 5 p.m. a las 8 p.m. De lunes a viernes (excepto la mayoría de las vacaciones)
- Fuera de pico (precio más bajo) -- antes de las 5 p.m. a las 8 p.m. De lunes a viernes y a todas horas los fines de semana y la mayoría de los dias festivos.

- Ocho meses (octubre a mayo) tienen precios más bajos que los cuatro meses de verano (junio a septiembre).
-A diferencia del plan de tarifas "Time-of-Use Rate Plan 4-9 p.m.", no hay asignación de referencia en este plan de tarifas.

#### Que se significa para usted:

- Tiene la oportunidad de ahorrar dinero si puede reducir su uso de energía en general y especialmente durante las horas de mayor precio (pico).
-Este plan de tarifa de tiempo de uso (E-TOU-D) puede ser más atractivo para los usuarios de mayor energía, porque el precio de electricidad es más bajo que E-TOU-C una vez que su uso mensual de electricidad exceda la cantidad de nivel 1 (asignación de referencia).

[Obtenga más información sobre el plan de tarifas "Time-of-Use (Peak Pricing 5 - 8pm Weekdays)", una tarifa de tiempo de uso (con precio máximo de 5 a 8pm) (https://www.pge.com/en_us/residential/rate-plans/rate-plan-options/time-of- use-base-plan/tou-weekdays.page)`;
