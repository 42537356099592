import { useState } from 'react';
import { useGeoIp } from '../../api/hooks/useGeoIp';
import { useUuid } from '../../api/hooks/useUuid';
import type { FormData } from './types';
import { SHA1, enc } from 'crypto-js';
import { Buffer as PackagedBuffer } from 'buffer';

const FEEDBACK_URL = process.env.REACT_APP_PGE_FEEDBACK_URL || '';

export default function useFeedback(formData: FormData) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const { data: ipData } = useGeoIp();
  const { uuid } = useUuid();

  const isSubmittable =
    status !== 'loading' &&
    (formData.whatIsGoal?.length ||
      formData.didYouAchieveGoal !== null ||
      formData.howWouldYouImprove?.length);

  const postFeedback = async () => {
    try {
      setStatus('loading');
      let data = {
        uuid: uuid,
        client_ip: ipData?.ip || '',
        current_path: window.location.href,
        ...(formData.whatIsGoal ? { goal: formData.whatIsGoal } : {}),
        ...(formData.didYouAchieveGoal ? { goal_achieved: formData.didYouAchieveGoal } : {}),
        ...(formData.howWouldYouImprove ? { suggestions: formData.howWouldYouImprove } : {}),
      };

      // @ts-ignore-next-line
      Object.keys(data).forEach((key) => data[key] == null && delete data[key]);

      const payload = new PackagedBuffer(JSON.stringify(data)).toString('base64');

      const feedback = {
        service: process.env.REACT_APP_PGE_SERVICE_ID || '',
        payload: [payload],
        checksum: '-Sha1-' + SHA1(payload).toString(enc.Hex),
      };

      await window.fetch(FEEDBACK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedback),
      });
      setStatus('idle');
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  return {
    postFeedback,
    isSubmittable,
  };
}
