import type { ElectricVehicle } from './../../types';
import IconBEV from '../../assets/images/icons/icon-electric-3.png';
import IconPHEV from '../../assets/images/icons/icon-hybrid-3.png';

const fuelTypeIcons = {
  BEV: {
    src: IconBEV,
    alt: 'All-Electric',
    width: 10,
    height: 18,
  },
  PHEV: {
    src: IconPHEV,
    alt: 'Hybrid',
    width: 38,
    height: 18,
  },
  GAS: null,
};

const FuelTypeIcon = ({ fuelType }: { fuelType: ElectricVehicle['fuelType'] }) => {
  if (!fuelTypeIcons[fuelType]) {
    return null;
  }

  const { src, alt, width, height } = fuelTypeIcons[fuelType];
  return <img src={src} alt={alt} width={width} height={height} />;
};

export default FuelTypeIcon;
