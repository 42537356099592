import e1__coast__nemn from './representative/e1__coast__nemn.json';
import e1__desert__nemn from './representative/e1__desert__nemn.json';
import e1__hills__nemn from './representative/e1__hills__nemn.json';
import e1__valley__nemn from './representative/e1__valley__nemn.json';

import etouc__coast__nemn from './representative/etouc__coast__nemn.json';
import etouc__desert__nemn from './representative/etouc__desert__nemn.json';
import etouc__hills__nemn from './representative/etouc__hills__nemn.json';
import etouc__valley__nemn from './representative/etouc__valley__nemn.json';

import etoud__coast__nemn from './representative/etoud__coast__nemn.json';
import etoud__desert__nemn from './representative/etoud__desert__nemn.json';
import etoud__hills__nemn from './representative/etoud__hills__nemn.json';
import etoud__valley__nemn from './representative/etoud__valley__nemn.json';

import eva__coast__nemn from './representative/eva__coast__nemn.json';
import eva__desert__nemn from './representative/eva__desert__nemn.json';
import eva__hills__nemn from './representative/eva__hills__nemn.json';
import eva__valley__nemn from './representative/eva__valley__nemn.json';

import e1__coast__nemy from './representative/e1__coast__nemy.json';
import e1__desert__nemy from './representative/e1__desert__nemy.json';
import e1__hills__nemy from './representative/e1__hills__nemy.json';
import e1__valley__nemy from './representative/e1__valley__nemy.json';

import etouc__coast__nemy from './representative/etouc__coast__nemy.json';
import etouc__desert__nemy from './representative/etouc__desert__nemy.json';
import etouc__hills__nemy from './representative/etouc__hills__nemy.json';
import etouc__valley__nemy from './representative/etouc__valley__nemy.json';

import etoud__coast__nemy from './representative/etoud__coast__nemy.json';
import etoud__desert__nemy from './representative/etoud__desert__nemy.json';
import etoud__hills__nemy from './representative/etoud__hills__nemy.json';
import etoud__valley__nemy from './representative/etoud__valley__nemy.json';

import eva__coast__nemy from './representative/eva__coast__nemy.json';
import eva__desert__nemy from './representative/eva__desert__nemy.json';
import eva__hills__nemy from './representative/eva__hills__nemy.json';
import eva__valley__nemy from './representative/eva__valley__nemy.json';

const REPRESENTATIVE_LOAD_PROFILES: { [key: string]: Array<number> } = {
  e1__coast__nemn,
  e1__desert__nemn,
  e1__hills__nemn,
  e1__valley__nemn,
  etouc__coast__nemn,
  etouc__desert__nemn,
  etouc__hills__nemn,
  etouc__valley__nemn,
  etoud__coast__nemn,
  etoud__desert__nemn,
  etoud__hills__nemn,
  etoud__valley__nemn,
  eva__coast__nemn,
  eva__desert__nemn,
  eva__hills__nemn,
  eva__valley__nemn,
  e1__coast__nemy,
  e1__desert__nemy,
  e1__hills__nemy,
  e1__valley__nemy,
  etouc__coast__nemy,
  etouc__desert__nemy,
  etouc__hills__nemy,
  etouc__valley__nemy,
  etoud__coast__nemy,
  etoud__desert__nemy,
  etoud__hills__nemy,
  etoud__valley__nemy,
  eva__coast__nemy,
  eva__desert__nemy,
  eva__hills__nemy,
  eva__valley__nemy,
};

export default REPRESENTATIVE_LOAD_PROFILES;

export const YEAR = 2020;
