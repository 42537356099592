import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

const Label = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Typography
      lineHeight="1"
      fontWeight="500"
      fontSize="0.75rem"
      textTransform="uppercase"
      sx={{ color: (theme) => theme.palette.grey[500] }}
    >
      {children}
    </Typography>
  );
};

type Size = 'xs' | 'sm' | 'md' | 'lg';
const fontSizes: Record<Size, string> = {
  xs: '1rem',
  sm: '1.2rem',
  md: '1.75rem',
  lg: '2.5rem',
};

const Value = ({ children, size }: { children: ReactNode; size: Size }): JSX.Element => {
  return (
    <Typography
      lineHeight="1"
      fontWeight="500"
      fontSize={fontSizes[size]}
      sx={{ color: (theme) => theme.palette.grey[700] }}
    >
      {children}
    </Typography>
  );
};

export default function Spec({
  label,
  children,
  size = 'md',
}: {
  label: ReactNode;
  children: ReactNode;
  size: Size;
}) {
  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" lineHeight="1">
      <Label>{label}</Label>
      <Value size={size}>{children}</Value>
    </Box>
  );
}
