import { UserPrefs, RateKey } from '../../types';
import { RateCalculator, LoadProfile } from '@bellawatt/electric-rate-engine';
import selectRate from '../rate/selectRate';
import { RateCalculatorInterface } from '../../types';

const CARE_DISCOUNT = 0.2;

const calcElectricBill = (rate: RateKey, loadProfile: LoadProfile, userPrefs: UserPrefs) => {
  const annualCost = new RateCalculator({
    ...selectRate(rate, userPrefs.zipcode),
    loadProfile,
  } as RateCalculatorInterface).annualCost();

  const careAdjustment = userPrefs.hasCareDiscount ? 1 - CARE_DISCOUNT : 1;
  return annualCost * careAdjustment;
};

export default calcElectricBill;
