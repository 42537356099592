import { Box, Slider as MuiSlider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatAsThousands } from '../../utils/formatters';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = {
  label?: string;
  labelTooltip?: string;
  value: number;
  valueSuffix?: string;
  valuePrefix?: string;
  valueFormatter?: (val: number) => string;
  valueVariant?: 'small' | 'large';
  describer?: (val: number) => string | null | undefined;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

export default function Slider({
  value,
  label,
  labelTooltip,
  valueSuffix,
  valuePrefix,
  valueVariant = 'large',
  valueFormatter,
  describer,
  min,
  max,
  step = 10,
  disabled = false,
  onChange,
}: Props) {
  // use internal state for displaying value until the user stops sliding, then fire onChange
  const [workingValue, setWorkingValue] = useState(value);

  useEffect(() => {
    setWorkingValue(value);
  }, [value]);

  return (
    <Box>
      {label && <Typography variant="label1">{label}</Typography>}
      {labelTooltip && (
        <Tooltip title={labelTooltip} placement="right">
          <InfoOutlinedIcon fontSize="inherit" sx={{ marginLeft: '8px' }} />
        </Tooltip>
      )}
      <MuiSlider
        disabled={disabled}
        aria-label={label}
        value={workingValue}
        min={min}
        max={max}
        step={step}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'number') {
            setWorkingValue(newValue);
          }
        }}
        onChangeCommitted={() => onChange(workingValue)}
        sx={{
          color: (theme) => theme.palette.grey[200],
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-rail': {
            opacity: 1,
          },
          '& .MuiSlider-thumb': {
            color: (theme) => theme.palette.primary.main,
          },
          '&.Mui-disabled .MuiSlider-thumb': {
            color: (theme) => theme.palette.grey['100'],
          },
          '&.Mui-disabled': {
            color: (theme) => theme.palette.grey[200],
          },
        }}
      />
      <Box
        textAlign="center"
        fontWeight="500"
        fontSize={valueVariant === 'large' ? '1rem' : '0.75rem'}
      >
        {describer?.(workingValue) ||
          `${valuePrefix || ''}${(valueFormatter || formatAsThousands)(workingValue)}${
            valueSuffix || ''
          }`}
      </Box>
    </Box>
  );
}
