import ICON_ROAD from '../../assets/images/icons/icon-road.png';
import ICON_SAVINGS from '../../assets/images/icons/icon-savings.png';
import ICON_CHARGING from '../../assets/images/icons/icon-charging.png';
import ICON_CHARGING_PUBLIC from '../../assets/images/icons/charger-pin-public.png';
import ICON_CHARGING_HIGH_POWER from '../../assets/images/icons/charger-pin-high-power.png';
import ICON_CHARGING_PRIVATE from '../../assets/images/icons/charger-pin-private.svg';
import ICON_DEALER_PIN from '../../assets/images/icons/dealer-pin.png';
import ICON_DRIVING from '../../assets/images/icons/icon-driving.png';
import ICON_CHECKMARK from '../../assets/images/icons/green-checkmark.svg';
import ICON_ZIPCODE from '../../assets/images/icons/icon-zip.png';
import ICON_CARET from '../../assets/images/icons/caret.svg';
import ICON_PARKING from '../../assets/images/icons/icon-parking.png';
import ICON_POLLUTION from '../../assets/images/icons/icon-pollution.png';
import ICON_EV_RIGHT from '../../assets/images/icons/icon-ev-right.svg';
import ICON_CHARGING_ROUTE from '../../assets/images/icons/icon-charging-route.png';
import ICON_RATE from '../../assets/images/icons/icon-rate.png';
import ICON_LIGHTNING from '../../assets/images/icons/icon-lightning.svg';
import { Box, SxProps, Theme } from '@mui/material';

const variants = {
  road: {
    alt: 'road',
    src: ICON_ROAD,
  },
  savings: {
    alt: 'savings',
    src: ICON_SAVINGS,
  },
  charging: {
    alt: 'charging',
    src: ICON_CHARGING,
  },
  chargingPublic: {
    alt: 'public charging',
    src: ICON_CHARGING_PUBLIC,
  },
  chargingHighPower: {
    alt: 'high power charging',
    src: ICON_CHARGING_HIGH_POWER,
  },
  chargingPrivate: {
    alt: 'private charging',
    src: ICON_CHARGING_PRIVATE,
  },
  dealerPin: {
    alt: 'dealer',
    src: ICON_DEALER_PIN,
  },
  driving: {
    alt: 'driving',
    src: ICON_DRIVING,
  },
  checkmark: {
    alt: 'checkmark',
    src: ICON_CHECKMARK,
  },
  pin: {
    alt: 'pin',
    src: ICON_ZIPCODE,
  },
  caret: {
    alt: 'caret',
    src: ICON_CARET,
  },
  parking: {
    alt: 'parking',
    src: ICON_PARKING,
  },
  pollution: {
    alt: 'pollution',
    src: ICON_POLLUTION,
  },
  evRight: {
    alt: 'electric vehicle',
    src: ICON_EV_RIGHT,
  },
  chargingRoute: {
    alt: 'charging route',
    src: ICON_CHARGING_ROUTE,
  },
  rate: {
    alt: 'rate',
    src: ICON_RATE,
  },
  lightning: {
    alt: 'lightning',
    src: ICON_LIGHTNING,
  },
} as const;

export type IconVariant = keyof typeof variants;

type Props = {
  variant: IconVariant;
  alt?: string;
  sx?: SxProps<Theme>;
};

export default function Icon({ variant, alt, sx }: Props): JSX.Element {
  const icon = variants[variant];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={sx}>
      <img alt={alt || icon.alt} src={icon.src} style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </Box>
  );
}
