import { Box } from '@mui/material';
import Icon from '../../../icon/Icon';

export default function DealerMarker({
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  isSelected?: boolean;
  lat: number;
  lng: number;
  label: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave?: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        opacity: isSelected ? 1 : 0.85,
        height: '24px',
        width: '24px',
      }}
    >
      <Icon variant="dealerPin" />
    </Box>
  );
}
