import React, { useEffect, useState, useCallback } from 'react';
import { USER_PREF_PRESETS } from '../constants/userPrefs';
import type { UserPrefs } from '../types/userPrefs';
import GaTracker from '../utils/GaTracker/GaTracker';

const USER_PREF_KEY = 'userPrefs' as const;

type HistoricalUserPrefs = UserPrefs & {
  vehicleIdForIncentives?: string;
  vehicleIdForRateComp?: string;
  vehicleFilters: UserPrefs['vehicleFilters'] & {
    make?: string;
  };
};

/**
 * upgrade() is run when userPrefs are loaded from localStorage, on page load. Make any adjustments
 * to the data that are necessary for old saved data to work with new code changes.
 */
const upgrade = (userPrefs: HistoricalUserPrefs) => {
  // 2022-09-15: We changed the keys of
  // electricVehicleLoadProfiles.ts to coincide
  // with the translations.ts file. So we
  // need to change the vehicleChargingPattern
  // value to match one of the new keys.
  const chargingPatternUpgrade: Record<string, string> = {
    'After midnight; Before 3pm': 'afterMidnightBefore3pm',
    'After 9pm; Before 4pm': 'after9pmBefore4pm',
    'After 4pm; Before 9pm': 'after4pmBefore9pm',
    'All Hours (Unpredictable)': 'unpredictable',
  };
  if (chargingPatternUpgrade[userPrefs.vehicleChargingPattern]) {
    userPrefs.vehicleChargingPattern = chargingPatternUpgrade[userPrefs.vehicleChargingPattern];
  }
  // 2023-04-25: We moved the filters on the
  // /vehicles page into the user preferences.
  // Existing users don't have this key yet.
  if (!userPrefs.vehicleFilters) {
    userPrefs.vehicleFilters = USER_PREF_PRESETS.vehicleFilters;
  }
  // 2023-05-03: We added the solar panel inputs.
  if (typeof userPrefs.solarPanelPower === 'undefined') {
    userPrefs.solarPanelPower = USER_PREF_PRESETS.solarPanelPower;
  }
  if (typeof userPrefs.solarPanelCount === 'undefined') {
    userPrefs.solarPanelCount = USER_PREF_PRESETS.solarPanelCount;
  }
  // 2023-07-24: New field. We assume returning
  // users have used the zipcode
  if (typeof userPrefs.zipcodeIsDefault === 'undefined') {
    userPrefs.zipcodeIsDefault = false;
  }
  // 2023-07-27: Clean up
  //   zipcodeError and zipcodeLastValid were
  //   new in October, and having them be
  //   undefined didn't hurt, but we run a
  //   tight ship here.
  if (typeof userPrefs.zipcodeError === 'undefined') {
    userPrefs.zipcodeError = USER_PREF_PRESETS.zipcodeError;
  }
  if (typeof userPrefs.zipcodeLastValid === 'undefined') {
    userPrefs.zipcodeLastValid = USER_PREF_PRESETS.zipcodeLastValid;
  }
  //   These 3 fields were removed some time
  //   ago. Delete 'em.
  if (typeof userPrefs.vehicleIdForIncentives !== 'undefined') {
    delete userPrefs.vehicleIdForIncentives;
  }
  if (typeof userPrefs.vehicleIdForRateComp !== 'undefined') {
    delete userPrefs.vehicleIdForRateComp;
  }
  if (typeof userPrefs.vehicleFilters.make !== 'undefined') {
    delete userPrefs.vehicleFilters.make;
  }
  return userPrefs;
};

type UserPrefsContextType = {
  userPrefs: UserPrefs;
  setUserPrefs: (prefChange: Function | Partial<UserPrefs>) => void;
  resetUserPrefs: () => void;
};

const UserPrefsContext = React.createContext<UserPrefsContextType>({
  userPrefs: USER_PREF_PRESETS,
  setUserPrefs: (newPrefs) => {},
  resetUserPrefs: () => {},
});

type Props = {
  children: JSX.Element;
};

const getSavedUserPrefs = (): UserPrefs => {
  const stored = localStorage.getItem(USER_PREF_KEY);
  return stored ? upgrade(JSON.parse(stored)) : USER_PREF_PRESETS;
};

export const getValidZipcode = (userPrefs: UserPrefs): string =>
  userPrefs.zipcodeError ? userPrefs.zipcodeLastValid : userPrefs.zipcode;

/**
 * If there are any changes between two values,
 * we'll detect them.
 *
 * Two objects are the same if all of their
 * values are the same, recursively.
 */
const detectChanges = function <T>(newT: T, oldT: T): boolean {
  if (newT && oldT && typeof newT === 'object' && typeof oldT === 'object') {
    return (Object.keys(newT) as Array<keyof T>).some((key) => detectChanges(newT[key], oldT[key]));
  } else {
    return newT !== oldT;
  }
};

/**
 * Mutators have the chance to check values being
 * submitted to setUserPrefs and change the
 * changes.
 *
 * A mutator must accept a Partial<UserPrefs>
 * and a UserPrefs as parameters and return a
 * Parital<UserPrefs>.
 *
 * The results of one mutator are fed into the
 * next one, in the order given.
 *
 * Example return values:
 *
 *  Return: { ...newPrefs, zipcode: '' }
 *  Effect: The given changes are applied with one
 *          extra change
 *
 *  Return: newPrefs
 *  Effect: Only the given changes are applied
 *
 *  Return: {}
 *  Effect: All changes are eliminated
 *
 *  Return: { zipcode: '' }
 *  Effect: Only change the `zipcode` field,
 *          eliminating all other changes
 *
 *  Return: const { zipcode, ...theRest }; return theRest;
 *  Effect: Remove the `zipcode` field from the
 *          changes, but keep all other changes
 */
const mutators: Array<(newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => Partial<UserPrefs>> = [
  (newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => {
    // Any time the user changes the vehicle filters,
    // the Show More button should reset.
    // This detects those changes and resets it.
    // Except when the change is `showCount` itself,
    // the property that controls the Show More
    // button.
    if (
      newPrefs.vehicleFilters &&
      detectChanges(newPrefs.vehicleFilters, oldPrefs.vehicleFilters) &&
      newPrefs.vehicleFilters.showCount === oldPrefs.vehicleFilters.showCount
    ) {
      return {
        ...newPrefs,
        vehicleFilters: {
          ...newPrefs.vehicleFilters,
          showCount: USER_PREF_PRESETS['vehicleFilters']['showCount'],
        },
      };
    }
    return newPrefs;
  },
  (newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => {
    // We want to hold onto a known good zipcode. If
    // we are replacing one that had no errors, then
    // let's keep it.
    // Either way we need to clear the error flag so
    // that we know to check again (elsewhere).
    if (newPrefs.zipcode && newPrefs.zipcode !== oldPrefs.zipcode) {
      if (oldPrefs.zipcodeError === true) {
        return { zipcodeError: false, ...newPrefs };
      } else {
        return {
          zipcodeError: false,
          zipcodeLastValid: oldPrefs.zipcode,
          ...newPrefs,
        };
      }
    }
    return newPrefs;
  },
  (newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => {
    // The zipcode has been updated. Remember that.
    if (newPrefs.zipcode) {
      return { ...newPrefs, zipcodeIsDefault: false };
    }
    return newPrefs;
  },
];

export default function UserPrefsProvider({ children }: Props) {
  const [userPrefs, _setUserPrefs] = useState<UserPrefs>(getSavedUserPrefs());

  const setUserPrefs = useCallback((prefChanges: Function | Partial<UserPrefs>) => {
    _setUserPrefs((currentUserPrefs) => {
      const changes: Partial<UserPrefs> =
        typeof prefChanges === 'function' ? prefChanges(currentUserPrefs) : prefChanges;

      if (!detectChanges(changes, currentUserPrefs)) {
        return currentUserPrefs;
      }

      const newPrefs = mutators.reduce(
        (changes, mutator) => mutator(changes, currentUserPrefs),
        changes,
      );

      return { ...currentUserPrefs, ...newPrefs };
    });
  }, []);

  const resetUserPrefs = () => {
    _setUserPrefs(USER_PREF_PRESETS);
  };

  useEffect(() => {
    if (userPrefs) {
      localStorage.setItem(USER_PREF_KEY, JSON.stringify(userPrefs));
    }
  }, [userPrefs]);

  useEffect(() => {
    if (userPrefs.zipcode !== USER_PREF_PRESETS.zipcode) {
      GaTracker.trackEvent({
        category: 'Data',
        action: 'ZIP Code Change',
        label: userPrefs.zipcode,
      });
    }
  }, [userPrefs.zipcode]);

  return (
    <UserPrefsContext.Provider value={{ userPrefs, setUserPrefs, resetUserPrefs }}>
      {children}
    </UserPrefsContext.Provider>
  );
}

export const useUserPrefs = () => React.useContext(UserPrefsContext);
