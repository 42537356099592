import { Box, Typography } from '@mui/material';
import type { ElectricVehicle } from '../../../../types';
import FuelTypeIcon from '../../../../components/fuelTypeIcon/FuelTypeIcon';
import Image from '../../../../components/image/Image';
import Card from '../../../../components/card/Card';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../../../utils/formatters';
import Button from '../../../../components/button/Button';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { useHistory } from 'react-router-dom';

type Props = {
  ev: ElectricVehicle;
  selected?: boolean;
  onClick?: () => void;
};

export default function EvCard({ ev, selected, onClick }: Props): JSX.Element {
  const { formatMessage } = useIntl();
  const imgSrc = ev.images?.find((image) => image.urlThumbnail)?.urlThumbnail;
  const { setUserPrefs } = useUserPrefs();
  const history = useHistory();

  const handleCompareClick = () => {
    setUserPrefs({
      comparedVehicleId1: ev.handle,
    });
    history.push('/compare?default-fuel-type=BEV');
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        p: 0,
        cursor: onClick ? 'pointer' : 'inherit',
        borderRadius: '4px',
        height: 'fit-content',
        ...(selected ? { boxShadow: (theme) => `0 0 0 4px ${theme.palette.blue[300]}` } : {}),
        textAlign: 'left',
      }}
    >
      <Box>
        <Box p="15px 15px 10px 15px">
          <Box display="flex" justifyContent="space-between">
            <Typography>{ev.make}</Typography>
            <FuelTypeIcon fuelType={ev.fuelType} />
          </Box>
          <Typography height="50px">{ev.displayName}</Typography>
          {imgSrc && <Image src={imgSrc} alt={'Go to ' + ev.displayName} sx={{ width: '100%' }} />}
          <Box mb={2}>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem" fontWeight="300">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.evRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="900" textAlign="right">
                {formatMessage(
                  { id: 'vehicleCatalog.vehicleCard.rangeMiles' },
                  { numMiles: Math.round(ev.electricRange) },
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem" fontWeight="300">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.totalRange' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="900" textAlign="right">
                {formatMessage(
                  { id: 'vehicleCatalog.vehicleCard.rangeMiles' },
                  { numMiles: Math.round(ev.totalRange) },
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box fontSize="0.7rem" fontWeight="300">
                {formatMessage({ id: 'vehicleCatalog.vehicleCard.msrp' })}
              </Box>
              <Box fontSize="0.875rem" fontWeight="900" textAlign="right">
                {formatAsDollars(ev.msrp)}
              </Box>
            </Box>
          </Box>
          {selected && (
            <Button onClick={handleCompareClick}>
              {formatMessage({ id: 'evOwners.findADealership.compareEvButton' })}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
}
