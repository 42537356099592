import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Button from '../button/Button';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import Honeybadger from '@honeybadger-io/js';

function ErrorFallback(): JSX.Element {
  const { formatMessage } = useIntl();
  const { resetUserPrefs } = useUserPrefs();

  return (
    <Box width="100%" minHeight="100vh" display="flex" flexDirection="column">
      <Header />
      <Box flex="1 1" textAlign="center" maxWidth="800px" mx="auto" p="40px">
        <div role="alert">
          <h1>{formatMessage({ id: 'errorBoundary.header' })}</h1>
          <p>{formatMessage({ id: 'errorBoundary.body' })}</p>
          <Box mt="40px">
            <Button
              onClick={() => {
                resetUserPrefs();
                window.location.reload();
              }}
            >
              {formatMessage({ id: 'errorBoundary.resetButton' })}
            </Button>
          </Box>
        </div>
      </Box>
      <Footer />
    </Box>
  );
}

const report = (error: Error) => Honeybadger.notify(error);

export default function ErrorBoundary({ children }: { children: JSX.Element }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={report}>
      {children}
    </ReactErrorBoundary>
  );
}
