import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import GaTracker from '../../../utils/GaTracker/GaTracker';

const HOST = process.env.REACT_APP_SMD_STATUS_HOST || 'http://localhost:3003';
const FAILED = 'failed';
export const useHistoricalLoadProfile = () => {
  const [loadProfile, setLoadProfile] = useState(null);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [usingHistorical, setUsingHistorical] = useState(!!token);
  const [error, setError] = useState('');

  const reset = () => {
    if (searchParams.has('token')) {
      searchParams.delete('token');
      history.push({
        search: searchParams.toString(),
      });
    }
    setUsingHistorical(false);
    setLoadProfile(null);
    setError('');
  };

  useEffect(() => {
    let intervalValue;

    if (!token) {
      return;
    }

    intervalValue = setInterval(() => {
      axios
        .get(`${HOST}/status/${token}`)
        .then(({ data }) => {
          const { progress, load_profile, status, errorMessage } = data;
          setProgress(progress);
          setLoadProfile(load_profile);
          // Error message only appears locally when using serverless-offline
          if (status === FAILED || !!errorMessage) {
            setError('Failed to load historical data');
            setUsingHistorical(false);
            GaTracker.trackEvent({
              category: 'Share My Data',
              action: 'Historical Data Failed to Load',
            });
          }
          if (progress >= 1 || status === FAILED) {
            GaTracker.trackEvent({
              category: 'Share My Data',
              action: 'Historical Data Loaded Successfully',
            });
            clearInterval(intervalValue);
          }
        })
        .catch((response) => {
          console.warn(response);
          setError('Failed to load historical data');
          setUsingHistorical(false);
          clearInterval(intervalValue);
          GaTracker.trackEvent({
            category: 'Share My Data',
            action: 'Historical Data Failed to Load',
          });
        });
    }, 5000);

    return () => intervalValue && clearInterval(intervalValue);
  }, [token]);

  return {
    progress,
    loadProfile,
    error,
    reset,
    usingHistorical,
  };
};
