import { Box, Typography } from '@mui/material';

export default function MultiUnitInstallation() {
  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Discussing installation with your property manager.
      </Typography>
      <p>
        California law requires property owners to allow tenants to install a residential charging
        station. Tenants cannot be denied a request to install a residential charging station if the
        tenant pays for the installation, maintenance, insurance and removal of the charging
        station. However, there are certain residential rental conditions where this law does not
        apply. For example, if there are already EV charging stations for 10 percent or more of the
        designated parking spaces for renters, the law does not apply.
        <a
          href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1947.6.&lawCode=CIV"
          target="_blank"
          rel="noopener noreferrer"
        >
          Review additional California legislative details
        </a>
        .
      </p>
      <p>Help your property owner understand options by educating yourself</p>
      <ul>
        <li>
          Determine demand. Perform a residential survey to see how many residents have an EV or
          planning to buy one.{' '}
          <a href="https://www.veloz.org/resource/empower-resident-survey/">
            Review an example residential survey
          </a>
          .
        </li>
        <li>
          Electrical capacity. Talk with an electrician to understand your property's electrical
          capacity for adding a charging station.
        </li>
        <li>
          Charger options. Choose a charging station that best meets your specific property's
          requirements. This will help show associated costs.
        </li>
        <li>
          Covering costs. Make clear you'll cover the installation costs and the ongoing costs of
          electricity.
        </li>
        <li>
          Funding. Research and apply for incentives to install a charging station.{' '}
          <a href="https://www.driveclean.ca.gov/pev/Incentives.php">
            Visit Drive Clean to learn about incentives available in your area
          </a>
          .
        </li>
        <li>
          Best practices. Find other installed charging stations in your neighborhood to show
          support of EVs within the community.
        </li>
      </ul>
      <p>Share property benefits of having EV chargers on site</p>
      <ul>
        <li>Charging stations will help make the property manager a leader in sustainability.</li>
        <li>
          As EVs continue to gain popularity, more residents will be interested in charging
          stations.
        </li>
        <li>
          Charging stations can help the property receive Leadership in Energy & Environmental
          Design (LEED) points.
        </li>
        <li>
          Installing charging stations can increase property value and give a competitive advantage
          for new tenants.
        </li>
      </ul>
      <Typography variant="h5" mb={1}>
        Obtain permission to install an EV charging station
      </Typography>
      <p>
        Once you've received approval to install a charging station, review our installation
        checklist for step-by-step information on how to install your EV level 2 charger.
      </p>
    </Box>
  );
}
