import Link from '../../../../components/link/Link';
import Card from '../../../../components/card/Card';
import { Box, Divider, Typography } from '@mui/material';

type Props = {
  heading?: string;
  description?: string;
  title?: string;
  to?: string;
};

const ProgramCard = ({ heading = '', description = '', title = '', to = '' }: Props) => {
  return (
    <Link external to={to}>
      <Card
        sx={{
          p: 0,
          height: '100%',
          borderRadius: '4px',
          boxShadow: 0,
          ':hover': {
            boxShadow: 5,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m="7px"
          fontSize="14px"
          fontWeight="400"
          height="30px"
          sx={{
            color: (theme) => theme.palette.white[100],
            backgroundColor: (theme) => theme.palette.blue[400],
            borderRadius: '4px',
          }}
        >
          {heading}
        </Box>
        <Box textAlign="center" m="19px">
          <Typography component="h5" fontWeight="500" fontSize="1.75rem" m="15px 0 30px 0">
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box
          p={{ xs: '8px', sm: '19px' }}
          textAlign="center"
          fontWeight="300"
          fontSize={{ xs: '0.875rem', sm: '1rem' }}
        >
          {description}
        </Box>
      </Card>
    </Link>
  );
};

export default ProgramCard;
