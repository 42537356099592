// enTranslationRateInfo.ts

export const EV2_A = `### Rate Plan: EV2-A

Non-tiered; time-of-use, for residential customers with an electric vehicle. [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/electric-vehicle-base-plan/electric-vehicle-base-plan.page)

Costs on the Home Charging EV2-A rate are lowest from 12 a.m. to 3 p.m., every day, including weekends and holidays when demand is lowest, making this the best time to charge your vehicle. Electricity is more expensive during Peak (4 - 9 p.m.) and Partial-Peak (3 - 4 p.m. and 9 p.m. - 12 a.m.) periods.

#### Customers who may benefit

This rate plan works for customers who have an electric vehicle (EV) and/or battery storage and can charge during off-peak hours of 12 a.m. to 3 p.m., in addition to shifting other household energy usage to off-peak hours.

#### Description

This rate plan applies to both home energy use and electric vehicle and/or battery charging.

It offers lower prices during times of day when energy supply costs are less expensive.

#### Things to consider

Avoid charging during peak hours. To maximize savings, consider shifting other household energy usage to less expensive, low demand hours (see chart, above).

Customers with high energy usage (over 800% baseline allowance) over the last 12 months are not eligible for this rate.`;

export const E_ELEC = `### Rate Plan: E-ELEC

PG&E’s rate for an electric-powered home. [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/electric-home-rate-plan.page)

The Electric Home rate plan is ideal for customers that have begun to electrify their home with one or more of the following: Electric Vehicles (EV), battery storage, heat pump space conditioning or heat pump water heaters.

#### How it works

E-ELEC includes a $15-per-month Base Services Charge that lowers the price you pay per unit of energy (KWh), on average, compared to other rate plans. 

The price you pay changes based on the time of day:
- Peak (highest price) – 4 p.m. to 9 p.m
- Partial-Peak – between 3 p.m and 4pm, and between 9 p.m and 12 a.m
- Off-Peak (lowest price) – between 12 a.m and 3 p.m

Unlike other rates, there are no tiers and rates are for 7 days a week.

#### What it means for you

- This can help reduce dramatic swings in bills. 
- If you are a large electricity user who can shift usage to lower priced times of day, this rate plan may save you money.
- Your home does not need to be all electric to qualify for this rate plan.`;

export const EV_B = `### Rate Plan: EV-B

Non-tiered; time-of-use, for residential customers with an electric vehicle. This rate plan involves the installation of another meter, which separates your 's electricity costs from those of your home. [More info.](https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf)

Costs on EV-B are lowest from 11 p.m. to 7 a.m. when demand is lowest, making this the best time to charge your vehicle. Electricity is more expensive during Peak (2 - 9 p.m.) and Partial-Peak (7 a.m. - 2 p.m. and 9 - 11 p.m.) periods.

#### Customers who may benefit

Customers who want to track their EV charging separate from their home energy consumption with a dedicated meter. If you can reduce your usage from 7 a.m. to 11 p.m. on weekdays, and 3 p.m. to 7 p.m. on weekends and holidays, this rate plan may be best for you.

#### Description

Dual meters: one for your household and one for electric vehicle charging. The price for charging varies throughout the day. Your home energy use is measured separately.

#### Things to consider

Avoid charging during peak hours. Costs for second meter installation may be significant. One-time $100 fee per meter, plus cost to install charging station and second electric panel. Service upgrade may also be required. The costs are generally around $2,000 and may be up to around $8,000 depending on site characteristics.

Charges

| Total Energy Rates ($ per kWh) | Summer | Winter |
| --- | --- | --- |
| Peak | $0.60057 | $0.41855 |
| Part-Peak | $0.35646 | $0.28654 |
| Off-Peak | $0.24391 | $0.21481 |

Total Meter Charge: $0.04928 per day.

Please see [here](https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf) for time period definitions and charges.`;

export const E_1 = `### Rate Plan: E-1

PG&E's Tiered Rate Plan. [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/tiered-base-plan.page)

This rate plan has two pricing levels, known as “tiers,” which are based
on how much energy you use. It also includes a High Usage Surcharge when
your usage exceeds four times your Baseline Allowance, also known as Tier 1.

#### Understanding Baseline Allowance

The first and lowest priced tier is called your Baseline Allowance. It
consists of an allotment of energy based on where you live, your heating
source, and the season—summer or winter. Summer runs May 1 through October
31 and winter runs November 1 through April 30.

- Energy used within your Baseline Allowance is billed at the lowest price.
- Energy used above the Baseline Allowance is considered Tier 2 and is billed at a higher price.
- If your energy use exceeds four times the Baseline Allowance during your monthly billing cycle, a [High Usage Surcharge](https://www.pge.com/en_US/residential/rate-plans/how-rates-work/rate-changes/high-usage-surcharge/high-usage-surcharge.page) will be applied.

[Find your Baseline Allowance.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/understanding-baseline-allowance.page)
`;

export const E_TOU_B = `### Rate Plan: E-TOU-B

Time-of-Use (Peak Pricing 4 - 9 P.M. Weekdays). [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

This rate plan offers lower prices during periods of the day when energy costs are lower---before 4 p.m. and after 9 p.m. on weekdays and during all hours on weekends and most holidays.

#### How it works:

The price you pay changes based on the time of day, the day of the week, and the season:

-   Peak (highest price) -- 4 p.m. to 9 p.m. Monday through Friday (except most holidays)
-   Off-Peak (lowest price) -- before 4 p.m. and after 9 p.m. Monday through Friday and all hours on weekends and most holidays
-   Eight months (October through May) have lower prices than the four months of summer (June through September)

Unlike the Time-of-Use Rate Plan 3-8 p.m., there is no Baseline Allowance on this rate plan.

#### What it means for you:

-   You have the opportunity to save money if you can reduce your energy usage overall and especially during the higher-priced (peak) hours.
-   This Time-of-Use rate plan (E-TOU-B) may be more attractive for higher energy users, because the price of electricity is lower than ETOU-A once your monthly electricity usage exceeds the Tier 1 amount (Baseline Allowance).`;

export const E_TOU_C = `### Rate Plan: E-TOU-C

Time-of-Use (Peak Pricing 4 - 9 P.M. Every Day). [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

#### How it works:

The price you pay changes based on the time of day

-   Peak (higher price) -- 4 p.m. to 9 p.m. every day
-   Off-Peak (lower price) -- before 4 p.m. and after 9 p.m. every day

#### What it means for you:

-   You can save money if you can reduce your energy usage overall and especially during the higher-priced (peak) hours.

[Learn more about the Time-of-Use (Peak Pricing 4 - 9pm Every Day) Rate Plan](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/tou-everyday.page)`;

export const E_TOU_D = `### Rate Plan: E-TOU-D

Time-of-Use (Peak Pricing 5 - 8 P.M. Weekdays). [More info.](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

This rate plan offers lower prices during periods of the day when energy costs are lower---before 5 p.m. and after 8 p.m. on weekdays and during all hours on weekends and most holidays.

#### How it works:

The price you pay changes based on the time of day, the day of the week, and the season

-   Peak (highest price) -- 5 p.m. to 8 p.m. Monday through Friday (except most holidays)
-   Off-Peak (lowest price) -- before 5 p.m. to 8 p.m. Monday through Friday and all hours on weekends and most holidays

-   Eight months (October through May) have lower prices than the four months of summer (June through September).
-   Unlike the Time-of-Use Rate Plan 4-9 p.m., there is no Baseline Allowance on this rate plan.

#### What it means for you:

-   You have the opportunity to save money if you can reduce your energy usage overall and especially during the higher-priced (peak) hours.
-   This Time-of-Use rate plan (E-TOU-D) may be more attractive for higher energy users, because the price of electricity is lower than E-TOU-C once your monthly electricity usage exceeds the Tier 1 amount (Baseline Allowance).

[Learn more about the Time-of-Use (Peak Pricing 5 - 8pm Weekdays) Rate Plan](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/tou-weekdays.page)`;
