import { Card as MuiCard, CardProps, styled } from '@mui/material';

const StyledCard = styled(MuiCard)(({ theme }) => ({
  padding: '24px',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '4px',
  backgroundColor: theme.palette.white[100],
}));

export default function Card(props: CardProps) {
  return <StyledCard variant="outlined" {...props} />;
}
