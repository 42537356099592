import type { ReactNode } from 'react';
import { Button as MuiButton, SxProps, Theme } from '@mui/material';
import GaTracker from '../../utils/GaTracker/GaTracker';

type Variant = 'outlined' | 'contained' | 'text';
type Size = 'large' | 'medium' | 'small';

const baseStyle: SxProps<Theme> = {
  fontSize: '16px',
  fontWeight: '500',
  px: '16px',
  textTransform: 'none',
  borderRadius: '3px',
  cursor: 'pointer',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',
  },

  '&:disabled': (theme) => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    cursor: 'default',
  }),
};

export const buttonStyles: Record<Variant, SxProps<Theme>> = {
  outlined: {
    ...baseStyle,
    color: 'blue.800',
    border: '1px solid',
    borderColor: 'grey.300',
    textTransform: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      border: '1px solid',
      borderColor: 'grey.300',
      backgroundColor: 'grey.100',
    },
  },
  contained: {
    ...baseStyle,
    color: 'blue.800',
    backgroundColor: 'orange.500',
    border: '1px solid',
    borderColor: 'orange.500',
    textTransform: 'none',
    '&:hover': {
      border: '1px solid',
      borderColor: 'orange.500',
      backgroundColor: 'orange.500',
    },
  },
  text: {
    ...baseStyle,
  },
};

export const sizeStyles: Record<Size, SxProps<Theme>> = {
  small: {
    fontSize: '0.75rem',
    fontWeight: '700',
    minHeight: '30px',
    px: '16px',
  },
  medium: {
    fontSize: '1rem',
    fontWeight: '500',
    minHeight: '48px',
    px: '16px',
  },
  large: {
    fontSize: '16px',
    fontWeight: '500',
    minHeight: '48px',
    px: '16px',
  },
};

export default function Button({
  variant = 'contained',
  children,
  fullWidth,
  disabled,
  label,
  onClick,
  size = 'medium',
  sx = {},
  ...rest
}: {
  variant?: Variant;
  onClick?: (event: React.MouseEvent) => void;
  fullWidth?: boolean;
  label?: string;
  disabled?: boolean;
  children: ReactNode;
  size?: Size;
  sx?: SxProps<Theme>;
}) {
  const style: SxProps<Theme> = Object.assign({}, buttonStyles[variant], sizeStyles[size], sx);

  return (
    <MuiButton
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      title={label}
      onClick={(event) => {
        GaTracker.trackEvent({
          category: 'Button',
          action: 'Click',
          label: label,
        });
        onClick?.(event);
      }}
      sx={style}
      {...rest}
    >
      {children}
    </MuiButton>
  );
}
