import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../../components/card/Card';
import Link from '../../../../components/link/Link';
import {
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
  SALES_TAX,
} from '../../../../data/assumptions/ASSUMPTIONS';
import AssumptionLineItem from './AssumptionLineItem';

export default function PriceAssumptions() {
  const { formatMessage } = useIntl();

  return (
    <Card>
      <Box display="flex" flexDirection="column">
        <Typography variant="label1" mb={2}>
          {formatMessage({ id: 'compare.assumptions.header' })}
        </Typography>
        <AssumptionLineItem
          label={formatMessage({ id: 'compare.assumptions.salesTax' })}
          value={SALES_TAX.valueAsFormatted}
          link={SALES_TAX.link}
        />
        <AssumptionLineItem
          label={formatMessage({ id: 'compare.assumptions.downPayment' })}
          value={formatMessage(
            { id: 'compare.assumptions.downPaymentValue' },
            { percent: Math.round(DOWN_PAYMENT.value * 100) },
          )}
        />
        <AssumptionLineItem
          label={formatMessage({ id: 'compare.assumptions.electricityRateLabel' })}
          value={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted}
          link={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.link}
        />

        <Typography fontSize="0.75rem" mt={2}>
          {formatMessage(
            { id: 'compare.assumptions.electricityRate' },
            {
              rateLink: (
                <Link
                  to="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV2%20(Sch).pdf"
                  external
                  sx={{ fontWeight: 'normal', fontSize: '0.75rem', textDecoration: 'underline' }}
                >
                  Rate EV-2A
                </Link>
              ),
            },
          )}
        </Typography>
      </Box>
    </Card>
  );
}
