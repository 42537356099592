import SelectedChargingStationCard from '../selectedChargingStationCard/SelectedChargingStationCard';
import ChargingStationsMapLegend from '../chargingStationsMapLegend/ChargingStationsMapLegend';
import { ChargingStation } from '../../../../types';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import Card from '../../../card/Card';
import isSuperChargingStation from '../../../../utils/predicates/isSuperChargingStation';

type Props = {
  chargingStations?: Array<ChargingStation>;
  selectedStation?: ChargingStation | null;
  children?: ReactNode;
};

export default function MapControlPanel({
  chargingStations,
  selectedStation,
  children,
}: Props): JSX.Element {
  const hasSuperChargerStations =
    (chargingStations || []).filter((station) => isSuperChargingStation(station)).length > 0;

  return (
    <Box display="flex" flexDirection="column" gap="1rem" justifyContent="stretch">
      <Card>
        <ChargingStationsMapLegend hasSuperChargerStations={hasSuperChargerStations} />
      </Card>
      {selectedStation && (
        <Card>
          <SelectedChargingStationCard station={selectedStation} />
        </Card>
      )}
      {children}
    </Box>
  );
}
