import { useState, useRef } from 'react';

import useGeoJsonUrls from '../../hooks/useGeojsonUrls';

import type { PspsEvent } from '../../../../types';
import GaTracker from '../../../../utils/GaTracker/GaTracker';
import { formatVehicleDisplayName, formatVehicleName } from '../../../../utils/formatters';
import setupGoogleMapsAutocomplete from '../../../../utils/setupGoogleMapsAutocomplete';
import { Box } from '@mui/material';
import BaseMapLegend from '../mapLegend/MapLegend';
import PspsEventLegend from '../pspsEventLegend/PspsEventLegend';
import BaseGoogleMap from '../baseGoogleMap/BaseGoogleMap';
import Loading from '../../../loading/Loading';
import Button from '../../../button/Button';
import useElectricVehicles from '../../../../api/hooks/useElectricVehicles';
import TextInput from '../../../textInput/TextInput';
import Select from '../../../select/Select';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { useIntl } from 'react-intl';

const INITIAL_CENTER = {
  lat: 37.7916858,
  lng: -122.397855,
};

function RangeIcon({
  borderColor,
  backgroundColor,
}: {
  borderColor: string;
  backgroundColor: string;
}): JSX.Element {
  return (
    <Box
      display="inline-block"
      width="1rem"
      height="1rem"
      borderRadius="50%"
      marginRight="0.5rem"
      border="2px solid"
      borderColor={borderColor}
      sx={{ backgroundColor: backgroundColor }}
    />
  );
}

const MapLegend = ({
  pspsEvents,
  currentElectricRange,
  currentTotalRange,
}: {
  pspsEvents: Array<PspsEvent>;
  currentElectricRange?: number;
  currentTotalRange?: number;
}): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const parts = [];

  if (pspsEvents.length > 0) {
    parts.push(<PspsEventLegend />);
  }

  if (currentElectricRange) {
    parts.push(
      <Box display="flex" alignItems="center">
        <RangeIcon borderColor="orange.300" backgroundColor="orange.200" />
        {formatMessage({ id: 'maps.travelRadiusMap.fullBatteryRange' }, { currentElectricRange })}
      </Box>,
    );
  }

  if (currentTotalRange && currentElectricRange && currentTotalRange > currentElectricRange) {
    parts.push(
      <Box display="flex" alignItems="center">
        <RangeIcon borderColor="blue.500" backgroundColor="blue.200" />
        {formatMessage({ id: 'maps.travelRadiusMap.fullGasRange' }, { currentTotalRange })}
      </Box>,
    );
  }

  if (parts.length > 0) {
    return <BaseMapLegend>{parts}</BaseMapLegend>;
  } else {
    return null;
  }
};

const TravelRadiusMap = ({
  isVisible = true,
  pspsEvents = [],
  showVehicleSelector = true,
}: {
  isVisible?: boolean;
  pspsEvents?: Array<PspsEvent>;
  showVehicleSelector?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { vehicles: electricVehicles } = useElectricVehicles();
  const [address, setAddress] = useState('San Francisco, CA, USA');
  const [geocodeError, setGeocodeError] = useState<string>();
  const [isGeocoding, setIsGeocoding] = useState(false);
  // Tracking as state (instead of calculating from currentVehicle) because
  // we want to reset/update this when fetching the zipcode data and not when
  // we select a vehicle.
  // Stored as an object (instead of separate values) to reduce re-renders
  const [{ currentElectricRange, currentTotalRange }, setRangeInMi] = useState<{
    currentElectricRange?: number;
    currentTotalRange?: number;
  }>({});

  const mapRef = useRef<google.maps.Map | null>(null);
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);
  const electricCircleRef = useRef<google.maps.Circle | null>(null);
  const totalCircleRef = useRef<google.maps.Circle | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const vehicleIdForTravelRadius = userPrefs.comparedVehicleId1
    ? userPrefs.comparedVehicleId1
    : electricVehicles && electricVehicles.length
    ? electricVehicles[Math.floor(Math.random() * electricVehicles.length)].handle
    : null;

  const registerGeojsonUrlMap = useGeoJsonUrls(pspsEvents.flatMap((event) => event.fileUrls));

  const currentVehicle = (electricVehicles || []).find(
    (v) => v.handle === vehicleIdForTravelRadius,
  );

  const fetchAddress = (isTrackingEvent = true) => {
    setIsGeocoding(true);
    setGeocodeError(undefined);
    setRangeInMi({
      currentElectricRange: undefined,
      currentTotalRange: undefined,
    });
    if (isTrackingEvent) {
      GaTracker.trackEvent({
        category: 'Maps',
        action: 'Searched Travel Radius',
        label: currentVehicle ? formatVehicleName(currentVehicle) : '',
      });
    }
    geocoderRef.current?.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === 'OK' && results) {
          renderMapWithCircles(results[0]);
        } else {
          setGeocodeError('There was an error geocoding this address');
        }
        setIsGeocoding(false);
      },
    );
  };

  const onCompleteAddress = () => {
    const newAddress = autocompleteRef.current?.getPlace().formatted_address;
    setAddress(newAddress || '');
  };

  const handleGoogleMapsApiLoaded = (map: google.maps.Map) => {
    mapRef.current = map;
    geocoderRef.current = new google.maps.Geocoder();
    totalCircleRef.current = new google.maps.Circle({
      strokeColor: '#07679b',
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: '#07679b',
      fillOpacity: 0.1,
      center: INITIAL_CENTER,
      radius: 0,
    });
    electricCircleRef.current = new google.maps.Circle({
      strokeColor: '#f2c06c',
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: '#f2c06c',
      fillOpacity: 0.5,
      center: INITIAL_CENTER,
      radius: 0,
    });
    const newAutocomplete = setupGoogleMapsAutocomplete(
      'travel-radius-map-address-input',
      onCompleteAddress,
    );
    if (newAutocomplete) {
      autocompleteRef.current = newAutocomplete;
    }
    fetchAddress();

    registerGeojsonUrlMap(map);
  };

  const renderCircle = (
    map: google.maps.Map,
    circle: google.maps.Circle,
    lat: number,
    lng: number,
    radiusInMeters: number,
  ) => {
    circle.setCenter({
      lat,
      lng,
    });

    circle.setRadius(radiusInMeters);
    circle.setMap(map);
  };

  const renderMapWithCircles = (result: google.maps.GeocoderResult) => {
    const {
      geometry: {
        location: { lat, lng },
      },
    } = result;
    const electricRadiusInMeters = currentVehicle ? currentVehicle.electricRange * 1609 : 0;
    const totalRadiusInMeters = currentVehicle ? currentVehicle.totalRange * 1609 : 0;

    mapRef.current &&
      electricCircleRef.current &&
      renderCircle(mapRef.current, electricCircleRef.current, lat(), lng(), electricRadiusInMeters);

    const isTotalRangeLarger = totalRadiusInMeters > electricRadiusInMeters;

    if (mapRef.current && totalCircleRef.current && isTotalRangeLarger) {
      renderCircle(mapRef.current, totalCircleRef.current, lat(), lng(), totalRadiusInMeters);
    }

    const bounds = electricCircleRef.current?.getBounds();
    bounds && mapRef.current?.fitBounds(bounds);
    setRangeInMi({
      currentElectricRange: currentVehicle?.electricRange,
      currentTotalRange: currentVehicle?.totalRange,
    });
  };

  const isVehicleSelectable = showVehicleSelector && (electricVehicles || []).length > 1;

  const isSubmittingAllowed = () => vehicleIdForTravelRadius && address && !isGeocoding;

  return !electricVehicles ? (
    <Loading />
  ) : (
    <Box display="flex" gap="1rem" flexDirection="column">
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretch', sm: 'flex-end' }}
        gap="1rem"
      >
        <Box flex="1 1" maxWidth="50%">
          <TextInput
            fullWidth
            label={formatMessage({ id: 'common.address' })}
            id="travel-radius-map-address-input"
            onChange={(value) => setAddress(value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && isSubmittingAllowed()) {
                fetchAddress();
              }
            }}
            value={address}
            disabled={isGeocoding}
          />
        </Box>
        {isVehicleSelectable && (
          <Box flex="1 1">
            <Select
              fullWidth
              value={vehicleIdForTravelRadius || ''}
              onChange={(handle) => setUserPrefs({ comparedVehicleId1: handle })}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && isSubmittingAllowed()) {
                  fetchAddress();
                }
              }}
              disabled={isGeocoding}
              label={formatMessage({ id: 'common.vehicle' })}
              options={[
                { label: 'Select a vehicle', value: '' },
                ...(electricVehicles || []).map((ev) => ({
                  label: formatVehicleDisplayName(ev),
                  value: ev.handle,
                })),
              ]}
            />
          </Box>
        )}
        <Button onClick={() => fetchAddress()} disabled={!isSubmittingAllowed()}>
          {formatMessage({ id: 'maps.travelRadiusMap.viewTravelRadius' })}
        </Button>
      </Box>
      {geocodeError && (
        <Box display="flex" gap="1rem" color="red">
          <p>We could not find that address. Please revise your search and try again.</p>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="1rem">
        <Box flex="1 1" minWidth="270px">
          <Box position="relative" gap="1rem">
            {isVisible && <BaseGoogleMap onLoad={handleGoogleMapsApiLoaded} />}
            <MapLegend
              pspsEvents={pspsEvents}
              currentElectricRange={currentElectricRange}
              currentTotalRange={currentTotalRange}
            />
          </Box>
          <p>{formatMessage({ id: 'maps.travelRadiusMap.disclaimer' })}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default TravelRadiusMap;
