import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { useIntl } from 'react-intl';
import { MaintenanceCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import isElectricVehicle from '../../../../calculations/vehicle/isElectricVehicle';
import isPHEV from '../../../../calculations/vehicle/isPHEV';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import {
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  AVERAGE_MSRP,
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
} from '../../../../data/assumptions/ASSUMPTIONS';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import {
  formatAsCentsDetailed,
  formatAsDollars,
  formatAsPercentRounded,
  formatAsThousands,
  formatVehicleDisplayName,
} from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

export default function MaintenanceTable({ vehicle1, vehicle2, vehicle1Tco, vehicle2Tco }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: { milesDrivenAnnually, monthsOfOwnership, electricMilesPortionForPhev },
  } = useUserPrefs();

  const lifetimeMiles = (milesDrivenAnnually * monthsOfOwnership) / 12;

  const renderPHEVFuelSplitAdjustment = (fuelType: string) => {
    if (!['BEV', 'PHEV'].includes(fuelType.toUpperCase())) return 'N/A';
    return ['PHEV'].includes(fuelType.toUpperCase())
      ? formatAsPercentRounded(electricMilesPortionForPhev)
      : '100%';
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.description' })}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle1)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle2)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.source' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.msrp' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1.msrp)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2.msrp)}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.maintenance.maintenancePerMileGas' })}
            </TableCell>
            <TableCell>
              {formatAsCentsDetailed(MaintenanceCost.maintenanceCostPerGasMile(vehicle1))}
            </TableCell>
            <TableCell>
              {formatAsCentsDetailed(MaintenanceCost.maintenanceCostPerGasMile(vehicle2))}
            </TableCell>
            <TableCell>
              {formatMessage(
                { id: 'compare.table.maintenance.maintenancePerMileGasExplainer' },
                {
                  costPerMile: AVERAGE_MAINTENANCE_COST_PER_MILE.valueAsFormatted,
                  avgMsrp: AVERAGE_MSRP.valueAsFormatted,
                },
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.maintenance.evCostReduction' })}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle1)
                ? ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.valueAsFormatted
                : 'N/A'}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle2)
                ? ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.valueAsFormatted
                : 'N/A'}
            </TableCell>
            <TableCell>
              <a
                href={ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage({ id: 'compare.table.maintenance.consumerReports' })}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.maintenance.maintenancePerMileElec' })}
            </TableCell>
            <TableCell>
              {formatAsCentsDetailed(MaintenanceCost.maintenanceCostPerElectricMile(vehicle1))}
            </TableCell>
            <TableCell>
              {formatAsCentsDetailed(MaintenanceCost.maintenanceCostPerElectricMile(vehicle2))}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.maintenance.maintenancePerMileElecExplainer' })}
            </TableCell>
          </TableRow>
          {(isPHEV(vehicle1) || isPHEV(vehicle2)) && (
            <>
              <TableRow>
                <TableCell component="th" scope="row">
                  {formatMessage({ id: 'compare.table.phevPortion' })}
                </TableCell>
                <TableCell>{renderPHEVFuelSplitAdjustment(vehicle1.fuelType)}</TableCell>
                <TableCell>{renderPHEVFuelSplitAdjustment(vehicle2.fuelType)}</TableCell>
                <TableCell>{formatMessage({ id: 'compare.table.phevPortionExplainer' })}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {formatMessage({ id: 'compare.table.maintenance.blendedMaintenancePerMile' })}
                </TableCell>
                <TableCell>{formatAsCentsDetailed(vehicle1Tco.maintenance.perMile)}</TableCell>
                <TableCell>{formatAsCentsDetailed(vehicle2Tco.maintenance.perMile)}</TableCell>
                <TableCell>
                  {formatMessage({
                    id: 'compare.table.maintenance.blendedMaintenancePerMileExplainer',
                  })}
                </TableCell>
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.lifetimeMiles' })}
            </TableCell>
            <TableCell colSpan={2}>{formatAsThousands(lifetimeMiles)}</TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.userInput' })}</TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.maintenance.totalMaintenanceCost' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1Tco.maintenance.total)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2Tco.maintenance.total)}</TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.maintenance.totalMaintenanceCostExplainer' })}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
