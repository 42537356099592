// From
// https://docs.google.com/spreadsheets/d/1RJtyf84pvfsJCVcscMf6O89wZYLr1pC_i_S_tKf7TTM/edit#gid=0

export const SOLAR_GHI = [
  0, 0, 0, 0, 0, 0, 0, 0, 67, 223, 362, 459, 500, 481, 406, 283, 120, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 73, 237, 380, 482, 522, 505, 429, 300, 140, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 67, 226, 369, 467, 511, 495, 420, 296, 139, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 66,
  225, 369, 469, 513, 497, 423, 299, 142, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 226, 370,
  471, 515, 498, 423, 299, 142, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 60, 210, 352, 450,
  492, 474, 397, 277, 129, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 59, 208, 344, 440, 486, 474,
  404, 286, 134, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 64, 220, 362, 462, 506, 490, 417, 295,
  143, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 61, 212, 352, 451, 498, 487, 419, 303, 150, 12,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 61, 214, 355, 458, 508, 496, 425, 305, 152, 15, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 65, 223, 364, 468, 517, 503, 432, 310, 155, 16, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 64, 219, 357, 455, 501, 492, 423, 303, 150, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 64, 220, 363, 466, 516, 505, 436, 316, 161, 18, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 67, 226, 371, 473, 522, 511, 441, 320, 164, 19, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  63, 218, 364, 468, 517, 503, 433, 311, 158, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 224,
  366, 467, 515, 504, 436, 318, 164, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 68, 226, 368,
  466, 514, 503, 433, 315, 163, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 66, 227, 377, 483,
  532, 523, 455, 335, 178, 26, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 71, 232, 376, 482, 534,
  522, 454, 334, 179, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 73, 235, 380, 487, 538, 529,
  460, 340, 183, 31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 71, 230, 380, 488, 541, 530, 465,
  344, 186, 32, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 250, 403, 511, 563, 555, 485, 361,
  200, 38, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 84, 254, 407, 514, 567, 555, 485, 362, 201, 39,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 83, 254, 407, 517, 571, 561, 491, 366, 204, 43, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 86, 259, 415, 527, 582, 575, 506, 381, 216, 48, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 91, 267, 422, 536, 592, 584, 513, 387, 221, 51, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 92, 267, 424, 536, 592, 584, 514, 389, 224, 54, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 93, 268, 419, 532, 586, 576, 507, 384, 220, 53, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  92, 264, 421, 533, 590, 584, 515, 391, 228, 58, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 91, 259,
  412, 522, 577, 569, 501, 380, 220, 56, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 95, 266, 419,
  528, 582, 573, 505, 384, 224, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 87, 251, 397, 504,
  562, 567, 502, 386, 227, 62, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100, 274, 427, 533, 585,
  577, 510, 390, 231, 64, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 97, 266, 421, 534, 591, 589,
  519, 392, 227, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 102, 276, 432, 543, 599, 598, 531,
  408, 245, 73, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 101, 268, 435, 551, 610, 601, 535, 411,
  246, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 117, 304, 468, 586, 644, 636, 565, 438, 268,
  84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 118, 301, 463, 578, 636, 628, 559, 433, 265, 84, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120, 302, 464, 580, 640, 636, 569, 443, 273, 89, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 122, 304, 462, 573, 628, 620, 551, 427, 261, 84, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 2, 124, 307, 465, 580, 637, 631, 562, 435, 267, 86, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 2, 124, 304, 476, 596, 656, 651, 581, 454, 284, 98, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 2, 133, 320, 482, 601, 660, 654, 586, 461, 289, 101, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 3, 139, 326, 490, 604, 660, 651, 580, 455, 286, 102, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  4, 128, 306, 463, 575, 627, 619, 555, 436, 272, 96, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 141,
  321, 473, 582, 654, 663, 600, 473, 300, 109, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 130, 306,
  488, 606, 664, 659, 590, 466, 299, 114, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 146, 334, 499,
  616, 674, 668, 600, 474, 304, 117, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 155, 347, 516, 634,
  692, 686, 614, 485, 313, 122, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 175, 372, 542, 660, 718,
  711, 638, 507, 330, 132, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 173, 369, 537, 655, 712, 704,
  633, 502, 325, 128, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 164, 353, 522, 644, 706, 699, 628,
  498, 323, 130, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 183, 381, 550, 670, 730, 723, 650, 517,
  339, 141, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 19, 194, 390, 558, 674, 732, 724, 649, 519, 343,
  145, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18, 183, 373, 535, 650, 707, 697, 627, 500, 329, 138,
  3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 19, 185, 377, 539, 656, 713, 702, 631, 503, 331, 140, 5, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 22, 187, 377, 538, 653, 711, 695, 621, 494, 325, 138, 5, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 23, 188, 376, 538, 653, 709, 700, 627, 498, 328, 140, 5, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 29, 207, 402, 555, 672, 728, 715, 641, 511, 339, 148, 6, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 31, 212, 408, 571, 690, 747, 738, 664, 534, 358, 161, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 36, 218, 414, 578, 692, 745, 735, 662, 532, 358, 161, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 35, 211, 403, 566, 681, 739, 727, 656, 528, 353, 158, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 39, 219, 414, 577, 692, 749, 741, 670, 541, 366, 168, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  43, 224, 420, 583, 702, 762, 752, 678, 547, 370, 171, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 43,
  218, 407, 571, 692, 751, 744, 673, 545, 369, 171, 13, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 51, 236,
  429, 595, 710, 766, 758, 686, 554, 377, 177, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 53, 241, 440,
  606, 723, 780, 771, 696, 564, 385, 184, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 62, 259, 460, 624,
  740, 796, 787, 713, 577, 395, 188, 19, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 62, 250, 444, 608, 726,
  779, 762, 688, 557, 380, 182, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 63, 255, 454, 621, 736, 790,
  780, 703, 569, 390, 190, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 74, 276, 480, 649, 767, 823, 812,
  733, 596, 413, 205, 25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 77, 275, 469, 635, 757, 820, 813, 734,
  597, 414, 207, 26, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 87, 296, 503, 672, 788, 843, 830, 750, 610,
  425, 216, 29, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 89, 297, 503, 672, 788, 843, 826, 746, 605, 421,
  211, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 88, 291, 491, 661, 777, 830, 818, 740, 605, 424, 217,
  31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 95, 300, 502, 670, 784, 836, 824, 745, 608, 426, 220, 34,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 99, 303, 505, 672, 788, 842, 826, 746, 609, 426, 219, 35, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 102, 307, 510, 677, 789, 840, 824, 743, 606, 425, 219, 35, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 99, 299, 499, 669, 785, 836, 822, 743, 606, 423, 217, 35, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 104, 305, 503, 667, 782, 837, 825, 749, 615, 435, 231, 41, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 116, 324, 527, 690, 805, 858, 844, 765, 628, 445, 237, 46, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 113, 311, 506, 667, 776, 824, 809, 731, 598, 419, 218, 41, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 111, 316, 520, 688, 803, 857, 842, 764, 626, 442, 234, 46, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 1, 122, 327, 528, 684, 800, 855, 840, 759, 621, 438, 231, 47, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 127, 327, 520, 678, 787, 834, 811, 733, 600, 423, 225, 47, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 2, 136, 346, 547, 708, 818, 866, 850, 768, 630, 448, 241, 52, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 3, 137, 344, 543, 705, 818, 871, 855, 779, 644, 461, 253, 57, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  4, 140, 346, 546, 707, 819, 868, 854, 774, 636, 454, 248, 57, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
  156, 369, 570, 736, 846, 894, 874, 791, 651, 467, 257, 61, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7,
  159, 366, 561, 730, 842, 890, 872, 791, 652, 467, 255, 62, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9,
  165, 376, 576, 737, 844, 889, 871, 787, 648, 463, 256, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8,
  151, 349, 540, 697, 803, 850, 837, 764, 632, 454, 250, 62, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11,
  164, 369, 564, 724, 832, 882, 866, 786, 650, 468, 263, 68, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13,
  170, 376, 567, 722, 826, 871, 851, 771, 636, 456, 252, 64, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15,
  178, 388, 584, 742, 851, 898, 876, 790, 649, 468, 263, 71, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16,
  178, 384, 580, 738, 843, 891, 872, 798, 660, 476, 269, 74, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 17,
  177, 382, 574, 730, 836, 882, 864, 782, 645, 466, 263, 72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 19,
  182, 386, 575, 735, 839, 884, 868, 782, 639, 459, 256, 71, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21,
  181, 380, 570, 725, 828, 873, 854, 773, 640, 463, 264, 76, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28,
  210, 425, 625, 782, 888, 932, 913, 826, 682, 496, 284, 84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30,
  210, 424, 626, 796, 903, 945, 921, 831, 688, 500, 288, 85, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30,
  203, 409, 599, 751, 856, 904, 887, 804, 666, 485, 280, 86, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 35,
  217, 429, 625, 782, 886, 930, 915, 832, 692, 508, 297, 93, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37,
  217, 425, 619, 778, 880, 921, 898, 812, 673, 492, 289, 93, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 42,
  223, 431, 620, 777, 880, 924, 906, 823, 679, 495, 289, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 43,
  225, 434, 624, 775, 868, 907, 887, 803, 665, 486, 285, 92, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 49,
  236, 449, 646, 800, 905, 948, 924, 837, 693, 509, 300, 99, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 51,
  239, 450, 645, 805, 908, 950, 928, 844, 704, 520, 312, 108, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56,
  246, 457, 650, 802, 903, 942, 920, 834, 693, 510, 304, 104, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 58,
  248, 460, 654, 808, 908, 948, 924, 838, 698, 516, 309, 107, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 55,
  239, 452, 652, 817, 926, 971, 948, 859, 712, 522, 306, 103, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 61,
  250, 463, 660, 826, 929, 970, 946, 860, 718, 534, 324, 116, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70,
  268, 482, 676, 831, 934, 976, 951, 864, 723, 536, 324, 119, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 68,
  256, 464, 653, 809, 907, 945, 921, 835, 697, 518, 315, 116, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70,
  257, 463, 651, 807, 907, 948, 926, 843, 706, 526, 323, 121, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 76,
  272, 483, 675, 830, 932, 973, 952, 869, 729, 546, 336, 128, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 80,
  274, 485, 676, 832, 931, 968, 944, 856, 716, 534, 330, 127, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 83,
  280, 494, 688, 838, 940, 981, 961, 875, 733, 548, 337, 130, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81,
  273, 484, 677, 830, 929, 969, 950, 865, 724, 541, 333, 128, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 83,
  272, 477, 665, 824, 923, 965, 943, 857, 717, 535, 328, 127, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 87,
  282, 494, 688, 845, 948, 986, 961, 875, 730, 546, 336, 133, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93,
  290, 502, 694, 847, 947, 985, 961, 875, 734, 552, 346, 141, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 101,
  305, 520, 715, 869, 971, 1010, 984, 896, 754, 568, 354, 143, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100,
  299, 512, 704, 860, 960, 998, 971, 883, 743, 559, 351, 144, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 104,
  305, 516, 707, 854, 954, 994, 973, 888, 748, 566, 358, 150, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 107,
  306, 516, 706, 859, 957, 995, 958, 872, 733, 551, 343, 140, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 105,
  301, 507, 693, 831, 928, 967, 947, 864, 728, 549, 348, 147, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 110,
  305, 510, 697, 848, 945, 984, 951, 865, 726, 547, 345, 146, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 109,
  301, 503, 687, 831, 928, 967, 940, 856, 719, 543, 343, 146, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 110,
  301, 502, 685, 829, 925, 965, 938, 856, 723, 546, 346, 148, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 115,
  313, 519, 705, 858, 953, 988, 960, 873, 733, 553, 350, 150, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 119,
  316, 523, 710, 854, 952, 990, 966, 883, 746, 568, 365, 161, 13, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 121,
  319, 525, 712, 863, 960, 997, 970, 886, 750, 571, 369, 165, 14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 124,
  321, 523, 705, 832, 928, 967, 957, 874, 740, 563, 364, 163, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 125,
  321, 523, 705, 847, 938, 971, 939, 862, 730, 556, 361, 163, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 122,
  314, 515, 696, 833, 927, 966, 941, 854, 716, 543, 350, 157, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 125,
  314, 509, 690, 832, 932, 973, 944, 862, 726, 550, 352, 159, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 128,
  323, 527, 717, 871, 968, 1006, 974, 891, 755, 577, 376, 173, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5,
  133, 327, 526, 705, 848, 941, 975, 946, 864, 735, 563, 367, 170, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5,
  129, 316, 518, 719, 876, 977, 1013, 981, 894, 756, 579, 379, 178, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  8, 143, 343, 547, 730, 874, 969, 1007, 982, 901, 766, 590, 388, 183, 24, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 7, 132, 324, 522, 701, 845, 939, 979, 966, 879, 737, 559, 358, 167, 21, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 9, 145, 344, 549, 732, 872, 967, 1003, 973, 889, 753, 577, 377, 177, 25, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 9, 141, 334, 535, 715, 861, 955, 992, 957, 875, 741, 568, 373, 177, 25, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 8, 137, 328, 527, 706, 848, 942, 979, 959, 878, 746, 574, 380, 184, 28, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 9, 146, 346, 551, 735, 887, 981, 1015, 992, 909, 771, 593, 389, 187, 29, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 9, 139, 334, 537, 722, 872, 964, 997, 961, 880, 749, 577, 383, 186, 29, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 10, 144, 337, 536, 716, 839, 932, 971, 959, 881, 752, 580, 386, 189, 31, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 11, 146, 340, 540, 720, 869, 964, 1001, 975, 896, 765, 594, 397, 196, 32, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 11, 142, 332, 528, 705, 848, 941, 977, 959, 883, 757, 588, 395, 196, 34, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 12, 147, 341, 540, 721, 865, 961, 999, 979, 898, 767, 594, 397, 198, 36, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 12, 147, 338, 535, 713, 858, 954, 992, 968, 889, 759, 589, 394, 197, 37, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 12, 147, 337, 532, 710, 858, 952, 991, 968, 888, 758, 588, 394, 197, 38,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 147, 337, 533, 710, 857, 950, 988, 965, 885, 756, 587, 395, 199,
  39, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 152, 347, 548, 729, 869, 964, 1003, 983, 904, 774, 603, 408,
  209, 43, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15, 160, 358, 560, 739, 883, 975, 1012, 990, 910, 780, 609,
  414, 213, 45, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 157, 352, 552, 732, 873, 970, 1011, 993, 916, 786,
  615, 419, 218, 47, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 163, 362, 564, 746, 889, 982, 1016, 992, 911,
  780, 609, 413, 214, 47, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 166, 369, 574, 760, 904, 1000, 1039, 1017,
  935, 800, 626, 427, 223, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 17, 172, 378, 587, 771, 918, 1014, 1049,
  1021, 936, 803, 628, 429, 225, 51, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 163, 361, 562, 741, 882, 974,
  1010, 987, 909, 780, 610, 414, 215, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15, 155, 349, 547, 726, 870,
  963, 1002, 979, 901, 772, 604, 411, 213, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15, 159, 355, 556, 736,
  879, 973, 1011, 986, 907, 779, 610, 416, 218, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15, 155, 347, 543,
  722, 867, 963, 1004, 987, 909, 781, 613, 421, 223, 53, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 161, 357,
  558, 739, 883, 978, 1015, 990, 909, 779, 608, 412, 212, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 155,
  347, 546, 727, 867, 965, 1005, 989, 913, 787, 619, 426, 226, 54, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15,
  158, 352, 553, 733, 878, 974, 1013, 993, 916, 787, 617, 422, 222, 53, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  15, 157, 350, 549, 730, 866, 963, 1004, 986, 911, 784, 616, 422, 223, 54, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 14, 150, 340, 537, 717, 866, 964, 1006, 983, 907, 779, 611, 418, 221, 54, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 15, 156, 348, 544, 723, 866, 966, 1009, 989, 915, 791, 624, 434, 234, 60, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 16, 163, 359, 559, 740, 879, 976, 1017, 1002, 925, 797, 629, 435, 234, 60, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 14, 150, 340, 536, 717, 871, 971, 1015, 998, 922, 794, 626, 433, 232, 58, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 16, 163, 361, 562, 744, 884, 982, 1025, 1014, 938, 812, 641, 443, 239, 61, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 15, 159, 357, 560, 743, 889, 985, 1025, 1005, 929, 801, 633, 440, 238, 61, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 15, 160, 358, 560, 741, 883, 981, 1022, 1005, 930, 802, 635, 441, 238, 62, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 15, 160, 358, 561, 744, 893, 991, 1033, 1008, 933, 806, 637, 442, 239, 62,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 159, 356, 558, 740, 879, 978, 1021, 1006, 933, 807, 639, 444, 240,
  63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 157, 350, 547, 726, 869, 969, 1014, 998, 926, 801, 634, 442,
  240, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 159, 355, 554, 736, 877, 974, 1015, 991, 917, 793, 627,
  436, 235, 61, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 154, 351, 554, 739, 882, 983, 1028, 1010, 935, 810,
  642, 447, 244, 64, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14, 161, 362, 566, 751, 898, 998, 1041, 1021, 947,
  820, 649, 452, 245, 65, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 155, 352, 554, 736, 871, 970, 1014, 1005,
  932, 808, 640, 445, 241, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 152, 349, 552, 735, 881, 980, 1022,
  1007, 934, 807, 639, 444, 241, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 152, 348, 548, 730, 873, 972,
  1014, 995, 921, 796, 629, 436, 235, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 147, 343, 545, 728, 876,
  976, 1019, 1002, 931, 808, 641, 447, 243, 63, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 144, 338, 539, 722,
  865, 964, 1009, 992, 919, 794, 628, 435, 234, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 146, 341, 543,
  727, 870, 969, 1014, 996, 923, 795, 626, 428, 227, 55, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 135, 328,
  529, 712, 859, 960, 1005, 993, 920, 793, 624, 429, 230, 58, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 135,
  324, 522, 704, 842, 943, 990, 975, 907, 787, 624, 435, 235, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 136,
  327, 525, 706, 851, 949, 991, 971, 899, 777, 615, 427, 230, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 136,
  328, 525, 706, 841, 940, 985, 970, 899, 775, 610, 421, 224, 55, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 132,
  322, 518, 698, 847, 946, 990, 971, 902, 781, 618, 430, 232, 59, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 141,
  335, 534, 716, 855, 955, 1000, 981, 907, 782, 616, 424, 227, 56, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
  137, 331, 531, 713, 855, 957, 1002, 987, 916, 793, 627, 434, 233, 57, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  6, 137, 333, 534, 717, 860, 962, 1008, 990, 918, 796, 629, 436, 233, 57, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 5, 137, 332, 533, 716, 853, 953, 1000, 989, 917, 794, 627, 434, 232, 56, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 5, 132, 326, 527, 708, 853, 953, 996, 981, 908, 784, 619, 427, 228, 54, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 4, 123, 311, 510, 693, 839, 943, 990, 980, 912, 789, 622, 428, 225, 52, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 4, 122, 315, 516, 700, 850, 951, 996, 981, 909, 784, 618, 425, 224, 51, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 2, 121, 311, 510, 691, 825, 927, 973, 959, 888, 765, 600, 410, 213, 47, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 2, 115, 305, 506, 691, 846, 949, 996, 978, 906, 781, 614, 419, 218, 47, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 2, 115, 307, 510, 697, 849, 953, 1000, 986, 914, 789, 621, 425, 221, 48, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 118, 311, 512, 694, 837, 938, 984, 969, 897, 772, 607, 416, 216, 46, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 115, 308, 512, 698, 846, 950, 996, 981, 908, 783, 614, 419, 216, 45, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 114, 306, 507, 692, 834, 936, 982, 976, 905, 782, 615, 421, 218, 45, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 2, 116, 313, 519, 706, 855, 958, 1003, 990, 915, 789, 618, 420, 216, 43, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 107, 297, 496, 680, 811, 913, 958, 952, 881, 758, 591, 399, 201, 37, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 109, 304, 507, 694, 844, 947, 994, 978, 907, 781, 610, 412, 207, 38, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 108, 304, 511, 701, 850, 955, 1002, 989, 915, 787, 616, 416, 209, 37, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 101, 291, 495, 682, 839, 944, 992, 976, 904, 778, 608, 410, 205, 35, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 104, 299, 506, 695, 845, 952, 1001, 985, 912, 784, 612, 413, 206, 34, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 101, 295, 502, 693, 841, 948, 998, 985, 912, 785, 614, 413, 205, 33, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 101, 298, 505, 694, 846, 951, 997, 979, 904, 775, 603, 403, 197, 30, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 97, 290, 496, 683, 823, 925, 972, 964, 892, 765, 596, 400, 196, 29, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 95, 287, 493, 682, 836, 942, 990, 974, 900, 772, 601, 402, 196, 27, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 95, 289, 496, 685, 835, 941, 988, 972, 897, 765, 592, 392, 188, 24, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 90, 281, 485, 673, 828, 933, 982, 967, 894, 766, 594, 395, 190, 24, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 90, 285, 493, 682, 822, 926, 971, 953, 879, 750, 579, 381, 178, 20, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 84, 275, 481, 672, 825, 931, 978, 964, 890, 761, 588, 386, 180, 20, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 83, 275, 484, 674, 827, 933, 981, 966, 891, 761, 587, 385, 178, 18, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 82, 273, 481, 671, 826, 931, 979, 964, 889, 758, 584, 380, 173, 16, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 76, 260, 460, 645, 797, 899, 945, 927, 854, 728, 559, 364, 165, 15, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 72, 255, 457, 643, 790, 895, 943, 924, 853, 728, 558, 364, 163, 13, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 73, 260, 465, 653, 813, 917, 965, 949, 876, 747, 574, 373, 168, 12, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 75, 266, 474, 664, 813, 920, 968, 957, 882, 752, 577, 375, 167, 11, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 74, 267, 476, 668, 825, 931, 979, 962, 885, 754, 577, 373, 164, 10, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 73, 268, 479, 670, 824, 929, 976, 959, 881, 748, 570, 365, 157, 9, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 69, 261, 471, 664, 818, 924, 973, 957, 882, 748, 571, 367, 157, 8, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 67, 260, 471, 666, 825, 932, 979, 963, 882, 747, 567, 358, 150, 6, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 64, 251, 457, 647, 796, 900, 947, 933, 859, 729, 554, 352, 146, 6, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 63, 252, 460, 651, 806, 910, 955, 937, 859, 725, 548, 345, 140, 5, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 63, 253, 462, 654, 796, 902, 948, 930, 852, 720, 543, 341, 137, 3, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 60, 251, 462, 656, 804, 910, 957, 935, 854, 720, 542, 338, 132, 2, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 54, 238, 445, 634, 783, 885, 928, 905, 824, 689, 514, 314, 118, 2, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 48, 225, 427, 616, 777, 881, 927, 912, 835, 704, 528, 326, 124, 2, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 51, 233, 438, 627, 780, 883, 928, 909, 830, 696, 520, 318, 118, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 47, 227, 430, 618, 762, 864, 909, 895, 817, 684, 509, 309, 111, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 47, 228, 435, 626, 783, 887, 932, 911, 831, 696, 517, 313, 112, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 48, 234, 444, 637, 788, 893, 937, 914, 832, 694, 513, 306, 105, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 42, 217, 419, 606, 758, 861, 904, 883, 803, 669, 493, 293, 98, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 39, 210, 408, 592, 738, 840, 883, 863, 785, 653, 478, 281, 91, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 39, 216, 423, 615, 770, 876, 922, 902, 823, 687, 506, 300, 98, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 40, 226, 437, 631, 781, 886, 930, 909, 826, 688, 505, 298, 95, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 38, 224, 435, 629, 781, 884, 926, 906, 823, 685, 503, 294, 92, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 37, 219, 426, 616, 767, 868, 908, 886, 802, 664, 484, 280, 84, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 34, 212, 417, 605, 750, 851, 893, 872, 790, 654, 476, 274, 80, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  34, 216, 424, 615, 767, 868, 908, 885, 801, 662, 481, 275, 78, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  31, 205, 408, 594, 736, 835, 875, 848, 765, 629, 451, 253, 67, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  27, 201, 407, 596, 749, 850, 891, 867, 784, 647, 466, 263, 69, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  28, 209, 420, 613, 763, 866, 909, 885, 800, 658, 474, 265, 68, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  26, 201, 408, 598, 735, 837, 879, 865, 781, 638, 454, 247, 58, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  24, 196, 402, 594, 749, 851, 891, 866, 781, 641, 456, 250, 58, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  23, 193, 400, 591, 739, 838, 876, 851, 766, 626, 445, 241, 52, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  21, 187, 392, 582, 736, 836, 876, 850, 764, 623, 439, 233, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  18, 182, 386, 577, 736, 835, 876, 851, 765, 622, 438, 232, 46, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  18, 186, 393, 584, 737, 838, 878, 852, 765, 622, 436, 229, 43, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  17, 184, 393, 587, 742, 844, 884, 856, 765, 619, 431, 222, 37, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  17, 185, 395, 589, 742, 844, 883, 856, 768, 624, 436, 227, 38, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  16, 183, 392, 584, 736, 835, 870, 840, 749, 605, 419, 211, 32, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  14, 169, 370, 558, 682, 799, 849, 826, 736, 590, 406, 203, 27, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  12, 173, 380, 572, 726, 825, 862, 835, 745, 600, 412, 205, 26, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  11, 164, 364, 550, 689, 791, 832, 808, 723, 582, 397, 193, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  11, 169, 376, 567, 719, 818, 854, 822, 731, 584, 397, 190, 19, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9,
  159, 361, 550, 703, 800, 835, 806, 714, 569, 385, 183, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10,
  165, 370, 560, 711, 809, 844, 814, 720, 573, 386, 181, 16, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9,
  157, 358, 545, 692, 786, 820, 785, 694, 551, 367, 168, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
  152, 353, 541, 694, 791, 827, 799, 707, 561, 375, 171, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
  155, 358, 546, 695, 789, 822, 791, 698, 551, 366, 163, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
  147, 344, 530, 679, 774, 809, 780, 689, 544, 358, 158, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 143,
  341, 526, 674, 769, 802, 768, 675, 530, 346, 147, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 140, 339,
  525, 669, 763, 798, 765, 675, 532, 347, 146, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 143, 349, 539,
  687, 785, 819, 785, 689, 537, 346, 142, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 141, 344, 532, 677,
  773, 806, 767, 668, 518, 330, 132, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 139, 343, 533, 680, 775,
  807, 770, 674, 524, 335, 134, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 135, 339, 530, 681, 777, 809,
  777, 680, 529, 338, 134, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 135, 339, 528, 676, 770, 802, 769,
  674, 524, 333, 129, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 130, 329, 515, 660, 755, 789, 760, 668,
  518, 327, 123, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 132, 336, 525, 670, 765, 796, 762, 664, 513,
  321, 119, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 125, 326, 513, 661, 756, 787, 752, 655, 504, 314,
  114, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 123, 322, 508, 653, 746, 776, 741, 642, 491, 302, 105,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 117, 315, 501, 650, 743, 773, 738, 641, 490, 302, 104, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 118, 320, 507, 651, 743, 770, 732, 634, 483, 294, 97, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120, 326, 517, 663, 762, 794, 757, 656, 501, 306, 102, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 120, 325, 515, 666, 762, 793, 756, 652, 492, 296, 93, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 98, 292, 479, 627, 722, 754, 712, 615, 466, 275, 81, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 101, 299, 487, 635, 729, 758, 726, 624, 466, 274, 79, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 100, 296, 484, 629, 720, 749, 707, 608, 456, 267, 75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 100, 296, 481, 627, 719, 747, 710, 609, 456, 267, 74, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  97, 292, 476, 617, 710, 739, 702, 602, 448, 259, 69, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 91,
  280, 461, 599, 688, 714, 676, 577, 427, 244, 62, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 87, 278,
  462, 605, 694, 719, 678, 578, 427, 244, 61, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 82, 265, 442,
  581, 669, 697, 658, 563, 417, 236, 56, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 77, 258, 433, 576,
  665, 693, 647, 548, 402, 224, 51, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 261, 437, 573, 660,
  685, 647, 550, 403, 224, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 79, 265, 444, 587, 676, 704,
  667, 568, 418, 232, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 77, 264, 444, 583, 671, 696, 655,
  555, 405, 222, 45, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 71, 253, 429, 569, 655, 682, 644, 546,
  398, 216, 40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70, 254, 434, 573, 661, 686, 646, 546, 395,
  211, 37, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 66, 248, 427, 568, 655, 679, 644, 543, 389, 204,
  31, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 56, 231, 409, 561, 647, 673, 636, 537, 386, 202, 31, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 256, 441, 588, 676, 698, 663, 555, 397, 209, 32, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 64, 251, 434, 576, 664, 687, 645, 538, 379, 193, 24, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 53, 225, 401, 539, 630, 661, 627, 532, 384, 200, 28, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 58, 240, 419, 557, 643, 669, 631, 530, 378, 193, 24, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 53, 235, 417, 556, 645, 675, 636, 534, 379, 192, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 50, 231, 413, 557, 645, 671, 632, 530, 376, 190, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  46, 221, 397, 545, 634, 661, 621, 520, 368, 183, 18, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 44,
  216, 391, 529, 615, 639, 601, 503, 355, 175, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 39, 209,
  383, 519, 604, 629, 591, 492, 344, 167, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 35, 202, 377,
  520, 606, 630, 592, 493, 344, 164, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 33, 195, 365, 502,
  589, 614, 576, 480, 333, 158, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 32, 194, 365, 500, 584,
  607, 570, 472, 326, 152, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28, 189, 360, 499, 583, 606,
  571, 474, 328, 154, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 28, 191, 364, 499, 584, 610, 572,
  476, 329, 153, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 183, 352, 483, 567, 591, 555, 460,
  317, 145, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 24, 180, 347, 476, 559, 584, 545, 450, 307,
  138, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 173, 341, 473, 557, 582, 547, 452, 308, 138, 6,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18, 166, 331, 463, 544, 568, 533, 441, 299, 131, 5, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 17, 166, 332, 459, 542, 566, 531, 437, 297, 131, 5, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 16, 156, 318, 442, 526, 553, 522, 430, 292, 128, 5, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 16, 161, 326, 456, 541, 566, 529, 435, 295, 128, 3, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 15, 159, 325, 458, 542, 569, 535, 442, 301, 131, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 12, 159, 326, 458, 542, 569, 531, 437, 295, 127, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 12, 156, 325, 457, 540, 562, 524, 429, 287, 120, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10,
  150, 316, 450, 535, 561, 524, 430, 289, 122, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 138,
  300, 433, 516, 542, 507, 415, 277, 115, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 134, 294,
  427, 512, 539, 508, 416, 277, 115, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 134, 296, 431,
  517, 545, 512, 420, 281, 116, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 138, 304, 439, 524,
  550, 515, 425, 285, 119, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 140, 307, 442, 528, 552,
  517, 422, 281, 116, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 122, 278, 403, 487, 511, 477,
  389, 257, 101, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 107, 265, 404, 492, 521, 491, 400,
  262, 103, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 117, 276, 412, 497, 525, 489, 399, 263,
  104, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 121, 284, 418, 502, 530, 493, 404, 269, 108, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 113, 268, 396, 478, 502, 472, 387, 253, 99, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 99, 250, 378, 461, 488, 454, 368, 239, 92, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 100, 252, 380, 465, 495, 462, 376, 247, 96, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 99, 251, 383, 467, 493, 464, 379, 249, 98, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 94, 243, 367, 451, 483, 450, 368, 242, 93, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 99,
  252, 378, 463, 493, 464, 379, 250, 98, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100, 256, 387,
  473, 503, 467, 382, 252, 99, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 94, 246, 371, 452, 481,
  452, 370, 246, 97, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 93, 246, 377, 464, 495, 464, 380,
  252, 99, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 94, 251, 383, 471, 503, 473, 390, 259, 102,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 90, 247, 383, 470, 502, 470, 385, 255, 100, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 83, 230, 356, 441, 473, 448, 370, 245, 97, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 84, 232, 354, 442, 476, 451, 372, 247, 99, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 81, 230, 360, 447, 478, 452, 371, 245, 97, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 87, 246, 381, 472, 506, 479, 393, 260, 104, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  84, 241, 377, 468, 502, 476, 392, 262, 107, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 83, 242,
  380, 473, 510, 485, 404, 273, 113, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 81, 240, 380, 473,
  509, 481, 397, 264, 107, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 70, 218, 348, 435, 469, 448,
  368, 245, 98, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 71, 219, 351, 442, 478, 455, 377, 253,
  104, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 74, 228, 364, 456, 494, 470, 391, 264, 111, 2,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 73, 228, 366, 460, 496, 473, 392, 264, 111, 2, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 69, 219, 353, 445, 483, 463, 388, 264, 112, 2, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 74, 231, 368, 464, 505, 482, 401, 273, 116, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 70, 224, 363, 456, 495, 475, 397, 271, 116, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 67, 221, 359, 454, 491, 470, 392, 268, 116, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 72,
  230, 372, 470, 511, 491, 412, 282, 124, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 71, 230, 372,
  468, 508, 486, 409, 281, 124, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 67, 222, 362, 458, 499,
  481, 405, 280, 125, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 63, 213, 348, 440, 480, 453, 381,
  262, 115, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 63, 215, 356, 454, 496, 480, 405, 282, 128,
  5, 0, 0, 0, 0, 0, 0,
];
