import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../../components/card/Card';
import ButtonLink from '../../../../components/buttonLink/ButtonLink';

export default function FinancialAssistancePrograms() {
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      flexWrap="wrap"
      gap="2rem"
      maxWidth="990px"
      mx="auto"
    >
      <Box flex="3 1">
        <Typography component="h2" fontSize="2rem" fontWeight="500" lineHeight="1.2" mb="0.5rem">
          {formatMessage({ id: 'incomeQualified.financialAssistance.headline' })}
        </Typography>
        <Typography fontSize="1.25rem" fontWeight="300">
          {formatMessage({ id: 'incomeQualified.financialAssistance.description' })}
        </Typography>
      </Box>
      <Box flex="1 1" minWidth="300px">
        <Card>
          <Box display="flex" flexDirection="column" gap="16px">
            <ButtonLink
              variant="contained"
              to="https://www.pge.com/en_US/residential/save-energy-money/help-paying-your-bill/longer-term-assistance/care/care.page?WT.mc_id=Vanity_carefera"
              external
              sx={{ mt: '12px' }}
            >
              {formatMessage({ id: 'incomeQualified.financialAssistance.careAndFera' })}
            </ButtonLink>
            <ButtonLink
              variant="contained"
              to="https://www.pge.com/en_US/about-pge/company-information/protective-protocols/financial-support-awareness.page?WT.mc_id=Vanity_billhelpadvocate"
              external
              sx={{ mt: '12px' }}
            >
              {formatMessage({ id: 'incomeQualified.financialAssistance.financialAssistance' })}
            </ButtonLink>
            <Box fontSize="0.85rem">
              {formatMessage({ id: 'incomeQualified.financialAssistance.formDetails' })}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
