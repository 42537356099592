import type { Incentive } from '../../types/incentive';
import { useEffect, useState } from 'react';
import { fetchRecords } from '../../utils/fetch';
import { transformApiIncentive } from '../transformers/transformIncentive';
import { getValidZipcode, useUserPrefs } from '../../context/UserPrefsProvider';
import { useLanguage } from '../../utils/ReactIntlProvider';

const filterIncentives = (incentives: Array<Incentive>) => {
  const allEligible = incentives.filter((incentive) => incentive.available);

  return {
    allEligible,
    allIncentives: incentives,
  };
};

const fetchIncentives = async (params: { [key: string]: string }) => {
  return fetchRecords('/incentives', {
    keep_unavailable_incentives: 'true',
    ...params,
  });
};

const useIncentives = () => {
  const { zrLanguage } = useLanguage();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [incentives, setIncentives] = useState<ReturnType<typeof filterIncentives> | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string>();

  const zipcode = getValidZipcode(userPrefs);

  useEffect(() => {
    const fetch = async () => {
      try {
        setStatus('loading');
        incentives && setIncentives(null);
        const data = await fetchIncentives({
          postcode: zipcode,
          tax_filing_type: userPrefs?.taxFilingStatus,
          household_size: userPrefs?.householdSize.toString(),
          turn_in_clunker: 'yes',
          household_income:
            userPrefs?.householdIncome === 0 ? '1' : userPrefs?.householdIncome?.toString(),
          lang: zrLanguage,
        });
        setIncentives(filterIncentives(data.incentives.map(transformApiIncentive)));
        setStatus('idle');
      } catch (err: any) {
        if (typeof err === 'object' && err.message.includes('postcode')) {
          setUserPrefs({ zipcodeError: true });
        }
        setStatus('error');
        setError(`Could not load incentives: ${err}`);
      }
    };
    if (!incentives && status === 'idle') {
      fetch();
    }
  }, [
    incentives,
    setUserPrefs,
    status,
    userPrefs?.householdIncome,
    userPrefs?.householdSize,
    userPrefs?.taxFilingStatus,
    zipcode,
    zrLanguage,
  ]);

  return {
    incentives,
    status,
    error,
  };
};

export default useIncentives;
