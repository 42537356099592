import { useState, useRef } from 'react';
import { transformChargingStation } from '../../../api/transformers/transformChargingStation';
import { Bounds, ChargingStation, Coords } from '../../../types';
import haversineDistance from '../../../utils/Helpers/haversineDistance';

const NEAREST_URL = 'https://developer.nrel.gov/api/alt-fuel-stations/v1/nearest.json';

const fetchData = (additionalParams: Record<string, string>, signal?: AbortSignal) => {
  const params: Record<string, string> = {
    // method: 'GET',
    // headers: {
    //   Accept: 'application/json',
    // },
    fuel_type: 'ELEC',
    format: 'JSON',
    // NREL API doesn't handle preflight CORS requests properly, so we have to
    // include the API key as a GET query param instead of as a header
    api_key: process.env.REACT_APP_NREL_API_KEY || '',
    radius: '25',
    limit: 'all',
    ...additionalParams,
  };
  const url = new URL(NEAREST_URL);

  const searchParams = new URLSearchParams(params);

  url.search = searchParams.toString();

  return window.fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    signal,
  });
};

const areBoundsWithinCurrentArea = (
  bounds: Bounds,
  currentCenter: Coords | undefined,
  currentRadiusInMi: number | undefined,
) => {
  if (!bounds.top || !bounds.left || !bounds.right || !bounds.bottom) return false;

  if (!currentCenter || !currentCenter.lat || !currentCenter.lng || !currentRadiusInMi)
    return false;

  const distances = boundsDistancesFromCenter(bounds, currentCenter);

  return !distances.some((distance) => distance >= currentRadiusInMi);
};

const calcNewRadiusInMi = (bounds: Bounds, newCenter: Coords) => {
  const distances = boundsDistancesFromCenter(bounds, newCenter);

  const maxDistance = Math.max(...distances);

  // The buffer reduces the number of requests that need to be made
  // to the NREL API. By grabbing stations outside of the immediate
  // visible boundary, stations don't need to be fetched when the
  // user scrolls around in small areas.
  const RADIUS_BUFFER_IN_MI = 15;
  const MAX_ALLOWED_RADIUS = 370; // If someone zooms way out, limit the request size. 370 is range of Tesla Model S.
  const bufferedDistance = (maxDistance || 0) + RADIUS_BUFFER_IN_MI;
  return Math.min(bufferedDistance, MAX_ALLOWED_RADIUS);
};

const boundsDistancesFromCenter = (bounds: Bounds, center: Coords) => {
  const coordinates = [
    { lat: bounds.top, lng: bounds.left },
    { lat: bounds.top, lng: bounds.right },
    { lat: bounds.bottom, lng: bounds.left },
    { lat: bounds.bottom, lng: bounds.right },
  ];

  return coordinates.map((coordinate) => haversineDistance(center, coordinate, true));
};

const useChargingStations = () => {
  const [loading, setLoading] = useState(false);
  const [chargingStations, setChargingStations] = useState<Array<ChargingStation>>([]);
  const [error, setError] = useState('');

  const [currentCenter, setCurrentCenter] = useState<Coords>();
  const [currentRadiusInMi, setCurrentRadiusInMi] = useState<number>();

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchChargingStations = async (
    { lat, lng, radius: userSuppliedRadius }: Coords & { radius?: number },
    bounds?: Bounds,
  ) => {
    if (!bounds || !lat || !lng) return;
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (areBoundsWithinCurrentArea(bounds, currentCenter, currentRadiusInMi)) return;

    try {
      abortControllerRef.current = new window.AbortController();
      setLoading(true);
      const newRadiusInMi = userSuppliedRadius || calcNewRadiusInMi(bounds, { lat, lng });

      const response = await fetchData(
        {
          latitude: lat.toString(),
          longitude: lng.toString(),
          radius: newRadiusInMi.toString(),
        },
        abortControllerRef.current.signal,
      );
      const json = await response.json();

      abortControllerRef.current = null;

      setLoading(false);
      setCurrentCenter({ lat, lng });
      setCurrentRadiusInMi(newRadiusInMi);
      if (json) {
        setChargingStations(json.fuel_stations.map(transformChargingStation));
      }
    } catch (error: any) {
      setLoading(false);

      if (error.name !== 'AbortError') {
        setError(error);
      }
      abortControllerRef.current = null;
    }
  };

  return {
    error,
    chargingStations,
    fetchChargingStations,
    loading,
  };
};

export default useChargingStations;
