export const TERRITORY_BY_ZIPCODE: Record<string, string | undefined> = {
  '92304': 'R',
  '92365': 'R',
  '92704': 'Y',
  '93062': 'R',
  '93101': 'X',
  '93105': 'X',
  '93110': 'X',
  '93111': 'X',
  '93117': 'X',
  '93201': 'W',
  '93203': 'W',
  '93204': 'W',
  '93206': 'W',
  '93210': 'R',
  '93212': 'W',
  '93214': 'W',
  '93219': 'W',
  '93224': 'R',
  '93230': 'W',
  '93232': 'W',
  '93234': 'R',
  '93239': 'W',
  '93240': 'W',
  '93241': 'W',
  '93242': 'R',
  '93243': 'W',
  '93245': 'W',
  '93249': 'W',
  '93250': 'W',
  '93251': 'R',
  '93252': 'W',
  '93254': 'R',
  '93256': 'W',
  '93263': 'W',
  '93265': 'R',
  '93266': 'W',
  '93268': 'W',
  '93274': 'W',
  '93276': 'W',
  '93277': 'W',
  '93280': 'W',
  '93282': 'W',
  '93286': 'W',
  '93287': 'R',
  '93291': 'W',
  '93292': 'W',
  '93301': 'W',
  '93302': 'W',
  '93303': 'W',
  '93304': 'W',
  '93305': 'W',
  '93306': 'W',
  '93307': 'W',
  '93308': 'W',
  '93309': 'W',
  '93311': 'W',
  '93312': 'W',
  '93313': 'W',
  '93314': 'W',
  '93330': 'W',
  '93401': 'T',
  '93402': 'T',
  '93405': 'T',
  '93406': 'T',
  '93420': 'T',
  '93421': 'T',
  '93422': 'X',
  '93423': 'X',
  '93424': 'T',
  '93426': 'X',
  '93427': 'X',
  '93428': 'T',
  '93429': 'T',
  '93430': 'T',
  '93432': 'X',
  '93433': 'T',
  '93434': 'T',
  '93435': 'T',
  '93436': 'T',
  '93437': 'X',
  '93438': 'X',
  '93440': 'T',
  '93441': 'X',
  '93442': 'T',
  '93443': 'T',
  '93444': 'T',
  '93445': 'T',
  '93446': 'X',
  '93449': 'T',
  '93450': 'X',
  '93451': 'X',
  '93452': 'T',
  '93453': 'X',
  '93454': 'T',
  '93455': 'T',
  '93456': 'T',
  '93458': 'T',
  '93460': 'X',
  '93461': 'X',
  '93463': 'X',
  '93464': 'X',
  '93465': 'X',
  '93466': 'X',
  '93516': 'R',
  '93523': 'R',
  '93526': 'R',
  '93527': 'R',
  '93555': 'R',
  '93558': 'R',
  '93561': 'R',
  '93562': 'R',
  '93601': 'R',
  '93602': 'R',
  '93603': 'R',
  '93604': 'R',
  '93606': 'R',
  '93607': 'R',
  '93608': 'R',
  '93609': 'R',
  '93610': 'R',
  '93611': 'R',
  '93612': 'R',
  '93614': 'R',
  '93615': 'W',
  '93616': 'R',
  '93618': 'W',
  '93619': 'R',
  '93620': 'R',
  '93621': 'R',
  '93622': 'R',
  '93623': 'Y',
  '93624': 'R',
  '93625': 'R',
  '93626': 'R',
  '93627': 'R',
  '93628': 'Y',
  '93630': 'R',
  '93631': 'R',
  '93633': 'Z',
  '93634': 'Y',
  '93635': 'R',
  '93636': 'R',
  '93637': 'R',
  '93638': 'R',
  '93639': 'R',
  '93640': 'R',
  '93641': 'Y',
  '93643': 'R',
  '93644': 'R',
  '93645': 'R',
  '93646': 'R',
  '93647': 'W',
  '93648': 'R',
  '93650': 'R',
  '93651': 'R',
  '93652': 'R',
  '93653': 'R',
  '93654': 'R',
  '93656': 'R',
  '93657': 'R',
  '93660': 'R',
  '93662': 'R',
  '93664': 'Y',
  '93665': 'R',
  '93666': 'W',
  '93667': 'R',
  '93668': 'R',
  '93669': 'R',
  '93670': 'W',
  '93673': 'W',
  '93675': 'R',
  '93701': 'R',
  '93702': 'R',
  '93703': 'R',
  '93704': 'R',
  '93705': 'R',
  '93706': 'R',
  '93710': 'R',
  '93711': 'R',
  '93720': 'R',
  '93721': 'R',
  '93722': 'R',
  '93723': 'R',
  '93725': 'R',
  '93726': 'R',
  '93727': 'R',
  '93728': 'R',
  '93730': 'R',
  '93737': 'R',
  '93741': 'R',
  '93901': 'T',
  '93905': 'T',
  '93906': 'T',
  '93907': 'T',
  '93908': 'T',
  '93915': 'T',
  '93920': 'T',
  '93921': 'T',
  '93922': 'T',
  '93923': 'T',
  '93924': 'X',
  '93925': 'X',
  '93926': 'X',
  '93927': 'X',
  '93928': 'X',
  '93930': 'X',
  '93932': 'X',
  '93933': 'T',
  '93940': 'T',
  '93943': 'T',
  '93950': 'T',
  '93953': 'T',
  '93954': 'X',
  '93955': 'T',
  '93960': 'X',
  '93962': 'T',
  '94002': 'X',
  '94003': 'X',
  '94005': 'T',
  '94010': 'X',
  '94014': 'T',
  '94015': 'T',
  '94018': 'T',
  '94019': 'T',
  '94020': 'T',
  '94021': 'T',
  '94022': 'X',
  '94024': 'X',
  '94025': 'X',
  '94026': 'X',
  '94027': 'X',
  '94028': 'X',
  '94030': 'X',
  '94034': 'X',
  '94037': 'T',
  '94038': 'T',
  '94040': 'X',
  '94041': 'X',
  '94043': 'X',
  '94044': 'T',
  '94060': 'T',
  '94061': 'X',
  '94062': 'X',
  '94063': 'X',
  '94065': 'X',
  '94066': 'X',
  '94070': 'X',
  '94074': 'T',
  '94080': 'T',
  '94085': 'X',
  '94086': 'X',
  '94087': 'X',
  '94088': 'X',
  '94089': 'X',
  '94101': 'T',
  '94102': 'T',
  '94103': 'T',
  '94104': 'T',
  '94105': 'T',
  '94107': 'T',
  '94108': 'T',
  '94109': 'T',
  '94110': 'T',
  '94111': 'T',
  '94112': 'T',
  '94113': 'T',
  '94114': 'T',
  '94115': 'T',
  '94116': 'T',
  '94117': 'T',
  '94118': 'T',
  '94121': 'T',
  '94122': 'T',
  '94123': 'T',
  '94124': 'T',
  '94127': 'T',
  '94129': 'T',
  '94130': 'T',
  '94131': 'T',
  '94132': 'T',
  '94133': 'T',
  '94134': 'T',
  '94141': 'T',
  '94143': 'T',
  '94158': 'T',
  '94159': 'T',
  '94203': 'S',
  '94211': 'S',
  '94248': 'P',
  '94301': 'P',
  '94303': 'X',
  '94305': 'X',
  '94306': 'X',
  '94401': 'X',
  '94402': 'X',
  '94403': 'X',
  '94404': 'X',
  '94501': 'T',
  '94502': 'T',
  '94503': 'X',
  '94504': 'X',
  '94505': 'S',
  '94506': 'X',
  '94507': 'X',
  '94508': 'X',
  '94509': 'S',
  '94510': 'X',
  '94511': 'S',
  '94512': 'S',
  '94513': 'S',
  '94514': 'S',
  '94515': 'X',
  '94516': 'X',
  '94517': 'X',
  '94518': 'X',
  '94519': 'X',
  '94520': 'X',
  '94521': 'X',
  '94522': 'X',
  '94523': 'X',
  '94525': 'X',
  '94526': 'X',
  '94527': 'S',
  '94528': 'X',
  '94530': 'T',
  '94531': 'S',
  '94533': 'S',
  '94534': 'S',
  '94535': 'S',
  '94536': 'X',
  '94538': 'X',
  '94539': 'X',
  '94541': 'X',
  '94542': 'X',
  '94543': 'S',
  '94544': 'X',
  '94545': 'X',
  '94546': 'X',
  '94547': 'X',
  '94548': 'S',
  '94549': 'X',
  '94550': 'X',
  '94551': 'X',
  '94552': 'X',
  '94553': 'X',
  '94555': 'X',
  '94556': 'X',
  '94558': 'X',
  '94559': 'X',
  '94560': 'X',
  '94561': 'S',
  '94562': 'X',
  '94563': 'X',
  '94564': 'T',
  '94565': 'S',
  '94566': 'X',
  '94567': 'X',
  '94568': 'X',
  '94569': 'X',
  '94571': 'S',
  '94572': 'X',
  '94573': 'X',
  '94574': 'X',
  '94576': 'X',
  '94577': 'X',
  '94578': 'X',
  '94579': 'X',
  '94580': 'X',
  '94582': 'X',
  '94583': 'X',
  '94585': 'S',
  '94586': 'X',
  '94587': 'X',
  '94588': 'X',
  '94589': 'X',
  '94590': 'X',
  '94591': 'X',
  '94595': 'X',
  '94596': 'X',
  '94597': 'X',
  '94598': 'X',
  '94599': 'X',
  '94601': 'T',
  '94602': 'T',
  '94603': 'T',
  '94604': 'T',
  '94605': 'T',
  '94606': 'T',
  '94607': 'T',
  '94608': 'T',
  '94609': 'T',
  '94610': 'T',
  '94611': 'T',
  '94612': 'T',
  '94613': 'T',
  '94618': 'T',
  '94619': 'T',
  '94621': 'T',
  '94662': 'S',
  '94701': 'T',
  '94702': 'T',
  '94703': 'T',
  '94704': 'T',
  '94705': 'T',
  '94706': 'T',
  '94707': 'T',
  '94708': 'T',
  '94709': 'T',
  '94710': 'T',
  '94720': 'T',
  '94801': 'T',
  '94803': 'T',
  '94804': 'T',
  '94805': 'T',
  '94806': 'T',
  '94901': 'X',
  '94903': 'X',
  '94904': 'X',
  '94909': 'X',
  '94913': 'X',
  '94914': 'X',
  '94920': 'T',
  '94922': 'T',
  '94923': 'T',
  '94924': 'T',
  '94925': 'X',
  '94928': 'X',
  '94929': 'T',
  '94930': 'X',
  '94931': 'X',
  '94933': 'X',
  '94937': 'T',
  '94938': 'X',
  '94939': 'X',
  '94940': 'T',
  '94941': 'T',
  '94942': 'P',
  '94945': 'X',
  '94946': 'X',
  '94947': 'X',
  '94949': 'X',
  '94950': 'T',
  '94951': 'X',
  '94952': 'X',
  '94954': 'X',
  '94956': 'T',
  '94957': 'X',
  '94960': 'X',
  '94963': 'X',
  '94964': 'X',
  '94965': 'T',
  '94966': 'T',
  '94970': 'T',
  '94971': 'T',
  '94972': 'T',
  '94973': 'X',
  '95001': 'T',
  '95002': 'X',
  '95003': 'T',
  '95004': 'T',
  '95005': 'T',
  '95006': 'T',
  '95007': 'T',
  '95008': 'X',
  '95010': 'T',
  '95012': 'T',
  '95013': 'X',
  '95014': 'X',
  '95017': 'T',
  '95018': 'T',
  '95019': 'T',
  '95020': 'X',
  '95021': 'T',
  '95023': 'X',
  '95030': 'X',
  '95031': 'X',
  '95032': 'X',
  '95033': 'Q',
  '95035': 'X',
  '95037': 'X',
  '95038': 'X',
  '95039': 'T',
  '95041': 'T',
  '95043': 'X',
  '95044': 'X',
  '95045': 'X',
  '95046': 'X',
  '95050': 'X',
  '95051': 'X',
  '95054': 'X',
  '95060': 'T',
  '95062': 'T',
  '95063': 'T',
  '95064': 'T',
  '95065': 'T',
  '95066': 'T',
  '95067': 'T',
  '95070': 'X',
  '95073': 'T',
  '95075': 'X',
  '95076': 'T',
  '95078': 'T',
  '95101': 'X',
  '95103': 'X',
  '95110': 'X',
  '95111': 'X',
  '95112': 'X',
  '95113': 'X',
  '95116': 'X',
  '95117': 'X',
  '95118': 'X',
  '95119': 'X',
  '95120': 'X',
  '95121': 'X',
  '95122': 'X',
  '95123': 'X',
  '95124': 'X',
  '95125': 'X',
  '95126': 'X',
  '95127': 'X',
  '95128': 'X',
  '95129': 'X',
  '95130': 'X',
  '95131': 'X',
  '95132': 'X',
  '95133': 'X',
  '95134': 'X',
  '95135': 'X',
  '95136': 'X',
  '95138': 'X',
  '95139': 'X',
  '95140': 'X',
  '95141': 'X',
  '95148': 'X',
  '95150': 'X',
  '95158': 'X',
  '95201': 'S',
  '95202': 'S',
  '95203': 'S',
  '95204': 'S',
  '95205': 'S',
  '95206': 'S',
  '95207': 'S',
  '95209': 'S',
  '95210': 'S',
  '95212': 'S',
  '95215': 'S',
  '95219': 'S',
  '95220': 'S',
  '95221': 'Z',
  '95222': 'P',
  '95223': 'Y',
  '95224': 'Y',
  '95225': 'S',
  '95226': 'S',
  '95227': 'S',
  '95228': 'S',
  '95229': 'P',
  '95230': 'S',
  '95231': 'S',
  '95232': 'P',
  '95233': 'Y',
  '95234': 'S',
  '95236': 'S',
  '95237': 'S',
  '95240': 'S',
  '95241': 'X',
  '95242': 'S',
  '95245': 'P',
  '95246': 'P',
  '95247': 'P',
  '95248': 'P',
  '95249': 'S',
  '95250': 'P',
  '95251': 'P',
  '95252': 'S',
  '95253': 'S',
  '95254': 'S',
  '95255': 'P',
  '95257': 'P',
  '95258': 'S',
  '95301': 'R',
  '95303': 'R',
  '95304': 'S',
  '95305': 'P',
  '95306': 'R',
  '95307': 'S',
  '95309': 'S',
  '95310': 'P',
  '95311': 'R',
  '95312': 'R',
  '95313': 'S',
  '95315': 'R',
  '95316': 'S',
  '95317': 'R',
  '95318': 'R',
  '95319': 'S',
  '95320': 'S',
  '95321': 'P',
  '95322': 'R',
  '95323': 'S',
  '95324': 'R',
  '95325': 'R',
  '95326': 'S',
  '95327': 'P',
  '95328': 'S',
  '95329': 'R',
  '95330': 'S',
  '95333': 'R',
  '95334': 'R',
  '95335': 'Y',
  '95336': 'S',
  '95337': 'S',
  '95338': 'R',
  '95339': 'R',
  '95340': 'R',
  '95341': 'R',
  '95344': 'R',
  '95345': 'R',
  '95346': 'Y',
  '95347': 'R',
  '95348': 'R',
  '95350': 'S',
  '95351': 'S',
  '95352': 'S',
  '95353': 'R',
  '95354': 'S',
  '95355': 'S',
  '95356': 'S',
  '95357': 'S',
  '95358': 'S',
  '95360': 'S',
  '95361': 'S',
  '95363': 'S',
  '95364': 'Y',
  '95365': 'R',
  '95366': 'S',
  '95367': 'S',
  '95368': 'S',
  '95369': 'R',
  '95370': 'P',
  '95372': 'P',
  '95374': 'R',
  '95375': 'Y',
  '95376': 'S',
  '95377': 'S',
  '95378': 'S',
  '95379': 'P',
  '95380': 'S',
  '95381': 'S',
  '95382': 'S',
  '95383': 'Y',
  '95385': 'S',
  '95386': 'S',
  '95387': 'S',
  '95388': 'R',
  '95389': 'Y',
  '95391': 'S',
  '95401': 'X',
  '95402': 'X',
  '95403': 'X',
  '95404': 'X',
  '95405': 'X',
  '95407': 'X',
  '95409': 'X',
  '95410': 'T',
  '95412': 'T',
  '95414': 'X',
  '95415': 'X',
  '95416': 'X',
  '95417': 'X',
  '95418': 'X',
  '95419': 'X',
  '95420': 'T',
  '95421': 'X',
  '95422': 'P',
  '95423': 'P',
  '95424': 'P',
  '95425': 'X',
  '95426': 'P',
  '95427': 'X',
  '95428': 'X',
  '95429': 'X',
  '95430': 'T',
  '95432': 'T',
  '95433': 'X',
  '95434': 'S',
  '95435': 'P',
  '95436': 'X',
  '95437': 'T',
  '95439': 'X',
  '95441': 'X',
  '95442': 'X',
  '95443': 'P',
  '95444': 'X',
  '95445': 'T',
  '95446': 'X',
  '95448': 'X',
  '95449': 'X',
  '95450': 'T',
  '95451': 'P',
  '95452': 'X',
  '95453': 'P',
  '95454': 'X',
  '95456': 'T',
  '95457': 'P',
  '95458': 'P',
  '95459': 'T',
  '95460': 'T',
  '95461': 'P',
  '95462': 'X',
  '95463': 'X',
  '95464': 'P',
  '95465': 'X',
  '95466': 'X',
  '95467': 'P',
  '95468': 'T',
  '95469': 'X',
  '95470': 'X',
  '95471': 'X',
  '95472': 'X',
  '95474': 'X',
  '95476': 'X',
  '95480': 'T',
  '95481': 'X',
  '95482': 'X',
  '95485': 'P',
  '95486': 'X',
  '95488': 'T',
  '95490': 'X',
  '95492': 'X',
  '95493': 'P',
  '95494': 'X',
  '95497': 'T',
  '95501': 'V',
  '95502': 'V',
  '95503': 'V',
  '95511': 'Y',
  '95514': 'Y',
  '95518': 'V',
  '95519': 'V',
  '95521': 'V',
  '95524': 'V',
  '95525': 'V',
  '95526': 'Y',
  '95527': 'Y',
  '95528': 'V',
  '95536': 'V',
  '95537': 'V',
  '95540': 'V',
  '95542': 'Y',
  '95545': 'Y',
  '95546': 'Y',
  '95547': 'V',
  '95549': 'V',
  '95550': 'V',
  '95551': 'V',
  '95552': 'Y',
  '95553': 'Y',
  '95554': 'Y',
  '95555': 'V',
  '95556': 'Y',
  '95558': 'V',
  '95559': 'Y',
  '95560': 'Y',
  '95561': 'V',
  '95562': 'V',
  '95563': 'Y',
  '95564': 'V',
  '95565': 'V',
  '95568': 'Y',
  '95569': 'V',
  '95570': 'V',
  '95571': 'Y',
  '95573': 'Y',
  '95585': 'X',
  '95587': 'X',
  '95589': 'V',
  '95595': 'Y',
  '95601': 'S',
  '95602': 'P',
  '95603': 'S',
  '95604': 'S',
  '95605': 'S',
  '95606': 'S',
  '95607': 'S',
  '95608': 'S',
  '95610': 'S',
  '95612': 'S',
  '95613': 'S',
  '95614': 'P',
  '95615': 'S',
  '95616': 'S',
  '95617': 'S',
  '95618': 'S',
  '95619': 'P',
  '95620': 'S',
  '95621': 'S',
  '95623': 'P',
  '95624': 'S',
  '95625': 'S',
  '95626': 'S',
  '95627': 'S',
  '95628': 'S',
  '95629': 'P',
  '95630': 'S',
  '95631': 'P',
  '95632': 'S',
  '95633': 'P',
  '95634': 'P',
  '95635': 'P',
  '95636': 'Y',
  '95637': 'S',
  '95639': 'P',
  '95640': 'S',
  '95641': 'S',
  '95642': 'S',
  '95643': 'P',
  '95645': 'S',
  '95648': 'S',
  '95650': 'S',
  '95651': 'S',
  '95652': 'S',
  '95653': 'S',
  '95654': 'S',
  '95656': 'P',
  '95658': 'S',
  '95659': 'S',
  '95660': 'S',
  '95661': 'S',
  '95662': 'S',
  '95663': 'S',
  '95664': 'S',
  '95665': 'P',
  '95666': 'Y',
  '95667': 'P',
  '95668': 'S',
  '95669': 'S',
  '95670': 'S',
  '95672': 'S',
  '95673': 'S',
  '95674': 'S',
  '95675': 'P',
  '95676': 'S',
  '95677': 'S',
  '95678': 'S',
  '95679': 'S',
  '95680': 'S',
  '95681': 'S',
  '95682': 'S',
  '95684': 'P',
  '95685': 'S',
  '95686': 'S',
  '95687': 'S',
  '95688': 'S',
  '95689': 'Y',
  '95690': 'S',
  '95691': 'S',
  '95692': 'S',
  '95694': 'S',
  '95695': 'S',
  '95697': 'S',
  '95698': 'S',
  '95699': 'S',
  '95701': 'Y',
  '95703': 'P',
  '95709': 'Y',
  '95712': 'P',
  '95713': 'P',
  '95714': 'Y',
  '95715': 'Y',
  '95717': 'Y',
  '95720': 'Y',
  '95721': 'Z',
  '95722': 'P',
  '95724': 'Z',
  '95726': 'Y',
  '95728': 'Z',
  '95735': 'Z',
  '95736': 'P',
  '95742': 'S',
  '95746': 'S',
  '95747': 'S',
  '95757': 'S',
  '95758': 'S',
  '95762': 'S',
  '95765': 'S',
  '95776': 'S',
  '95811': 'S',
  '95812': 'S',
  '95814': 'S',
  '95815': 'S',
  '95816': 'S',
  '95817': 'S',
  '95818': 'S',
  '95819': 'S',
  '95820': 'S',
  '95821': 'S',
  '95822': 'S',
  '95823': 'S',
  '95824': 'S',
  '95825': 'S',
  '95826': 'S',
  '95827': 'S',
  '95828': 'S',
  '95829': 'S',
  '95830': 'S',
  '95831': 'S',
  '95832': 'S',
  '95833': 'S',
  '95834': 'S',
  '95835': 'S',
  '95837': 'S',
  '95838': 'S',
  '95841': 'S',
  '95842': 'S',
  '95843': 'S',
  '95864': 'S',
  '95901': 'S',
  '95903': 'S',
  '95910': 'Y',
  '95912': 'S',
  '95913': 'S',
  '95914': 'S',
  '95915': 'Y',
  '95916': 'P',
  '95917': 'S',
  '95918': 'S',
  '95919': 'P',
  '95920': 'S',
  '95922': 'P',
  '95923': 'Y',
  '95924': 'P',
  '95925': 'P',
  '95926': 'S',
  '95927': 'S',
  '95928': 'S',
  '95930': 'Y',
  '95932': 'S',
  '95934': 'Y',
  '95935': 'P',
  '95936': 'Y',
  '95937': 'S',
  '95938': 'S',
  '95939': 'S',
  '95940': 'P',
  '95941': 'P',
  '95942': 'P',
  '95943': 'S',
  '95944': 'Y',
  '95945': 'P',
  '95946': 'P',
  '95947': 'Y',
  '95948': 'S',
  '95949': 'P',
  '95950': 'S',
  '95951': 'S',
  '95953': 'S',
  '95954': 'P',
  '95955': 'S',
  '95956': 'Y',
  '95957': 'S',
  '95958': 'S',
  '95959': 'P',
  '95960': 'P',
  '95961': 'S',
  '95962': 'P',
  '95963': 'S',
  '95965': 'S',
  '95966': 'S',
  '95967': 'P',
  '95968': 'S',
  '95969': 'P',
  '95970': 'S',
  '95971': 'Y',
  '95972': 'S',
  '95973': 'S',
  '95974': 'S',
  '95975': 'P',
  '95977': 'P',
  '95978': 'Y',
  '95979': 'S',
  '95980': 'Y',
  '95981': 'Z',
  '95982': 'S',
  '95983': 'Y',
  '95984': 'Y',
  '95986': 'P',
  '95987': 'S',
  '95988': 'S',
  '95991': 'S',
  '95993': 'S',
  '96001': 'R',
  '96002': 'R',
  '96003': 'R',
  '96007': 'R',
  '96008': 'R',
  '96009': 'Y',
  '96010': 'Y',
  '96011': 'Y',
  '96013': 'Y',
  '96016': 'Y',
  '96019': 'R',
  '96020': 'Y',
  '96021': 'R',
  '96022': 'R',
  '96028': 'Y',
  '96029': 'R',
  '96033': 'R',
  '96035': 'R',
  '96040': 'Y',
  '96041': 'Y',
  '96047': 'R',
  '96051': 'R',
  '96055': 'R',
  '96056': 'Y',
  '96059': 'R',
  '96061': 'Z',
  '96062': 'R',
  '96063': 'Z',
  '96065': 'Y',
  '96068': 'Y',
  '96069': 'R',
  '96071': 'Z',
  '96073': 'R',
  '96074': 'R',
  '96075': 'Y',
  '96076': 'Y',
  '96078': 'R',
  '96080': 'R',
  '96084': 'Y',
  '96087': 'R',
  '96088': 'Y',
  '96089': 'R',
  '96090': 'R',
  '96092': 'R',
  '96095': 'R',
  '96096': 'Y',
  '96125': 'Y',
  '96137': 'Z',
};
