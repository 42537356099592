import translations, { TranslationType } from '../constants/translations';
import { difference, uniq } from 'lodash';

// From https://formatjs.io/docs/react-intl/upgrade-guide-2x/#flatten-messages-object
function flattenMessages(nestedMessages: TranslationType, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: Record<string, string>, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value; // eslint-disable-line
    } else if (value === null) {
      // react-intl will not allow empty strings so we have to put a space for translations set to null
      // https://github.com/formatjs/formatjs/issues/607
      messages[prefixedKey] = ' '; // eslint-disable-line
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const languages = Object.keys(translations).reduce((languageDict: Record<string, string>, key) => {
  return {
    ...languageDict,
    [key]: key,
  };
}, {});

const messages = Object.keys(translations).reduce(
  (messages: Record<string, Record<string, string>>, key) => {
    return {
      ...messages,
      [key]: flattenMessages(translations[key].translation),
    };
  },
  {},
);

type GapType = {
  key: string;
} & Record<string, string | null>;

/**
 * This returns an array describing missing translation data.
 *
 * The result is in this format:
 *
 * [
 *   {
 *     "key": "cost.hammer.carpet",
 *     "en" : "The cost of hammers is 2 carpets.",
 *     "es" : null,
 *     "zh" : null,
 *   },
 *   {
 *     "key": "some.thing.else",
 *     "en" : null,
 *     "es" : "Otra cosa",
 *     "zh" : null,
 *   },
 * ]
 */
const findTranslationGaps = (): GapType[] => {
  const languages = Object.keys(messages);
  const allKeys = uniq(languages.flatMap((lang) => Object.keys(messages[lang])));
  const missing: Record<string, GapType> = {};
  languages.forEach((lang) => {
    difference(allKeys, Object.keys(messages[lang]))
      .filter((key) => !missing[key])
      .forEach((key) => {
        missing[key] = { key };
        languages.forEach((lang) => {
          missing[key][lang] = messages[lang][key] || null;
        });
      });
  });
  return Object.values(missing);
};

export { messages, languages, findTranslationGaps };
