import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { useIntl } from 'react-intl';
import { ElectricityCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import isElectricVehicle from '../../../../calculations/vehicle/isElectricVehicle';
import isPHEV from '../../../../calculations/vehicle/isPHEV';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ELECTRICITY_RATE_IN_DOLLARS_PER_KWH } from '../../../../data/assumptions/ASSUMPTIONS';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import {
  formatAsCents,
  formatAsDollars,
  formatAsPercentRounded,
  formatAsThousands,
  formatVehicleDisplayName,
} from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

export default function ElectricityTable({ vehicle1, vehicle2, vehicle1Tco, vehicle2Tco }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: { milesDrivenAnnually, monthsOfOwnership, electricMilesPortionForPhev },
  } = useUserPrefs();

  const lifetimeMiles = (milesDrivenAnnually * monthsOfOwnership) / 12;

  const renderPHEVFuelSplitAdjustment = (fuelType: string) => {
    if (!['BEV', 'PHEV'].includes(fuelType.toUpperCase())) return 'N/A';
    return ['PHEV'].includes(fuelType.toUpperCase())
      ? formatAsPercentRounded(electricMilesPortionForPhev)
      : '100%';
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.description' })}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle1)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle2)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.source' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle1)
                ? ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted
                : 'N/A'}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle2)
                ? ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted
                : 'N/A'}
            </TableCell>
            <TableCell>
              {formatMessage(
                { id: 'compare.table.electricity.rateExplainer' },
                {
                  rateLink: (
                    <a href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV2%20(Sch).pdf">
                      Rate EV-2A
                    </a>
                  ),
                },
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.electricity.efficiency' })}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle1) ? vehicle1.electricEfficiency + ' kWh/100 mi' : 'N/A'}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle2) ? vehicle2.electricEfficiency + ' kWh/100 mi' : 'N/A'}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.electricity.efficiencyExplainer' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.electricity.costPer100Miles' })}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle1)
                ? formatAsCents(ElectricityCost.perHundredElectricMiles(vehicle1))
                : 'N/A'}
            </TableCell>
            <TableCell>
              {isElectricVehicle(vehicle2)
                ? formatAsCents(ElectricityCost.perHundredElectricMiles(vehicle2))
                : 'N/A'}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.electricity.costPer100MilesExplainer' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.lifetimeMiles' })}
            </TableCell>
            <TableCell colSpan={2}>{formatAsThousands(lifetimeMiles)}</TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.userInput' })}</TableCell>
          </TableRow>
          {(isPHEV(vehicle1) || isPHEV(vehicle2)) && (
            <TableRow>
              <TableCell component="th" scope="row">
                {formatMessage({ id: 'compare.table.phevPortion' })}
              </TableCell>
              <TableCell>{renderPHEVFuelSplitAdjustment(vehicle1.fuelType)}</TableCell>
              <TableCell>{renderPHEVFuelSplitAdjustment(vehicle2.fuelType)}</TableCell>
              <TableCell>{formatMessage({ id: 'compare.table.phevPortionExplainer' })}</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.electricity.totalElectricityCost' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1Tco.electricity.total)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2Tco.electricity.total)}</TableCell>
            <TableCell>
              {formatMessage({
                id:
                  isPHEV(vehicle1) || isPHEV(vehicle2)
                    ? 'compare.table.electricity.totalElectricityCostExplainerPHEV'
                    : 'compare.table.electricity.totalElectricityCostExplainer',
              })}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
