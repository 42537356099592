import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../components/card/Card';
import Select from '../../../components/select/Select';
import Slider from '../../../components/slider/Slider';
import Switch from '../../../components/switch/Switch';
import { useUserPrefs } from '../../../context/UserPrefsProvider';
import { PurchaseMethod, PURCHASE_METHODS } from '../../../types';
import { formatAsCents, formatAsThousands } from '../../../utils/formatters';

export default function FilterPanel({ isComparingPhev = false }: { isComparingPhev?: boolean }) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: {
      purchaseMethod,
      milesDrivenAnnually,
      electricMilesPortionForPhev,
      monthsOfOwnership,
      gasolinePriceInCentsPerGal,
      interestRateAsBasisPoints,
      includeResaleValue,
    },
    setUserPrefs,
  } = useUserPrefs();

  const purchaseMethods = PURCHASE_METHODS.map((method) => ({
    value: method,
    label: formatMessage({ id: `common.purchaseMethods.${method}` }),
  }));

  return (
    <Card>
      <Box display="flex" flexDirection="column">
        <Box mb={2} display="flex" flexDirection="column">
          <Select
            label={formatMessage({ id: 'compare.filters.purchaseMethod' })}
            value={purchaseMethod}
            options={purchaseMethods}
            onChange={(val) => setUserPrefs({ purchaseMethod: val as PurchaseMethod })}
          />
        </Box>

        <Box mb={2}>
          <Slider
            label={formatMessage({ id: 'compare.filters.annualMiles' })}
            value={milesDrivenAnnually}
            valueFormatter={formatAsThousands}
            valueSuffix={' ' + formatMessage({ id: 'common.miles' })}
            valueVariant="small"
            onChange={(val) => setUserPrefs({ milesDrivenAnnually: val })}
            min={1000}
            max={100000}
            step={1000}
          />
        </Box>

        {isComparingPhev && (
          <Box mb={2}>
            <Slider
              label={formatMessage({ id: 'compare.filters.phevElectricPortion' })}
              value={electricMilesPortionForPhev}
              valueSuffix="%"
              valueVariant="small"
              onChange={(val) => setUserPrefs({ electricMilesPortionForPhev: val })}
              min={0}
              max={100}
              step={5}
            />
          </Box>
        )}

        <Box mb={2}>
          <Slider
            label={formatMessage({ id: 'compare.filters.yearsOfOwnership' })}
            value={monthsOfOwnership}
            valueSuffix={' ' + formatMessage({ id: 'common.years' })}
            valueFormatter={(months) => `${Math.floor(months / 12)}`}
            valueVariant="small"
            onChange={(val) => setUserPrefs({ monthsOfOwnership: val })}
            min={12}
            max={240}
            step={12}
          />
        </Box>

        {(purchaseMethod === 'loan' || purchaseMethod === 'lease') && (
          <Box mb={2}>
            <Slider
              label={formatMessage({ id: 'compare.filters.interestRate' })}
              value={interestRateAsBasisPoints}
              valueSuffix="%"
              valueVariant="small"
              valueFormatter={(points) => (points / 100).toFixed(2)}
              onChange={(val) => setUserPrefs({ interestRateAsBasisPoints: val })}
              min={5}
              max={1000}
              step={5}
            />
          </Box>
        )}

        <Box mb={2}>
          <Slider
            label={formatMessage({ id: 'compare.filters.gasPrice' })}
            value={gasolinePriceInCentsPerGal}
            valueFormatter={(val) => formatAsCents(val / 100)}
            valueVariant="small"
            onChange={(val) => setUserPrefs({ gasolinePriceInCentsPerGal: val })}
            min={100}
            max={1000}
            step={10}
          />
        </Box>

        <Box>
          <Switch
            label={formatMessage({ id: 'compare.filters.includeResale' })}
            checked={includeResaleValue}
            onChange={(checked) => setUserPrefs({ includeResaleValue: checked })}
            labelPlacement="end"
          />
        </Box>
      </Box>
    </Card>
  );
}
