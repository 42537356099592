import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { times } from 'lodash';
import { useIntl } from 'react-intl';
import { VehicleCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import calcTotalCostOfOwnership from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import Card from '../../../../components/card/Card';
import CollapseToggleButton from '../../../../components/collapseToggleButton/CollapseToggleButton';
import Slider from '../../../../components/slider/Slider';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle, UserPrefs } from '../../../../types';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatAsDollars, formatVehicleDisplayName } from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
};

const CHART_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  elements: {
    line: {
      borderWidth: 5,
      tension: 0.000001,
    },
  },
  plugins: {
    filler: {
      propagate: false,
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (items) => `${items[0].label} ${items[0].parsed.x === 1 ? 'year' : 'years'}`,
        label: (item) => `${item.dataset.label}: ${formatAsDollars(item.raw as number)}`,
      },
      ...baseTooltipStyles,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        autoSkip: false,
        callback: (value) => `${value} ${value === 1 ? 'year' : 'years'}`,
      },
    },
    y: {
      ticks: {
        callback: (value) => formatAsDollars(typeof value === 'string' ? parseInt(value) : value),
      },
    },
  },
};

const breakevenCosts = (
  vehicle: ElectricVehicle | GasVehicle | EquivalentFossilVehicle,
  userPrefs: UserPrefs,
  yearsOfOwnership: number[],
) => {
  return yearsOfOwnership.map((year) => {
    switch (year) {
      case 0:
        return VehicleCost.afterIncentives(vehicle);

      case yearsOfOwnership.length - 1:
        return calcTotalCostOfOwnership(
          vehicle,
          'cash',
          year * 12,
          userPrefs.milesDrivenAnnually,
          userPrefs.interestRateAsBasisPoints,
          userPrefs.electricMilesPortionForPhev,
          userPrefs.gasolinePriceInCentsPerGal,
          userPrefs.includeResaleValue,
        ).summed.total;

      default:
        return calcTotalCostOfOwnership(
          vehicle,
          'cash',
          year * 12,
          userPrefs.milesDrivenAnnually,
          userPrefs.interestRateAsBasisPoints,
          userPrefs.electricMilesPortionForPhev,
          userPrefs.gasolinePriceInCentsPerGal,
          false, // do not include resale value in intermediate years
        ).summed.total;
    }
  });
};

export default function PaybackPeriodChart({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const theme = useTheme();

  const yearsOfOwnership = times(userPrefs.monthsOfOwnership / 12 + 1, (i) => i);
  const vehicle1Costs = breakevenCosts(vehicle1, userPrefs, yearsOfOwnership);
  const vehicle2Costs = breakevenCosts(vehicle2, userPrefs, yearsOfOwnership);

  const chartData = {
    labels: yearsOfOwnership,
    datasets: [
      {
        data: vehicle2Costs,
        label: formatVehicleDisplayName(vehicle2),
        lineTension: 0,
        backgroundColor: 'rgba(55, 173, 221,  0.6)',
        borderColor: theme.palette.blue[300],
        pointBackgroundColor: theme.palette.blue[300],
        fill: false, //no fill here
      },
      {
        data: vehicle1Costs,
        label: formatVehicleDisplayName(vehicle1),
        backgroundColor: 'rgba(55, 173, 221, 0.6)',
        borderColor: theme.palette.orange[400],
        pointBackgroundColor: theme.palette.orange[400],
        fill: '-1', //fill until previous dataset
        lineTension: 0,
      },
    ],
  };

  return (
    <Card>
      <Typography px={8} mb={2} textAlign="center">
        {formatMessage(
          { id: 'compare.charts.paybackPeriodHeader' },
          {
            vehicle1: (
              <Typography component="span" fontWeight="bold">
                {formatVehicleDisplayName(vehicle1)}{' '}
              </Typography>
            ),
            vehicle2: (
              <Typography component="span" fontWeight="bold">
                {formatVehicleDisplayName(vehicle2)}
              </Typography>
            ),
          },
        )}
      </Typography>

      <Box px={{ xs: 0, md: 6 }}>
        <ReactChart type="line" data={chartData} options={CHART_OPTIONS} height={250} />
      </Box>

      <Box display="flex" justifyContent="center" mt={1}>
        <Box display="flex" alignItems="center" mr={3}>
          <Box height={12} width={12} bgcolor="orange.400" mr={1} />
          <Typography fontSize="0.75rem" color="grey.400">
            {formatVehicleDisplayName(vehicle1)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box height={12} width={12} bgcolor="blue.300" mr={1} />
          <Typography fontSize="0.75rem" color="grey.400">
            {formatVehicleDisplayName(vehicle2)}
          </Typography>
        </Box>
      </Box>

      <Box mt={2} mb={2} px={{ xs: 0, md: 8 }}>
        <Slider
          label={formatMessage({ id: 'compare.filters.yearsOfOwnership' })}
          value={userPrefs.monthsOfOwnership}
          valueSuffix={' ' + formatMessage({ id: 'common.years' })}
          valueFormatter={(months) => `${Math.floor(months / 12)}`}
          onChange={(val) => setUserPrefs({ monthsOfOwnership: val })}
          valueVariant="small"
          min={12}
          max={240}
          step={12}
        />
      </Box>

      <CollapseToggleButton
        openText={formatMessage({ id: 'compare.charts.showGraphValues' })}
        closeText={formatMessage({ id: 'compare.charts.hideGraphValues' })}
        buttonPosition="center"
      >
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col" sx={{ fontWeight: 'bold' }}>
                  {formatMessage({ id: 'compare.charts.yearHeader' })}
                </TableCell>
                <TableCell component="th" scope="col" sx={{ fontWeight: 'bold' }}>
                  {formatVehicleDisplayName(vehicle1)}
                </TableCell>
                <TableCell component="th" scope="col" sx={{ fontWeight: 'bold' }}>
                  {formatVehicleDisplayName(vehicle2)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {yearsOfOwnership.map((year) => (
                <TableRow key={year}>
                  <TableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
                    {formatMessage({ id: 'compare.charts.year' }, { numYear: year })}
                  </TableCell>
                  <TableCell>{formatAsDollars(vehicle1Costs[year])}</TableCell>
                  <TableCell>{formatAsDollars(vehicle2Costs[year])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography fontStyle="italic" mt={2}>
            {formatMessage({ id: 'compare.charts.cashPurchaseDisclaimer' })}
          </Typography>
        </Box>
      </CollapseToggleButton>
    </Card>
  );
}
