import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import type { ChargingStation } from '../../../../types';
import Link from '../../../link/Link';

type Props = {
  station: ChargingStation;
};

export default function SelectedChargingStationCard({ station }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const { stationName, streetAddress, city, state, zip, stationPhone, evNetwork, evNetworkWeb } =
    station;

  return (
    <Box>
      <b>{stationName}</b>
      <br />
      {streetAddress && (
        <>
          {streetAddress}
          <br />
        </>
      )}
      {`${city}, ${state} ${zip}`}
      <br />
      {stationPhone}
      <br />
      {evNetwork && evNetworkWeb && (
        <Link to={evNetworkWeb} external={evNetworkWeb[0] === '/' ? undefined : true}>
          {evNetwork}
        </Link>
      )}
      {(!evNetwork || !evNetworkWeb) && (
        <em>{formatMessage({ id: 'maps.chargingStationNetworkUnavailable' })}</em>
      )}
    </Box>
  );
}
