import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { useIntl } from 'react-intl';
import { VehicleCost } from '../../../../calculations/vehicle/CostOfOwnership/calcs';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { DOWN_PAYMENT, SALES_TAX } from '../../../../data/assumptions/ASSUMPTIONS';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import {
  formatAsDollars,
  formatAsThousands,
  formatVehicleDisplayName,
} from '../../../../utils/formatters';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

export default function LoanPurchaseMethodTable({
  vehicle1,
  vehicle2,
  vehicle1Tco,
  vehicle2Tco,
}: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: {
      purchaseMethod,
      milesDrivenAnnually,
      monthsOfOwnership,
      interestRateAsBasisPoints,
    },
  } = useUserPrefs();

  const lifetimeMiles = (milesDrivenAnnually * monthsOfOwnership) / 12;

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.description' })}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle1)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatVehicleDisplayName(vehicle2)}
            </TableCell>
            <TableCell component="th" scope="col">
              {formatMessage({ id: 'compare.table.source' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.purchaseMethod' })}
            </TableCell>
            <TableCell colSpan={2}>
              {formatMessage({ id: 'common.purchaseMethods.loan' })}
            </TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.userInput' })}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.msrp' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1.msrp)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2.msrp)}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.salesTax' })}
            </TableCell>
            <TableCell colSpan={2}>{SALES_TAX.valueAsFormatted}</TableCell>
            <TableCell>
              <a href={SALES_TAX.link}>
                {formatMessage({ id: 'compare.table.salesTaxSourceText' })}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.downPayment' })}
            </TableCell>
            <TableCell>{formatAsDollars(VehicleCost.downPayment(vehicle1))}</TableCell>
            <TableCell>{formatAsDollars(VehicleCost.downPayment(vehicle2))}</TableCell>
            <TableCell>
              {formatMessage(
                { id: 'compare.table.purchase.assumption' },
                { assumption: DOWN_PAYMENT.valueAsFormatted },
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.loan' })}
            </TableCell>
            <TableCell>{formatAsDollars(VehicleCost.loanAmountToBeFinanced(vehicle1))}</TableCell>
            <TableCell>{formatAsDollars(VehicleCost.loanAmountToBeFinanced(vehicle2))}</TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.purchase.loanExplainer' })}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.monthlyPayments' })}
            </TableCell>
            <TableCell>
              {formatAsDollars(
                VehicleCost.monthlyLoanPayment(
                  vehicle1,
                  monthsOfOwnership,
                  interestRateAsBasisPoints,
                ),
              )}
            </TableCell>
            <TableCell>
              {formatAsDollars(
                VehicleCost.monthlyLoanPayment(
                  vehicle2,
                  monthsOfOwnership,
                  interestRateAsBasisPoints,
                ),
              )}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.purchase.monthlyPaymentsExplainer' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.monthsOfOwnership' })}
            </TableCell>
            <TableCell colSpan={2}>{formatAsThousands(monthsOfOwnership)}</TableCell>
            <TableCell>{formatMessage({ id: 'compare.table.userInput' })}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.totalMonthlyPayments' })}
            </TableCell>
            <TableCell>
              {formatAsDollars(
                VehicleCost.monthlyLoanPaymentsTotal(
                  vehicle1,
                  monthsOfOwnership,
                  interestRateAsBasisPoints,
                ),
              )}
            </TableCell>
            <TableCell>
              {formatAsDollars(
                VehicleCost.monthlyLoanPaymentsTotal(
                  vehicle2,
                  monthsOfOwnership,
                  interestRateAsBasisPoints,
                ),
              )}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.purchase.totalMonthlyPaymentsExplainer' })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.incentives' })}
            </TableCell>
            <TableCell>
              {formatAsDollars(VehicleCost.incentives(vehicle1, purchaseMethod))}
            </TableCell>
            <TableCell>
              {formatAsDollars(VehicleCost.incentives(vehicle2, purchaseMethod))}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.resale' })}
            </TableCell>
            <TableCell>
              {formatAsDollars(VehicleCost.resaleValue(vehicle1, lifetimeMiles))}
            </TableCell>
            <TableCell>
              {formatAsDollars(VehicleCost.resaleValue(vehicle2, lifetimeMiles))}
            </TableCell>
            <TableCell>
              {formatMessage(
                { id: 'compare.table.purchase.resaleExplainer' },
                { numMiles: formatAsThousands(lifetimeMiles) },
              )}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell component="th" scope="row">
              {formatMessage({ id: 'compare.table.purchase.totalCost' })}
            </TableCell>
            <TableCell>{formatAsDollars(vehicle1Tco.vehicle.total)}</TableCell>
            <TableCell>{formatAsDollars(vehicle2Tco.vehicle.total)}</TableCell>
            <TableCell>
              {formatMessage({ id: 'compare.table.purchase.totalCostExplainerLoan' })}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
