import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';
import { AvailableChargingPlugIcon } from '../../../components/icon/Vectors';
import Link from '../../../components/link/Link';
import type { TomTomChargingStation } from '../../../types';
import smoothScroll from '../../../utils/smoothScroll';

export default function ContactInfo({ charger }: { charger: TomTomChargingStation }) {
  const { formatMessage } = useIntl();

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Box display="flex" justifyContent="space-between" gap={2} mb={2}>
        <Box flex="1 1" display="flex" alignItems="center" mr={2}>
          <Box flexShrink={0} mr={1}>
            <AvailableChargingPlugIcon />
          </Box>
          <Typography fontWeight="500" fontSize="0.875rem">
            {charger.poi.name}
          </Typography>
        </Box>
        <Box flex="1 1">
          <Typography variant="label1" component="p">{`${charger.address.streetNumber || ''} ${
            charger.address.streetName
          }`}</Typography>
          <Typography
            variant="label1"
            component="p"
          >{`${charger.address.municipality}, ${charger.address.countrySubdivisionName} ${charger.address.postalCode}`}</Typography>
          <Typography variant="label1" component="p">
            {charger.phone}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="outlined" onClick={() => smoothScroll('charger-details-route-map')}>
          {formatMessage({ id: 'chargerDetails.routeToChargerButton' })}
        </Button>
        {charger.poi.url && (
          <Link
            to={/^https:/.test(charger.poi.url) ? charger.poi.url : `https://${charger.poi.url}`}
            external
          >
            <Button>{formatMessage({ id: 'chargerDetails.chargerWebsiteButton' })}</Button>
          </Link>
        )}
      </Box>
    </Card>
  );
}
