import { Box } from '@mui/material';
import Image from '../../../components/image/Image';
import { ElectricVehicle, GasVehicle } from '../../../types';
import PricePanel from './PricePanel';
import SelectVehicle from './SelectVehicle';
import VehicleDetails from './VehicleDetails';
import CloakedCar from '../../../assets/images/cloaked-car.png';

type Props = {
  vehicle?: ElectricVehicle | GasVehicle;
  vehicles: (ElectricVehicle | GasVehicle)[];
  onVehicleChange: (vehicleId?: string) => void;
};

const invalidImageUrls = [
  'https://assets.zappyride.com',
  'https://assets.zappyride.com/img/vehicles/NoImageAvailable.png',
];

export default function VehicleColumn({ vehicles, vehicle, onVehicleChange }: Props) {
  const images = vehicle?.images
    ? vehicle.images.filter((image) => image.urlFull && !invalidImageUrls.includes(image.urlFull))
    : [];

  const image = !vehicle
    ? { urlFull: '', altText: '' }
    : images.length > 0
    ? images[0]
    : { urlFull: CloakedCar, altText: vehicle.displayName };

  return (
    <Box>
      <SelectVehicle vehicle={vehicle} vehicles={vehicles} onVehicleChange={onVehicleChange} />
      <Image src={image.urlFull} alt={image.altText} height={220} width="auto" />
      <PricePanel vehicle={vehicle} />
      <VehicleDetails vehicle={vehicle} />
    </Box>
  );
}
