import type { RateKey, UserPrefs } from '../../types';
import calcElectricBill from './calcElectricBill';
import { LoadProfile } from '@bellawatt/electric-rate-engine';

export type ElectricBill = {
  name: RateKey;
  home: number;
  total: number;
  ev: number;
};

const getElectricBill = (
  rate: RateKey,
  homeLoadProfile: LoadProfile,
  totalLoadProfile: LoadProfile,
  userPrefs: UserPrefs,
): ElectricBill => {
  // Warning: EV costs cannot be calculated independently of the home load profile. This is because
  // of the existance of block rates where EV Load Profiles are not indepdendent variables (ie, a
  // first block exists with one price, and the second block is based on total usage and has another
  // price).
  const home = calcElectricBill(rate, homeLoadProfile, userPrefs);
  const total = calcElectricBill(rate, totalLoadProfile, userPrefs);
  return {
    name: rate,
    home: home,
    total: total,
    ev: total - home,
  };
};

export default getElectricBill;
