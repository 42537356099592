import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../../components/card/Card';
import { ElectricVehicle } from '../../../../types';
import { DCFC_CHARGING_RATE } from '../../../../utils/assumptions';
import { formatVehicleDisplayName } from '../../../../utils/formatters';
import AssumptionLineItem from './AssumptionLineItem';

type Props = {
  vehicle1: ElectricVehicle;
  vehicle2: ElectricVehicle;
};

export default function FastChargingAssumptions({ vehicle1, vehicle2 }: Props) {
  const { formatMessage } = useIntl();

  const isVehicle1BelowChargerRate = (vehicle1.dcChargingPower || 0) < DCFC_CHARGING_RATE;
  const isVehicle2BelowChargerRate = (vehicle2.dcChargingPower || 0) < DCFC_CHARGING_RATE;
  const isBothVehiclesAboveRate = !isVehicle1BelowChargerRate && !isVehicle2BelowChargerRate;

  const vehicle1ChargeRateMessage = isVehicle1BelowChargerRate
    ? formatMessage(
        { id: 'compare.assumptions.maxChargeRateDisclaimer' },
        { vehicle: formatVehicleDisplayName(vehicle1), chargeRate: vehicle1.dcChargingPower },
      )
    : formatMessage(
        { id: 'compare.assumptions.normalChargeRateDisclaimer' },
        { vehicle: formatVehicleDisplayName(vehicle1), chargeRate: DCFC_CHARGING_RATE },
      );

  const vehicle2ChargeRateMessage = isVehicle2BelowChargerRate
    ? formatMessage(
        { id: 'compare.assumptions.maxChargeRateDisclaimer' },
        { vehicle: formatVehicleDisplayName(vehicle2), chargeRate: vehicle2.dcChargingPower },
      )
    : formatMessage(
        { id: 'compare.assumptions.normalChargeRateDisclaimer' },
        { vehicle: formatVehicleDisplayName(vehicle2), chargeRate: DCFC_CHARGING_RATE },
      );

  return (
    <Card>
      <Box display="flex" flexDirection="column">
        <Typography variant="label1" mb={1}>
          {formatMessage({ id: 'compare.assumptions.header' })}
        </Typography>
        {isBothVehiclesAboveRate ? (
          <AssumptionLineItem
            label={formatMessage({ id: 'compare.assumptions.fastChargingSpeed' })}
            value={formatMessage(
              { id: 'compare.assumptions.chargeRate' },
              { rate: DCFC_CHARGING_RATE },
            )}
          />
        ) : (
          <Typography fontSize="0.75rem">
            {vehicle1ChargeRateMessage} {vehicle2ChargeRateMessage}
          </Typography>
        )}
      </Box>
    </Card>
  );
}
