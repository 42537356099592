import { Box } from '@mui/material';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from '../../../components/select/Select';
import { ElectricVehicle, GasVehicle, Vehicle } from '../../../types';
import Uuid from '../../../utils/Uuid/Uuid';
import { useLocation } from 'react-router-dom';

type Props = {
  vehicle?: ElectricVehicle | GasVehicle;
  vehicles: (ElectricVehicle | GasVehicle)[];
  onVehicleChange: (vehicleId?: string) => void;
};

const isFuelType = (value: string): value is Vehicle['fuelType'] => {
  return ['BEV', 'PHEV', 'GAS'].includes(value);
};

const FUEL_TYPE_COPY = {
  GAS: 'Gasoline',
  BEV: 'All-electric',
  PHEV: 'Plug-in Hybrid',
};

const makeVehicleTypeOptions = (vehicles: (ElectricVehicle | GasVehicle)[]) => {
  const fuelTypes = vehicles.map((ev) => ev.fuelType);

  const uniqueFuelTypes = fuelTypes.filter((value, index, self) => self.indexOf(value) === index);
  return uniqueFuelTypes.sort().map((fuelType) => ({
    label: FUEL_TYPE_COPY[fuelType],
    value: fuelType,
  }));
};

const makeVehicleMakeOptions = (
  vehicles: (ElectricVehicle | GasVehicle)[],
  fuelType: Vehicle['fuelType'],
) => {
  const makes = uniq(
    vehicles.filter((v) => v.fuelType === fuelType).map((vehicle) => vehicle.make),
  );
  return makes.sort().map((make) => ({
    label: make,
    value: make,
  }));
};

const makeVehicleOptions = (
  vehicles: (ElectricVehicle | GasVehicle)[],
  fuelType: Vehicle['fuelType'],
  make: string,
) => {
  return vehicles
    .filter((vehicle) => {
      return vehicle.fuelType === fuelType && vehicle.make === make;
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((vehicle) => ({
      label: vehicle.displayName,
      value: vehicle.handle,
    }));
};

export default function SelectVehicle({ vehicle, vehicles, onVehicleChange }: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultFuelTypeParam = searchParams.get('default-fuel-type');
  const defaultFuelType =
    defaultFuelTypeParam && isFuelType(defaultFuelTypeParam) ? defaultFuelTypeParam : undefined;

  const [uuid] = useState(Uuid());
  const { formatMessage } = useIntl();
  const [fuelType, setFuelType] = useState<Vehicle['fuelType']>(
    vehicle?.fuelType || defaultFuelType || 'GAS',
  );
  const [make, setMake] = useState<string | undefined>(vehicle?.make);

  const onChangeFuelType = (newFuelType: string) => {
    if (isFuelType(newFuelType) && newFuelType !== fuelType) {
      setFuelType(newFuelType);
      setMake(undefined);
      vehicle && onVehicleChange(undefined);
    }
  };

  const onChangeMake = (newMake: string) => {
    if (newMake !== make) {
      setMake(newMake);
      vehicle && onVehicleChange(undefined);
    }
  };

  return (
    <Box component="form" display="flex" flexDirection="column">
      <Select
        id={`${uuid}_fuelType`}
        label={formatMessage({ id: 'compare.selectVehicle.fuelType' })}
        value={fuelType || ''}
        options={makeVehicleTypeOptions(vehicles)}
        onChange={onChangeFuelType}
      />
      <Select
        id={`${uuid}_carMake`}
        label={formatMessage({ id: 'compare.selectVehicle.carMake' })}
        value={make || ''}
        options={fuelType ? makeVehicleMakeOptions(vehicles, fuelType) : []}
        onChange={onChangeMake}
      />
      <Select
        id={`${uuid}_carModel`}
        label={formatMessage({ id: 'compare.selectVehicle.carModel' })}
        value={vehicle?.handle || ''}
        options={fuelType && make ? makeVehicleOptions(vehicles, fuelType, make) : []}
        onChange={onVehicleChange}
      />
    </Box>
  );
}
