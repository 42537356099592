import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

const deepCompareEquals = (a: any, b: any) => {
  return isEqual(a, b);
};

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffect = (callback: () => void, dependencies: any[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareEffect;
