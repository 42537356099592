import { Box, Chip } from '@mui/material';

export default function DeployedBranchName() {
  const branchName = process.env.REACT_APP_DEPLOYED_BRANCH;
  if (!branchName) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '50%',
        bottom: '10px',
        transform: 'translateX(50%)',
        zIndex: 1,
      }}
    >
      <Chip
        label={`Deployed branch: ${branchName}`}
        size="small"
        sx={{
          backgroundColor: '#6893D4',
        }}
      />
    </Box>
  );
}
