// zhTranslationRateInfo.ts

export const EV2_A = `###费率计划: EV2-A

非分层收费；按使用时间收费，适用于拥有电动车的住宅客户。[详情](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/electric-vehicle-base-plan/electric-vehicle-base-plan.page)

家庭充电EV2-A费率的电费在每天凌晨12点至下午3点是最低的，包括周末和节假日，因为这个时间段的用电需求最低，也是为您的车辆充电的最佳时间。高峰期（下午4点至晚上9点）和部分高峰期（下午3点至4点和晚上9点至凌晨12点）的电费更贵。

可受益客户人群

该费率计划适用于拥有电动汽车和/或电池存储的客户，他们可以在凌晨12点至下午3点的非高峰时段充电，此外还可以将其他家庭能源使用转移到非高峰时段。

#### 说明

该费率计划适用于家庭能源使用和电动汽车和/或电池充电。

该计划能在一天中能源供应成本较低的时间段提供较低的价格。

#### 注意事项

避免在高峰时段充电。为了最大限度地节省开支，考虑将其他家庭能源使用转移到成本较低的低需求时段（见上图）。

在过去12个月中，能源使用率高（超过800%的基线补贴）的客户不符合此费率计划的条件。`;

export const EV_B = `###费率计划：EV-B

非分层收费；按使用时间收费，适用于拥有电动车的住宅客户。这个费率计划涉及到安装另一个电表，将您的电费与你的家庭的电费分开。[详情](https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf)

EV-B费率的电费在晚上11点至早上7点是最低的，因为这个时间段的用电需求最低，也是为您的车辆充电的最佳时间。高峰期（下午2点至晚上9点）和部分高峰期（上午7点至下午2点和晚上9点至11点）的电费更贵。

#### 可受益客户人群

希望通过专用电表将电动车充电与家庭能源消耗分开追记录的客户。如果您能在工作日的早上7点至晚上11点，以及周末和节假日的下午3点到晚上7点减少你的用电量，这个费率计划可能是最适合你的。

#### 说明

双重电表：一个用于您的家庭，一个用于电动车充电。一天内不同时间段的充电费用各不相同。您的家庭能源使用是分开测量的。

#### 注意事项

避免在高峰时段充电。安装第二个电表的费用可能很高。每个电表一次性收费100美元，加上安装充电站和第二个电板的费用。也可能需要升级服务。升级费用一般为2000美元左右，根据场地情况，可高达8000美元左右。

费用

| 总能源价格（每千瓦时美元） | 夏季 | 冬季 |
| --- | --- | --- |
|高峰期 | $0.60057 | $0.41855 |
| 部分高峰期 | $0.35646 | $0.28654 |
| 非高峰期 | $0.24391 | $0.21481 |

电表总费用：每天$0.04928。

请查看[这里](https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf)了解时间段的定义和收费。

通过www.DeepL.com/Translator（免费版）翻译`;

export const E_1 = ``;

export const E_TOU_B = `###利率计划: E-1

PG&E的分层费率计划。[详情](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/tiered-base-plan.page)

此费率计划包含两级定价，所谓的"层级"，是
根据您的使用能源收费。当您的用电量超过
"Baseline Allowance" (基线配额) 的四倍，也称为第1层，此计划还额外收取"High Usage Surcharge" (高使用量附加费)

#### 了解基线配额

第一层也是价格最低的一层，被称为你的Baseline Allowance (基线配额)。它将根据您的住址，供暖源，爱你的季节-夏季或冬季来分配能源。夏季为5月1日至10月31日，而冬季为11月1日至4月30日。

- 在你的 Baseline Allowance (基线配额)内使用的能源按将按最低价格收费。
- 超过Baseline Allowance (基线配额) 的能源使用属于第2级，以较高的价格收费。
- 如果您在月度账单周期内的能源使用量超过基线允许量的四倍，您将被收取[高用量附加费](https://www.pge.com/en_US/residential/rate-plans/how-rates-work/rate-changes/high-usage-surcharge/high-usage-surcharge.page)。

[查找您的基线配额](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/understanding-baseline-allowance.page)
`;

export const E_TOU_C = `###费率计划: E-TOU-C

使用时间（每天下午4点至晚上9点的高峰定价）。[详情](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

#### 运作方式：

您支付的电价会根据一天中的不同时间段而有所不同

- 高峰期（价格较高）-- 每天下午4点至晚上9点
- 非高峰期（价格较低）--每天下午4点之前及晚上9点之后。

#### 这对您意味着什么：

- 如果您能从整体上减少你的用电量，特别是在高价（高峰）时段，您就能省钱。

[了解更多关于使用时间段（每天下午4点至9点的高峰定价）费率计划](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/tou-everyday.page)`;

export const E_TOU_D = `###费率计划：E-TOU-D

使用时间（工作日高峰时段晚上5点至 8点）。[详情](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page)

该费率计划在一天中能源成本较低的时段提供较低的电价---工作日下午5点之前和晚上8点之后，以及周末和大多数节假日的所有时段。

#### 运作方式

您所支付的电费会根据一天中的不同时间、星期几和季节而有所不同

- 高峰期（最高价格）-- 周一至周五下午5点至晚上8点（大多数节假日除外）。
- 非高峰期（最低价格）-- 周一至周五下午5点前和晚上8点后，以及周末和大多数节假日的所有时段

- 冬季的八个月（10月至5月）的电费比夏季的四个月（6月至9月）低。
- 与使用时间费率计划下午4点至9点不同之处是，该费率计划没有Baseline Allowance (基线配额)。

#### 这对你意味着什么:

- 如果你能从整体上减少您的用电量，特别是在高价位（高峰）时段，你就能省钱。
- 该分时费率计划（E-TOU-D）可能更使用于高能耗用户，因为一旦您的月用电量超过了第一级用量（基线配额），电价就会比E-TOU-C低。

[了解更多关于使用时间段（工作日高峰期晚上5点至8点）费率计划的信息](https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/tou-weekdays.page)`;
