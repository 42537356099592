import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import useFilteredVehiclesForRates from '../../../../../api/hooks/useFilteredVehiclesForRates';
import Select from '../../../../../components/select/Select';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';

export const VehicleStep = () => {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { status, makes, models, selectedVehicle } = useFilteredVehiclesForRates();

  if (status !== 'idle') return <></>;

  return (
    <>
      <Box>
        <Typography variant="h5" textAlign="center" id="modal-title">
          {formatMessage({ id: 'rates.wizard.vehicle.vehicleTitle' })}
        </Typography>
      </Box>
      <Box>
        <Box display="flex" flexDirection="column" gap="1rem" justifyContent="center">
          <Select
            value={selectedVehicle?.make || userPrefs.vehicleMakeFilter}
            label={formatMessage({ id: 'rates.wizard.vehicle.vehicleMakeLabel' })}
            options={makes.map((make) => ({
              label: make,
              value: make,
            }))}
            onChange={(value) => {
              if (value !== userPrefs.vehicleMakeFilter) {
                setUserPrefs({
                  vehicleMakeFilter: value,
                  comparedVehicleId1: undefined,
                });
              }
            }}
          />
          <Select
            value={userPrefs.comparedVehicleId1 || ''}
            label={formatMessage({ id: 'rates.wizard.vehicle.vehicleModelLabel' })}
            disabled={!userPrefs.vehicleMakeFilter || userPrefs.vehicleMakeFilter === 'All'}
            options={Object.keys(models).map((key) => {
              return {
                label: models[key],
                value: key,
              };
            })}
            onChange={(value) => setUserPrefs({ comparedVehicleId1: value })}
          />
        </Box>
      </Box>
    </>
  );
};
