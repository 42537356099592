import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Page from '../../components/page/Page';

const Header = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h2" mb={1} mt={6}>
    {children}
  </Typography>
);

type QaProps = {
  question: string;
  children: React.ReactNode;
};

const QA = ({ question, children }: QaProps) => (
  <Box p={3} mb={2} border="1px solid" borderColor="grey.200">
    <Typography variant="h5" component="p" fontWeight="600" fontSize="1.25rem" mb={1}>
      {question}
    </Typography>
    <Box>{children}</Box>
  </Box>
);

const STYLES = {
  lineHeight: '26px',
  '& a': {
    color: 'blue.400',
    '&:not(:hover)': {
      textDecoration: 'none',
    },
  },
  '& p:last-child': {
    marginBottom: 0,
  },
  '& .MuiTableCell-root': {
    fontSize: '1rem',
  },
};

export default function FAQ() {
  return (
    <Page>
      <Box bgcolor="grey.100">
        <Container maxWidth="md">
          <Typography variant="h1" py={8}>
            Questions? Look here.
          </Typography>
        </Container>
      </Box>
      <Box my={7} sx={STYLES}>
        <Container maxWidth="md">
          <Header>Electric Vehicle Basics</Header>
          <QA question="How does a Plug-In Electric Vehicle Work?">
            A plug-in electric vehicle is a vehicle that can be plugged into an electrical outlet or
            charging device to recharge its battery. There are two types: battery electric vehicles,
            which run only on electricity, and plug-in hybrids, which run mainly or solely on
            electricity until the battery is depleted and then are powered by an internal combustion
            engine.
          </QA>
          <QA question="How Does A Battery Electric Vehicle Work?">
            A battery electric vehicle is fueled only by electricity, essentially replacing
            gasoline, diesel or other types of combustible fuels. It is purely electric, utilizing
            an electric motor to propel itself. A battery electric vehicle must plug in to a power
            source to recharge its battery.
          </QA>
          <QA question="How Does A Plug-in Hybrid Electric Vehicle Work?">
            <p>
              A plug-in hybrid electric vehicle is a vehicle that uses both gasoline (stored in a
              gas tank) and electricity (stored in a battery).
            </p>
            <p>
              Plug-in Hybrid electric vehicles can be categorized by the way they manage their
              gasoline and electricity:
            </p>
            <ul>
              <li>
                A parallel hybrid uses both a combustion engine and an electric motor to deliver
                power to the wheels. The use of these two forms of power varies from vehicle to
                vehicle: the vehicle can be powered by just the electric motor, just the combustion
                engine, or a combination of both depending on driving conditions.
              </li>
              <li>
                A series hybrid is directly powered only by the electric motor. The combustion
                engine is only used to recharge the battery, acting as an electric generator that
                converts gasoline to electricity. The three are aligned in-series: the combustion
                engine, then the electric motor, then the wheels. The market has commonly termed
                this as an extended-range electric vehicle because of its similarity to an
                all-electric battery electric vehicle, with the exception of using gasoline to
                "extend" its range.
              </li>
            </ul>
          </QA>
          <QA question="What Should I Consider Before Purchasing An Electric Vehicle">
            <p>
              Similar to selecting a gasoline-powered car, choosing the electric vehicle that's best
              for you depends on a number of different factors including your driving habits and
              personal preference. Here are some factors to consider:
            </p>
            <ul>
              <li>
                <i>Total Range</i>: How far will you travel? The total range of current electric
                vehicles vary greatly—anywhere from 40-350 miles.
              </li>
              <li>
                <i>Gasoline Use</i>: How much gasoline do you want to use? The battery capacity of
                an electric vehicle determines how far you can go without using a drop of gasoline.
              </li>
              <li>
                <i>Charging</i>: Where will you charge? Where you drive and how you'll charge your
                vehicle can help you decide which electric vehicle will meet your needs.
              </li>
            </ul>
            <p>
              If your daily commute is less than 40 miles, many electric vehicles—hybrid or battery
              electric—will be able to handle your daily driving without the need for gas. If you
              want the ability to drive much farther, several battery electric vehicles can travel
              100 to 200+ miles on a charge. If you need to drive farther without charging, consider
              an extended-range hybrid like the Chevy Volt.
            </p>
          </QA>
          <QA question="What Are The Typical Maintenance Costs Of An Electric Vehicle?">
            Plug-in electric vehicles typically have lower total cost of ownership and, in
            particular, lower maintenance costs. This is because they have fewer moving parts,
            reduced oil changes (or none for a full electric) and fewer brake jobs—battery
            regeneration absorbs most of the energy. Hybrids and plug-in electric vehicles can go
            100,000 miles before receiving a brake job.
          </QA>
          <QA question="What Will Happen To The Battery After It Has Exceeded Its Life Expectancy?">
            <p>
              They will probably be recycled, but PG&amp;E and others are researching second-life
              applications.
            </p>
            <a
              href="http://www.pgecurrents.com/2017/06/08/pge-bmw-pilot-successfully-demonstrates-electric-vehicles-as-an-effective-grid-resource/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Find out what PG&amp;E is doing.
            </a>
          </QA>

          <QA question="Are There Conveniently Located Charging Stations Where I Can Charge My Electric Vehicle?">
            <p>
              Yes, public charging stations are located in supermarket parking lots, city garages,
              gas stations and many other locations across the country. Some public charging
              stations are free and others require a fee or membership.
            </p>
            <p>
              PG&amp;E is also supporting installations of charging stations at workplaces and
              multi-family residences through the EV Charge Network Program. Visit{' '}
              <a
                href="https://www.pge.com/en_US/large-business/solar-and-vehicles/clean-vehicles/ev-charge-network.page"
                target="_blank"
                rel="noopener noreferrer"
              >
                EV Charge Network
              </a>
              .
            </p>
          </QA>

          <QA question="What Are The Environmental Benefits Of An Electric Vehicle?">
            <p>
              Electric vehicles reduce the amount of gasoline we burn and are less costly to
              maintain, among many other benefits:
            </p>
            <ul>
              <li>
                <i>Reduced Operating Emissions</i>: The emissions associated with the electric
                drivetrain of plug-in electric vehicles come from power plants generating
                electricity to charge the batteries and not from tailpipe emissions. Additionally,
                from well to wheel, electric vehicles emit approximately 66 percent less carbon
                dioxide (CO2) compared with internal combustion vehicles. CO2 is the principal gas
                associated with global warming.
              </li>
              <li>
                <i>Water Quality Preservation</i>: Decreased use of petroleum gasoline and motor oil
                means fewer spills and pollution to oceans, rivers and ground water.
              </li>
              <li>
                <i>Reduced Noise</i>: In addition to being cleaner, electric vehicles are quieter
                than gasoline-powered vehicles, resulting in less noise pollution.
              </li>
            </ul>
            <p>
              Additionally, more than two-thirds of the electricity PG&amp;E provides comes from
              sources that are either renewable or emit no greenhouse gases. That's why by choosing
              to drive an electric vehicle, you are helping to reduce pollution.
            </p>
          </QA>

          <QA question="What Are The Driving Benefits Of An Electric Vehicle?">
            <ul>
              <li>
                <i>Laws and Incentives</i>: California has adopted several laws to accommodate the
                use of plug-in electric vehicles, including the qualification of select plug-in
                electric vehicles to use the HOV lane.{' '}
                <a
                  href="https://ww2.arb.ca.gov/our-work/programs/carpool-stickers#vehicles"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the qualifying vehicles for clean air vehicle decals
                </a>
                .
              </li>
              <li>
                <i>Safety</i>: Many electric vehicles receive top National Highway Safety Traffic
                Administration safety ratings.
              </li>
            </ul>
            <p>
              To date, findings have shown that several electric vehicle features maximize safety.
              For example, electric vehicles tend to have a lower center of gravity that makes them
              less likely to roll over, electric vehicles have less potential for major fires or
              explosions and the body construction and durability of electric vehicles enhance
              vehicle safety in a collision.
            </p>
          </QA>

          <QA question="What Are The Financial Benefits Of Owning An Electric Vehicle?">
            <ul>
              <li>
                <i>Lower Operational Costs</i>: The estimated cost of electricity needed to power a
                plug-in electric vehicle is about one-third of the cost of gasoline.
              </li>
              <li>
                <i>Lower Maintenance Costs</i>: The electrical components of plug-in electric
                vehicles require little to no regular maintenance due to far less moving parts. In
                hybrids, this leads to less wear and tear of gasoline components.
              </li>
              <li>
                <i>Rebates &amp; Tax Credits</i>: Many government agencies and local and regional
                entities offer rebates and tax credits, up to $7,500, to encourage the adoption of
                plug-in electric vehicles.
              </li>
            </ul>
            <p>
              To date, findings have shown that several electric vehicle features maximize safety.
              For example, electric vehicles tend to have a lower center of gravity that makes them
              less likely to roll over, electric vehicles have less potential for major fires or
              explosions and the body construction and durability of electric vehicles enhance
              vehicle safety in a collision.
            </p>
          </QA>

          <Header>Rates and Costs</Header>
          <QA question="If I try the EV2-A rate and don't like it, can I change to another rate?">
            Yes, customers have the option of PG&amp;E's tiered rate (E-1), tiered, time-of-use rate
            (E-TOU) or our separately metered EV rate plan, EV-B. It is important to note that EV-B
            customers will be losing the less costly off-peak rates formerly applied to home power
            use. The EV-B rate also requires the installation of a separate meter, which may come at
            a significant cost to the customer."
          </QA>
          <QA question="What vehicles are eligible for PG&amp;E's EV rate?">
            <p>
              PG&amp;E's electric vehicle (EV) rate applies to all PG&amp;E customers who have a
              currently registered battery electric vehicle (BEV) or plug-in hybrid electric vehicle
              (PHEV) charged via a charging outlet at the customer's residence. PG&amp;E's EV rate
              is not available to customers with a conventional hybrid electric vehicle (HEV), low
              speed electric vehicle or electrically powered motorcycles or bicycles.
            </p>
            <p>
              <a
                href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV2%20(Sch).pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Veiw the EV2-A Full Schedule and Rates
              </a>
            </p>
            <p>
              <a
                href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV%20(Sch).pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Veiw the EV-B Full Schedule and Rates
              </a>
            </p>
          </QA>
          <QA question="How do I get billed for electricity if I plug in at home, work, a public charging station or a friend's house? What if a friend charges his/her vehicle at my house?">
            Electricity used when charging an electric vehicle at home will appear as a charge in
            the customer's normal monthly utility bill. When charging at a friend's house, that
            electricity usage will appear on the friend's bill. We anticipate that billing systems
            for vehicle charging outside the home will evolve over time in ways similar to how
            customers today pay for gasoline. If your place of employment has a charging station,
            ask your employer how the electricity from the charger is billed.
          </QA>
          <QA question="How does paying for kilowatt hours compare to buying gasoline?">
            <p>
              The U.S. Energy Department has created a website to determine an eGallon, or the cost
              of fueling a vehicle with electricity compared to a similar vehicle that runs on
              gasoline. On PG&amp;E's EV-2A rate, during off-peak hours electricity costs the
              equivalent of around $1.60 per eGallon.
            </p>
            <a href="https://www.energy.gov/maps/egallon" target="_blank" rel="noopener noreferrer">
              View eGallon info
            </a>
            .
          </QA>
          <QA question="Will electric vehicles drive up the price of electricity?">
            The effect of plug-in electric vehicles on electricity rates will be determined in large
            part by the California Public Utilities Commission. The CPUC is currently investigating
            rates, charging infrastructure and other policies to prepare for the expected growth of
            plug-in electric vehicles.
          </QA>

          <Header>800% of Basline EV Rate Eligibility Requirement</Header>

          <QA question="What is the new EV 800% of baseline eligibility requirement?">
            As of July 1, 2019, PG&amp;E's EV rates are subject to a new usage requirement.
            Customers on an EV rate cannot have annual usage of more than 8 times (800%) of annual
            baseline allowance for their area. Customers who exceed and have been on the rate at
            least 12 months are subject to be moved to the Time of Use (Peak Pricing 5-8 p.m.
            Weekdays) TOU-D rate plan."
          </QA>
          <QA question="How is baseline calculated?">
            <p>
              Baseline is determined by your climate zone, the season, and your heating source. For
              more information on baseline allowance, please visit{' '}
              <a
                href="https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/tiered-base-plan/understanding-baseline-allowance.page?WT.mc_id=Vanity_baseline"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.pge.com/baseline
              </a>
              .
            </p>
          </QA>
          <QA question="My EV rate doesn't have a baseline allowance on my bill. How do you determine the baseline allowance? ">
            <p>EV-A &amp; EV-B</p>
            <ul>
              <li>The Tiered rate (E-1) baseline allowance is used to calculate baseline</li>
            </ul>
            <p>Home Charging EV2-A</p>
            <ul>
              <li>Time-of-Use (Peak Pricing 49 p.m. Every Day) is used to calculate baseline</li>
            </ul>
          </QA>
          <QA question="What happens if I exceed 800% of baseline?">
            <p>
              Customers will receive an alert letter every month in which their cumulative usage (up
              to 12 months) exceeds 800% of their cumulative baseline allowance. Depending on
              customer's usage and how long they've been on the rate, customers could reduce usage
              to below 800% of baseline and remain on the rate.
            </p>
            <p>
              If a customer exceeds 800% of baseline and has been on the rate 12 months or longer,
              they will be removed from the EV rate after they receive at least one alert and placed
              on the Time of Use (Peak Pricing 5-8 p.m. Weekdays) TOU-D rate.
            </p>
          </QA>
          <QA question="Can I return back to the EV rate if I lower my usage to under 800%?">
            Customers who are removed from their EV rate due to exceeding 800% of baseline can
            return to an EV rate 12 months after being removed."
          </QA>
          <QA question="If I am transitioned to E-TOU-D, when will it be effective on my bill?">
            The new rate will be effective the first day of the next billing cycle. Customers can
            expect to receive their first billing statement 2-3 months from when the final
            transition notification is received."
          </QA>
          <QA question="Can I elect to be transitioned to a rate other than E-TOU-D?">
            <p>
              Customers can do a rate comparison by visiting{' '}
              <a href="https://www.pge.com/rateanalysis" target="_blank" rel="noopener noreferrer">
                www.pge.com/rateanalysis
              </a>{' '}
              to determine which non-EV rate is best for them. At that point, or at any point, they
              can change their rate online.
            </p>
          </QA>
          <QA question="If I have more than one EV do I receive more baseline?">
            Usage beyond 800% of baseline is unlikely to be related to EV charging. The baseline
            allowance was designed to account for an average household usage and two electric
            vehicles, with each vehicle accounting for approximately 100% of baseline. Additional
            baseline allowance is not granted based on number of EVs or specific climate zones."
          </QA>
          <QA question="What changes can I make to reduce my usage and stay on the EV rate?">
            <p>
              Reducing energy usage at home with changes in behavior or adopting energy efficiency
              upgrades.
            </p>
            <p>Customers can visit these resource pages for tips and tools to reduce usage:</p>
            <p>
              <a href="www.pge.com/ratechoices" target="_blank" rel="noopener noreferrer">
                www.pge.com/ratechoices
              </a>
            </p>
            <p>
              <a href="www.pge.com/homenergycheckup" target="_blank" rel="noopener noreferrer">
                www.pge.com/homenergycheckup
              </a>{' '}
              (requires login)
            </p>
            <p>
              <a href="www.pge.com/billalert" target="_blank" rel="noopener noreferrer">
                www.pge.com/billalert
              </a>{' '}
              (NEM not eligible)
            </p>
            <p>
              Customers can also charge away from home, at public stations or at their workplace, to
              reduce energy consumption at home.
            </p>
          </QA>

          <Header>Home Charging EV2-A Rate Plan</Header>
          <QA question="Why was the new Home Charging EV2-A Rate developed?">
            The Home Charging EV2-A rate was developed to encourage conservation and for the benefit
            of EV charging. The extended Time of Use hours take advantage of low-cost energy costs
            when demand is lowest and also take advantage of renewable energy available in the
            middle of the day.
          </QA>
          <QA question="What is the difference between EV-A and Home Charging EV2-A?">
            <p>
              Like EV-A, Home Charging EV2-A applies to whole home energy usage, including electric
              vehicle and/or battery charging.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="td" scope="col" style={{ width: '30%' }} />
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    EV-A
                  </TableCell>
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    Home Charging EV2-A
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Peak
                  </TableCell>
                  <TableCell>
                    2:00pm - 9:00pm M-F and 3:00pm - 7:00pm Sat, Sun, and Holidays
                  </TableCell>
                  <TableCell>4:00pm - 9:00pm everyday</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Partial Peak
                  </TableCell>
                  <TableCell>7:00am -2:00pm and 9:00pm - 11:00pm M-F except Holidays</TableCell>
                  <TableCell>3:00pm - 4:00pm and 9:00pm - 12:00am everyday</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Off-Peak
                  </TableCell>
                  <TableCell>All other hours</TableCell>
                  <TableCell>12:00am - 3:00pm everyday</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <p>
              Home Charging EV2-A time-of-use periods apply every day, including weekends and
              holidays.
            </p>

            <p>
              <b>Updated Pricing as of March 1, 2021</b>
            </p>

            <p>
              <b>SUMMER</b>
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="td" scope="col" style={{ width: '30%' }} />
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    EV-A
                  </TableCell>
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    Home Charging EV2-A
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Peak
                  </TableCell>
                  <TableCell>$0.56201</TableCell>
                  <TableCell>$0.49616</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Partial Peak
                  </TableCell>
                  <TableCell>$0.30714</TableCell>
                  <TableCell>$0.38567</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Off-Peak
                  </TableCell>
                  <TableCell>$0.14381</TableCell>
                  <TableCell>$0.18366</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <p>
              <b>WINTER</b>
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="td" scope="col" style={{ width: '30%' }} />
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    EV-A
                  </TableCell>
                  <TableCell component="th" scope="col" style={{ width: '35%' }}>
                    Home Charging EV2-A
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Peak
                  </TableCell>
                  <TableCell>$0.40828</TableCell>
                  <TableCell>$0.36905</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Partial Peak
                  </TableCell>
                  <TableCell>$0.24768</TableCell>
                  <TableCell>$0.35235</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Off-Peak
                  </TableCell>
                  <TableCell>$0.14722</TableCell>
                  <TableCell>$0.18366</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <p>
              <b>Seasonal Changes</b>
            </p>
            <p>
              On Home Charging EV2-A summer season is June 1 - September 30, and winter season is
              October 1 - May 31.
            </p>
            <p>
              On EV-A summer season is May 1 - October 31, and winter season is November 1 - April
              30.
            </p>

            <p>
              <b>Battery Storage Customers</b>
            </p>
            <p>
              Battery storage only customers who have applied for interconnection and received
              Permission to Operate (PTO) may be eligible for enrollment on the Home Charging EV2-A
              rate. Customers may need to provide information on battery storage capacity upon
              enrollment in the rate.
            </p>
          </QA>
          <QA question="When will EV-A customers be transitioned to EV2-A?">
            The EV-A rate closed to new enrollments on July 1, 2019. Customers currently on EV-A
            will be transitioned to EV2-A based on their NEM grandfathered status.
          </QA>
          <QA question="I received a notice my rate is transitioning from EV-A to EV2-A. How do I find out what date my rate will change?">
            <p>
              The EV-A rate closed to new enrollments on July 1, 2019. Customers on the EV-A rate
              are automatically transitioned to the EV2-A on a rolling basis.
            </p>
            <p>
              Transitions are scheduled based on each customer's last billed date, and the new rate
              is effective on the first date of the following bill cycle.
            </p>
            <p>
              For more information on how to find your bill cycle date on your PG&amp;E bill please
              refer to the{' '}
              <a
                href="https://www.pge.com/pge_global/common/pdfs/save-energy-money/analyze-your-usage/your-usage/view-and-share-your-data-with-smartmeter/reading-the-smartmeter/mtr_schedule_2021.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                2021 meter reading schedule (PDF, 115 KB)PDF
              </a>
              .
            </p>
            <p>
              If you received a notice of an upcoming transition to the EV2-A rate you can use the
              meter read date along with the transition schedule information below to determine an
              approximate start date on the new rate. Please note that meter read dates can vary
              between two days before and two days after the posted dates.
            </p>
            <p>Week 1: Meter read dates 6/1 - 6/7 will be transitioned on 6/10</p>
            <p>Week 2: Meter read dates 7/8 6/14 will be transitioned on 6/17</p>
            <p>Week 3: Meter read dates 6/15 6/21 will be transitioned on 6/24</p>
            <p>Week 4: Meter read dates 6/22 6/30 will be transitioned on 7/1</p>
            <p className="font-italic">
              Example:
              <br />
              Customer is on meter read schedule "Y" and their meter is read on 6/22. This date is
              included in the transition schedule for week 4 with the transition date happening on
              7/1. The rate effective date will be back-dated to coincide with the start of the
              customer's next bill cycle, 6/23. EV chargers should be reprogrammed to start charging
              at 12 a.m. instead of 11 p.m. on 6/23 to be aligned with off-peak hours of their new
              rate Home Charging EV2-A.
            </p>
          </QA>

          <Header>Charging and Installation</Header>
          <QA question="Who do I contact to set up a charging station for my car?">
            <p>
              Work with your electrical contractor to evaluate your home's wiring, electrical
              outlets and other hardware that can support the charging requirements of your new
              electric vehicle.
            </p>
            <p>
              Your dealer may offer a home assessment as part of the electric vehicle purchase
              price. Some automakers offer a consultation with an electrical contractor as part of
              the electric vehicle purchase.
            </p>
            <p>
              <small>
                PLEASE NOTE: PG&amp;E can only perform work outside the home at your
                meter/electrical panel location to enable the necessary utility service to the
                house.
              </small>
            </p>
          </QA>
          <QA question="What does it cost to get plug-in ready?">
            <p>
              Total cost varies depending on current electrical design, local code requirements, the
              rate and charging options you choose and other factors. Potential costs include the
              following:
            </p>
            <ul>
              <li>
                <i>Charging equipment installation</i>: This cost can be provided by your licensed
                electrical contractor.
              </li>
              <li>
                <i>Second electrical meter installation</i>: You'll need to budget for the
                installation of a second electrical meter and service panel if you decide to switch
                to PG&amp;E's EV-B pricing plan. This allows your home's electric load to be
                measured on the existing meter while a second meter and dedicated breaker is used to
                measure your electric vehicle's energy usage. PG&amp;E charges a one-time $100 fee
                to residential customers for any new meter installation—in addition to any service
                upgrade costs.
              </li>
              <li>
                <i>Electrical panel upgrade</i>: This applies to customers who choose the faster
                charging Level 2 option, which utilizes 208-240 volts. This adds significant load to
                you electrical panel, resulting in an electrical panel upgrade. The upgrade cost can
                be provided by a licensed electrical contractor.
              </li>
              <li>
                <i>Utility service upgrade</i>: Your home may require utility electrical system
                upgrades in order to charge the vehicle and/or accommodate a second meter. This cost
                can be determined by PG&amp;E after an on-site assessment.
              </li>
            </ul>
          </QA>
          <QA question="What do I need to do if my situation changes?">
            <ul>
              <li>
                <i>If you're Moving</i>: If you move to another residence you will need to contact
                us and follow the process for getting your new home ready for your electric vehicle.
              </li>
              <li>
                <i>If you're Purchasing a second vehicle</i>: Please contact us if you are
                considering purchasing a second vehicle so that we can evaluate the utility side of
                your service to ensure you have adequate capacity to charge both vehicles. You may
                want to hire your own qualified electrical contractor to evaluate your load
                capacity, including accommodating your new Level I and Level 2 charging
                requirements.
              </li>
              <li>
                <i>If you no longer own an electric vehicle</i>: If you no longer require the
                electric vehicle rate, please call us to request that the electric vehicle service
                be turned off. There is no charge for disconnecting the EV-B meter panel or
                cancelling the rate.
              </li>
            </ul>
          </QA>
          <QA question="Can PG&amp;E shut off my vehicle charging?">
            Only a power outage or failure to pay your electric bill will interrupt vehicle charging
            without your permission.
          </QA>
          <QA question="Can I sell back electricity from my electric vehicle battery through vehicle-to-grid technology?">
            This technology is not currently available. However, PG&amp;E is currently researching
            the future feasibility of vehicle-to-grid applications.
          </QA>
          <QA question="How do I charge my electric vehicle when I&rsquo;m not at home? Where else can I charge it?">
            <p>
              If your vehicle is capable of charging at 110 volts, you will be able to plug your
              vehicle into any standard outlet for charging while away from home (assuming you can
              get permission to plug your vehicle from the outlet owner).
            </p>
            <p>
              Plug-in hybrid electric vehicles have gasoline engines, so you can always buy gasoline
              as you ordinarily do to extend the range of your vehicle.
            </p>
            <p>
              With battery electric vehicles, to avoid inconveniences you will want to fully charge
              your vehicle before you leave home, especially if the round trip you are taking is
              close to the range of the vehicle. If the trip is longer than the range of the vehicle
              you will need to plan where you will be able to recharge your vehicle.
            </p>
          </QA>

          <Header>Solar Power and Electric Vehicles</Header>
          <QA question="When will NEM customers be transitioned to the new EV rate?">
            <p>
              NEM customers are eligible for 5-years of grandfathering based on their Permission to
              Operate (PTO) date. Please refer to this table for an example schedule.
            </p>
            <p>
              <b>NEM1 Transition Examples</b>
            </p>
            <ul>
              <li>
                <i>PTO Date Prior to 1130/14</i>: No Grandfathering
              </li>
              <li>
                <i>PTO Date 12/1/14 - 11/30/15</i>: Grandfathered until November 2020
              </li>
              <li>
                <i>PTO Date 12/1/15 - 11/30/16</i>: Grandfathered until November 2021
              </li>
              <li>
                <i>PTO Date 12/1/16 - 11/30/17</i>: Grandfathered until November 2022
              </li>
            </ul>
            <p>
              <b>NEM2 Transition Examples</b>
            </p>
            <ul>
              <li>
                <i>NEM 2.0 Active Date 12/16/16 - 11/30/17</i>: Grandfathered until November 2022
              </li>
              <li>
                <i>NEM 2.0 Active Date 12/1/17 - 11/30/18</i>: Grandfathered until November 2023
              </li>
              <li>
                <i>NEM 2.0 Active Date 12/1/18 - 11/30/19</i>: Grandfathered until November 2024
              </li>
              <li>
                <i>NEM 2.0 Active Date 12/1/19 - 10/31/20</i>: Grandfathered until November 2025
              </li>
            </ul>
          </QA>
          <QA question="I have a rooftop solar-power system. What are my rate options?">
            PG&amp;E customers who have a solar generating system and an electric vehicle are
            eligible to enroll in the electric vehicle rates EV2-A and EV-B.
          </QA>
          <QA question="How will having solar panels impact the cost of charging an electric vehicle?">
            The electricity generated from a solar generating system could help offset the costs of
            electricity used to charge an electric vehicle. The net effect of the solar generating
            system will depend on the system's efficiency, the weather, the amount of energy used to
            charge the vehicle and other factors.
          </QA>
          <QA question="Will charging an electric vehicle affect my solar power agreement?">
            <p>
              Charging an electric vehicle will not impact your solar power agreement as long as
              there are no changes to your solar generating system. To make changes to your solar
              generating system, please work with your contractor and apply via the ACE-IT
              interconnection portal located at{' '}
              <a href="https://www.egi-pge.com/" target="_blank" rel="noopener noreferrer">
                https://www.egi-pge.com/
              </a>
            </p>
          </QA>
          <QA question="If I add solar panels to my existing solar generating system to compensate for charging an electric vehicle, are those new panels eligible for a rebate?">
            <p>
              If you already have installed a solar generating system and have received a rebate for
              the system, you are eligible to receive a new rebate for any additional solar panels
              that are added to the system. The rebate will be directly proportional to the size of
              the new installation.
            </p>
            <p>
              <small>
                PLEASE NOTE: PG&amp;E will not energize a new service until the completed work
                passes inspection and PG&amp;E has been notified of such by the city or county.
              </small>
            </p>
          </QA>
        </Container>
      </Box>
    </Page>
  );
}
