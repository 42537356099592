import { ReactNode } from 'react';
import PreviewImage from '../../assets/images/pge-ev-savings-calculator-preview.jpeg';
import { Box } from '@mui/material';
import NpsScore from '../npsScore/NpsScore';
import ZappyRideLogo from '../../assets/images/zappyride-jpd-logo.svg';

type Props = {
  children: ReactNode;
  disclaimers?: string[];
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: string;
  structuredData?: Record<string, string>;
};

export default function Page({
  children,
  disclaimers = [],
  metaTitle = 'PG&E EV Savings Calculator',
  metaDescription = 'PG&E EV Savings Calculator',
  metaImage = PreviewImage,
  structuredData,
}: Props) {
  return (
    <>
      {/* <Meta>
        <Meta.Title>{title}</Meta.Title>
        <Meta.Description>{description}</Meta.Description>
        <Meta.Image src={metaImage} />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Meta> */}
      <Box
        tabIndex={-1}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box flex="1 1">{children}</Box>
        <Box
          p={2}
          pt={4}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            maxWidth="950px"
            mx="auto"
            fontSize="0.625rem"
            sx={{ color: (theme) => theme.palette.grey[600] }}
          >
            <Box fontSize="20px" mb={0}>
              EV solutions provided by <img src={ZappyRideLogo} alt="ZappyRide" />, a J.D. Power
              business.
            </Box>
            <Box fontSize="12px" mb={0}>
              Figures shown on this page are estimates based on data provided by third-parties. This
              material is provided for informational purposes only and should not be relied on for
              tax, legal, or financial advice. Neither J.D. Power® nor PG&E provides tax, legal, or
              financial advice. You should independently verify the estimates provided here before
              engaging in any transaction.
            </Box>
            {[
              ...(disclaimers || []),
              `“PG&E” refers to Pacific Gas and Electric Company, a
              subsidiary of PG&E Corporation. ©2020 Pacific Gas and
              Electric Company. All rights reserved. These offerings are
              funded by California utility customers and administered by
              PG&E under the auspices of the California Public Utilities
              Commission.`,
            ].map((disclaimer, i) => {
              return (
                <Box key={i} fontSize="12px" mb={0}>
                  {disclaimer}
                </Box>
              );
            })}
          </Box>
        </Box>
        <NpsScore />
      </Box>
    </>
  );
}
