import { ApiDealer, Dealer } from '../../types';

export const transformDealer = (apiDealer: ApiDealer): Dealer => {
  return {
    id: `${apiDealer.dealer_id}`,
    name: apiDealer.name,
    handle: apiDealer.dealership_handle,
    oem: apiDealer.oem,
    street: apiDealer.street,
    city: apiDealer.city,
    region: apiDealer.state,
    postcode: apiDealer.postcode,
    phone: apiDealer.phone,
    website: apiDealer.website,
    lat: apiDealer.latitude,
    lng: apiDealer.longitude,
  };
};
