import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import isReactSnapping from '../../utils/predicates/isReactSnapping';

const ONE_TRUST_DOMAIN = '6ee2f262-83e9-4ecd-ad53-5811c1f34d6d';
const ENABLED_ENVIRONMENTS = ['production', 'staging'];
const ONE_TRUST_SDK_SRC = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

// NOTE: There is another tag in the public/index.html which relates to this.
// That script auto blocks cookies from being added, and it must be added as
// a blocking tag before the React app loads.
// We can't add these tags that way because react-snap will add the SDK tag
// a second time when it renders the page, causing the OneTrust script to error
// out.

type TagData = {
  [key: string]: string;
};

const tagData: TagData[] = [
  {
    type: 'text/javascript',
    src: ONE_TRUST_SDK_SRC,
    charset: 'UTF-8',
    'data-domain-script': ONE_TRUST_DOMAIN,
  },
  {
    type: 'text/javascript',
    text: 'function OptanonWrapper() {}',
  },
];

const insertTags = () => {
  const firstChild = document.head.firstChild;
  const haveNotBeenAdded = !document.querySelector(`script[src~="${ONE_TRUST_SDK_SRC}"]`);

  if (haveNotBeenAdded) {
    tagData.forEach((tag) => {
      let node = document.createElement('script');
      node.setAttribute('data-ot-ignore', '');
      Object.keys(tag).forEach((key) => {
        if (key === 'text') {
          node[key] = tag[key];
        } else {
          node.setAttribute(key, tag[key]);
        }
      });

      document.head.insertBefore(node, firstChild);
    });
  }
};

const OneTrustCookieSettings = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    const env = process.env.REACT_APP_ENVIRONMENT as string;
    if (ENABLED_ENVIRONMENTS.includes(env) && !isReactSnapping()) {
      insertTags();
    }
  }, []);

  const handleClick = () => {
    if ((window as any).OneTrust) {
      (window as any).OneTrust.ToggleInfoDisplay();
    }
  };

  return (
    <Button
      id="ot-sdk-btn"
      className="ot-sdk-show-settings"
      onClick={handleClick}
      sx={{
        display: 'inline-block !important',
        color: 'white !important',
        backgroundColor: 'transparent !important',
        border: 'none !important',
        textTransform: 'uppercase !important',
        fontWeight: '500 !important',
        letterSpacing: '0.6px !important',
        fontSize: '14px !important',
        padding: '0 !important',
        marginBottom: '10px !important',
        lineHeight: '1.6 !important',
        '&:hover': {
          color: '#FABA45 !important',
        },
      }}
    >
      {formatMessage({ id: 'nav.cookies' })}
    </Button>
  );
};

export default OneTrustCookieSettings;
