import { apiSettings } from '../config/api';

export const fetchRecords = async (path: string, params: { [key: string]: string } = {}) => {
  const { url: baseUrl, token } = apiSettings;

  let url = new URL(baseUrl + path);

  // Remove null params
  Object.keys(params).forEach((key) => params[key] == null && delete params[key]);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams.toString();

  const response = await window.fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  if (json.code >= 400) {
    throw new Error(json.message);
  }

  return json;
};

export const postRecords = async (path: string, params: { [key: string]: string } = {}) => {
  const { url: baseUrl, token } = apiSettings;

  let url = new URL(baseUrl + path);

  // Remove null params
  Object.keys(params).forEach((key) => params[key] == null && delete params[key]);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams.toString();

  const response = await window.fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  return json;
};
