export const CLIMATE_ZONE_BY_ZIPCODE: Record<string, string | undefined> = {
  '92304': 'desert',
  '92365': 'desert',
  '92704': 'desert',
  '93062': 'desert',
  '93101': 'hills',
  '93105': 'hills',
  '93110': 'hills',
  '93111': 'hills',
  '93117': 'hills',
  '93201': 'desert',
  '93203': 'desert',
  '93204': 'desert',
  '93206': 'desert',
  '93210': 'desert',
  '93212': 'desert',
  '93214': 'desert',
  '93219': 'desert',
  '93224': 'desert',
  '93230': 'desert',
  '93232': 'desert',
  '93234': 'desert',
  '93239': 'desert',
  '93240': 'desert',
  '93241': 'desert',
  '93242': 'desert',
  '93243': 'desert',
  '93245': 'desert',
  '93249': 'desert',
  '93250': 'desert',
  '93251': 'desert',
  '93252': 'desert',
  '93254': 'desert',
  '93256': 'desert',
  '93263': 'desert',
  '93265': 'desert',
  '93266': 'desert',
  '93268': 'desert',
  '93274': 'desert',
  '93276': 'desert',
  '93277': 'desert',
  '93280': 'desert',
  '93282': 'desert',
  '93286': 'desert',
  '93287': 'desert',
  '93291': 'desert',
  '93292': 'desert',
  '93301': 'desert',
  '93302': 'desert',
  '93303': 'desert',
  '93304': 'desert',
  '93305': 'desert',
  '93306': 'desert',
  '93307': 'desert',
  '93308': 'desert',
  '93309': 'desert',
  '93311': 'desert',
  '93312': 'desert',
  '93313': 'desert',
  '93314': 'desert',
  '93330': 'desert',
  '93401': 'coast',
  '93402': 'coast',
  '93405': 'coast',
  '93406': 'coast',
  '93420': 'coast',
  '93421': 'coast',
  '93422': 'hills',
  '93423': 'hills',
  '93424': 'coast',
  '93426': 'hills',
  '93427': 'hills',
  '93428': 'coast',
  '93429': 'coast',
  '93430': 'coast',
  '93432': 'hills',
  '93433': 'coast',
  '93434': 'coast',
  '93435': 'coast',
  '93436': 'coast',
  '93437': 'hills',
  '93438': 'hills',
  '93440': 'coast',
  '93441': 'hills',
  '93442': 'coast',
  '93443': 'coast',
  '93444': 'coast',
  '93445': 'coast',
  '93446': 'hills',
  '93449': 'coast',
  '93450': 'hills',
  '93451': 'hills',
  '93452': 'coast',
  '93453': 'hills',
  '93454': 'coast',
  '93455': 'coast',
  '93456': 'coast',
  '93458': 'coast',
  '93460': 'hills',
  '93461': 'hills',
  '93463': 'hills',
  '93464': 'hills',
  '93465': 'hills',
  '93466': 'hills',
  '93516': 'desert',
  '93523': 'desert',
  '93526': 'desert',
  '93527': 'desert',
  '93555': 'desert',
  '93558': 'desert',
  '93561': 'desert',
  '93562': 'desert',
  '93601': 'desert',
  '93602': 'desert',
  '93603': 'desert',
  '93604': 'desert',
  '93606': 'desert',
  '93607': 'desert',
  '93608': 'desert',
  '93609': 'desert',
  '93610': 'desert',
  '93611': 'desert',
  '93612': 'desert',
  '93614': 'desert',
  '93615': 'desert',
  '93616': 'desert',
  '93618': 'desert',
  '93619': 'desert',
  '93620': 'desert',
  '93621': 'desert',
  '93622': 'desert',
  '93623': 'desert',
  '93624': 'desert',
  '93625': 'desert',
  '93626': 'desert',
  '93627': 'desert',
  '93628': 'desert',
  '93630': 'desert',
  '93631': 'desert',
  '93633': 'desert',
  '93634': 'desert',
  '93635': 'desert',
  '93636': 'desert',
  '93637': 'desert',
  '93638': 'desert',
  '93639': 'desert',
  '93640': 'desert',
  '93641': 'desert',
  '93643': 'desert',
  '93644': 'desert',
  '93645': 'desert',
  '93646': 'desert',
  '93647': 'desert',
  '93648': 'desert',
  '93650': 'desert',
  '93651': 'desert',
  '93652': 'desert',
  '93653': 'desert',
  '93654': 'desert',
  '93656': 'desert',
  '93657': 'desert',
  '93660': 'desert',
  '93662': 'desert',
  '93664': 'desert',
  '93665': 'desert',
  '93666': 'desert',
  '93667': 'desert',
  '93668': 'desert',
  '93669': 'desert',
  '93670': 'desert',
  '93673': 'desert',
  '93675': 'desert',
  '93701': 'desert',
  '93702': 'desert',
  '93703': 'desert',
  '93704': 'desert',
  '93705': 'desert',
  '93706': 'desert',
  '93710': 'desert',
  '93711': 'desert',
  '93720': 'desert',
  '93721': 'desert',
  '93722': 'desert',
  '93723': 'desert',
  '93725': 'desert',
  '93726': 'desert',
  '93727': 'desert',
  '93728': 'desert',
  '93730': 'desert',
  '93737': 'desert',
  '93741': 'desert',
  '93901': 'coast',
  '93905': 'coast',
  '93906': 'coast',
  '93907': 'coast',
  '93908': 'coast',
  '93915': 'coast',
  '93920': 'coast',
  '93921': 'coast',
  '93922': 'coast',
  '93923': 'coast',
  '93924': 'hills',
  '93925': 'hills',
  '93926': 'hills',
  '93927': 'hills',
  '93928': 'hills',
  '93930': 'hills',
  '93932': 'hills',
  '93933': 'coast',
  '93940': 'coast',
  '93943': 'coast',
  '93950': 'coast',
  '93953': 'coast',
  '93954': 'hills',
  '93955': 'coast',
  '93960': 'hills',
  '93962': 'coast',
  '94002': 'hills',
  '94003': 'hills',
  '94005': 'coast',
  '94010': 'hills',
  '94014': 'coast',
  '94015': 'coast',
  '94018': 'coast',
  '94019': 'coast',
  '94020': 'coast',
  '94021': 'coast',
  '94022': 'hills',
  '94024': 'hills',
  '94025': 'hills',
  '94026': 'hills',
  '94027': 'hills',
  '94028': 'hills',
  '94030': 'hills',
  '94034': 'hills',
  '94037': 'coast',
  '94038': 'coast',
  '94040': 'hills',
  '94041': 'hills',
  '94043': 'hills',
  '94044': 'coast',
  '94060': 'coast',
  '94061': 'hills',
  '94062': 'hills',
  '94063': 'hills',
  '94065': 'hills',
  '94066': 'hills',
  '94070': 'hills',
  '94074': 'coast',
  '94080': 'coast',
  '94085': 'hills',
  '94086': 'hills',
  '94087': 'hills',
  '94088': 'hills',
  '94089': 'hills',
  '94101': 'coast',
  '94102': 'coast',
  '94103': 'coast',
  '94104': 'coast',
  '94105': 'coast',
  '94107': 'coast',
  '94108': 'coast',
  '94109': 'coast',
  '94110': 'coast',
  '94111': 'coast',
  '94112': 'coast',
  '94113': 'coast',
  '94114': 'coast',
  '94115': 'coast',
  '94116': 'coast',
  '94117': 'coast',
  '94118': 'coast',
  '94121': 'coast',
  '94122': 'coast',
  '94123': 'coast',
  '94124': 'coast',
  '94127': 'coast',
  '94129': 'coast',
  '94130': 'coast',
  '94131': 'coast',
  '94132': 'coast',
  '94133': 'coast',
  '94134': 'coast',
  '94141': 'coast',
  '94143': 'coast',
  '94158': 'coast',
  '94159': 'coast',
  '94203': 'valley',
  '94211': 'valley',
  '94248': 'valley',
  '94301': 'valley',
  '94303': 'hills',
  '94305': 'hills',
  '94306': 'hills',
  '94401': 'hills',
  '94402': 'hills',
  '94403': 'hills',
  '94404': 'hills',
  '94501': 'coast',
  '94502': 'coast',
  '94503': 'hills',
  '94504': 'hills',
  '94505': 'valley',
  '94506': 'hills',
  '94507': 'hills',
  '94508': 'hills',
  '94509': 'valley',
  '94510': 'hills',
  '94511': 'valley',
  '94512': 'valley',
  '94513': 'valley',
  '94514': 'valley',
  '94515': 'hills',
  '94516': 'hills',
  '94517': 'hills',
  '94518': 'hills',
  '94519': 'hills',
  '94520': 'hills',
  '94521': 'hills',
  '94522': 'hills',
  '94523': 'hills',
  '94525': 'hills',
  '94526': 'hills',
  '94527': 'valley',
  '94528': 'hills',
  '94530': 'coast',
  '94531': 'valley',
  '94533': 'valley',
  '94534': 'valley',
  '94535': 'valley',
  '94536': 'hills',
  '94538': 'hills',
  '94539': 'hills',
  '94541': 'hills',
  '94542': 'hills',
  '94543': 'valley',
  '94544': 'hills',
  '94545': 'hills',
  '94546': 'hills',
  '94547': 'hills',
  '94548': 'valley',
  '94549': 'hills',
  '94550': 'hills',
  '94551': 'hills',
  '94552': 'hills',
  '94553': 'hills',
  '94555': 'hills',
  '94556': 'hills',
  '94558': 'hills',
  '94559': 'hills',
  '94560': 'hills',
  '94561': 'valley',
  '94562': 'hills',
  '94563': 'hills',
  '94564': 'coast',
  '94565': 'valley',
  '94566': 'hills',
  '94567': 'hills',
  '94568': 'hills',
  '94569': 'hills',
  '94571': 'valley',
  '94572': 'hills',
  '94573': 'hills',
  '94574': 'hills',
  '94576': 'hills',
  '94577': 'hills',
  '94578': 'hills',
  '94579': 'hills',
  '94580': 'hills',
  '94582': 'hills',
  '94583': 'hills',
  '94585': 'valley',
  '94586': 'hills',
  '94587': 'hills',
  '94588': 'hills',
  '94589': 'hills',
  '94590': 'hills',
  '94591': 'hills',
  '94595': 'hills',
  '94596': 'hills',
  '94597': 'hills',
  '94598': 'hills',
  '94599': 'hills',
  '94601': 'coast',
  '94602': 'coast',
  '94603': 'coast',
  '94604': 'coast',
  '94605': 'coast',
  '94606': 'coast',
  '94607': 'coast',
  '94608': 'coast',
  '94609': 'coast',
  '94610': 'coast',
  '94611': 'coast',
  '94612': 'coast',
  '94613': 'coast',
  '94618': 'coast',
  '94619': 'coast',
  '94621': 'coast',
  '94662': 'valley',
  '94701': 'coast',
  '94702': 'coast',
  '94703': 'coast',
  '94704': 'coast',
  '94705': 'coast',
  '94706': 'coast',
  '94707': 'coast',
  '94708': 'coast',
  '94709': 'coast',
  '94710': 'coast',
  '94720': 'coast',
  '94801': 'coast',
  '94803': 'coast',
  '94804': 'coast',
  '94805': 'coast',
  '94806': 'coast',
  '94901': 'hills',
  '94903': 'hills',
  '94904': 'hills',
  '94909': 'hills',
  '94913': 'hills',
  '94914': 'hills',
  '94920': 'coast',
  '94922': 'coast',
  '94923': 'coast',
  '94924': 'coast',
  '94925': 'hills',
  '94928': 'hills',
  '94929': 'coast',
  '94930': 'hills',
  '94931': 'hills',
  '94933': 'hills',
  '94937': 'coast',
  '94938': 'hills',
  '94939': 'hills',
  '94940': 'coast',
  '94941': 'coast',
  '94942': 'valley',
  '94945': 'hills',
  '94946': 'hills',
  '94947': 'hills',
  '94949': 'hills',
  '94950': 'coast',
  '94951': 'hills',
  '94952': 'hills',
  '94954': 'hills',
  '94956': 'coast',
  '94957': 'hills',
  '94960': 'hills',
  '94963': 'hills',
  '94964': 'hills',
  '94965': 'coast',
  '94966': 'coast',
  '94970': 'coast',
  '94971': 'coast',
  '94972': 'coast',
  '94973': 'hills',
  '95001': 'coast',
  '95002': 'hills',
  '95003': 'coast',
  '95004': 'coast',
  '95005': 'coast',
  '95006': 'coast',
  '95007': 'coast',
  '95008': 'hills',
  '95010': 'coast',
  '95012': 'coast',
  '95013': 'hills',
  '95014': 'hills',
  '95017': 'coast',
  '95018': 'coast',
  '95019': 'coast',
  '95020': 'hills',
  '95021': 'coast',
  '95023': 'hills',
  '95030': 'hills',
  '95031': 'hills',
  '95032': 'hills',
  '95033': 'coast',
  '95035': 'hills',
  '95037': 'hills',
  '95038': 'hills',
  '95039': 'coast',
  '95041': 'coast',
  '95043': 'hills',
  '95044': 'hills',
  '95045': 'hills',
  '95046': 'hills',
  '95050': 'hills',
  '95051': 'hills',
  '95054': 'hills',
  '95060': 'coast',
  '95062': 'coast',
  '95063': 'coast',
  '95064': 'coast',
  '95065': 'coast',
  '95066': 'coast',
  '95067': 'coast',
  '95070': 'hills',
  '95073': 'coast',
  '95075': 'hills',
  '95076': 'coast',
  '95078': 'coast',
  '95101': 'hills',
  '95103': 'hills',
  '95110': 'hills',
  '95111': 'hills',
  '95112': 'hills',
  '95113': 'hills',
  '95116': 'hills',
  '95117': 'hills',
  '95118': 'hills',
  '95119': 'hills',
  '95120': 'hills',
  '95121': 'hills',
  '95122': 'hills',
  '95123': 'hills',
  '95124': 'hills',
  '95125': 'hills',
  '95126': 'hills',
  '95127': 'hills',
  '95128': 'hills',
  '95129': 'hills',
  '95130': 'hills',
  '95131': 'hills',
  '95132': 'hills',
  '95133': 'hills',
  '95134': 'hills',
  '95135': 'hills',
  '95136': 'hills',
  '95138': 'hills',
  '95139': 'hills',
  '95140': 'hills',
  '95141': 'hills',
  '95148': 'hills',
  '95150': 'hills',
  '95158': 'hills',
  '95201': 'valley',
  '95202': 'valley',
  '95203': 'valley',
  '95204': 'valley',
  '95205': 'valley',
  '95206': 'valley',
  '95207': 'valley',
  '95209': 'valley',
  '95210': 'valley',
  '95212': 'valley',
  '95215': 'valley',
  '95219': 'valley',
  '95220': 'valley',
  '95221': 'desert',
  '95222': 'valley',
  '95223': 'desert',
  '95224': 'desert',
  '95225': 'valley',
  '95226': 'valley',
  '95227': 'valley',
  '95228': 'valley',
  '95229': 'valley',
  '95230': 'valley',
  '95231': 'valley',
  '95232': 'valley',
  '95233': 'desert',
  '95234': 'valley',
  '95236': 'valley',
  '95237': 'valley',
  '95240': 'valley',
  '95241': 'hills',
  '95242': 'valley',
  '95245': 'valley',
  '95246': 'valley',
  '95247': 'valley',
  '95248': 'valley',
  '95249': 'valley',
  '95250': 'valley',
  '95251': 'valley',
  '95252': 'valley',
  '95253': 'valley',
  '95254': 'valley',
  '95255': 'valley',
  '95257': 'valley',
  '95258': 'valley',
  '95301': 'desert',
  '95303': 'desert',
  '95304': 'valley',
  '95305': 'valley',
  '95306': 'desert',
  '95307': 'valley',
  '95309': 'valley',
  '95310': 'valley',
  '95311': 'desert',
  '95312': 'desert',
  '95313': 'valley',
  '95315': 'desert',
  '95316': 'valley',
  '95317': 'desert',
  '95318': 'desert',
  '95319': 'valley',
  '95320': 'valley',
  '95321': 'valley',
  '95322': 'desert',
  '95323': 'valley',
  '95324': 'desert',
  '95325': 'desert',
  '95326': 'valley',
  '95327': 'valley',
  '95328': 'valley',
  '95329': 'desert',
  '95330': 'valley',
  '95333': 'desert',
  '95334': 'desert',
  '95335': 'desert',
  '95336': 'valley',
  '95337': 'valley',
  '95338': 'desert',
  '95339': 'desert',
  '95340': 'desert',
  '95341': 'desert',
  '95344': 'desert',
  '95345': 'desert',
  '95346': 'desert',
  '95347': 'desert',
  '95348': 'desert',
  '95350': 'valley',
  '95351': 'valley',
  '95352': 'valley',
  '95353': 'desert',
  '95354': 'valley',
  '95355': 'valley',
  '95356': 'valley',
  '95357': 'valley',
  '95358': 'valley',
  '95360': 'valley',
  '95361': 'valley',
  '95363': 'valley',
  '95364': 'desert',
  '95365': 'desert',
  '95366': 'valley',
  '95367': 'valley',
  '95368': 'valley',
  '95369': 'desert',
  '95370': 'valley',
  '95372': 'valley',
  '95374': 'desert',
  '95375': 'desert',
  '95376': 'valley',
  '95377': 'valley',
  '95378': 'valley',
  '95379': 'valley',
  '95380': 'valley',
  '95381': 'valley',
  '95382': 'valley',
  '95383': 'desert',
  '95385': 'valley',
  '95386': 'valley',
  '95387': 'valley',
  '95388': 'desert',
  '95389': 'desert',
  '95391': 'valley',
  '95401': 'hills',
  '95402': 'hills',
  '95403': 'hills',
  '95404': 'hills',
  '95405': 'hills',
  '95407': 'hills',
  '95409': 'hills',
  '95410': 'coast',
  '95412': 'coast',
  '95414': 'hills',
  '95415': 'hills',
  '95416': 'hills',
  '95417': 'hills',
  '95418': 'hills',
  '95419': 'hills',
  '95420': 'coast',
  '95421': 'hills',
  '95422': 'valley',
  '95423': 'valley',
  '95424': 'valley',
  '95425': 'hills',
  '95426': 'valley',
  '95427': 'hills',
  '95428': 'hills',
  '95429': 'hills',
  '95430': 'coast',
  '95432': 'coast',
  '95433': 'hills',
  '95434': 'valley',
  '95435': 'valley',
  '95436': 'hills',
  '95437': 'coast',
  '95439': 'hills',
  '95441': 'hills',
  '95442': 'hills',
  '95443': 'valley',
  '95444': 'hills',
  '95445': 'coast',
  '95446': 'hills',
  '95448': 'hills',
  '95449': 'hills',
  '95450': 'coast',
  '95451': 'valley',
  '95452': 'hills',
  '95453': 'valley',
  '95454': 'hills',
  '95456': 'coast',
  '95457': 'valley',
  '95458': 'valley',
  '95459': 'coast',
  '95460': 'coast',
  '95461': 'valley',
  '95462': 'hills',
  '95463': 'hills',
  '95464': 'valley',
  '95465': 'hills',
  '95466': 'hills',
  '95467': 'valley',
  '95468': 'coast',
  '95469': 'hills',
  '95470': 'hills',
  '95471': 'hills',
  '95472': 'hills',
  '95474': 'hills',
  '95476': 'hills',
  '95480': 'coast',
  '95481': 'hills',
  '95482': 'hills',
  '95485': 'valley',
  '95486': 'hills',
  '95488': 'coast',
  '95490': 'hills',
  '95492': 'hills',
  '95493': 'valley',
  '95494': 'hills',
  '95497': 'coast',
  '95501': 'coast',
  '95502': 'coast',
  '95503': 'coast',
  '95511': 'desert',
  '95514': 'desert',
  '95518': 'coast',
  '95519': 'coast',
  '95521': 'coast',
  '95524': 'coast',
  '95525': 'coast',
  '95526': 'desert',
  '95527': 'desert',
  '95528': 'coast',
  '95536': 'coast',
  '95537': 'coast',
  '95540': 'coast',
  '95542': 'desert',
  '95545': 'desert',
  '95546': 'desert',
  '95547': 'coast',
  '95549': 'coast',
  '95550': 'coast',
  '95551': 'coast',
  '95552': 'desert',
  '95553': 'desert',
  '95554': 'desert',
  '95555': 'coast',
  '95556': 'desert',
  '95558': 'coast',
  '95559': 'desert',
  '95560': 'desert',
  '95561': 'coast',
  '95562': 'coast',
  '95563': 'desert',
  '95564': 'coast',
  '95565': 'coast',
  '95568': 'desert',
  '95569': 'coast',
  '95570': 'coast',
  '95571': 'desert',
  '95573': 'desert',
  '95585': 'hills',
  '95587': 'hills',
  '95589': 'coast',
  '95595': 'desert',
  '95601': 'valley',
  '95602': 'valley',
  '95603': 'valley',
  '95604': 'valley',
  '95605': 'valley',
  '95606': 'valley',
  '95607': 'valley',
  '95608': 'valley',
  '95610': 'valley',
  '95612': 'valley',
  '95613': 'valley',
  '95614': 'valley',
  '95615': 'valley',
  '95616': 'valley',
  '95617': 'valley',
  '95618': 'valley',
  '95619': 'valley',
  '95620': 'valley',
  '95621': 'valley',
  '95623': 'valley',
  '95624': 'valley',
  '95625': 'valley',
  '95626': 'valley',
  '95627': 'valley',
  '95628': 'valley',
  '95629': 'valley',
  '95630': 'valley',
  '95631': 'valley',
  '95632': 'valley',
  '95633': 'valley',
  '95634': 'valley',
  '95635': 'valley',
  '95636': 'desert',
  '95637': 'valley',
  '95639': 'valley',
  '95640': 'valley',
  '95641': 'valley',
  '95642': 'valley',
  '95643': 'valley',
  '95645': 'valley',
  '95648': 'valley',
  '95650': 'valley',
  '95651': 'valley',
  '95652': 'valley',
  '95653': 'valley',
  '95654': 'valley',
  '95656': 'valley',
  '95658': 'valley',
  '95659': 'valley',
  '95660': 'valley',
  '95661': 'valley',
  '95662': 'valley',
  '95663': 'valley',
  '95664': 'valley',
  '95665': 'valley',
  '95666': 'desert',
  '95667': 'valley',
  '95668': 'valley',
  '95669': 'valley',
  '95670': 'valley',
  '95672': 'valley',
  '95673': 'valley',
  '95674': 'valley',
  '95675': 'valley',
  '95676': 'valley',
  '95677': 'valley',
  '95678': 'valley',
  '95679': 'valley',
  '95680': 'valley',
  '95681': 'valley',
  '95682': 'valley',
  '95684': 'valley',
  '95685': 'valley',
  '95686': 'valley',
  '95687': 'valley',
  '95688': 'valley',
  '95689': 'desert',
  '95690': 'valley',
  '95691': 'valley',
  '95692': 'valley',
  '95694': 'valley',
  '95695': 'valley',
  '95697': 'valley',
  '95698': 'valley',
  '95699': 'valley',
  '95701': 'desert',
  '95703': 'valley',
  '95709': 'desert',
  '95712': 'valley',
  '95713': 'valley',
  '95714': 'desert',
  '95715': 'desert',
  '95717': 'desert',
  '95720': 'desert',
  '95721': 'desert',
  '95722': 'valley',
  '95724': 'desert',
  '95726': 'desert',
  '95728': 'desert',
  '95735': 'desert',
  '95736': 'valley',
  '95742': 'valley',
  '95746': 'valley',
  '95747': 'valley',
  '95757': 'valley',
  '95758': 'valley',
  '95762': 'valley',
  '95765': 'valley',
  '95776': 'valley',
  '95811': 'valley',
  '95812': 'valley',
  '95814': 'valley',
  '95815': 'valley',
  '95816': 'valley',
  '95817': 'valley',
  '95818': 'valley',
  '95819': 'valley',
  '95820': 'valley',
  '95821': 'valley',
  '95822': 'valley',
  '95823': 'valley',
  '95824': 'valley',
  '95825': 'valley',
  '95826': 'valley',
  '95827': 'valley',
  '95828': 'valley',
  '95829': 'valley',
  '95830': 'valley',
  '95831': 'valley',
  '95832': 'valley',
  '95833': 'valley',
  '95834': 'valley',
  '95835': 'valley',
  '95837': 'valley',
  '95838': 'valley',
  '95841': 'valley',
  '95842': 'valley',
  '95843': 'valley',
  '95864': 'valley',
  '95901': 'valley',
  '95903': 'valley',
  '95910': 'desert',
  '95912': 'valley',
  '95913': 'valley',
  '95914': 'valley',
  '95915': 'desert',
  '95916': 'valley',
  '95917': 'valley',
  '95918': 'valley',
  '95919': 'valley',
  '95920': 'valley',
  '95922': 'valley',
  '95923': 'desert',
  '95924': 'valley',
  '95925': 'valley',
  '95926': 'valley',
  '95927': 'valley',
  '95928': 'valley',
  '95930': 'desert',
  '95932': 'valley',
  '95934': 'desert',
  '95935': 'valley',
  '95936': 'desert',
  '95937': 'valley',
  '95938': 'valley',
  '95939': 'valley',
  '95940': 'valley',
  '95941': 'valley',
  '95942': 'valley',
  '95943': 'valley',
  '95944': 'desert',
  '95945': 'valley',
  '95946': 'valley',
  '95947': 'desert',
  '95948': 'valley',
  '95949': 'valley',
  '95950': 'valley',
  '95951': 'valley',
  '95953': 'valley',
  '95954': 'valley',
  '95955': 'valley',
  '95956': 'desert',
  '95957': 'valley',
  '95958': 'valley',
  '95959': 'valley',
  '95960': 'valley',
  '95961': 'valley',
  '95962': 'valley',
  '95963': 'valley',
  '95965': 'valley',
  '95966': 'valley',
  '95967': 'valley',
  '95968': 'valley',
  '95969': 'valley',
  '95970': 'valley',
  '95971': 'desert',
  '95972': 'valley',
  '95973': 'valley',
  '95974': 'valley',
  '95975': 'valley',
  '95977': 'valley',
  '95978': 'desert',
  '95979': 'valley',
  '95980': 'desert',
  '95981': 'desert',
  '95982': 'valley',
  '95983': 'desert',
  '95984': 'desert',
  '95986': 'valley',
  '95987': 'valley',
  '95988': 'valley',
  '95991': 'valley',
  '95993': 'valley',
  '96001': 'desert',
  '96002': 'desert',
  '96003': 'desert',
  '96007': 'desert',
  '96008': 'desert',
  '96009': 'desert',
  '96010': 'desert',
  '96011': 'desert',
  '96013': 'desert',
  '96016': 'desert',
  '96019': 'desert',
  '96020': 'desert',
  '96021': 'desert',
  '96022': 'desert',
  '96028': 'desert',
  '96029': 'desert',
  '96033': 'desert',
  '96035': 'desert',
  '96040': 'desert',
  '96041': 'desert',
  '96047': 'desert',
  '96051': 'desert',
  '96055': 'desert',
  '96056': 'desert',
  '96059': 'desert',
  '96061': 'desert',
  '96062': 'desert',
  '96063': 'desert',
  '96065': 'desert',
  '96068': 'desert',
  '96069': 'desert',
  '96071': 'desert',
  '96073': 'desert',
  '96074': 'desert',
  '96075': 'desert',
  '96076': 'desert',
  '96078': 'desert',
  '96080': 'desert',
  '96084': 'desert',
  '96087': 'desert',
  '96088': 'desert',
  '96089': 'desert',
  '96090': 'desert',
  '96092': 'desert',
  '96095': 'desert',
  '96096': 'desert',
  '96125': 'desert',
  '96137': 'desert',
};
