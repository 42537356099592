import { useState, useRef, useEffect, useCallback } from 'react';
import transformChargerDetails from '../../../api/transformers/transformChargerDetails';
import { useCachedFetch } from '../../../context/apiCacheProvider';
import { ApiTomTomChargingStation, TomTomChargingStation } from '../../../types';

const CHARGER_URL = 'https://api.tomtom.com/search/2/place.json';

export default function useChargerDetails(id: string) {
  const { fetchRecords } = useCachedFetch();
  const [loading, setLoading] = useState(false);
  const [charger, setCharger] = useState<TomTomChargingStation | null>(null);
  const [error, setError] = useState('');

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchChargerDetails = useCallback(
    async (id: string) => {
      if (!id) return;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      try {
        abortControllerRef.current = new window.AbortController();
        setLoading(true);

        const json = await fetchRecords<{ results: ApiTomTomChargingStation[] }>(CHARGER_URL, {
          key: process.env.REACT_APP_TOMTOM_API_KEY || '',
          entityId: id,
        });

        abortControllerRef.current = null;

        setLoading(false);

        if (json) {
          setCharger(transformChargerDetails(json.results[0]));
        }
      } catch (error: any) {
        setLoading(false);

        if (error.name !== 'AbortError') {
          setError(error);
        }
        abortControllerRef.current = null;
      }
    },
    [fetchRecords],
  );

  useEffect(() => {
    fetchChargerDetails(id);
  }, [fetchChargerDetails, id]);

  return {
    error,
    charger,
    loading,
  };
}
