import { useCallback, useState } from 'react';
import type { Bounds, Coords } from '../../../types';

const useMapBounds = () => {
  const [bounds, setBounds] = useState<Bounds>();
  const [center, setCenter] = useState<Coords>();

  const registerMapBoundsListeners = (map: google.maps.Map) => {
    const updateMapBounds = () => {
      const bounds = map.getBounds();
      const mapCenter = map.getCenter();

      if (bounds) {
        setBounds({
          top: bounds.getNorthEast().lat(),
          right: bounds.getNorthEast().lng(),
          bottom: bounds.getSouthWest().lat(),
          left: bounds.getSouthWest().lng(),
        });
      }

      if (mapCenter) {
        setCenter({
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        });
      }
    };

    map.addListener('idle', updateMapBounds);
    map.addListener('tilesloaded', updateMapBounds);
  };

  const withinCurrentBounds = useCallback(
    (lat: number, lng: number): boolean => {
      if (!bounds) return false;
      const { top, bottom, left, right } = bounds;

      if (!top || !bottom || !left || !right) {
        return false;
      }
      if (top >= lat && lat >= bottom) {
        if (left <= right && left <= lng && lng <= right) {
          return true;
        } else if (left > right && (left <= lng || lng <= right)) {
          return true;
        }
      }
      return false;
    },
    [bounds],
  );

  const filterWithinBounds = useCallback(
    <T extends { lat?: number; lng?: number; latitude?: number; longitude?: number }>(
      things: Array<T>,
    ): Array<T> => {
      return (things || []).filter((thing) => {
        const lat = thing.latitude || thing.lat;
        const lng = thing.longitude || thing.lng;
        return lat && lng ? withinCurrentBounds(lat, lng) : false;
      });
    },
    [withinCurrentBounds],
  );

  return {
    bounds,
    center,
    filterWithinBounds,
    registerMapBoundsListeners,
    withinCurrentBounds,
  };
};

export default useMapBounds;
