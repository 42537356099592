import { Box } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import FeedbackModal from './FeedbackModal';

export default function FeedbackFixedButton(): JSX.Element {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box component="div" position="fixed" left="0" top={{ xs: '85%', md: '50%' }}>
      <Box
        component="button"
        p="0.5rem 1.75rem"
        fontSize={{ xs: '0.75rem', md: '1rem' }}
        fontWeight="400"
        sx={{
          border: 'none',
          color: 'black.100',
          backgroundColor: 'blue.200',
          transform: 'rotate(270deg)',
          transformOrigin: 'left top 0',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
      >
        {formatMessage({ id: 'feedback.title' })}
      </Box>
      <FeedbackModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
}
