import type { ApiGasVehicle, GasVehicle } from '../../types';
import { transformVehicleIncentive } from './transformVehicleIncentive';

export const transformGasVehicle = (apiVehicle: ApiGasVehicle): GasVehicle => {
  return {
    id: `${apiVehicle.fossil_fuel_vehicle_id}`,
    epaId: `${apiVehicle.epa_id}`,
    handle: apiVehicle.handle,
    msrp: apiVehicle.msrp,
    modelYear: apiVehicle.model_year,
    make: apiVehicle.make,
    model: apiVehicle.model,
    subtype: apiVehicle.form_factor,
    displayName: apiVehicle.display_name,
    type: apiVehicle.type,
    trim: apiVehicle.trim,
    fuelType: apiVehicle.fuel === 'gas' ? 'GAS' : apiVehicle.fuel,
    doors: apiVehicle.doors,
    seatsMin: apiVehicle.seats_min,
    seatsMax: apiVehicle.seats_max,
    cargoVolumeMin: apiVehicle.cargo_volume_min,
    cargoVolumeMax: apiVehicle.cargo_volume_max,
    acceleration: apiVehicle.acceleration,
    officialWebsiteUrl: apiVehicle.official_website_url,
    totalRange: apiVehicle.total_range,
    fossilFuelEfficiency: apiVehicle.fossil_fuel_efficiency,
    drivetrain: apiVehicle.drivetrain,
    curbWeight: apiVehicle.ac_charging_power,
    images: apiVehicle.images.map((image) => ({
      altText: image.alt,
      urlFull: image.url_full,
      urlThumbnail: image.url_thumbnail || '',
    })),
    incentives: apiVehicle.incentives.map(transformVehicleIncentive),
  };
};
