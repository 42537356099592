import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../../../components/card/Card';

export default function RangeAssumptions() {
  const { formatMessage } = useIntl();

  return (
    <Card>
      <Box display="flex" flexDirection="column">
        <Typography variant="label1" mb={2}>
          {formatMessage({ id: 'compare.assumptions.header' })}
        </Typography>
        <Typography fontSize="0.75rem">
          {formatMessage({ id: 'compare.assumptions.range' })}
        </Typography>
      </Box>
    </Card>
  );
}
