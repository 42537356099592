// Source: https://docs.google.com/document/d/1IxO36l_f6CJGLjfmNWTvziNJv7trLSTVOLaI9NoIkj0/edit

import capitalizedInLease from '../../../incentives/capitalization/capitalizedInLease';
import nonCapitalizedInLease from '../../../incentives/capitalization/nonCapitalizedInLease';
import capitalizedInPurchase from '../../../incentives/capitalization/capitalizedInPurchase';

import ResidualValue from '../../../financial/calcResidualValue';
import LoanPayment from '../../../financial/calcLoanPayment';

import { DOWN_PAYMENT, SALES_TAX } from '../../../../data/assumptions/ASSUMPTIONS';
import type { PurchaseMethod, Vehicle } from '../../../../types';
import isElectricVehicle from '../../isElectricVehicle';

const salesTax = SALES_TAX.value;

const VehicleCost = {
  msrpPlusTax: function (vehicle: Vehicle): number {
    return vehicle.msrp * (1 + salesTax);
  },

  afterIncentives: function (vehicle: Vehicle): number {
    const incentives = isElectricVehicle(vehicle) ? capitalizedInPurchase(vehicle.incentives) : 0;
    return vehicle.msrp - incentives;
  },

  downPayment: function (vehicle: Vehicle): number {
    return this.msrpPlusTax(vehicle) * DOWN_PAYMENT.value;
  },

  resaleValue: function (vehicle: Vehicle, lifetimeMiles: number): number {
    return vehicle.msrp * ResidualValue(lifetimeMiles);
  },

  incentives: function (vehicle: Vehicle, purchaseMethod: PurchaseMethod): number {
    if (isElectricVehicle(vehicle)) {
      return purchaseMethod === 'lease'
        ? capitalizedInLease(vehicle.incentives)
        : capitalizedInPurchase(vehicle.incentives);
    }
    return 0;
  },

  nonCapitalizedLeaseIncentives: function (vehicle: Vehicle): number {
    return isElectricVehicle(vehicle) ? nonCapitalizedInLease(vehicle.incentives) : 0;
  },

  loanAmountToBeFinanced: function (vehicle: Vehicle): number {
    return this.msrpPlusTax(vehicle) - this.downPayment(vehicle);
  },

  monthlyLoanPayment: function (
    vehicle: Vehicle,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
  ): number {
    return LoanPayment.monthly(
      interestRateAsBasisPoints,
      monthsOfOwnership,
      this.loanAmountToBeFinanced(vehicle),
    );
  },

  monthlyLoanPaymentsTotal: function (
    vehicle: Vehicle,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
  ): number {
    return (
      monthsOfOwnership *
      this.monthlyLoanPayment(vehicle, monthsOfOwnership, interestRateAsBasisPoints)
    );
  },

  moneyFactor: function (interestRateAsBasisPoints: number): number {
    return interestRateAsBasisPoints / 240000;
  },

  leaseCapitalizedCost: function (vehicle: Vehicle): number {
    const incentives = isElectricVehicle(vehicle) ? this.incentives(vehicle, 'lease') : 0;
    return vehicle.msrp - incentives - this.downPayment(vehicle);
  },

  monthlyDepreciationCost: function (
    vehicle: Vehicle,
    lifetimeMiles: number,
    monthsOfOwnership: number,
  ): number {
    return (
      (this.leaseCapitalizedCost(vehicle) - this.resaleValue(vehicle, lifetimeMiles)) /
      monthsOfOwnership
    );
  },

  monthlyFinancingCost: function (
    vehicle: Vehicle,
    lifetimeMiles: number,
    interestRateAsBasisPoints: number,
  ): number {
    return (
      (this.leaseCapitalizedCost(vehicle) + this.resaleValue(vehicle, lifetimeMiles)) *
      this.moneyFactor(interestRateAsBasisPoints)
    );
  },

  monthlyLeaseDepreciationAndFinancingCosts: function (
    vehicle: Vehicle,
    annualMiles: number,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
  ): number {
    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

    return (
      this.monthlyDepreciationCost(vehicle, lifetimeMiles, monthsOfOwnership) +
      this.monthlyFinancingCost(vehicle, lifetimeMiles, interestRateAsBasisPoints)
    );
  },

  monthlyLeasePayment: function (
    vehicle: Vehicle,
    annualMiles: number,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
  ): number {
    return (
      (1 + salesTax) *
      this.monthlyLeaseDepreciationAndFinancingCosts(
        vehicle,
        annualMiles,
        monthsOfOwnership,
        interestRateAsBasisPoints,
      )
    );
  },

  firstLeasePayment: function (
    vehicle: Vehicle,
    annualMiles: number,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
  ): number {
    return (
      this.downPayment(vehicle) +
      this.monthlyLeasePayment(vehicle, annualMiles, monthsOfOwnership, interestRateAsBasisPoints)
    );
  },

  total: function (
    vehicle: Vehicle,
    annualMiles: number,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
    purchaseMethod: PurchaseMethod,
    includeResaleValue?: boolean,
  ): number {
    if (!vehicle) return 0;

    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

    let cost = 0;

    const resaleValue = includeResaleValue ? this.resaleValue(vehicle, lifetimeMiles) : 0;

    switch (purchaseMethod) {
      case 'loan':
        cost =
          this.downPayment(vehicle) +
          this.monthlyLoanPaymentsTotal(vehicle, monthsOfOwnership, interestRateAsBasisPoints) -
          this.incentives(vehicle, 'loan') -
          resaleValue;
        break;
      case 'lease':
        cost =
          this.firstLeasePayment(
            vehicle,
            annualMiles,
            monthsOfOwnership,
            interestRateAsBasisPoints,
          ) +
          (monthsOfOwnership - 1) *
            this.monthlyLeasePayment(
              vehicle,
              annualMiles,
              monthsOfOwnership,
              interestRateAsBasisPoints,
            ) -
          this.nonCapitalizedLeaseIncentives(vehicle);
        break;
      default:
        // case 'cash'
        cost = this.msrpPlusTax(vehicle) - this.incentives(vehicle, 'cash') - resaleValue;
    }
    return isFinite(cost) ? cost : 0;
  },

  perMile: function (
    vehicle: Vehicle,
    annualMiles: number,
    monthsOfOwnership: number,
    interestRateAsBasisPoints: number,
    purchaseMethod: PurchaseMethod,
    includeResaleValue?: boolean,
  ): number {
    const totalVehicleCost = this.total(
      vehicle,
      annualMiles,
      monthsOfOwnership,
      interestRateAsBasisPoints,
      purchaseMethod,
      includeResaleValue,
    );
    const cost = totalVehicleCost / (annualMiles / 12) / monthsOfOwnership;

    return isFinite(cost) ? cost : 0;
  },
};

export default VehicleCost;
