import { Box, Divider, Typography } from '@mui/material';
import { VehicleIncentive } from '../../../types/vehicleIncentive';
import { formatAsDollars } from '../../../utils/formatters';
import Link from '../../../components/link/Link';
import Card from '../../../components/card/Card';
import Icon from '../../../components/icon/Icon';
import type { IconVariant } from '../../../components/icon/Icon';
import GaTracker from '../../../utils/GaTracker/GaTracker';
import { Incentive } from '../../../types';

const isVehicleIncentive = (
  incentive: VehicleIncentive | Incentive,
): incentive is VehicleIncentive => {
  return incentive.hasOwnProperty('evaluation');
};

type Props = {
  incentive: VehicleIncentive | Incentive;
};

export default function IncentiveCard({ incentive }: Props): JSX.Element {
  const { grantor = '', type = '', name = '', description = '', typicalAmount = '' } = incentive;
  const evaluation = isVehicleIncentive(incentive) ? incentive.evaluation : null;

  const handleClickIncentive = () => {
    GaTracker.trackEvent({
      category: 'Incentives',
      action: 'Clicked Incentive Card',
      label: `${name} from ${grantor}`,
    });
  };

  // Source: https://docs.google.com/spreadsheets/d/1PzIwWxazz8MsT67AkAhXJsI9OPOAPlL1AA6PHV6WQDs
  let iconVariant: IconVariant = 'savings';
  switch (type) {
    case 'Parking exemption':
      iconVariant = 'parking';
      break;
    case 'charging_network_rebate':
    case 'discounted_electric_rate':
    case 'Public Charging':
      iconVariant = 'charging';
      break;
    case 'vehicle_retirement':
      iconVariant = 'pollution';
      break;
    case 'HOV lane':
    case 'Toll discount':
      iconVariant = 'road';
      break;
    default:
  }

  const title =
    evaluation && evaluation.amountInPurchase && evaluation.amountInPurchase > 0 ? (
      formatAsDollars(evaluation.amountInPurchase)
    ) : typicalAmount !== 'N/A' ? (
      typicalAmount.replace('-', '- ')
    ) : (
      <Icon variant={iconVariant} sx={{ mx: 'auto', height: '60px', width: '60px' }} />
    );
  return (
    <Link external to={incentive.detailsUrl} onClick={handleClickIncentive}>
      <Card
        sx={{
          p: 0,
          height: '100%',
          borderRadius: '4px',
          boxShadow: 0,
          ':hover': {
            boxShadow: 5,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          m="7px"
          fontSize="14px"
          fontWeight="400"
          minHeight="30px"
          px={1}
          sx={{
            color: grantor === 'PG&E' ? 'black.100' : 'white.100',
            backgroundColor: grantor === 'PG&E' ? 'blue.200' : 'blue.400',
            borderRadius: '4px',
          }}
        >
          {grantor}
        </Box>
        <Box textAlign="center" m="19px">
          <Typography
            component="h5"
            fontWeight="500"
            fontSize="1.75rem"
            mt="15px"
            mb={{ xs: '15px', md: '30px' }}
          >
            {title}
          </Typography>
          <Typography component="h6" fontWeight="500">
            {name}
          </Typography>
        </Box>
        <Divider />
        <Box
          p={{ xs: '10px', md: '19px' }}
          textAlign="center"
          fontWeight="300"
          fontSize={{ xs: '0.875rem', md: '1rem' }}
          color={{ xs: 'grey.800', md: 'grey.700' }}
        >
          {description}
        </Box>
      </Card>
    </Link>
  );
}
