import { Box, Button, Popover, SxProps, Theme } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { HamburgerMenuIcon, MenuCloseIcon } from '../icon/Vectors';
import MobileNavMenu from '../mobileNavMenu/MobileNavMenu';
import logo from './../../assets/images/pge_spot_full_rgb_rev_md.png';
import Search from './../../assets/images/icons/search.svg';
import { useLanguage } from '../../utils/ReactIntlProvider';

type MenuItemProps = {
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  subMenu?: boolean;
  children: ReactNode;
};

function MenuItem({ to, onClick, subMenu, children }: MenuItemProps) {
  const location = useLocation();

  const commonStyle: SxProps<Theme> = {
    flex: '0 0 auto',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    fontSize: '0.875rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: (theme) => theme.palette.white[100],
  };

  const style: SxProps<Theme> = subMenu
    ? {
        ...commonStyle,
        px: '10px',
        py: '10px',
        width: '100%',
        borderLeft: '5px solid transparent',
        '&:hover': { borderLeft: (theme) => `5px solid ${theme.palette.orange[500]}` },
      }
    : {
        ...commonStyle,
        borderBottom: '4px solid transparent',
        '&:hover': { borderBottom: (theme) => `4px solid ${theme.palette.orange[500]}` },
      };

  if (to) {
    const active = location.pathname === to;

    return (
      <Link to={to} onClick={onClick} style={{ textDecoration: 'none' }}>
        <Box
          component="span"
          sx={{
            ...style,
            borderBottom: subMenu ? 'none' : '4px solid',
            borderColor: active ? 'orange.500' : 'transparent',
            color: active && subMenu ? 'orange.500' : 'white.100',
          }}
        >
          {children}
        </Box>
      </Link>
    );
  } else {
    return (
      <Link
        to=""
        onClick={(event) => {
          event.preventDefault();
          onClick && onClick(event);
        }}
        style={{ textDecoration: 'none' }}
      >
        <Box sx={style}>{children}</Box>
      </Link>
    );
  }
}

type SubMenuProps = {
  title: string;
  children?: ReactNode;
};

const SubMenu = ({ title, children }: SubMenuProps) => {
  const buttonRef = useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const location = useLocation();

  const onClose = () => setMenuAnchorEl(null);

  useEffect(() => {
    // When the URL changes, the menu should close
    location.pathname && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <MenuItem to="">
        <Button
          ref={buttonRef}
          onClick={() => setMenuAnchorEl(buttonRef.current)}
          aria-controls="maps-nav-menu"
          aria-label={menuAnchorEl ? 'Close nav menu' : 'Open nav menu'}
          aria-expanded={menuAnchorEl ? 'true' : 'false'}
          disableRipple
          sx={{
            height: '100%',
            padding: 0,
            margin: 0,
            color: 'white.100',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&::after': {
              display: 'inline-block',
              marginLeft: '0.255em',
              verticalAlign: '0.255em',
              content: '""',
              borderTop: '0.3em solid',
              borderRight: '0.3em solid transparent',
              borderBottom: '0',
              borderLeft: '0.3em solid transparent',
            },
          }}
        >
          {title}
        </Button>
      </MenuItem>

      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="maps-nav-menu"
        marginThreshold={0}
        PaperProps={{
          sx: {
            borderRadius: 0,
            backgroundColor: 'blue.600',
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default function Header() {
  const { formatMessage } = useIntl();
  const { setLanguage } = useLanguage();

  const headerRef = useRef(null);

  const [mobileNavMenuAnchorEl, setMobileNavMenuAnchorEl] = useState<Element | null>(null);

  return (
    <Box
      component="header"
      ref={headerRef}
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="0 1rem"
      height="80px"
      sx={{
        color: 'white',
        backgroundColor: 'blue.600',
      }}
    >
      <Box>
        <Link to="/">
          <img alt="PG&amp;E Logo" src={logo} width="56" />
        </Link>
      </Box>
      <Box
        component="nav"
        aria-label="primary"
        height="100%"
        display={{ xs: 'none', md: 'flex' }}
        flexDirection="row"
        gap={3}
        zIndex={100}
      >
        <MenuItem to="/">{formatMessage({ id: 'nav.home' })}</MenuItem>
        <SubMenu title={formatMessage({ id: 'nav.incentives' })}>
          <MenuItem subMenu to="/income-qualified">
            {formatMessage({ id: 'nav.incomeQualified' })}
          </MenuItem>
          <MenuItem subMenu to="/incentives">
            {formatMessage({ id: 'nav.allOtherIncentives' })}
          </MenuItem>
        </SubMenu>
        <SubMenu title={formatMessage({ id: 'nav.vehiclesAndRates' })}>
          <MenuItem subMenu to="/vehicles">
            {formatMessage({ id: 'nav.vehicles' })}
          </MenuItem>
          <MenuItem subMenu to="/rates">
            {formatMessage({ id: 'nav.rates' })}
          </MenuItem>
        </SubMenu>
        <MenuItem to="/compare">{formatMessage({ id: 'nav.compare' })}</MenuItem>
        <SubMenu title={formatMessage({ id: 'nav.maps' })}>
          <MenuItem to="/charging-stations" subMenu>
            {formatMessage({ id: 'nav.stations' })}
          </MenuItem>
          <MenuItem to="/charging-station-availability" subMenu>
            {formatMessage({ id: 'nav.availability' })}
          </MenuItem>
          <MenuItem to="/travel-range" subMenu>
            {formatMessage({ id: 'nav.range' })}
          </MenuItem>
          <MenuItem to="/map-a-route" subMenu>
            {formatMessage({ id: 'nav.route' })}
          </MenuItem>
          <MenuItem to="/dealers" subMenu>
            {formatMessage({ id: 'nav.dealer' })}
          </MenuItem>
        </SubMenu>
        <MenuItem to="/search">
          <img src={Search} alt="Search" />
        </MenuItem>
        <SubMenu title={formatMessage({ id: 'languageName' })}>
          <MenuItem onClick={() => setLanguage('en')} subMenu>
            English
          </MenuItem>
          <MenuItem onClick={() => setLanguage('es')} subMenu>
            Español
          </MenuItem>
          <MenuItem onClick={() => setLanguage('zh')} subMenu>
            中文
          </MenuItem>
        </SubMenu>
      </Box>

      <Box display={{ xs: 'block', md: 'none' }}>
        <Button
          onClick={() => setMobileNavMenuAnchorEl(headerRef.current)}
          aria-controls="mobile-nav-menu"
          aria-label={mobileNavMenuAnchorEl ? 'Close nav menu' : 'Open nav menu'}
          aria-expanded={mobileNavMenuAnchorEl ? 'true' : 'false'}
        >
          <Box display={mobileNavMenuAnchorEl ? 'flex' : 'none'} alignItems="center">
            <MenuCloseIcon />
          </Box>
          <Box display={mobileNavMenuAnchorEl ? 'none' : 'flex'} alignItems="center">
            <HamburgerMenuIcon />
          </Box>
        </Button>

        <MobileNavMenu
          open={Boolean(mobileNavMenuAnchorEl)}
          anchorEl={mobileNavMenuAnchorEl}
          onClose={() => setMobileNavMenuAnchorEl(null)}
        />
      </Box>
    </Box>
  );
}
