import { ReactNode, useState } from 'react';
import { Collapse, Box } from '@mui/material';
import Button from '../button/Button';

type Props = {
  children: ReactNode;
  openText: ReactNode;
  closeText: ReactNode;
  buttonPosition: 'left' | 'center' | 'right';
};

export default function CollapseToggleButton({
  children,
  openText,
  closeText,
  buttonPosition,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box textAlign={buttonPosition}>
        <Button variant="outlined" size="large" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? closeText : openText}
        </Button>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
}
