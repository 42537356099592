import { Box, Typography } from '@mui/material';
import Link from '../../../../components/link/Link';

type Props = {
  label: string;
  value: string;
  link?: string;
};

export default function AssumptionLineItem({ label, value, link }: Props) {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Typography fontSize="0.75rem">{label}</Typography>
      {link ? (
        <Link
          external
          to={link}
          sx={{ fontWeight: 'normal', fontSize: '0.75rem', textDecoration: 'underline' }}
        >
          {value}
        </Link>
      ) : (
        <Typography fontSize="0.75rem">{value}</Typography>
      )}
    </Box>
  );
}
