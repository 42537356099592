import { useState } from 'react';
import Select from '../select/Select';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { times } from 'lodash';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import Button from '../button/Button';
import GaTracker from '../../utils/GaTracker/GaTracker';
import { useIntl } from 'react-intl';

type CloseButtonProps = {
  onClick: () => void;
  isMobile?: boolean;
};

const CloseButton = ({ onClick, isMobile }: CloseButtonProps) => {
  return (
    <Box
      component="button"
      type="button"
      className="btn-close"
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: '1rem',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        color: 'grey.500',
        fontSize: '2rem',
        top: isMobile ? '1rem' : 'auto',
      }}
    >
      &times;
    </Box>
  );
};

type SubmitButtonProps = {
  disabled: boolean;
  isSubmitting: boolean;
  onClick: () => void;
};

const SubmitButton = ({ disabled, isSubmitting, onClick }: SubmitButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <Button disabled={disabled} onClick={onClick} size="small">
      {isSubmitting
        ? formatMessage({ id: 'common.submitting' })
        : formatMessage({ id: 'common.submit' })}
    </Button>
  );
};

const zeroThroughTen = times(11);

const NpsScore = () => {
  const [score, setScore] = useState<number | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { formatMessage } = useIntl();

  const SCORE_OPTIONS = [
    {
      label: formatMessage({ id: 'npsScore.selectResponse' }),
      value: '',
    },
    ...zeroThroughTen.map((num) => {
      let label;
      if (num === 0) {
        label = '0 - ' + formatMessage({ id: 'npsScore.notLikely' });
      } else if (num === 10) {
        label = '10 - ' + formatMessage({ id: 'npsScore.veryLikely' });
      } else {
        label = num;
      }
      return {
        value: `${num}`,
        label,
      };
    }),
  ];

  const closeSurvey = () => {
    const currentTime = new Date().getTime();
    setUserPrefs({ closedExperienceSurveyAt: currentTime });
  };

  const handleClickScoreButton = (num: number) => {
    if (score === num) {
      setScore(undefined);
    } else {
      setScore(num);
    }
  };

  const handleClickClose = () => {
    closeSurvey();
  };

  const handleSelectScore = (value: string) => {
    value === '' ? setScore(undefined) : setScore(parseInt(value));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    GaTracker.trackEvent({
      category: 'User',
      action: 'NPS survey',
      label: `Submitted score of ${score}`,
      value: score,
    });

    // Simulate a loading state so the banner doesn't just disappear
    setTimeout(() => {
      closeSurvey();
      setIsSubmitting(false);
    }, 500);
  };

  if (userPrefs.closedExperienceSurveyAt) return null;

  const isSubmitButtonDisabled = score === undefined || isSubmitting;

  return (
    <Box height={{ xs: 90, lg: 60 }} minHeight={{ xs: 90, lg: 60 }} position="sticky" bottom={0}>
      <Box
        height={{ xs: 90, lg: 60 }}
        minHeight={{ xs: 90, lg: 0 }}
        pt={1}
        width="100%"
        zIndex={1}
        bottom={0}
        color="grey.700"
        bgcolor="#FFF"
        textAlign="center"
      >
        <Box
          display={{ xs: 'none', lg: 'flex' }}
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Typography fontSize="0.85rem">{formatMessage({ id: 'npsScore.prompt' })}</Typography>
          <Box mx={4} className="nps-buttons-container" display="flex" alignItems="center">
            <Typography fontSize="0.6rem" textTransform="uppercase" mx={0.5}>
              {formatMessage({ id: 'npsScore.notLikely' })}
            </Typography>
            {zeroThroughTen.map((num) => (
              <MuiButton
                key={`nps-butditon-${num}`}
                onClick={() => handleClickScoreButton(num)}
                sx={{
                  minWidth: 0,
                  height: 28,
                  width: 28,
                  border: '1px solid',
                  borderColor: 'grey.300',
                  color: score === num ? '#FFF' : 'grey.800',
                  backgroundColor: score === num ? 'grey.800' : '#FFF',
                  p: 0,
                  mx: 0.5,
                  fontSize: '0.675rem',
                  '&:hover, &:focus, &:active': {
                    color: score === num ? '#FFF' : 'grey.800',
                    backgroundColor: score === num ? 'grey.800' : 'grey.100',
                  },
                }}
              >
                {num}
              </MuiButton>
            ))}
            <Typography fontSize="0.6rem" textTransform="uppercase" mx={0.5}>
              {formatMessage({ id: 'npsScore.veryLikely' })}
            </Typography>
          </Box>
          <SubmitButton
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
          />

          <CloseButton onClick={handleClickClose} />
        </Box>
        <Box display={{ lg: 'none' }} position="relative" className="mobile-container">
          <Typography fontSize="0.875rem" mb={1}>
            {formatMessage({ id: 'npsScore.prompt' })}
          </Typography>
          <Box className="nps-container-mobile" display="flex" justifyContent="center">
            <Box mr={1}>
              <Select
                ariaLabel={formatMessage({ id: 'npsScore.prompt' })}
                label=""
                value={score === undefined ? '' : `${score}`}
                onChange={handleSelectScore}
                options={SCORE_OPTIONS}
              />
            </Box>
            <SubmitButton
              disabled={isSubmitButtonDisabled}
              onClick={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </Box>
          <CloseButton onClick={handleClickClose} isMobile={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default NpsScore;
