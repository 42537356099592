import { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useUserPrefs } from '../../context/UserPrefsProvider';

export default function InvalidZipcodeAlert() {
  const { userPrefs } = useUserPrefs();
  const [zipcodeErrorOpen, setZipcodeErrorOpen] = useState(false);

  const handleZipcodeErrorClose = () => setZipcodeErrorOpen(false);

  useEffect(() => {
    if (userPrefs.zipcodeError) {
      setZipcodeErrorOpen(true);
    }
  }, [userPrefs.zipcodeError]);

  return (
    <Snackbar
      open={zipcodeErrorOpen}
      autoHideDuration={6000}
      onClose={handleZipcodeErrorClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleZipcodeErrorClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        The zipcode you entered is invalid. Showing data for {userPrefs.zipcodeLastValid}.
      </Alert>
    </Snackbar>
  );
}
