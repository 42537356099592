import { CLIMATE_ZONE_BY_ZIPCODE } from '../../data/climate_zone_by_zipcode';
import type { UserPrefs } from '../../types';

const selectClimateZone = (userPrefs: UserPrefs) => {
  if (!userPrefs) return null;

  // Resource: https://docs.google.com/spreadsheets/d/1VNlrLjNtcqFVSz1dmqMa4NGDv2dYMYoGd9j-J_ClU9c/edit#gid=1333801258
  const climateZone = CLIMATE_ZONE_BY_ZIPCODE[userPrefs.zipcode];

  return climateZone != null ? climateZone : 'coast';
};

export default selectClimateZone;
