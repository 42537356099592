import { useEffect, useState } from 'react';

type GeoIpResponse = {
  ip: string;
  type: 'ipv4';
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: {
    geoname_id: number;
    capital: string;
    languages: [
      {
        code: string;
        name: string;
        native: string;
      },
    ];
    country_flag: string;
    country_flag_emoji: string;
    country_flag_emoji_unicode: string;
    calling_code: string;
    is_eu: boolean;
  };
  time_zone: {
    id: string;
    current_time: string;
    gmt_offset: number;
    code: string;
    is_daylight_saving: boolean;
  };
  currency: {
    code: string;
    name: string;
    plural: string;
    symbol: string;
    symbol_native: string;
  };
  connection: {
    asn: number;
    isp: string;
  };
};

type GeoIpData = {
  ip: string;
};

const transformGeoIpData = (data: GeoIpResponse): GeoIpData => {
  return {
    ip: data.ip,
  };
};

const geolocateUser = (): Promise<GeoIpData> =>
  new Promise((resolve, reject) => {
    let url = new URL(
      `https://api.ipstack.com/check?format=json&access_key=${process.env.REACT_APP_IPSTACK_KEY}`,
    );
    window
      .fetch(url.toString())
      .then((response) => response.json())
      .then((data: GeoIpResponse) => {
        resolve(transformGeoIpData(data));
      })
      .catch((err) => reject(err));
  });

export const useGeoIp = () => {
  const [geoIpData, setGeoIpData] = useState<GeoIpData>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');

  useEffect(() => {
    const loadGeoIpData = async () => {
      try {
        const data = await geolocateUser();
        setGeoIpData(data);
        setStatus('idle');
      } catch {
        setStatus('error');
      }
    };
    if (!geoIpData && status === 'idle') {
      loadGeoIpData();
    }
  }, [geoIpData, status]);

  return {
    data: geoIpData,
    status,
  };
};
