import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { isValidZipcode } from '../../utils/zipcodes';
import Icon from '../icon/Icon';
import TextInput from '../textInput/TextInput';

type Props = {
  onValidZipcode: (zipCode: string) => void;
  zipcode?: string;
};

export default function ZipcodeInput({ onValidZipcode, zipcode }: Props): JSX.Element {
  const {
    userPrefs: { zipcodeError },
  } = useUserPrefs();
  const { formatMessage } = useIntl();
  const [workingZipcode, setWorkingZipcode] = useState<string>(zipcode || '');

  // Sync the internal working zipcode to an external value,
  // most probably one from context
  useEffect(() => {
    if (zipcode) {
      setWorkingZipcode(zipcode);
    }
  }, [zipcode, setWorkingZipcode]);

  return (
    <TextInput
      label={formatMessage({ id: 'common.zipInput.label' })}
      placeholder={formatMessage({ id: 'common.zipInput.placeholder' })}
      errorMessage={
        zipcodeError || workingZipcode.length > 5 || /\D/.test(workingZipcode)
          ? formatMessage({ id: 'common.zipInput.invalid' })
          : undefined
      }
      startIcon={<Icon variant="pin" />}
      value={workingZipcode}
      onChange={(value) => {
        setWorkingZipcode(value);

        if (isValidZipcode(value)) {
          onValidZipcode(value);
        }
      }}
    />
  );
}
