import { Box, InputLabel, Typography } from '@mui/material';
import { useState } from 'react';
import Button from '../../../../components/button/Button';
import ButtonMultiSelect from '../../../../components/buttonMultiSelect/ButtonMultiSelect';
import Slider from '../../../../components/slider/Slider';
import Switch from '../../../../components/switch/Switch';
import { VehicleFilters, isVehicleStatus } from '../../../../types';
import IncentivesModal from '../incentivesModal/IncentivesModal';
import { useIntl } from 'react-intl';
import Select from '../../../../components/select/Select';

type Props = {
  filters: VehicleFilters;
  onChange: (newFilters: Partial<VehicleFilters>) => void;
};

export default function FilterControls({ filters, onChange }: Props): JSX.Element {
  const { formatMessage } = useIntl();

  const [openIncentivesModal, setOpenIncentivesModal] = useState<boolean>(false);

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Typography variant="h5" component="h3">
        {formatMessage({ id: 'vehicleCatalog.filterControls.title' })}
      </Typography>
      <Select
        value={filters.vehicleStatus}
        label={formatMessage({ id: 'vehicleCatalog.filterControls.status' })}
        onChange={(value) => isVehicleStatus(value) && onChange({ vehicleStatus: value })}
        options={[
          { label: formatMessage({ id: 'vehicleCatalog.filterControls.new' }), value: 'new' },
          { label: formatMessage({ id: 'vehicleCatalog.filterControls.preOwned' }), value: 'used' },
          {
            label: formatMessage({ id: 'vehicleCatalog.filterControls.upcoming' }),
            value: 'upcoming',
          },
        ]}
      />
      {filters.vehicleStatus === 'used' && (
        <Typography variant="body2" color="grey.500">
          {formatMessage({ id: 'vehicleCatalog.filterControls.preOwnedDisclaimer' })}
        </Typography>
      )}
      <Slider
        label={formatMessage({ id: 'vehicleCatalog.filterControls.range' })}
        valueSuffix={' ' + formatMessage({ id: 'vehicleCatalog.filterControls.miles' })}
        value={filters.rangeMin}
        min={0}
        max={500}
        step={10}
        describer={(val: number) => {
          if (val === 0) {
            return formatMessage({ id: 'vehicleCatalog.filterControls.anyRange' });
          }
          return null;
        }}
        onChange={(value) => onChange({ rangeMin: value })}
      />
      <Slider
        label={formatMessage({ id: 'vehicleCatalog.filterControls.budget' })}
        valuePrefix={formatMessage({ id: 'vehicleCatalog.filterControls.moneySymbol' })}
        value={filters.priceMax || 0}
        min={15000}
        max={200000}
        step={1000}
        onChange={(value) => onChange({ priceMax: value })}
      />
      <Button size="small" variant="outlined" onClick={() => setOpenIncentivesModal(true)}>
        {formatMessage({ id: 'vehicleCatalog.filterControls.personalizeIncentivesButton' })}
      </Button>
      <IncentivesModal open={openIncentivesModal} onClose={() => setOpenIncentivesModal(false)} />
      <Slider
        label={formatMessage({ id: 'vehicleCatalog.filterControls.seatsMin' })}
        valueSuffix={' ' + formatMessage({ id: 'vehicleCatalog.filterControls.seats' })}
        value={filters.seatsMin}
        min={2}
        max={7}
        step={1}
        onChange={(value) => onChange({ seatsMin: value })}
      />
      <Box>
        <InputLabel>
          <Typography variant="label1">
            {formatMessage({ id: 'vehicleCatalog.filterControls.fuel' })}
          </Typography>
        </InputLabel>
        <Switch
          fullWidth
          label={formatMessage({ id: 'vehicleCatalog.filterControls.allElectric' })}
          checked={filters.fuelType.includes('BEV')}
          onChange={(checked) => {
            if (checked) {
              onChange({ fuelType: [...filters.fuelType, 'BEV'] });
            } else {
              if (filters.fuelType.includes('PHEV')) {
                onChange({ fuelType: ['PHEV'] });
              } else {
                onChange({ fuelType: ['BEV', 'PHEV'] });
              }
            }
          }}
        />
        <Switch
          fullWidth
          label={formatMessage({ id: 'vehicleCatalog.filterControls.hybrid' })}
          checked={filters.fuelType.includes('PHEV')}
          onChange={(checked) => {
            if (checked) {
              onChange({ fuelType: [...filters.fuelType, 'PHEV'] });
            } else {
              if (filters.fuelType.includes('BEV')) {
                onChange({ fuelType: ['BEV'] });
              } else {
                onChange({ fuelType: ['BEV', 'PHEV'] });
              }
            }
          }}
        />
      </Box>
      <ButtonMultiSelect
        label={formatMessage({ id: 'vehicleCatalog.filterControls.type' })}
        selected={filters.subtype}
        options={[
          {
            value: 'Sedan',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.sedan' }),
          },
          {
            value: 'Hatchback',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.hatchback' }),
          },
          {
            value: 'Coupe',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.coupe' }),
          },
          {
            value: 'Minivan',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.minivan' }),
          },
          {
            value: 'Station Wagon',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.wagon' }),
          },
          {
            value: 'pickup_truck',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.truck' }),
          },
          {
            value: 'SUV',
            label: formatMessage({ id: 'vehicleCatalog.filterControls.typeOptions.suv' }),
          },
        ]}
        onChange={(value) => {
          if (value.includes('SUV') && !value.includes('Crossover')) {
            value.push('Crossover');
          } else if (!value.includes('SUV') && value.includes('Crossover')) {
            value = value.filter((v) => v !== 'Crossover');
          }
          onChange({ subtype: value });
        }}
      />
      <Typography fontSize="0.875rem" fontWeight="400">
        {formatMessage({ id: 'vehicleCatalog.filterControls.disclaimer' })}
      </Typography>
    </Box>
  );
}
