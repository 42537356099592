import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../../icon/Icon';

type Props = {
  hasSuperChargerStations?: boolean;
};

const StationItem = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
}): JSX.Element => {
  return (
    <Box display="flex" gap="1rem">
      {icon}
      <Box>
        <Typography fontWeight="700">{title}</Typography>
        <Typography>{description}</Typography>
      </Box>
    </Box>
  );
};

const ChargingStationsMapLegend = ({ hasSuperChargerStations }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      {hasSuperChargerStations && (
        <StationItem
          icon={<Icon variant="chargingHighPower" sx={{ width: '24px' }} />}
          title={formatMessage({ id: 'maps.stationsMap.dcfcStationTitle' })}
          description={formatMessage({ id: 'maps.stationsMap.dcfcStationDescription' })}
        />
      )}
      <StationItem
        icon={<Icon variant="chargingPublic" sx={{ width: '24px' }} />}
        title={formatMessage({ id: 'maps.stationsMap.publicStationTitle' })}
        description={formatMessage({ id: 'maps.stationsMap.publicStationDescription' })}
      />
      <StationItem
        icon={<Icon variant="chargingPrivate" sx={{ width: '24px' }} />}
        title={formatMessage({ id: 'maps.stationsMap.privateStationTitle' })}
        description={formatMessage({ id: 'maps.stationsMap.privateStationDescription' })}
      />
    </Box>
  );
};

export default ChargingStationsMapLegend;
