import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../../components/card/Card';
import { CurvyChargingPlugIcon } from '../../components/icon/Vectors';
import { TomTomChargingStation, TomTomConnector, TomTomConnectors } from '../../types';

const CHARGER_CURRENT_TYPES = {
  AC1: 'Level 1',
  AC2: 'Level 2',
  AC3: 'DC',
  DC: 'DC',
};

const CHARGER_CONNECTORS: Record<string, string> = {
  IEC62196Type1: 'J1772',
  Tesla: 'Tesla',
  IEC62196Type1CCS: 'CCS',
  Chademo: 'CHAdeMO',
};

const Connector = ({
  connector,
  charger,
}: {
  connector: TomTomConnector;
  charger: TomTomChargingStation;
}) => {
  const available =
    connector.availability?.current.available && connector.availability?.current.available >= 1;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        '&:not(:last-child)': {
          mb: 3,
        },
      }}
    >
      <Box>
        <Box display="flex" alignItems="center">
          <CurvyChargingPlugIcon />
          <Typography fontWeight="bold" fontSize="0.875rem" ml={1}>
            {CHARGER_CONNECTORS[connector.type] || connector.type}
          </Typography>
        </Box>
        {charger.chargingPark &&
          charger.chargingPark.connectors.map((chargerConnector) => (
            <Typography key={`${chargerConnector.type}-${chargerConnector.ratedPowerKW}`}>
              {`${CHARGER_CURRENT_TYPES[chargerConnector.currentType]} - ${
                chargerConnector.ratedPowerKW
              }kW`}
            </Typography>
          ))}
      </Box>

      {connector.availability && (
        <Box
          p={0.5}
          alignSelf="center"
          bgcolor={available ? 'green.300' : 'grey.200'}
          borderRadius="2px"
          minWidth="1.75rem"
          textAlign="center"
        >
          <Typography fontSize="0.875rem" color={available ? 'white.100' : 'black'}>
            {connector.availability.current.available || 0}/{connector.total}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default function ConnectorAvailability({
  charger,
  connectors,
}: {
  charger: TomTomChargingStation;
  connectors?: TomTomConnectors['connectors'];
}) {
  const { formatMessage } = useIntl();

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        fontWeight={500}
        sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
      >
        <Box>{formatMessage({ id: 'chargerDetails.plugType' })}</Box>
        <Box>{formatMessage({ id: 'chargerDetails.plugAvailability' })}</Box>
      </Box>
      {charger.chargingPark?.connectors.map((connector) => {
        const availabilityConnector = connectors?.find((c) => c.type === connector.type);

        return (
          <Connector
            key={connector.type}
            connector={availabilityConnector || connector}
            charger={charger}
          />
        );
      })}
    </Card>
  );
}
