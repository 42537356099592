import { Color } from '@mui/material';
import { createTheme, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label1: true;
  }
}

declare module '@mui/material/styles' {
  export interface Theme {
    palette: {
      primary: {
        main: string;
      };
      white: Partial<Color>;
      grey: Partial<Color>;
      blue: Partial<Color>;
      green: Partial<Color>;
      orange: Partial<Color>;
      red: Partial<Color>;
    };
  }
  export interface PaletteOptions {
    primary?: PaletteColorOptions;
    white?: Partial<Color>;
    grey?: Partial<Color>;
    blue?: Partial<Color>;
    green?: Partial<Color>;
    orange?: Partial<Color>;
    red?: Partial<Color>;
  }

  interface TypographyVariants {
    label1: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    label1?: React.CSSProperties;
  }
}

const WHITE = '#FFFFFF';

const ORANGE_200 = '#f2e0c2';
const ORANGE_300 = '#f2c06c';
const ORANGE_400 = '#F1BE53';
const ORANGE_500 = '#FABA45';
const ORANGE_600 = '#A06903';

const GREEN_300 = '#429538';
const GREEN_500 = '#218739';

const RED_100 = '#FFCCCB';
const RED_500 = '#D13D3D';

const BLUE_100 = '#e6edf4';
const BLUE_200 = '#1ba4da';
const BLUE_300 = '#0089c4';
const BLUE_400 = '#00527c';
const BLUE_500 = '#215179';
const BLUE_600 = '#052136';
const BLUE_700 = '#07679b';
const BLUE_800 = '#071929';

const GREY_100 = '#f2f2f2';
const GREY_200 = '#DBDBDB';
const GREY_300 = '#ced4da';
const GREY_400 = '#555555';
const GREY_500 = '#6c757d';
const GREY_600 = '#333333';
const GREY_700 = '#212529';
const GREY_800 = '#042037';
const GREY_900 = '#000000';

const theme = createTheme({
  palette: {
    primary: {
      main: ORANGE_500,
    },
    secondary: {
      main: BLUE_800,
    },
    white: {
      100: WHITE,
    },
    grey: {
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      800: GREY_800,
      900: GREY_900,
    },
    blue: {
      100: BLUE_100,
      200: BLUE_200,
      300: BLUE_300,
      400: BLUE_400,
      500: BLUE_500,
      600: BLUE_600,
      700: BLUE_700,
      800: BLUE_800,
    },
    green: {
      300: GREEN_300,
      500: GREEN_500,
    },
    red: {
      100: RED_100,
      500: RED_500,
    },
    orange: {
      200: ORANGE_200,
      300: ORANGE_300,
      400: ORANGE_400,
      500: ORANGE_500,
      600: ORANGE_600,
    },
    info: {
      main: BLUE_300,
    },
  },
  typography: {
    fontFamily: [
      'din',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    label1: {
      color: GREY_500,
      fontSize: '0.75rem',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'din';
        src: url("/fonts/din/DINOT_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 900;
        src: url("/fonts/din/DINOT-Black_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 900;
        font-style: italic;
        src: url("/fonts/din/DINOT-BlackItalic_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 700;
        src: url("/fonts/din/DINOT-Bold_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 700;
        font-style: italic;
        src: url("/fonts/din/DINOT-BoldItalic_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        src: url("/fonts/din/DINOT-Cond_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 900;
        src: url("/fonts/din/DINOT-CondBlack_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 900;
        font-style: italic;
        src: url("/fonts/din/DINOT-CondBlackIta_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 700;
        src: url("/fonts/din/DINOT-CondBold_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 700;
        font-style: italic;
        src: url("/fonts/din/DINOT-CondBoldIta_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-style: italic;
        src: url("/fonts/din/DINOT-CondIta_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 200;
        src: url("/fonts/din/DINOT-CondLight_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 200;
        font-style: italic;
        src: url("/fonts/din/DINOT-CondLightIta_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 500;
        font-style: italic;
        src: url("/fonts/din/DINOT-CondMediIta_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-stretch: condensed;
        font-weight: 500;
        src: url("/fonts/din/DINOT-CondMedium_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-style: italic;
        src: url("/fonts/din/DINOT-Italic_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 200;
        src: url("/fonts/din/DINOT-Light_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 200;
        font-style: italic;
        src: url("/fonts/din/DINOT-LightItalic_0.otf")
          format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 500;
        src: url("/fonts/din/DINOT-Medium_0.otf") format("opentype");
      }

      @font-face {
        font-family: 'din';
        font-weight: 500;
        font-style: italic;
        src: url("/fonts/din/DINOT-MediumItalic_0.otf")
          format("opentype");
      }

      /* Backup: Alte Din */
      /* Source: https://www.1001fonts.com/alte-din-1451-mittelschrift-font.html */

      @font-face {
        font-family: alte-din;
        src: url("/fonts/alte-din/din1451alt.ttf") format("truetype");
      }

      @font-face {
        font-family: alte-din;
        font-weight: 700;
        src: url("/fonts/alte-din/din1451altbold.ttf")
          format("truetype");
      }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: 0,
          },
        },
      },
    },
    MuiTypography: {},
  },
});

export default theme;
