import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';

type InputProps = {
  id?: string;
  label: string;
  ariaLabel?: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
  inline?: boolean;
  fullWidth?: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  sx?: SxProps<Theme>;
};

type Option = {
  value: string;
  label: ReactNode;
};

export type SelectProps = InputProps & {
  select: boolean;
  options: Array<Option>;
};

export type TextAreaProps = InputProps & {
  multiline: true;
  minRows: number;
};

export default function TextInput(props: InputProps | SelectProps | TextAreaProps): JSX.Element {
  const {
    id,
    label,
    ariaLabel,
    value,
    errorMessage,
    disabled,
    placeholder,
    startIcon,
    inline,
    fullWidth,
    onChange,
    sx = {},
  } = props;
  const fieldId = id || label.replace(/\s/g, '');
  const labelId = fieldId ? fieldId + '-label' : null;
  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <Box
        display="flex"
        width={fullWidth ? '100%' : undefined}
        flexDirection={inline ? 'row' : 'column'}
        alignItems={inline ? 'center' : 'inherit'}
        gap={inline ? '0.75rem' : '0'}
      >
        {label && (
          <InputLabel
            htmlFor={fieldId}
            id={labelId || undefined}
            sx={{ position: 'relative', transform: 'unset' }}
          >
            <Typography variant="label1">{label}</Typography>
          </InputLabel>
        )}
        <Box display="flex">
          {startIcon && (
            <Box
              height="39px"
              width="39px"
              display="flex"
              justifyContent="center"
              p="8px"
              sx={{
                backgroundColor: (theme) => theme.palette.grey[100],
                border: (theme) =>
                  `1px solid ${errorMessage ? theme.palette.red[500] : theme.palette.grey[300]}`,
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
              }}
            >
              {startIcon}
            </Box>
          )}
          <TextField
            id={fieldId}
            inputProps={
              label && labelId
                ? { 'aria-labelledby': labelId, 'aria-label': ariaLabel }
                : { 'aria-label': ariaLabel }
            }
            fullWidth
            color="secondary"
            variant="outlined"
            value={value}
            placeholder={placeholder}
            error={!!errorMessage}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
            // onKeyDown={onKeyDown}
            onBlur={(event) => onChange(event.target.value)}
            sx={{
              flex: '1 1',
              '& .MuiOutlinedInput-input': {
                backgroundColor: (theme) =>
                  disabled ? theme.palette.grey[100] : theme.palette.white[100],
                py: '8px',
                borderRadius: '4px',
                borderTopLeftRadius: startIcon ? '0' : '4px',
                borderBottomLeftRadius: startIcon ? '0' : '4px',
              },
            }}
            select={'select' in props}
            multiline={'multiline' in props}
            minRows={'minRows' in props ? props['minRows'] : undefined}
          >
            {'select' in props
              ? props.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : null}
          </TextField>
        </Box>
        {errorMessage && (
          <Typography color="red.500" fontSize="0.7rem" position="absolute" top="calc(100% + 1px)">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </FormControl>
  );
}
