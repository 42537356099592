import useElectricVehicles from './useElectricVehicles';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import filterVehicles from '../../utils/filterVehicles';

const useFilteredVehiclesForRates = () => {
  /**
   * We need all the user's preferences and
   * all the *new* vehicles for the rates page.
   */
  const { userPrefs } = useUserPrefs();
  const { newElectricVehicles, status } = useElectricVehicles();

  /**
   * Now we filter the vehicles using the
   * user preferences filters that are visible
   * on the page.
   *
   * We don't actually need the array of
   * filtered vehicles that this function
   * provides.
   *
   * We just need values for the Make and Model
   * dropdowns.
   */
  const { makes, models } = filterVehicles(newElectricVehicles || [], {
    make: userPrefs.vehicleMakeFilter,
    type: userPrefs.vehicleFilters.type,
  });

  /**
   * The only vehicle we need is the current
   * `selectedVehicle`.
   */
  const selectedVehicle = newElectricVehicles?.find(
    (vehicle) => vehicle.handle === userPrefs.comparedVehicleId1,
  );

  return {
    status,
    makes,
    models,
    selectedVehicle,
  };
};

export default useFilteredVehiclesForRates;
