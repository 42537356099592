export const YEAR = 2020;
export const LOAD_PROFILE_HOURS = YEAR % 4 === 0 ? 8784 : 8760;

export const DIESEL_EMISSIONS_IN_LBS_PER_GAL = 22.38; // http://www.patagoniaalliance.org/wp-content/uploads/2014/08/How-much-carbon-dioxide-is-produced-by-burning-gasoline-and-diesel-fuel-FAQ-U.S.-Energy-Information-Administration-EIA.pdf

export const GASOLINE_EMISSIONS_IN_LBS_PER_GAL = 19.64; // http://www.patagoniaalliance.org/wp-content/uploads/2014/08/How-much-carbon-dioxide-is-produced-by-burning-gasoline-and-diesel-fuel-FAQ-U.S.-Energy-Information-Administration-EIA.pdf

export const DCFC_CHARGING_RATE = 50;
export const LEVEL_2_CHARGING_RATE = 7.7;

export interface BatteryFactors {
  BMW: number;
  Audi: number;
  Nissan: number;
  Ford: number;
  Chevrolet: number;
  Fiat: number;
  Tesla: number;
  Toyota: number;
  Average: number;
}

export const ELECTRIC_BATTERY_FACTORS = {
  BMW: 0.233,
  Audi: 0.176,
  Nissan: 0.221,
  Ford: 0.198,
  Chevrolet: 0.197,
  Fiat: 0.4,
  Tesla: 0.123,
  Toyota: 0.114,
  Average: 0.201,
} as BatteryFactors;

export const BATTERY_DEGRADATION_FACTORS = [1, 0.98, 0.96, 0.939, 0.919, 0.899, 0.879, 0.877];
