import { Box } from '@mui/material';
import type { ChargingStation } from '../../../../types';
import Icon from '../../../icon/Icon';

type Props = {
  station: ChargingStation;
  ignoreSuperchargerStations?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  lat: number;
  lng: number;
};

// NOTE: refactored this into its own component to mitigate this:
// https://github.com/google-map-react/google-map-react/issues/583
export default function ChargingStationAvailabilityMapMarker({
  station,
  selected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  return (
    <Box
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      sx={{
        opacity: selected ? 1 : 0.85,
        height: '37px',
        width: '24px',
        marginLeft: '-12px',
        marginTop: '-37px',
      }}
    >
      {station.availabilityStatus === 'available' ? (
        <Icon variant="chargingPublic" sx={{ width: '24px' }} />
      ) : (
        <Icon variant="chargingPrivate" sx={{ width: '24px' }} />
      )}
    </Box>
  );
}
