import { useCallback, useEffect, useState } from 'react';
import { getValidZipcode, useUserPrefs } from '../../context/UserPrefsProvider';
import type { ApiElectricVehicle, ElectricVehicle } from '../../types';
import { fetchRecords } from '../../utils/fetch';
import { transformElectricVehicle } from '../transformers/transformElectricVehicle';
import { useLanguage } from '../../utils/ReactIntlProvider';

const path = '/vehicles';
const defaultParams = {
  zipcode: '94108',
  taxFilingStatus: 'single',
  householdSize: '1',
  householdIncome: '75000',
  turnInClunker: 'true',
};

const fetchElectricVehicle = (
  handle: string,
  params?: Record<string, string>,
): Promise<{ vehicle: ApiElectricVehicle }> => {
  return fetchRecords(`${path}/${handle}`, params);
};

export default function useElectricVehicleDetails(handle?: string | null) {
  const { zrLanguage } = useLanguage();
  const { userPrefs, setUserPrefs } = useUserPrefs();
  const [vehicle, setVehicle] = useState<ElectricVehicle>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string>();

  const zipcode = getValidZipcode(userPrefs);

  const fetch = useCallback(
    async (params: Record<string, string>) => {
      if (!handle) return;

      try {
        setStatus('loading');
        setVehicle(undefined);
        const data = await fetchElectricVehicle(handle, params);
        setVehicle(transformElectricVehicle(data.vehicle));
        setStatus('idle');
      } catch (e: any) {
        if (typeof e === 'object' && e.message.includes('postcode')) {
          setUserPrefs({ zipcodeError: true });
        }
        setStatus('error');
        setError('Could not load vehicle');
      }
    },
    [handle, setUserPrefs],
  );

  useEffect(() => {
    if (handle) {
      fetch({
        postcode: zipcode,
        tax_filing_type: userPrefs?.taxFilingStatus || defaultParams.taxFilingStatus,
        household_size: userPrefs?.householdSize.toString() || defaultParams.householdSize,
        turn_in_clunker: defaultParams.turnInClunker,
        household_income:
          userPrefs?.householdIncome === 0
            ? '1'
            : userPrefs?.householdIncome?.toString() || defaultParams.householdIncome,
        lang: zrLanguage,
      });
    }
  }, [
    fetch,
    handle,
    userPrefs?.householdIncome,
    userPrefs?.householdSize,
    userPrefs?.taxFilingStatus,
    zipcode,
    zrLanguage,
  ]);

  useEffect(() => {
    if (vehicle && !handle) {
      setVehicle(undefined);
    }
  }, [handle, vehicle]);

  return {
    vehicle,
    status,
    error,
  };
}
