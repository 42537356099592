const SALES_TAX = {
  title: 'Sales Tax',
  value: 0.0725,
  valueAsFormatted: '7.25%',
  link: 'http://www.cdtfa.ca.gov/taxes-and-fees/sut-rates-description.htm',
  linkText: 'Statewide Base Sales and Use Tax Rate',
} as const;

const ELECTRICITY_RATE_IN_DOLLARS_PER_KWH = {
  title: 'Electricity Rate',
  value: 0.3521,
  valueAsFormatted: '$0.3521/kWh',
  link: 'https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_EV2%20(Sch).pdf',
} as const;

const ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH = {
  title: 'Electricity Emissions in CO2 lbs/MWh',
  value: 294,
  valueAsFormatted: '294 lbs/MWh',
  link: 'http://www.pgecorp.com/corp_responsibility/reports/2018/en02_climate_change.html',
} as const;

const GASOLINE_EMISSIONS_IN_LBS_PER_GAL = {
  title: 'Gasoline Emissions in CO2 lbs/gal',
  value: 19.6,
  valueAsFormatted: '19.6 lbs/gal',
  link: 'http://www.patagoniaalliance.org/wp-content/uploads/2014/08/How-much-carbon-dioxide-is-produced-by-burning-gasoline-and-diesel-fuel-FAQ-U.S.-Energy-Information-Administration-EIA.pdf',
} as const;

const EMISSIONS_PER_TREE_IN_LBS_OF_CO2 = {
  title: 'Emissions Saved per Tree',
  value: 48,
  valueAsFormatted: '48 lbs of CO2 per tree per year',
  link: 'https://www.arborday.org/trees/treefacts/',
} as const;

const DOWN_PAYMENT = {
  title: 'Down Payment',
  value: 0.1,
  valueAsFormatted: '10% of MSRP + Tax',
} as const;

const AVERAGE_MSRP = {
  title: 'Average MSRP',
  value: 37000,
  valueAsFormatted: '$37,000',
} as const;

const AVERAGE_MAINTENANCE_COST_PER_MILE = {
  // ZappyRide analysis
  title: 'Average Maintneance Cost Per Mile',
  value: 0.06,
  valueAsFormatted: '$0.06/mile',
} as const;

const MAINTENANCE_COST_SCALING_FACTOR = {
  // Maintenance cost scaling factor: 20% (ZappyRide analysis) aka how much maintenance cost increases if MSRP doubles
  title: 'Maintenance Cost Scaling Factor',
  value: 0.2,
  valueAsFormatted: '20%',
} as const;

const ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR = {
  // EV maintenance cost reduction: 33% (ZappyRide analysis) aka maintenance cost reduction for an EV of the same MSRP
  title: 'EV maintenance cost reduction',
  value: 0.5,
  valueAsFormatted: '50%',
  link: 'https://advocacy.consumerreports.org/wp-content/uploads/2020/09/Maintenance-Cost-White-Paper-9.24.20-1.pdf',
} as const;

const AVERAGE_INSURANCE_COST_ANNUALLY = {
  title: 'Average Insurance Cost In California',
  value: 1731,
  valueAsFormatted: '$1,731',
  link: 'https://www.insure.com/car-insurance/car-insurance-rates.html',
} as const;

const INSURANCE_COST_SCALING_FACTOR = {
  // Insurance cost scaling factor: 20% (ZappyRide analysis) aka how much insurance cost increases if MSRP doubles
  title: 'Insurance Cost Scaling Factor',
  value: 0.2,
  valueAsFormatted: '20%',
} as const;

export {
  SALES_TAX,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  DOWN_PAYMENT,
  AVERAGE_MSRP,
  AVERAGE_MAINTENANCE_COST_PER_MILE,
  MAINTENANCE_COST_SCALING_FACTOR,
  ELECTRIC_COST_MAINTENANCE_REDUCTION_FACTOR,
  AVERAGE_INSURANCE_COST_ANNUALLY,
  INSURANCE_COST_SCALING_FACTOR,
};
