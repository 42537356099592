import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Link from '../../../../components/link/Link';
import Tabs from '../../../../components/tabs/Tabs';
import { useIntl } from 'react-intl';

const markdown = (md: string): JSX.Element => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[remarkGfm]}
      components={{
        a: (props) => {
          const href = props.href || '';
          return href.startsWith('http') ? (
            <Link to={href} external>
              {props.children}
            </Link>
          ) : (
            <Link to={href}>{props.children}</Link>
          );
        },
      }}
    >
      {md}
    </ReactMarkdown>
  );
};

export default function RateInfo(): JSX.Element {
  const { formatMessage } = useIntl();

  const tabConfig = [
    {
      label: 'EV2-A',
      content: markdown(formatMessage({ id: 'rateInfo.EV2_A' })),
    },
    {
      label: 'E-ELEC',
      content: markdown(formatMessage({ id: 'rateInfo.E_ELEC' })),
    },
    {
      label: 'E-1',
      content: markdown(formatMessage({ id: 'rateInfo.E_1' })),
    },
    {
      label: 'E-TOU-B',
      content: markdown(formatMessage({ id: 'rateInfo.E_TOU_B' })),
    },
    {
      label: 'E-TOU-C',
      content: markdown(formatMessage({ id: 'rateInfo.E_TOU_C' })),
    },
    {
      label: 'E-TOU-D',
      content: markdown(formatMessage({ id: 'rateInfo.E_TOU_D' })),
    },
    {
      label: 'EV-B',
      content: markdown(formatMessage({ id: 'rateInfo.EV_B' })),
    },
  ];

  return (
    <Box
      sx={{
        ' a': {
          color: (theme) => theme.palette.blue[700],
          textDecoration: 'none',
        },
        '& .MuiFormControl-root': {
          width: 200,
        },
      }}
    >
      <Tabs tabs={tabConfig} variant="scrollable" ariaLabel="Select a Rate" />
    </Box>
  );
}
