import { Alert, Box, LinearProgress, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import Modal from '../../components/modal/Modal';
import Page from '../../components/page/Page';
import AllRates from './components/allRates/AllRates';
import CompareChart from './components/compareChart/CompareChart';
import SolarGeneration from './components/solarGeneration/SolarGeneration';
import Filters from './components/filters/Filters';
import RateInfo from './components/rateInfo/RateInfo';
import Wizard from './components/wizard/Wizard';
import { useHistoricalLoadProfile } from './hooks/useHistoricalLoadProfile';
import HistoricalDataModal from './components/HistoricalDataModal';
import { useUserPrefs } from '../../context/UserPrefsProvider';

export default function Rates() {
  const { formatMessage } = useIntl();
  const { userPrefs } = useUserPrefs();

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const openHistorical = searchParams.get('open-historical');

  const [showWizard, setShowWizard] = useState(Boolean(!token && !openHistorical));
  const [showFilters, setShowFilters] = useState(false);
  const [historicalDataOpen, setHistoricalDataOpen] = useState(Boolean(openHistorical));

  const { loadProfile, usingHistorical, progress, reset, error } = useHistoricalLoadProfile();

  const isHistoricalFailed = !!error;
  const isHistoricalInProgress = usingHistorical && !loadProfile && !isHistoricalFailed;

  if (process.env.REACT_APP_ENVIRONMENT !== 'production' && usingHistorical && !!loadProfile) {
    (window as any).loadProfile = loadProfile;
  }

  return (
    <Page>
      <Box p={{ xs: 2, sm: 4 }} bgcolor="grey.100">
        <Wizard open={showWizard} onClose={() => setShowWizard(false)} />
        <HistoricalDataModal
          open={historicalDataOpen}
          onClose={() => setHistoricalDataOpen(false)}
        />
        <Box display="flex" flexDirection="column" gap="1rem" maxWidth="1140px" m="auto">
          <Box display="flex" gap="1.5rem" justifyContent="space-between">
            <Box>
              <Typography variant="h1" component="h1">
                {formatMessage({ id: 'rates.header' })}
              </Typography>
              <Typography fontSize="1.25rem" fontWeight="300" mb="0.5rem">
                {formatMessage({ id: 'rates.description' })}
              </Typography>
            </Box>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }} width="100%">
            <Button fullWidth onClick={() => setShowFilters(true)}>
              {formatMessage({ id: 'rates.filtersCta' })}
            </Button>
          </Box>
          <Box
            display="flex"
            gap="1.5rem"
            justifyContent="space-around"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Box display={{ xs: 'none', md: 'block' }} flex="1 1" minWidth="250px">
              <Filters
                usingHistorical={usingHistorical}
                resetHistorical={reset}
                setHistoricalDataOpen={setHistoricalDataOpen}
              />
            </Box>
            <Box flex="3 1" minWidth="300px" display="flex" flexDirection="column" gap="1rem">
              {isHistoricalInProgress && (
                <Card>
                  <Typography>{formatMessage({ id: 'rates.historicalLoading' })}</Typography>
                  <LinearProgress variant="determinate" value={progress * 100} />
                </Card>
              )}
              {isHistoricalFailed && (
                <Card>
                  <Alert
                    onClose={reset}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%', mb: 2 }}
                  >
                    {formatMessage({ id: 'rates.historicalDataError.alert' })}
                  </Alert>
                  <Typography>
                    {formatMessage({ id: 'rates.historicalDataError.useEstimates' })}{' '}
                    <Link
                      component="button"
                      onClick={() => setHistoricalDataOpen(true)}
                      underline="none"
                      color="blue.700"
                      sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                    >
                      {formatMessage({ id: 'rates.historicalDataError.tryAgain' })}
                    </Link>
                  </Typography>
                </Card>
              )}

              <Card sx={{ padding: 0 }}>
                <CompareChart
                  historicalLoadProfile={loadProfile}
                  usingHistorical={usingHistorical}
                  onShowWizard={() => setShowWizard(true)}
                />
              </Card>
              {userPrefs.hasSolarAtHome && (
                <Card>
                  <SolarGeneration />
                </Card>
              )}
              <Card>
                <AllRates historicalLoadProfile={loadProfile} usingHistorical={usingHistorical} />
              </Card>
              <Card>
                <RateInfo />
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={showFilters}
        onClose={() => setShowFilters(false)}
        title={formatMessage({ id: 'rates.filtersCta' })}
      >
        <Box p={{ xs: 1, sm: 2 }}>
          <Filters
            usingHistorical={usingHistorical}
            resetHistorical={reset}
            setHistoricalDataOpen={setHistoricalDataOpen}
          />
        </Box>
      </Modal>
    </Page>
  );
}
