import { Box, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import calcCharging from '../../../calculations/vehicle/Charging/calcCharging';
import calcEmissions from '../../../calculations/vehicle/Emissions/calcEmissions';
import isElectricVehicle from '../../../calculations/vehicle/isElectricVehicle';
import Button from '../../../components/button/Button';
import Link from '../../../components/link/Link';
import { useUserPrefs } from '../../../context/UserPrefsProvider';
import { ElectricVehicle, GasVehicle } from '../../../types';
import { formatAsThousands, formatAsTime } from '../../../utils/formatters';

type Props = {
  vehicle?: ElectricVehicle | GasVehicle;
};

type DetailProps = {
  label: string;
  value: string;
  tooltip?: string;
};

type DetailRowProps = {
  children: ReactNode;
};

const FUEL_TYPE_DISPLAY_VALUES = {
  GAS: 'compare.specs.gasoline',
  BEV: 'compare.specs.electric',
  PHEV: 'compare.specs.electricAndGasoline',
};

const Detail = ({ label, value, tooltip }: DetailProps) => (
  <Grid item xs={6} display="flex" flexDirection="column">
    <Typography fontSize="0.75rem" textTransform="uppercase" fontWeight="bold" color="grey.500">
      {label}
    </Typography>
    <Typography fontSize="1.125rem" fontWeight="bold" color="grey.600">
      {value}
    </Typography>
  </Grid>
);

const DetailRow = ({ children }: DetailRowProps) => (
  <Box borderBottom="1px solid" borderColor="grey.200">
    <Grid container spacing={5} py={2} display="flex" justifyContent="space-between">
      {children}
    </Grid>
  </Box>
);

export default function VehicleDetails({ vehicle }: Props) {
  const { formatMessage } = useIntl();

  const {
    userPrefs: { milesDrivenAnnually, electricMilesPortionForPhev },
  } = useUserPrefs();

  return (
    <Box py={1}>
      <Box mb={2}>
        <DetailRow>
          <Detail
            label={formatMessage({ id: 'compare.specs.carMake' })}
            value={vehicle ? vehicle.make : '--'}
          />
          <Detail
            label={formatMessage({ id: 'compare.specs.carModel' })}
            value={vehicle ? `${vehicle.model} ${vehicle.trim || ''}` : '--'}
          />
        </DetailRow>

        <DetailRow>
          <Detail
            label={formatMessage({ id: 'compare.specs.vehicleType' })}
            value={vehicle?.subtype || '--'}
          />
          <Detail
            label={formatMessage({ id: 'compare.specs.fuelType' })}
            value={
              vehicle ? formatMessage({ id: FUEL_TYPE_DISPLAY_VALUES[vehicle.fuelType] }) : '--'
            }
          />
        </DetailRow>

        <DetailRow>
          <Detail
            label={formatMessage({ id: 'compare.specs.batterySize' })}
            value={vehicle && isElectricVehicle(vehicle) ? `${vehicle.batteryCapacity} kWh` : '--'}
          />
          <Detail
            label={formatMessage({ id: 'compare.specs.totalRange' })}
            value={vehicle ? `${vehicle.totalRange} miles` : '--'}
          />
        </DetailRow>

        <DetailRow>
          <Detail
            label={formatMessage({ id: 'compare.specs.timeToChargeLevel2' })}
            value={
              vehicle && isElectricVehicle(vehicle)
                ? `~ ${formatAsTime(
                    calcCharging.timeForFullBattery(
                      vehicle.batteryCapacity,
                      vehicle.acChargingPower,
                      'level_2',
                    ),
                  )}`
                : '--'
            }
          />
          <Detail
            label={formatMessage({ id: 'compare.specs.milesPer30MinFastCharge' })}
            value={
              vehicle && isElectricVehicle(vehicle) && vehicle.dcChargingPower
                ? `~ ${Math.round(
                    calcCharging.milesPerHalfHourOfFastCharge(
                      vehicle.electricEfficiency,
                      vehicle.dcChargingPower,
                      vehicle.electricRange,
                    ),
                  )} miles`
                : '--'
            }
          />
        </DetailRow>

        <DetailRow>
          <Detail
            label={formatMessage({ id: 'compare.specs.emissionsReduction' })}
            value={
              vehicle && isElectricVehicle(vehicle)
                ? `${formatAsThousands(
                    calcEmissions.reductionInLbs(
                      vehicle,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev,
                    ),
                  )} lbs/yr`
                : '--'
            }
          />
        </DetailRow>
      </Box>

      {vehicle && isElectricVehicle(vehicle) && (
        <Link to={`/vehicles/${vehicle.handle}`} fullWidth>
          <Button variant="outlined" fullWidth>
            {formatMessage(
              { id: 'compare.specs.viewDetails' },
              { vehicleDisplayName: `${vehicle.make} ${vehicle.displayName}` },
            )}
          </Button>
        </Link>
      )}
    </Box>
  );
}
