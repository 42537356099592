import { useState, useRef } from 'react';
import { transformChargingStation } from '../../../api/transformers/transformChargingStation';
import { ChargingStation } from '../../../types';
const NEAREST_URL = 'https://developer.nrel.gov/api/alt-fuel-stations/v1/nearby-route.json';

const fetchData = (additionalParams: Record<string, string>, signal: AbortController['signal']) => {
  const params = {
    fuel_type: 'ELEC',
    format: 'JSON',
    // NREL API doesn't handle preflight CORS requests properly, so we have to
    // include the API key as a GET query param instead of as a header
    limit: 'all',
    distance: '5',
    ...additionalParams,
  };
  const url = new URL(NEAREST_URL);
  url.search = new URLSearchParams({
    api_key: process.env.REACT_APP_NREL_API_KEY || '',
  }).toString();

  const body = new URLSearchParams(params);

  return window.fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body,
    signal,
  });
};

const useChargingStations = () => {
  const [loading, setLoading] = useState(false);
  const [chargingStations, setChargingStations] = useState<Array<ChargingStation>>([]);
  const [error, setError] = useState('');

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchChargingStations = async ({ route }: { route: string }) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (!route) return;

    try {
      abortControllerRef.current = new window.AbortController();
      setLoading(true);

      const response = await fetchData(
        {
          route,
        },
        abortControllerRef.current.signal,
      );
      const json = await response.json();

      abortControllerRef.current = null;

      setLoading(false);
      if (json) {
        setChargingStations(json.fuel_stations.map(transformChargingStation));
      }
    } catch (error: any) {
      setLoading(false);

      if (error.name !== 'AbortError') {
        setError(error);
      }
      abortControllerRef.current = null;
    }
  };

  return {
    error,
    chargingStations,
    fetchChargingStations,
    loading,
  };
};

export default useChargingStations;
