import spinner from '../../assets/images/ellipsis-3s-90px/Ellipsis-3s-90px.svg';
import Box from '@mui/material/Box';

const Loading = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={spinner} alt="loading" />
  </Box>
);

export default Loading;
