import { ApiTomTomChargingStation, TomTomChargingStation } from '../../types';

export default function transformChargerDetails(
  apiCharger: ApiTomTomChargingStation,
): TomTomChargingStation {
  return {
    ...apiCharger,
    chargingPark: apiCharger.chargingPark
      ? {
          connectors: apiCharger.chargingPark.connectors.map(({ connectorType, ...connector }) => {
            return {
              ...connector,
              type: connectorType,
            };
          }),
        }
      : undefined,
  };
}
