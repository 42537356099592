import { Box, Container, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import useElectricVehicles from '../../api/hooks/useElectricVehicles';
import useGasVehicles from '../../api/hooks/useGasVehicles';
import Card from '../../components/card/Card';
import Loading from '../../components/loading/Loading';
import Page from '../../components/page/Page';
import { useUserPrefs } from '../../context/UserPrefsProvider';
import { formatVehicleDisplayName } from '../../utils/formatters';
import CompareCharts from './components/charts/CompareCharts';
import VehicleColumn from './components/VehicleColumn';

export default function Compare() {
  const { formatMessage } = useIntl();
  const { userPrefs, setUserPrefs } = useUserPrefs();

  const { vehicles: electricVehicles, status: evStatus } = useElectricVehicles();
  const { vehicles: gasVehicles, status: gasStatus } = useGasVehicles();

  const [visibleVehicle, setVisibleVehicle] = useState(1);

  const handleChangeVisibleVehicle = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    val: number,
  ) => {
    if (val !== null) {
      setVisibleVehicle(val);
    }
  };

  if (
    gasStatus === 'loading' ||
    gasStatus === 'error' ||
    evStatus === 'loading' ||
    evStatus === 'error' ||
    !gasVehicles ||
    !electricVehicles
  )
    return <Loading />;

  const allVehicles = [...(electricVehicles || []), ...(gasVehicles || [])].filter(
    (v) => v.images.length > 0,
  );

  const comparedVehicleId1 = userPrefs.comparedVehicleId1 || electricVehicles?.[0]?.handle;
  const comparedVehicleId2 = userPrefs.comparedVehicleId2 || undefined; // gasVehicles?.[0].handle;

  const comparedVehicle1 =
    allVehicles.find((vehicle) => vehicle.handle === comparedVehicleId1) || allVehicles[0];
  const comparedVehicle2 =
    allVehicles.find((vehicle) => vehicle.handle === comparedVehicleId2) || undefined; // allVehicles[1];

  const handleVehicle1Change = (handle?: string) => {
    if (handle) {
      const newVehicle =
        allVehicles.find((vehicle) => handle === comparedVehicleId1) || allVehicles[0];
      setUserPrefs({
        vehicleMakeFilter: newVehicle.make,
        comparedVehicleId1: handle,
      });
    } else {
      setUserPrefs({
        vehicleMakeFilter: undefined,
        comparedVehicleId1: undefined,
      });
    }
  };
  const handleVehicle2Change = (handle?: string) => {
    setUserPrefs({
      comparedVehicleId2: handle,
    });
  };

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pt={6}
        pb={10}
        sx={{
          background: `linear-gradient(to bottom, #F2F2F2 480px,#FFFFFF 480px)`,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h1" textAlign="center" mb={3}>
            {formatMessage({ id: 'compare.header' })}
          </Typography>
          <Typography
            display={{ xs: 'none', sm: 'block' }}
            variant="h3"
            component="h2"
            textAlign="center"
            mb={7}
          >
            {formatMessage({ id: 'compare.subheader' })}
          </Typography>

          <Box display={{ xs: 'flex', sm: 'none' }} mb={2}>
            <ToggleButtonGroup
              value={visibleVehicle}
              exclusive
              fullWidth
              onChange={handleChangeVisibleVehicle}
              aria-label="Toggle visible vehicle"
              sx={{
                '& .MuiToggleButton-root': {
                  fontWeight: 'bold',
                  color: '#000',
                  backgroundColor: 'white.100',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    backgroundColor: 'orange.500',
                  },
                },
              }}
            >
              <ToggleButton value={1} aria-label={formatVehicleDisplayName(comparedVehicle1)}>
                {formatVehicleDisplayName(comparedVehicle1)}
              </ToggleButton>
              <ToggleButton
                value={2}
                aria-label={
                  comparedVehicle2
                    ? formatVehicleDisplayName(comparedVehicle2)
                    : formatMessage({ id: 'compare.alternativeVehicle' })
                }
              >
                {comparedVehicle2
                  ? formatVehicleDisplayName(comparedVehicle2)
                  : formatMessage({ id: 'compare.alternativeVehicle' })}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Grid container spacing={{ sm: '50px', md: '150px' }}>
            <Grid
              item
              xs={12}
              sm={6}
              display={{ xs: visibleVehicle === 1 ? 'block' : 'none', sm: 'block' }}
            >
              <VehicleColumn
                vehicles={allVehicles}
                vehicle={comparedVehicle1}
                onVehicleChange={handleVehicle1Change}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={{ xs: visibleVehicle === 2 ? 'block' : 'none', sm: 'block' }}
            >
              <VehicleColumn
                vehicles={allVehicles}
                vehicle={comparedVehicle2}
                onVehicleChange={handleVehicle2Change}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor="grey.100" py={8}>
        <Container maxWidth="lg">
          <CompareCharts vehicle1={comparedVehicle1} vehicle2={comparedVehicle2} />
          <Box display={{ xs: 'block', sm: 'none' }} mt={2}>
            <Card>
              <Typography fontWeight="bold">
                {formatMessage({ id: 'compare.smallScreenDisclaimer' })}
              </Typography>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
}
