import { useCallback } from 'react';

/**
 * Let's say you have a state object with
 * lots of little children.
 *
 * You may decide that one of those children
 * is such a complicated object that it needs
 * its own useState hook.
 *
 * This will help you do that.
 *
 * -------
 * Example:
 *
 * const [parent, setParentState] = useState({
 *   child1: { a: 1, b: 2 },
 *   child2: { c: 3, d: 4 },
 * });
 *
 * const [child1, setChild1] = useSubState(parent, setParentState, 'child1');
 *
 * Now you can use child1 and setChild1 as you would
 * any other useState hook.
 *
 * -------
 * Bonus:

 * This is a merging setter, so you can do this:
 *
 *   setChild1({ a: 2 });
 *
 * And it will merge the new state with the old.
 *
 * -------
 * Callback form:
 *
 * You can send a callback to the setters from this
 * hook.
 *
 * Example:
 *
 *   setChild1((child1) => ({ a: child1.a + 1 }));
 *
 * Use this form to avoid overwriting intermediate
 * updates.
 */
const useSubState = <T, U extends keyof T>(
  parentState: T,
  setParentState: (state: Function) => void,
  childKey: U,
): [T[U], (stateChanges: Function | Partial<T[U]>) => void] => {
  return [
    parentState[childKey],
    useCallback(
      (stateChanges: Function | Partial<T[U]>) => {
        setParentState((parentState: T) => {
          const state = parentState[childKey];
          const newState: T[U] =
            typeof stateChanges === 'function' ? stateChanges(state) : stateChanges;
          return { ...parentState, [childKey]: { ...state, ...newState } };
        });
      },
      [childKey, setParentState], // fun fact, in most cases, these will stay the same forever
    ),
  ];
};

export default useSubState;
