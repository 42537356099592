import { useState } from 'react';
import { Dealer } from '../../../types';

const getDealer = (dealers: Array<Dealer>, id?: string) => {
  if (!id) return null;
  return (dealers || []).find((dealer) => dealer.handle === id) || null;
};

export default function useSelectedDealer(dealers: Array<Dealer>, initialDealerId?: string) {
  const [selectedDealer, setSelectedDealer] = useState<Dealer | null>(
    getDealer(dealers, initialDealerId),
  );
  const selectDealer = (dealerId: string | null) => {
    const dealer = dealerId ? getDealer(dealers, dealerId) : null;
    setSelectedDealer(dealer);
  };

  const deselectDealers = () => setSelectedDealer(null);

  return { selectedDealer, selectDealer, deselectDealers };
}
