import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { useIntl } from 'react-intl';
import { VehicleTCO } from '../../../../calculations/vehicle/CostOfOwnership/calcTotalCostOfOwnership';
import Card from '../../../../components/card/Card';
import Slider from '../../../../components/slider/Slider';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import { ElectricVehicle, EquivalentFossilVehicle, GasVehicle } from '../../../../types';
import { baseTooltipStyles } from '../../../../utils/chartStyles';
import { formatAsDollars, formatVehicleDisplayName } from '../../../../utils/formatters';
import useChartLegendTooltip from '../../../../utils/hooks/useChartLegendTooltip';

type Props = {
  vehicle1: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle2: ElectricVehicle | GasVehicle | EquivalentFossilVehicle;
  vehicle1Tco: VehicleTCO;
  vehicle2Tco: VehicleTCO;
};

const TOOLTIP_TRANSLATION_IDS = [
  'compare.charts.tooltipVehicles',
  'compare.charts.tooltipElectricity',
  'compare.charts.tooltipGasoline',
  'compare.charts.tooltipMaintenance',
  'compare.charts.tooltipInsurance',
];

export default function TcoChart({ vehicle1, vehicle2, vehicle1Tco, vehicle2Tco }: Props) {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const { tooltipPosition, tooltipTranslationId, isTooltipVisible, onShowTooltip, onHideTooltip } =
    useChartLegendTooltip(TOOLTIP_TRANSLATION_IDS);

  const {
    userPrefs: { monthsOfOwnership },
    setUserPrefs,
  } = useUserPrefs();

  const yearsOfOwnership = monthsOfOwnership / 12;

  const costDelta = vehicle2Tco.summed.total - vehicle1Tco.summed.total;

  const chartOptions: ChartOptions = {
    animation: {
      duration: 0,
    },
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: (value) =>
            `$${Math.floor((typeof value === 'string' ? parseInt(value) : value) / 1000)}k`,
        },
      },
      y: {
        stacked: true,
      },
    },
    layout: {
      padding: {
        right: isLargeScreen ? 75 : 35,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        display: true,
        onHover: (e, legendItem: any) => {
          onShowTooltip(e.x || 0, e.y || 0, legendItem.datasetIndex);
        },
        onLeave: onHideTooltip,
        onClick: () => {},
        labels: {
          boxHeight: 12,
          boxWidth: 12,
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets as any[];
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach((dataset) => {
              sum += dataset.data[ctx.dataIndex];
            });
            return formatAsDollars(sum);
          } else {
            return '';
          }
        },
        align: 'end',
        anchor: 'end',
        color: '#333333',
        font: {
          weight: 700,
        },
      },
      tooltip: {
        ...baseTooltipStyles,
        callbacks: {
          label: (item) => `${item.dataset.label}: ${formatAsDollars(item.raw as number)}`,
        },
      },
    },
  };

  const chartData = {
    labels: [formatVehicleDisplayName(vehicle1), formatVehicleDisplayName(vehicle2)],
    datasets: [
      {
        label: formatMessage({ id: 'compare.charts.vehicleCost' }),
        backgroundColor: '#749D25',
        data: [vehicle1Tco.vehicle.total, vehicle2Tco.vehicle.total],
        barPercentage: 0.6,
      },
      {
        label: formatMessage({ id: 'compare.charts.electricity' }),
        backgroundColor: '#FBBB36',
        data: [vehicle1Tco.electricity.total, vehicle2Tco.electricity.total],
        barPercentage: 0.6,
      },

      {
        label: formatMessage({ id: 'compare.charts.gasoline' }),
        backgroundColor: '#333333',
        data: [vehicle1Tco.gasoline.total, vehicle2Tco.gasoline.total],
        barPercentage: 0.6,
      },

      {
        label: formatMessage({ id: 'compare.charts.maintenance' }),
        backgroundColor: '#005179',
        data: [vehicle1Tco.maintenance.total, vehicle2Tco.maintenance.total],
        barPercentage: 0.6,
      },

      {
        label: formatMessage({ id: 'compare.charts.insurance' }),
        backgroundColor: '#0089C4',
        data: [vehicle1Tco.insurance.total, vehicle2Tco.insurance.total],
        barPercentage: 0.6,
      },
    ],
  };

  return (
    <Card>
      <Box>
        <Typography fontWeight={500} textAlign="center" mb={3}>
          {formatMessage(
            {
              id: costDelta < 0 ? 'compare.charts.expensive' : 'compare.charts.cheap',
            },
            {
              vehicle: formatVehicleDisplayName(vehicle1),
              costDelta: (
                <Box component="span" color="green.500">
                  {formatAsDollars(Math.abs(costDelta))}
                </Box>
              ),
              ownershipYears: yearsOfOwnership,
            },
          )}
        </Typography>

        <Box position="relative">
          <ReactChart type="bar" data={chartData} height={220} options={chartOptions} />
          <Box
            display={isTooltipVisible ? 'block' : 'none'}
            position="absolute"
            bgcolor="white.100"
            color="grey.800"
            zIndex="100"
            maxWidth="277px"
            sx={{
              left: tooltipPosition.x,
              top: tooltipPosition.y,
              fontSize: '0.75rem',
              borderRadius: '4px',
              border: '2px solid',
              borderColor: 'grey.200',
              padding: '6px',
            }}
          >
            {formatMessage({ id: tooltipTranslationId })}
          </Box>
        </Box>

        <Box mt={2} width={{ xs: '100%', md: 570 }} ml="auto" mr={10}>
          <Slider
            label={formatMessage({ id: 'compare.filters.yearsOfOwnership' })}
            value={monthsOfOwnership}
            valueSuffix={' ' + formatMessage({ id: 'common.years' })}
            valueFormatter={(months) => `${Math.floor(months / 12)}`}
            onChange={(val) => setUserPrefs({ monthsOfOwnership: val })}
            valueVariant="small"
            min={12}
            max={240}
            step={12}
          />
        </Box>
      </Box>
    </Card>
  );
}
