import { Box } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../button/Button';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Modal from '../modal/Modal';
import TextArea from '../textArea/TextArea';
import type { FormData } from './types';
import useFeedback from './useFeedback';

export default function FeedbackModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): JSX.Element {
  const { formatMessage } = useIntl();

  const [formData, _setFormData] = useState<FormData>({
    whatIsGoal: null,
    didYouAchieveGoal: null,
    howWouldYouImprove: null,
  });
  const { isSubmittable, postFeedback } = useFeedback(formData);

  const setFormData = <T extends keyof FormData>(field: T, value: FormData[T]) => {
    _setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <Modal
      title={formatMessage({ id: 'feedback.title' })}
      open={open}
      onClose={() => onClose()}
      actions={
        <Button
          disabled={!isSubmittable}
          onClick={() => {
            postFeedback();
            onClose();
          }}
        >
          {formatMessage({ id: 'feedback.submit' })}
        </Button>
      }
    >
      <Box minWidth="350px" display="flex" flexDirection="column" gap="1rem">
        <TextArea
          label={formatMessage({ id: 'feedback.whatIsGoal' })}
          aria-label={formatMessage({ id: 'feedback.whatIsGoal' })}
          value={formData.whatIsGoal || ''}
          minRows={2}
          onChange={(value) => setFormData('whatIsGoal', value)}
        />
        <ButtonGroup
          label={formatMessage({ id: 'feedback.didYouAchieveGoal' })}
          value={
            formData.didYouAchieveGoal === true
              ? 'yes'
              : formData.didYouAchieveGoal === false
              ? 'no'
              : ''
          }
          options={[
            {
              label: formatMessage({ id: 'feedback.yes' }),
              value: 'yes',
            },
            {
              label: formatMessage({ id: 'feedback.no' }),
              value: 'no',
            },
          ]}
          onClick={(value) => setFormData('didYouAchieveGoal', value === 'yes')}
        />
        <TextArea
          label={formatMessage({ id: 'feedback.howWouldYouImprove' })}
          value={formData.howWouldYouImprove || ''}
          minRows={4}
          onChange={(value) => setFormData('howWouldYouImprove', value)}
        />
      </Box>
    </Modal>
  );
}
