import type {
  ElectricVehicle,
  EquivalentFossilVehicle,
  GasVehicle,
  PurchaseMethod,
} from '../../../types';
import isElectricVehicle from '../isElectricVehicle';
import * as Calcs from './calcs';

const Private = {
  perYear: function (costPerMile: number, milesDrivenAnnually: number) {
    if (!costPerMile || !milesDrivenAnnually) return 0;
    return costPerMile * milesDrivenAnnually;
  },

  perMonth: function (costPerMile: number, milesDrivenAnnually: number) {
    if (!costPerMile || !milesDrivenAnnually) return 0;
    return this.perYear(costPerMile, milesDrivenAnnually) / 12;
  },

  total: function (costPerMile: number, milesDrivenAnnually: number, monthsOfOwnership: number) {
    if (!costPerMile || !milesDrivenAnnually || !monthsOfOwnership) return 0;
    return this.perMonth(costPerMile, milesDrivenAnnually) * monthsOfOwnership;
  },
};

// vehicle could be electric or gas
const calcTotalCostOfOwnership = (
  vehicle: EquivalentFossilVehicle | GasVehicle | ElectricVehicle,
  purchaseMethod: PurchaseMethod,
  monthsOfOwnership: number,
  milesDrivenAnnually: number,
  interestRateAsBasisPoints: number,
  electricMilesPortionForPhev: number,
  gasolinePriceInCentsPerGal: number,
  includeResaleValue?: boolean,
) => {
  const vehicleCostPerMile = Calcs.VehicleCost.perMile(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue,
  );
  const electricityPerMile = isElectricVehicle(vehicle)
    ? Calcs.ElectricityCost.perMile(vehicle, electricMilesPortionForPhev)
    : 0;
  const gasPerMile = Calcs.GasolineCost.perMile(
    vehicle,
    electricMilesPortionForPhev,
    gasolinePriceInCentsPerGal,
  );
  const maintenancePerMile = Calcs.MaintenanceCost.perMile(vehicle, electricMilesPortionForPhev);
  const insurancePerMile = Calcs.InsuranceCost.perMile(vehicle, milesDrivenAnnually);
  const summedPerMile =
    vehicleCostPerMile + electricityPerMile + gasPerMile + maintenancePerMile + insurancePerMile;

  return {
    vehicle: {
      perMile: vehicleCostPerMile,
      perMonth: Private.perMonth(vehicleCostPerMile, milesDrivenAnnually),
      perYear: Private.perYear(vehicleCostPerMile, milesDrivenAnnually),
      total: Private.total(vehicleCostPerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    electricity: {
      perMile: electricityPerMile,
      perMonth: Private.perMonth(electricityPerMile, milesDrivenAnnually),
      perYear: Private.perYear(electricityPerMile, milesDrivenAnnually),
      total: Private.total(electricityPerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    gasoline: {
      perMile: gasPerMile,
      perMonth: Private.perMonth(gasPerMile, milesDrivenAnnually),
      perYear: Private.perYear(gasPerMile, milesDrivenAnnually),
      total: Private.total(gasPerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    maintenance: {
      perMile: maintenancePerMile,
      perMonth: Private.perMonth(maintenancePerMile, milesDrivenAnnually),
      perYear: Private.perYear(maintenancePerMile, milesDrivenAnnually),
      total: Private.total(maintenancePerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    insurance: {
      perMile: insurancePerMile,
      perMonth: Private.perMonth(insurancePerMile, milesDrivenAnnually),
      perYear: Private.perYear(insurancePerMile, milesDrivenAnnually),
      total: Private.total(insurancePerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    summed: {
      perMile: summedPerMile,
      perMonth: Private.perMonth(summedPerMile, milesDrivenAnnually),
      perYear: Private.perYear(summedPerMile, milesDrivenAnnually),
      total: Private.total(summedPerMile, milesDrivenAnnually, monthsOfOwnership),
    },
  };
};

export type VehicleTCO = ReturnType<typeof calcTotalCostOfOwnership>;

export default calcTotalCostOfOwnership;
