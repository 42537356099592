import {
  AVERAGE_MSRP,
  INSURANCE_COST_SCALING_FACTOR,
  AVERAGE_INSURANCE_COST_ANNUALLY,
} from '../../../../data/assumptions/ASSUMPTIONS';
import { Vehicle } from '../../../../types';

const InsuranceCost = {
  perYear: function (vehicle: Vehicle): number {
    return (
      AVERAGE_INSURANCE_COST_ANNUALLY.value *
      (1 + INSURANCE_COST_SCALING_FACTOR.value * (vehicle.msrp / AVERAGE_MSRP.value - 1))
    );
  },

  perMile: function (vehicle: Vehicle, milesDrivenAnnually: number): number {
    const cost = this.perYear(vehicle) / milesDrivenAnnually;

    return isFinite(cost) ? cost : 0;
  },
};

export default InsuranceCost;
