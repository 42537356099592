import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonMultiSelect from '../../../../../components/buttonMultiSelect/ButtonMultiSelect';
import { E_1, E_TOU_C, E_TOU_D } from '../../../../../constants/rates';
import { useUserPrefs } from '../../../../../context/UserPrefsProvider';
import { isRateKey } from '../../../../../types';

export default function ElectricRateStep(): JSX.Element {
  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();

  return (
    <>
      <Box>
        <Typography variant="h5" textAlign="center" id="modal-title">
          {formatMessage({ id: 'rates.wizard.rate.electricRateTitle' })}
        </Typography>
        <Typography textAlign="center">
          {formatMessage({ id: 'rates.wizard.rate.electricRateHelper' })}
        </Typography>
      </Box>
      <Box>
        <ButtonMultiSelect
          label=""
          selected={[userPrefs.currentElectricRate]}
          onChange={(value) =>
            isRateKey(value[1]) && setUserPrefs({ currentElectricRate: value[1] })
          }
          options={[
            {
              label: E_1,
              value: E_1,
            },
            {
              label: E_TOU_C,
              value: E_TOU_C,
            },
            {
              label: E_TOU_D,
              value: E_TOU_D,
            },
          ]}
        />
      </Box>
    </>
  );
}
