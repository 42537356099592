import { Box, InputLabel, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import Button from '../button/Button';

type Option = {
  label: ReactNode;
  value: string;
};

type Props = {
  label: string;
  options: Array<Option>;
  selected: Array<string>;
  onChange: (selected: Array<string>) => void;
};

export default function ButtonMultiSelect({ label, options, selected, onChange }: Props) {
  return (
    <Box>
      <InputLabel
        sx={{
          position: 'relative',
          mb: 1,
        }}
      >
        <Typography variant="label1">{label}</Typography>
      </InputLabel>
      <Box role="group" display="flex" flexWrap="wrap" gap="8px">
        {options.map((option) => {
          const checked = selected.includes(option.value);

          return (
            <Box key={option.value} minWidth="48%" flex="1 0 auto">
              <Button
                fullWidth
                onClick={() => {
                  checked
                    ? onChange(selected.filter((value) => value !== option.value))
                    : onChange([...selected, option.value]);
                }}
                variant={checked ? 'contained' : 'outlined'}
              >
                {option.label}
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
